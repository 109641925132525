@import '../../../../assets/styles/variables.scss';

.card-users-tabs-organization {
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .card-users-tabs__loading {
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    span {
      font: $regular-s-font;
      color: $black;

      &.anticon {
        font: $regular-xl-font;
      }
    }
  }

  .card-users-tabs__header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .header__search {
      width: 200px;
      display: flex;
      align-items: center;
      position: relative;

      input {
        width: 200px;
        height: 32px;
        padding: 8px 41px 8px 15px;
        background-color: $cool-gray;
        border: 1px solid $cool-gray;
        border-radius: 5px;
        transition: 0.3s;
        font: $regular-s-font;
        color: $dark-gray;

        &:hover,
        &:focus {
          border-color: $dark-mint;
        }
      }

      .search__icon {
        position: absolute;
        right: 15px;

        i {
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .header__button {
      display: flex;
      justify-content: center;
      align-content: center;
      gap: 10px;

      .button__matrix {
        display: flex;
        gap: 5px;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        i {
          img {
            width: 16px;
            height: 16px;
          }
        }

        span {
          font: $regular-s-font;
          color: $dark-mint;
        }
      }
    }
  }

  .card-users-tabs__table {
    width: 100%;

    .avatar-column {
      padding: 0 10px;

      .avatar-image-column,
      .avatar-name-column,
      .avatar-icon-column {
        width: 24px;
        height: 24px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .avatar-name-column {
        background-color: $black;
        color: $white;
        font: $regular-xs-font;
        text-transform: uppercase;
      }

      .avatar-icon-column {
        i {
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .name-column,
    .surname-column,
    .email-column,
    .position-column,
    .roles-column,
    .status-column,
    .last-active-column {
      font: $regular-s-font;
      color: $black;
      padding: 0 10px;
    }

    .position-column-input,
    .name-column-input,
    .surname-column-input {
      width: 100%;
      height: 40px;
      border: 1px solid transparent;
      background-color: transparent;
      transition: 0.3s;
      padding: 0 10px;
      cursor: pointer;

      &:hover {
        border-color: $dark-gray;
      }

      &:focus {
        border-color: $dark-mint;
      }
    }

    .projects-column {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      border: 1px solid transparent;
      transition: 0.3s;
      padding: 0 10px;

      span {
        font: $regular-s-font;
        color: $black;
      }

      i {
        img {
          width: 14px;
          height: 14px;
        }
      }
    }

    .company-column-popover,
    .projects-column-popover {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid transparent;
      cursor: pointer;
      transition: 0.3s;
      padding: 0 10px;

      &:hover {
        border-color: $dark-gray;
      }

      &.ant-popover-open {
        border-color: $dark-mint;
      }

      span {
        font: $regular-s-font;
        color: $black;
      }

      i {
        img {
          width: 14px;
          height: 14px;
        }
      }
    }

    .company-column-popover__label {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 10px;

      span {
        font: $regular-s-font;
        color: $black;
      }
    }

    .roles-column {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .roles-column__icon {
        cursor: pointer;

        i {
          img {
            width: 14px;
            height: 14px;
          }
        }
      }
    }

    .roles-column-popover {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid transparent;
      cursor: pointer;
      transition: 0.3s;
      padding: 0 10px;

      &:hover {
        border-color: $dark-gray;
      }

      &.ant-popover-open {
        border-color: $dark-mint;
      }

      span {
        font: $regular-s-font;
        color: $black;
      }

      i {
        img {
          width: 14px;
          height: 14px;
        }
      }
    }

    .last-active-column {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;

      .last-active__icon {
        cursor: pointer;

        i {
          img {
            width: 14px;
            height: 14px;
          }
        }
      }
    }

    .action-column {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;

      span {
        font: $regular-s-font;
        color: $red;
        cursor: pointer;
      }

      .action-column__icon {
        width: 100%;
        display: flex;
        justify-content: center;
        cursor: pointer;

        i {
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
