@import '../../../assets/styles/variables.scss';

.duplicate-stage__content {
  display: flex;
  flex-direction: column;

  .content__form {
    display: flex;
    flex-direction: column;

    .form__label {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: $white;
      margin-bottom: 10px;
    }

    input {
      height: 24px;
      border-radius: 5px;
      border: 0;
      padding: 0 10px;
      background-color: $dark-green;
      color: $white;
      font-size: 12px;
      line-height: 14px;
      font-weight: 300;
    }

    .form__select {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .select__label {
        display: flex;
        flex-direction: column;

        label {
          font-size: 12px;
          line-height: 14px;
          font-weight: 400;
          color: $white;
          margin-bottom: 5px;
        }

        span {
          font-size: 12px;
          line-height: 14px;
          font-weight: 300;
          color: $white;
        }
      }
    }

    .form__customize {
      margin-top: 20px;
      border: 1px solid $border-color-check;
      padding: 16px;
      border-radius: 6px;

      .customize__type {
        display: flex;
        flex-direction: column;

        .type__title {
          display: flex;
          align-items: center;
          margin-bottom: 18px;

          h6 {
            margin: 0;
            font-size: 12px;
            line-height: 14px;
            font-weight: 400;
            color: $white;
          }

          div {
            margin-left: 12px;

            i {
              img {
                display: flex;
              }
            }
          }
        }

        .type__options {
          display: grid;
          grid-template-rows: auto;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 12px;
          padding: 0 28px;
        }

        .type__line {
          margin: 0 28px 16px;
          padding-bottom: 16px;
          border-bottom: 1px solid $border-color-check;
        }
      }
    }
  }

  .content__buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    button {
      height: 32px;
      border: 0;
      border-radius: 5px;
      padding: 0 10px;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      transition: 0.3s;

      &.buttons__cancel {
        background-color: $cool-gray;
        color: $black;
      }

      &.buttons__save {
        background-color: $primary;
        color: $black;
        margin-left: 8px;

        &:disabled {
          opacity: $disabled-opacity;
          cursor: no-drop;
        }
      }
    }
  }
}
