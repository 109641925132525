@import '../UiKit/UiKit.scss';
@import '../../../assets/styles/variables.scss';

.check-design-system {
  display: flex;
  align-items: center;
  @include web-kit-user-select();

  .check-design-system__input {
    flex: 0 0 12px;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    background-color: transparent;
    border: 2px solid $bed-rock;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &.disabled {
      opacity: $disabled-opacity;
    }
  }

  .check-design-system__label {
    font: $regular-s-font !important;
    margin-left: 12px;
    line-height: 14px;
    color: $white;
    cursor: pointer;

    &.disabled {
      opacity: $disabled-opacity;
    }
  }
}
