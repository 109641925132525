@import '../../../stylesheets/base';
@import '../../../assets/styles/variables.scss';

.activity-log-panel {
  background-color: $black;
  border: 1px solid $black;
  border-radius: $default-border-radius;
  color: $white;
  padding: 20px;
  font-size: $font-xs;

  ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  em {
    display: inline-block;
    width: 100px;
    border-right: 1px solid $white;
    line-height: 28px;
    text-align: right;
    font-style: normal;
    padding-right: 15px;
    margin-right: 15px;
    position: relative;
  }

  &__title {
    i {
      margin-right: 8px;
    }

    color: $white;
    font-size: $font-xs;
    border-bottom: 1px solid $white;
    padding-bottom: 10px;
  }

  &__content {
    overflow: auto;
    height: 185px;
    margin-top: 15px;

    > ol > li > em {
      color: $gray-30;
      font-weight: 500;
    }
  }

  &__time-entries {
    em::before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $white;
      position: absolute;
      right: -5px;
      top: 8px;
    }
  }

  &__time-entry span {
    display: inline-block;
    margin-right: 4px;
  }

  &__user {
    color: $primary;
  }
}
