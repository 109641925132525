@import '../../stylesheets/base';

.hovering-portal {
  &__drawer {
    position: fixed;
    z-index: 100;

    &--right,
    &--left {
      top: $app-toolbar-height;
      bottom: 0;
    }

    &--right {
      right: 0;
    }

    &--left {
      left: 0;
    }

    &--bottom {
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__item {
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }

  &__mask {
    position: fixed;
    inset: 0;
    background-color: $overlay-color;
    pointer-events: initial;
  }
}
