.progress-bar-custom-input .ant-input-number-input {
  padding-bottom: 8px !important;
}

.card-progress-bar .range-fixed-card {
  position: relative !important;
  top: 12px;
  width: 100%;
}

.card-progress-bar .custom-input-number-card {
  float: right;
  border: 0px solid #d9d9d9 !important;
  left: 0%;
  height: 28px !important;
  font-size: 12px !important;
  top: 0px;
}

.card-progress .ant-input-number-handler-wrap {
  display: none;
}

.card-progress .progress-card-input {
  width: 50px;
}
