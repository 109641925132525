@import '../../stylesheets/base';

.toolbar-group {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

%common-division {
  height: 32px;
  border-radius: 0;
  vertical-align: top;

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:not(:last-child) {
    margin-right: 2px;
  }
}

.toolbar-group__division {
  > * {
    @extend %common-division;
  }

  &:not(:last-child) {
    margin-right: 5px;
  }
}

.toolbar-massive-group__division {
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    @extend %common-division;
    height: 26px !important;
  }
}
