.help-center {
  &__container {
    position: relative;
  }

  &__search-section {
    position: absolute;
    top: 110px;
    width: 100%;
    z-index: 1;
  }

  &__resources-section {
    position: absolute;
    top: 450px;
    width: 100%;
    padding-bottom: 20px;
  }
}
