@import '../../../assets/styles/variables.scss';

.popover-company-design-system {
  min-width: 180px;
  padding: 10px 0;

  .popover-company-design-system__search {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 20px;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    input {
      width: 100%;
      background-color: transparent;
      font: $regular-s-font;
      border: 0;
      height: 16px;
    }

    .search__clean {
      flex: 16px 1 0;
      width: 16px;
      cursor: pointer;

      i {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    .search__icon {
      flex: 16px 1 0;
      width: 16px;

      i {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .popover-company-design-system__items {
    width: 100%;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $black;
    }

    .items__empty {
      width: 100%;
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 10px;

      .empty__icon {
        width: 24px;

        i {
          img {
            width: 24px;
            height: 24px;
          }
        }
      }

      span {
        font: $regular-s-font;
      }
    }

    .items__list {
      display: flex;
      flex-direction: column;

      .list__empty {
        width: 100%;
        height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 10px;

        .empty__icon {
          width: 24px;

          i {
            img {
              width: 24px;
              height: 24px;
            }
          }
        }

        span {
          font: $regular-s-font;
        }
      }

      .list__select {
        width: 100%;
        height: 30px;
        display: flex;
        padding: 0 20px;
        transition-duration: 0.3s;
        align-items: center;
        cursor: pointer;

        .select__checked {
          width: 14px;
          height: 14px;
          margin-right: 15px;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            img {
              width: 14px;
              height: 14px;
            }
          }

          .checked__empty {
            width: 14px;
            height: 14px;
            border-radius: 50%;
          }
        }

        span {
          font: $regular-s-font;
          margin-left: 23px;
        }
      }

      .list__item {
        width: 100%;
        height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 20px;
        transition-duration: 0.3s;
        cursor: pointer;

        &.disabled {
          opacity: $disabled-opacity;
          cursor: not-allowed;
        }

        .item__text {
          display: flex;
          justify-content: space-between;

          .text {
            display: flex;
            align-items: center;

            .text__check {
              width: 14px;
              height: 14px;
              margin-right: 15px;
              display: flex;
              justify-content: center;
              align-items: center;

              i {
                img {
                  width: 14px;
                  height: 14px;
                }
              }

              .check__empty {
                width: 14px;
                height: 14px;
                border-radius: 50%;
                border: 2px solid $gray-2;
              }
            }

            .text__color {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              margin-right: 7px;
            }

            span {
              font: $regular-s-font;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .popover-company-design-system__cta {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 0 10px;
    border-top-width: 1px;
    border-top-style: solid;
    transition-duration: 0.3s;

    .cta__icon {
      width: 16px;

      i {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    span {
      font: $regular-s-font;
    }
  }

  &.theme--light {
    .popover-company-design-system__search {
      border-bottom-color: $gray-2;

      input {
        color: $black;

        &::placeholder {
          color: $black;
          opacity: $disabled-opacity;
        }

        &::-ms-input-placeholder {
          color: $black;
        }
      }
    }

    .popover-company-design-system__items {
      &::-webkit-scrollbar-thumb {
        background: $gray-2;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $dark-mint;
      }

      .items__empty {
        span {
          color: $dark-gray;
        }
      }

      .items__list {
        .list__empty {
          span {
            color: $dark-gray;
          }
        }

        .list__select {
          &:hover {
            background-color: $cool-gray;
          }

          .select__checked {
            .checked__empty {
              border: 2px solid $gray-2;
            }
          }
          span {
            color: $black;
          }
        }

        .list__item {
          &:hover {
            background-color: $cool-gray;
          }

          .item__text {
            .text {
              .text__check {
                .check__empty {
                  border: 2px solid $gray-2;
                }
              }

              span {
                color: $black;
              }
            }
          }
        }
      }
    }

    .popover-company-design-system__cta {
      border-top-color: $gray-2;

      &:hover {
        background-color: $cool-gray;
      }

      span {
        color: $dark-mint;
      }
    }
  }

  &.theme--dark {
    .popover-company-design-system__search {
      border-bottom-color: $bed-rock;

      input {
        color: $white;

        &::placeholder {
          color: $white;
          opacity: $disabled-opacity;
        }

        &::-ms-input-placeholder {
          color: $white;
        }
      }
    }

    .popover-company-design-system__items {
      &::-webkit-scrollbar-thumb {
        background: $bed-rock;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $primary;
      }

      .items__empty {
        span {
          color: $clear-bed-rock;
        }
      }

      .items__list {
        .list__empty {
          span {
            color: $clear-bed-rock;
          }
        }

        .list__select {
          &:hover {
            background-color: $black;
          }

          .select__checked {
            .checked__empty {
              border: 2px solid $bed-rock;
            }
          }
          span {
            color: $white;
          }
        }

        .list__item {
          &:hover {
            background-color: $black;
          }

          .item__text {
            .text {
              .text__check {
                .check__empty {
                  border: 2px solid $bed-rock;
                }
              }

              span {
                color: $white;
              }
            }
          }
        }
      }
    }

    .popover-company-design-system__cta {
      border-top-color: $bed-rock;

      &:hover {
        background-color: $black;
      }

      span {
        color: $primary;
      }
    }
  }
}
