.wrapper-unselect {
  display: inline-flex;
  height: 32px;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  color: #fff;
}

.wrapper-unselect button {
  height: 24px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 10px;
}

.info-text {
  font-size: 11px;
}

.label-info {
  display: block;
  margin: 0 10px 0 0px;
  font-weight: 400;
  line-height: 14.06px;
  font-size: 13px;
}

.info-text span:nth-child(2) {
  font-weight: bold;
}

.label-info span {
  color: var(--primary);
  margin-right: 2px;
}
