/**
 * ====================
 *  ResourcesCheckbox
 * ===================
 * */
.imgContainer {
  position: relative;
  top: 1px;
}

.itemContent {
  margin-left: 10px;
}

.toggleChilds {
  cursor: pointer;
}

/**
 * ==================
 *  Resource Content
 * ==================
 * */
.itemContent {
  font-size: 12px;
}

.mainItem {
  font-size: 14px;
  color: var(--primary);
}

.resourceName {
  display: flex;
  justify-content: space-between;
}

/**
 * ==================
 *  List Assosiations
 * ==================
 * */
.listAssosiationsList {
  padding-left: 20px;
  margin-top: 10px;
  font-size: 11px;
}

.listAssosiationsItem {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
}
