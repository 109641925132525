.calendarDate {
  display: flex;
  justify-content: center;
  user-select: none;
}

.calendarDate span {
  text-align: center;
}

.calendarDate span p {
  margin-bottom: 8px;
}

.calendarIcon {
  font-size: 20px;

  margin-left: 20px;
  cursor: pointer;
}

.orangeColor {
  color: var(--primary);
}

.spaceRigth {
  margin-right: 10px;
}

.blueColor {
  color: #1890ff !important;
}

.dateWithAssign {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dateWithAssign::after {
  display: block;
  margin: -5px auto;
  content: '';
  width: 4px;
  height: 4px;
  background-color: var(--primary);
  border-radius: 50%;
}
