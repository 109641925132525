@import '../../../../../stylesheets/base';

.tab__dependencies {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  padding: 0 20px 25px;

  .dependencies__predecessors {
    grid-row: 1/1;
    grid-column: 1/2;
  }

  .dependencies__successors {
    grid-row: 1/1;
    grid-column: 2/3;
  }

  .dependencies__title {
    margin-bottom: 20px;

    h6 {
      font-size: $font-xs;
      line-height: 14px;
      color: $white;
      font-weight: 400;
      margin: 0;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
