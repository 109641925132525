@import '../../../assets/styles/variables.scss';

.super-admin-cell {
  display: flex;
  gap: 5px;

  .tooltip-icon img {
    width: 14px;
    height: 14px;
  }
}

.tableWrapper {
  width: 100%;
  overflow: auto;
}

.cell {
  font: $regular-s-font;
  color: $white;
  padding: 0 10px;
  text-align: left;
}

.permissions-block {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.permissions-legend {
  margin-top: 20px;
  border: 1px solid $bed-rock;
  padding: 10px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  color: white;

  div {
    display: flex;
    gap: 10px;
  }

  p {
    margin-bottom: 0px;
    font-size: 13px;
  }

  .permission-item {
    display: flex;
    flex-direction: column;

    &:nth-child(2) {
      margin-left: 10px;
    }
  }
}
