.editable-input-icon:hover {
  transform: scale(1.3);
  color: #2c3421;
}

.save-input-icon:hover {
  transform: scale(1.3);
  color: #2c3421;
}

.responsable:hover {
  transform: scale(1.5);
  color: #2c3421;
}

.gantt-visualization .open-card-class {
  width: 95%;
  display: inline-block;
}

.lookahead-table-view .open-card-class {
  width: 70% !important;
  display: inline-block;
}
