/* CARDS RIGHT CATEGORY */
.grid-cards-category {
  display: grid;
  grid-template-columns: 35% 5% 30% 30%;
  margin: 2px;
  font-size: 12px;
  color: #121212;
}

.grid-cards-category .cat-actions {
  text-align: right;
  line-height: 27px;
}

.cat-icon-select {
  line-height: 27px;
  float: left;
  margin-right: 5px;
}

.grid-cards-category .cat-icon {
  width: 20px;
  line-height: 27px;
}

.grid-cards-category .cat-square {
  display: flex;
  align-items: center;
}

.grid-cards-category .cat-name {
  padding: 0 10px;
  line-height: 30px;
}

.grid-cards-category .cat-name .input-inline {
  border: none !important;
  width: 50% !important;
  padding: 0 !important;
  height: auto !important;
}

.grid-cards-category .cat-type .ant-select-selection-selected-value {
  overflow: initial;
}

.grid-cards-category .cat-type .ant-select-selection--single {
  height: auto !important;
  color: #2c3421 !important;
  text-decoration: underline !important;
}

.grid-cards-category .square-tag {
  color: red;
  font-size: 20px;
}

.card-category {
  border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  margin: 0 5px 5px 5px !important;
}

.scroll-table {
  overflow: scroll;
  height: 160px;
  width: 100%;
  overflow-x: hidden;
}

.scroll-table::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.scroll-table::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scroll-table::-webkit-scrollbar-thumb {
  background: #d6d0d0;
}

/* Handle on hover */
.scroll-table::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.grid-category-task {
  display: grid;
  grid-template-columns: 80% 20%;
}

.grid-category-delete {
  display: grid;
  grid-template-columns: 90% 10%;
}

.constraint-right {
  margin-top: 0;
}

.cnc-right {
  margin-top: 20px;
}

.tag-right {
  margin-top: 20px;
}

.txt-category {
  font-size: 11px;
  margin-left: 10px;
}

.position-btn-add-right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.btn-agregar-right {
  width: auto;
  font-size: 12px !important;
  background-color: var(--primary) !important;
  color: var(--black) !important;
  margin-top: 35px;
  margin: 5px;
}

.btn-agregar-right:hover {
  background-color: var(--primary) !important;
  outline: none;
  box-shadow: none;
  border-color: 1px solid var(--primary);
}

.cat-square .flexbox-fix {
  display: none !important;
}

.picker-wrapp {
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius-topleft: 5px !important;
}
