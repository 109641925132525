.overlay-tooltip-task-dropdown .ant-tooltip-content {
  width: 260px;
}

.overlay-tooltip-task-dropdown .ant-tooltip-content .ant-tooltip-inner {
  background-color: var(--dark-green-sec);
  color: var(--white);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.overlay-tooltip-task-dropdown .ant-tooltip-content .ant-tooltip-arrow::before {
  background-color: var(--dark-green-sec);
}

.overlay-tooltip-task-dropdown
  .ant-tooltip-content
  .ant-tooltip-inner
  .content-tooltip-disabled {
  display: flex;
  flex-direction: column;
}

.overlay-tooltip-task-dropdown
  .ant-tooltip-content
  .ant-tooltip-inner
  .content-tooltip-disabled
  .content-tooltip-disabled__header
  span {
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  color: var(--white);
}

.overlay-tooltip-task-dropdown
  .ant-tooltip-content
  .ant-tooltip-inner
  .content-tooltip-disabled
  .content-tooltip-disabled__footer {
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.overlay-tooltip-task-dropdown
  .ant-tooltip-content
  .ant-tooltip-inner
  .content-tooltip-disabled
  .content-tooltip-disabled__footer
  .footer__icon {
  margin-right: 10px;
}

.overlay-tooltip-task-dropdown
  .ant-tooltip-content
  .ant-tooltip-inner
  .content-tooltip-disabled
  .content-tooltip-disabled__footer
  .footer__icon
  i
  img {
  width: 20px;
}

.overlay-tooltip-task-dropdown
  .ant-tooltip-content
  .ant-tooltip-inner
  .content-tooltip-disabled
  .content-tooltip-disabled__footer
  span {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: #f6c29b;
}

.popover-task-content {
  width: 100%;
  display: flex;
  position: relative;
  cursor: pointer;
}

.popover-task-content .content__arrow {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  transition-duration: 0.3s;
  visibility: hidden;
}

.popover-task-content .content__arrow.open,
.popover-task-content.ant-popover-open .content__arrow {
  visibility: visible;
  opacity: 1;
}

.popover-task-content .content__arrow i img {
  width: 10px;
}

.dropdown-popover-task .task__menu div::-webkit-scrollbar {
  width: 4px;
  height: 36px;
}

.dropdown-popover-task .task__menu div::-webkit-scrollbar-thumb {
  background-color: var(--gray);
  border-radius: 3px;
}

.dropdown-popover-task .task__menu div::-webkit-scrollbar-track {
  background-color: var(--black);
  border-radius: 3px;
}

.dropdown-popover-task .task__menu {
  padding: 10px 10px 10px 0;
}

.dropdown-popover-task .task__menu .menu__item {
  height: 30px;
  cursor: pointer;
}

.dropdown-popover-task .task__menu .menu__item:hover {
  background-color: var(--black);
}

.dropdown-popover-task .task__menu .menu__item.active {
  background-color: var(--primary);
}

.dropdown-popover-task .task__menu .menu__item.active span {
  color: var(--black);
}

.dropdown-popover-task .task__menu .menu__item.disabled .tooltip-task-content {
  color: var(--gray);
}

.dropdown-popover-task .task__menu .menu__item .tooltip-task-content {
  display: flex;
  align-items: center;
  height: 30px;
  color: var(--white);
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
}

.dropdown-popover-task
  .task__menu
  .menu__item
  .tooltip-task-content
  .content-icon {
  margin-right: 4px;
}

.dropdown-popover-task
  .task__menu
  .menu__item
  .tooltip-task-content
  .content-icon
  i
  img {
  width: 10px;
}

.dropdown-popover-task
  .task__menu
  .menu__item
  .tooltip-task-content
  .content-icon.rotate {
  transform: rotate(180deg);
}

.dropdown-popover-task
  .task__menu
  .menu__item
  .tooltip-task-content
  .content-icon
  .icon-line {
  width: 10px;
  height: 2px;
  border-radius: 10px;
  background-color: var(--white);
}

.overlay-task-dropdown-popover .ant-popover-content .ant-popover-arrow {
  border-right-color: var(--dark-green-sec);
  border-bottom-color: var(--dark-green-sec);
}

.overlay-task-dropdown-popover .ant-popover-content .ant-popover-inner {
  background-color: var(--dark-green-sec);
  border-radius: 5px;
}

.overlay-task-dropdown-popover
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content {
  padding: 0;
}

.overlay-task-dropdown-popover
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .dropdown-popover-task {
  width: 273px;
}
