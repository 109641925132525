.constraint-empty__container {
  width: 116px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.constraint-empty__container i {
  width: 44px;
  margin-bottom: 14px;
}

.constraint-empty__container i img {
  width: 100%;
}

.constraint-empty__container h5 {
  margin: 0;
  font-size: 10px;
  line-height: 12px;
  font-weight: 300;
  color: var(--grey-dark-color);
}
