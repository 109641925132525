@import '../../assets/styles/variables.scss';

.responsible-list {
  display: flex;
  color: $white;
  gap: 7px;

  img {
    height: 16px;
    width: 16px;
    border-radius: 100%;
    display: block;
    margin-top: 2px;
  }

  .one-responsible {
    display: flex;
    flex-direction: row;
    gap: 7px;
  }

  .responsible-name {
    color: $white;
    width: 150px;
  }

  .many-responsibles {
    display: grid;
    position: relative;
    grid-template-columns: repeat(3, 12px);

    .image0 {
      grid-row: 1;
      z-index: 2;
    }

    .image1 {
      grid-column: 2 / -1;
      grid-row: 1;
      z-index: 1;
    }

    .image2 {
      grid-column: 3 / -1;
      grid-row: 1;
    }
  }
}
