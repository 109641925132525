.container {
  width: 100%;
  margin-right: 10px;

  & input {
    width: inherit;
    height: inherit;
    padding: 8px 10px;

    border: none;
    background-color: #f5f5f5;
    border-radius: 5px;

    &::placeholder {
      color: #121212;
      font-size: 12px;
      font-weight: 300;
    }
  }
}

.input-container {
  position: relative;
  width: inherit;
  height: 32px;
}

.icon-position {
  position: absolute;
  right: 10px;

  display: inline-flex !important;
  height: inherit;
  align-items: center;
}
