.commitment__row {
  margin-bottom: 20px;

  display: grid;
  grid-template-columns: 2.7fr 1.5fr 1fr;
  grid-column-gap: 5px;

  box-sizing: border-box;
  border-radius: 5px 0px 0px 0px;
}

.activity__container {
  border-radius: 5px 0px 0px 0px;
}

.rfv__container {
  border-radius: 0px 5px 0px 0px;
}

.activity__container,
.timeline__container,
.rfv__container {
  border: 0.5px solid #efefef;
}

.activity {
  display: flex;
  align-items: center;
  padding: 7px;

  width: 100%;
  height: 30px;

  background-color: #f5f5f5cc;
  background-color: #d1d1d1;

  & > span {
    margin-left: 5px;
    color: #121212;
    font-size: 12px;
    font-weight: 500;
  }

  & :global .ant-checkbox-wrapper {
    display: flex;
  }

  & :global .ant-checkbox-inner {
    background-color: #f5f5f5cc;
  }
}
