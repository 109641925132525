@import '../../../assets/styles/variables.scss';

.first-screen__body {
  display: flex;
  flex-direction: column;

  .body__title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 20px 0;

    h2 {
      font: $regular-l-font;
      line-height: 24px;
      color: $black;
    }
  }

  .body__notification {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
  }

  .body__form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    button {
      width: 100%;
      height: 40px;
      color: $black;
      background-color: $primary;
      font: $regular-m-font;
      line-height: 18px;
      border: 0;
      border-radius: 5px;
      transition-duration: $transition-duration-default;
      margin-top: 10px;
      outline: none;

      &:hover {
        background-color: $dark-mint;
      }

      &:disabled {
        background-color: $gray-2;
        color: $gray-1;
      }
    }
  }

  .body__links {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    span {
      font-size: 16px;
      line-height: 18px;
      font-weight: 300;
      color: $black;

      a {
        font-weight: 400;
        color: $dark-mint;
        margin-left: 5px;
      }
    }
  }
}
