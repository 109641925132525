@import '../../../../assets/styles/variables.scss';

.signup__third-screen-a {
  width: 420px;
  position: relative;
  z-index: 1;
  background-color: $white;
  padding: 50px 50px 32px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .third-screen-a__logo {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .third-screen-a__body {
    display: flex;
    flex-direction: column;

    .body__title {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 20px 0;

      h2 {
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
        color: $black;
      }
    }

    .body__company {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      background-color: $cool-gray;
      border-radius: 10px;
      padding: 10px 20px;
      gap: 20px;

      .company__logo {
        display: flex;
        flex: 0 0 40px;
        height: 40px;

        img {
          border-radius: 50%;
          object-fit: cover;
        }

        .logo__name {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $avatar-company-name;
          border-radius: 50%;

          span {
            font-size: 25px;
            line-break: 30px;
            font-weight: 400;
            color: $white;
          }
        }
      }

      .company__info {
        display: flex;
        flex-direction: column;

        h6 {
          font-size: 16px;
          line-height: 18px;
          font-weight: 600;
          color: $black;
          margin: 0;
        }
      }
    }

    .body__button {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      button {
        width: 100%;
        height: 40px;
        color: $black;
        background-color: $primary;
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
        border: 0;
        border-radius: 5px;
        transition-duration: $transition-duration-default;
        margin-top: 20px;
        outline: none;

        &:hover {
          background-color: $dark-mint;
        }

        &:disabled {
          background-color: $gray-2;
          color: $gray-1;
        }
      }
    }

    .body__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      span {
        font-size: 15px;
        line-height: 18px;
        font-weight: 300;
        color: $black;
      }

      a {
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
        color: $dark-mint;
        margin-top: 10px;
      }
    }
  }
}
