:root {
  /* Brand Colors */
  --white: #fff;
  --black: #121212;
  --primary: #7dff8a;

  /* Achromatic Colors */
  --gray-1: #b3b3b3;
  --gray-2: #d8d8d8;
  --gray: #c4c4c4;
  --cool-gray: #f0f4f5;
  --l-gray-4: #e7e7e7;
  --l-gray-3: #f4f4f4;
  --l-gray-2: #f7f7f7;
  --l-gray-1: #fcfcfc;

  /* Outbuild Colors Variants */
  --dark-green-sec: #252b1d;
  --dark-green: #3e482d;
  --bed-rock: #7d8671;
  --bed-rock-t1: #616a54;
  --bed-rock-t2: #2c3421;
  --bed-rock-t3: #151b0e;
  --bed-rock-t4: #090d03;
  --mist: #cce2d6;

  /* Other Colors */
  --dark-mint: #53c255;
  --light-green: #bdffc4;
  --lighter-green: #ecffee;
  --blue: #498e98;
  --light-blue: #a3c6cb;
  --lighter-blue: #c9dee1;
  --dark-blue-green: #0e5381;
  --deep-dark-red: #4c2a2e;
  --dark-red: #8e0101;
  --red: #e50101;
  --light-red: #fa7676;
  --lighter-red: #fce6e6;
  --yellow: #fdd551;
  --light-yellow: #fdd99b;
  --lighter-yellow: #fefbf0;

  /* Company / Tag Colors */
  --sb-red: #f7b3b3;
  --sb-light-red: #ffc2c2;
  --sb-orange: #ffceb3;
  --sb-yellow: #fdebcd;
  --sb-olive: #dfd8b5;
  --sb-green: #b4e7b4;
  --sb-dark-green: #b5d8b5;
  --sb-greenish-blue: #b4cbcb;
  --sb-cyan-blue: #b3e0e7;
  --sb-light-blue: #b8cffb;
  --sb-royal-blue: #b5bff6;
  --sb-red-purple: #dcb4ef;
  --sb-pink-purple: #f1b4d1;
  --sb-pink: #ffc7e2;
  --sb-gray: #cdcdcd;
  --sb-dark-gray: #c2c2c2;

  /* Other */
  --disabled-opacity: 0.5;
  --separation-line: #5f696d;
  --border-color-check: #688a96;
  --transition-duration-default: 0.3s;
}
