.constraint__new-modal .ant-modal-content {
  border-radius: var(--border-radius-modal);
  background-color: var(--background-modal);
}

.constraint__new-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container {
  display: flex;
  flex-direction: column;
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__header {
  width: 100%;
  height: 40px;
  background-color: var(--dark-green-sec);
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--border-radius-modal) var(--border-radius-modal) 0 0;
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__header
  .header__title {
  display: flex;
  align-items: center;
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__header
  .header__title
  i {
  margin-right: 10px;
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__header
  .header__title
  i
  img {
  top: 0;
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__header
  .header__title
  h6 {
  margin: 0;
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__header
  .header__close {
  cursor: pointer;
  color: var(--primary);
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__body {
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: var(--black);
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__body
  .body__inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__body
  .body__inputs
  .inputs__area,
.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__body
  .body__inputs
  .inpust__name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__body
  .body__inputs
  .inputs__area {
  margin-bottom: 10px;
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__body
  .body__inputs
  .inputs__area
  .ant-select-selection:focus {
  box-shadow: none;
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__body
  .body__inputs
  .inputs__area
  .ant-select-selection
  .ant-select-arrow
  i
  svg {
  fill: var(--white);
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__body
  .body__inputs
  .inputs__area
  label,
.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__body
  .body__inputs
  .inpust__name
  label {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: var(--white);
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__body
  .body__inputs
  .inputs__area
  .area__select
  .ant-select-selection,
.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__body
  .body__inputs
  .inpust__name
  input {
  width: 190px;
  height: 32px;
  background-color: var(--dark-green);
  border-radius: var(--border-radius-input-modal);
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: var(--white);
  border: 0;
  padding: 0 10px;
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__body
  .body__inputs
  .inputs__area
  .area__select
  .ant-select-selection {
  padding: 0;
}

.area__dropdown {
  background-color: var(--dark-green);
}

.area__dropdown .ant-select-dropdown-menu::-webkit-scrollbar {
  width: 4px;
  height: 36px;
}

.area__dropdown .ant-select-dropdown-menu::-webkit-scrollbar-thumb {
  background-color: var(--gray-1);
  border-radius: 3px;
}

.area__dropdown .ant-select-dropdown-menu::-webkit-scrollbar-track {
  background-color: var(--dark-green-sec);
  border-radius: 3px;
}

.area__dropdown .ant-select-dropdown-menu-item {
  font-size: 12px;
  font-weight: 400;
  color: var(--white);
}

.area__dropdown
  .ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.area__dropdown
  .ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.area__dropdown .ant-select-dropdown-menu-item-selected {
  background-color: var(--primary);
  color: var(--black);
}

.area__dropdown
  .ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
  background-color: var(--black);
  color: var(--white);
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__body
  .body__actions {
  display: flex;
  justify-content: flex-end;
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__body
  .body__actions
  button {
  height: 32px;
  border-radius: var(--border-radius-input-modal);
  border: 0;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__body
  .body__actions
  .actions__cancel {
  margin-right: 10px;
  background-color: var(--cool-gray);
  color: var(--black);
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__body
  .body__actions
  .actions__add {
  background-color: var(--primary);
  color: var(--black);
}

.constraint__new-modal
  .ant-modal-content
  .ant-modal-body
  .new-modal__container
  .container__body
  .body__actions
  .actions__add:disabled {
  cursor: not-allowed;
  opacity: var(--disabled-opacity);
}
