.PopConfirmHTML {
  position: relative;
  display: inline-block;
}
.PopConfirmHTML .PopConfirmHTMLElement {
  visibility: initial;
}

.PopConfirmHTML .PopConfirmHTMLContent {
  min-width: 30px;
  min-height: 90px;
  text-decoration: none;
  word-wrap: break-word;
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px #00000026;
  box-shadow: 0 2px 8px #00000026;
  visibility: visible;
  text-align: center;
  position: fixed;
  bottom: 100%;
  transform: translate(-45%, -100%);
  opacity: 1;
  transition: opacity 0.3s;
  z-index: 9999;
  padding: 12px 16px;
  color: #000000a6;
}

.PopConfirmHTML .PopConfirmHTMLContent p {
  margin: 0;
  padding: 0 5px;
  font-size: 14px;
}

.PopConfirmHTML .PopConfirmHTMLContent::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.PopConfirmHTML .PopConfirmHTMLContent .PopConfirmHTMLContent__title {
  display: flex;
  align-items: center;
}

.gantt_grid .gantt_grid_scale {
  z-index: 0 !important;
}
