@import '../../assets/styles/variables.scss';

.schedule-of-schedules {
  font-family:
    Roboto,
    Open Sans,
    Helvetica,
    Arial,
    sans-serif !important;
  font-size: $s-size-font !important;

  .gantt_marker_content::before {
    content: '';
    position: absolute;
    height: 0px;
    width: 0px;
    left: -2px;
    z-index: 0;
    top: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #7dff8a !important;
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(91deg);
  }

  .gantt_marker_content {
    border-radius: 3px;
    font-weight: 500;
    background-color: #7dff8a !important;
    position: relative;
    padding: 3px !important;
    font-size: 8px !important;
    width: 28px;
    top: 7px;
    color: black !important;
    font-weight: bold;
  }

  .today-custom-timeline {
    width: 2px !important;
    background-color: #7dff8a !important;
  }

  .gantt_task_line.task-row.gantt_bar_task.gantt_task_inline_color.gantt_split_child {
    border-radius: 5px;
    border-width: 1px;
    z-index: 100;
  }
  .task-row {
    height: 20px !important;
    border-style: none;
    border-color: var(--schedule-of-schedules-bar-border-color);

    &.task-completed {
      border: 0.5px solid var(--Color-Success, #53c255);
      background: var(--Color-Success, #53c255);
      border-radius: 5px;
    }

    &.task-empty {
      border: 0.5px solid var(--Color-Important, #f26d0c);
      background: rgba(242, 109, 12, 0.3);
      border-radius: 5px;
    }

    &.task-advanced {
      border: 0.5px solid var(--Color-Info, #498e98);
      background: rgba(73, 142, 152, 0.3);
      border-radius: 5px;
    }
  }

  .today-custom-timeline {
    z-index: 200;
  }

  .gantt_side_content.gantt_right {
    position: relative;
    top: -25px !important;
    width: 189px;
    height: 30px;

    .right-size-text {
      color: $black;
      font-size: 13px;
      position: relative;
      top: 1px;
    }
  }
  .gantt_row.odd {
    background: $l-gray-2;
  }
  .gantt_grid_head_cell {
    border-bottom: 2px solid #0000000d;
  }
  .gantt_task_line.gantt_split_parent {
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    background-color: $cool-gray;
    opacity: 1;
  }
}
