@import '../../../assets/styles/variables.scss';

.dropdown-design-system,
.dropdown-design-system__submenu {
  ul {
    border-radius: 5px;
    padding: 10px 0;
    box-shadow: 2px 2px 4px 0px $dark-black-10;

    li {
      width: 100%;
      height: 30px;
      padding: 9px 20px 0 !important;
      position: relative;

      span {
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
      }

      &.divider {
        span {
          font-weight: 500;
        }
      }

      &.ant-dropdown-menu-item-divider {
        height: 1px;
        padding: 0 !important;
      }

      &.ant-dropdown-menu-item-disabled,
      &.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled
        .ant-dropdown-menu-submenu-title {
        opacity: $disabled-opacity;
        cursor: not-allowed;
      }

      &.ant-dropdown-menu-item-divider {
        width: calc(100% - 40px);
        margin-left: auto;
        margin-right: auto;
      }

      div {
        margin-top: 0 !important;
        padding: 0 !important;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
      }

      .ant-dropdown-menu-submenu-title,
      .ant-dropdown-menu-submenu-arrow i {
        background-color: transparent !important;
      }

      i {
        margin-right: 10px !important;
      }

      .item__divider {
        width: calc(100% - 40px);
        height: 1px;
        position: absolute;
        bottom: 0;
        margin-left: auto;
        margin-right: auto;
        padding: 0 !important;
      }

      .item__radio {
        display: flex;
        justify-content: center;
        width: 14px;
        height: 14px;
        box-sizing: border-box;
        border-radius: 50%;
        margin-right: 15px;

        .radio {
          width: 4px;
          height: 9px;
          border-style: solid;
          border-width: 0 1.5px 1.5px 0;
          transform: rotate(45deg);
        }
      }
    }
  }

  &.theme--light {
    ul {
      background-color: $white;
      border: 1px solid $cool-gray;

      li {
        color: $black;

        &.ant-dropdown-menu-item-divider {
          background-color: $cool-gray;
        }

        &.ant-dropdown-menu-item-disabled,
        &.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled
          .ant-dropdown-menu-submenu-title {
          color: $black !important;
        }

        &:hover {
          background-color: $cool-gray !important;
        }

        &.ant-dropdown-menu-item.active {
          background-color: $dark-mint !important;
        }

        div {
          color: $black;
        }

        .item__divider {
          background-color: $cool-gray;
        }

        .ant-dropdown-menu-submenu-arrow i svg {
          color: $black;
        }

        .item__radio {
          border: 1.5px solid $gray-2;

          &.active {
            background-color: $black;
            border-color: $black;

            .radio {
              border-color: $dark-mint;
            }
          }
        }
      }
    }
  }

  &.theme--dark {
    ul {
      background-color: $black;
      border: 1px solid $dark-green;

      li {
        color: $white;

        &.ant-dropdown-menu-item-divider {
          background-color: $dark-green;
        }

        &.ant-dropdown-menu-item-disabled,
        &.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled
          .ant-dropdown-menu-submenu-title {
          color: $white !important;
        }

        &:hover {
          background-color: $dark-green !important;
        }

        &.ant-dropdown-menu-item.active {
          background-color: $primary !important;
          color: $black;

          div {
            span {
              color: $black;
            }
          }
        }

        div {
          color: $white;
        }

        .item__divider {
          background-color: $dark-green;
        }

        .ant-dropdown-menu-submenu-arrow i svg {
          color: $white;
        }

        .item__radio {
          border: 1.5px solid $dark-gray;

          &.active {
            background-color: $black;
            border-color: $black;

            .radio {
              border-color: $primary;
            }
          }
        }
      }
    }

    &.v2 {
      ul {
        background-color: $dark-green;
        border: 1px solid $black;
        max-height: 250px;

        &::-webkit-scrollbar {
          width: 4px !important;
          height: 36px !important;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $white !important;
          border-radius: 3px !important;
        }

        &::-webkit-scrollbar-track {
          background-color: $dark-green !important;
          border-radius: 3px !important;
        }

        li {
          &.ant-dropdown-menu-item-divider {
            background-color: $black;
          }

          &:hover {
            background-color: $black !important;
          }

          &.ant-dropdown-menu-item.active {
            background-color: $primary !important;
            color: $black;
          }

          .item__divider {
            background-color: $black;
          }
        }
      }
    }
  }
}

.dropdown-design-system__label {
  width: 100%;
  padding: 0 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &.theme--light {
    background-color: $cool-gray;
    color: $black;

    &.active {
      border: 1px solid $dark-mint;
    }

    &.v2 {
      background-color: $gray-2;
    }
  }

  &.theme--dark {
    background-color: $black;
    color: $white;

    &.active {
      border: 1px solid $primary;
    }

    &.v2 {
      background-color: $dark-green;
    }
  }

  &.error {
    &.theme--light {
      background-color: $light-red;
      border: 1px solid $red;
    }

    &.theme--dark {
      background-color: $deep-dark-red;
      border: 1px solid $light-red;
    }
  }

  .label__content {
    display: flex;
    align-items: center;
    gap: 5px;

    span {
      font: $regular-s-font;
      line-height: 14px;
    }
  }
}
