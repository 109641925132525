.link-style {
  margin-right: 10px;
}

.route-toolbar-container {
  background-color: #efefef !important;
  height: 80px !important;
  z-index: 100;
  margin-top: 0px;
}

.gutter-example .ant-row > div {
  background: transparent;
  border: 0;
}
.gutter-box {
  background: #00a0e9;
  padding: 5px 0;
}
.search-btn {
  background-color: #686363 !important;
  border-color: #686363 !important;
  color: white !important;
  position: absolute !important;
  top: 6px !important;
  left: 100px !important;
}

.route-logo-style {
  width: 58px !important;
  border-radius: 6px !important;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  position: absolute !important;
  top: 12px !important;
  left: 34px !important;
  background-color: white;
  padding: 15px;
  box-shadow: -4px 4px 3px #d0d0d0;
}

.company-logo-style {
  width: 37px !important;
  border-radius: 6px !important;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  position: relative !important;
  top: 19px !important;
  left: 10px !important;
  background-color: white;
  box-shadow: -4px 4px 3px #d0d0d0;
}

.title-route-label {
  position: absolute !important;
  top: 15px !important;
  left: 90px !important;
  font-size: 20px;
  font-weight: bold;
}

.route-logo {
  color: var(--black) !important;
  font-size: 12px;
  position: relative;
  top: -1px;
}

.title-route {
  top: 22px !important;
  left: 135px !important;
  font-size: 23px;
  font-weight: bold;
  width: 100%;
  display: block;
  padding-left: 35px;
  padding-top: 10px;
}
.breadcrumb-route {
  top: 22px !important;
  left: 135px !important;
  font-size: 15px;
  width: 100%;
  color: var(--black);
  padding-left: 35px;
}

.subtitle-route-label {
  position: absolute !important;
  left: 90px !important;
  font-size: 12px !important;
  font-weight: normal !important;
  top: 43px !important;
  width: 100%;
  display: block;
}

.label2 {
  top: 52px !important;
}

.buttonsNav {
  height: 50px;
  padding-left: 22px;
  padding-top: 12px;
}

.buttonsNav .ant-radio-button-wrapper {
  background-color: white;
  border: none !important;
  color: #a1a1a1 !important;
  text-align: center !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  margin-right: 10px;
}

.buttonsNav .ant-radio-button-wrapper-checked {
  text-align: center !important;
  background-color: #065c76 !important;
  border: 1px solid #065c76 !important;
  border-radius: 8px !important;
  -webkit-border-radius: 9px !important;
  -moz-border-radius: 9px !important;
  padding: 0 !important;
  color: white !important;
}

.buttonsNav .ant-radio-button-wrapper-checked span {
  padding: 0 14px !important;
}

.buttonsNav .selectFilter {
  float: right;
  min-width: 150px;
  margin-left: 10px;
}

.buttonsNav .ant-select-selection {
  background-color: #00a8b9 !important;
  border: none !important;
}
.buttonsNav .ant-select-arrow {
  padding-left: 12px;
  border-left: 1px solid white;
}

.buttonsNav .ant-select-focused .ant-select-selection,
.buttonsNav .ant-select-selection:focus,
.buttonsNav .ant-select-selection:active,
.buttonsNav .ant-select-open .ant-select-selection,
.buttonsNav .ant-input:focus {
  border-color: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.buttonsNav .ant-input {
  height: 27px !important;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):before {
  background-color: white !important;
}

.ant-radio-button-wrapper:not(:first-child):before {
  left: -10px !important;
  background-color: white !important;
}

.projectList {
  height: 50px;
  padding-top: 12px;
  float: left;
  width: 100%;
  height: auto;
}

/* list images */
.list {
  padding: 6px;
}
.list li {
  display: table;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 16px;
  min-height: 140px;
}
.inner {
  display: table-row;
  overflow: hidden;
}
.li-img {
  display: table-cell;
  vertical-align: middle;
  width: 10%;
  text-align: center;
}
.li-img img {
  display: block;
  width: 80%;
  height: auto;
}

.li-img .ant-avatar {
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
}

.li-text {
  display: table-cell;
  vertical-align: middle;
  width: 70%;
}
.li-head {
  margin: 0;
  padding-top: 8px;
  text-align: center;
  height: 28px;
  overflow: hidden;
  margin: auto;
  width: 70% !important;
}
.li-sub {
  margin: 0;
}

.addProjectIcon {
  margin-top: 20px !important;
  width: auto !important;
}

.imgList {
  padding: 0;
}

@media all and (min-width: 45em) {
  .imgList {
    padding-left: 14px;
  }
  .list li {
    float: left;
    width: 8%;
  }
}

@media all and (min-width: 75em) {
  .list li {
    width: 8%;
  }
}

.ant-avatar-icon {
  background-color: white !important;
}

.li-not-found {
  margin-top: 35px;
}

.inputSearch {
  float: right;
  min-width: 100px !important;
}

.ant-input-search {
  width: 150px !important;
}

.ant-input-search.custom-search-bar-prop {
  width: 100% !important;
}

.buttonsNav .ant-input {
  border: 1px solid #d9d9d9 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.toolbar-container-secondary {
  background-color: #fff !important;
  z-index: 9999;
  -webkit-box-shadow: 0px 3px 6px 1px rgba(223, 223, 223, 1);
  -moz-box-shadow: 0px 3px 6px 1px rgba(223, 223, 223, 1);
  box-shadow: 0px 3px 6px 1px rgba(223, 223, 223, 1);
  padding-bottom: 10px;
}
