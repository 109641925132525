@import '../../../../../assets/styles/variables.scss';

.sort-columns-gantt {
  display: flex;
  gap: 2px;

  .sort-columns-gantt__popover {
    width: 160px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5;
    padding: 0 10px;
    background-color: $gray-2;
    border-radius: 5px 0px 0px 5px;
    cursor: pointer;

    span {
      color: $black;
      font: $regular-s-font;
    }

    i {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .sort-columns-gantt__button {
    width: 32px;
    height: 32px;
    border: 0;
    background-color: $gray-2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 5px 5px 0px;
  }
}
