.img-tabla-etapa {
  height: 15px;
  width: 15px;
  border-radius: 50px;
  margin: 0;
}
.btn-table-productividad {
  text-align: center;
  user-select: none;
  padding: 0.33rem 0.3rem;
  font-size: 10px;
  line-height: 1.5;
  display: block;
  border: 0px solid #000000;
  cursor: pointer;
  color: white;
  width: 90px;
}

.bg-btn-productivo {
  background-color: #2c3421;
}

.bg-no-productivo {
  background-color: #586666;
}

.head-table-settings .ant-table-thead > tr:first-child > th {
  background: white;
}

.head-table-settings tbody > tr > td {
  background: white;
}

.head-table-settings tbody > tr {
  background: white;
}

.head-table-settings tbody tr:nth-child(odd):hover {
  background: white !important;
}

.head-table-settings tbody tr:nth-child(even):hover {
  background: white !important;
}

.head-table-settings tbody > tr:hover > td {
  background: white !important;
}

.date-stage {
  position: relative;
  top: 1px;
}
