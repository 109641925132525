%baseSettigns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.settings {
  @extend %baseSettigns;
  &__element {
    @extend %baseSettigns;
  }
}
