.resource-add-btn {
  color: #c4c4c4;
  margin-left: 55px;
  margin-bottom: 12px;
  cursor: pointer;
  width: 130px;
  margin-top: 10px;
  transition: 0.3s;
  font-size: 13px;
}

.resource-add-btn:hover {
  color: black;
}

.resource-add-btn .anticon-plus {
  margin-right: 3px;
}

.empty {
  padding: 10px !important;
}

.button-resource {
  margin-top: 8px !important;
  margin-left: 60px !important;
  margin-right: 0px !important;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  padding: 0px 1% 0px 1% !important;
  min-width: 80px;
  height: 24px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.frm-constraint .title-frm-add {
  font-size: 18px;
  font-weight: bold;
  color: #2c3421;
  border-bottom: 1px solid #2c3421;
  padding-bottom: 10px;
  /* width: 85%; */
  text-align: left;
  margin: auto;
}

.frm-constraint .title-close {
  color: #d1d1d1;
  float: right;
  cursor: pointer;
  font-size: 16px;
}

.txtName {
  display: block !important;
  box-sizing: border-box !important;
  width: 40% !important;
  border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -webkit-border-radius: 0;
  border: 1px solid white !important;
  padding: 10px 0 !important;
  margin: auto !important;
  margin-bottom: 2px !important;
  font-size: 18px !important;
  top: 1px !important;
  border-bottom: 1px solid #77c8d4 !important;
  text-align: center;
}

.constraint-title-frm {
  color: #2c3421;

  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: flex-end;
  text-align: center;
}

.titleFrm div {
  display: inline-block;
  color: #ababab;
  font-size: 28px;
  margin-top: 7px;
  cursor: pointer;
}

.constraint-title-frm div:first-child {
  float: left;
}

.constraint-title-frm div:last-child {
  float: right;
}

.frm-constraint .frm {
  display: flex !important;
  align-items: center;
  width: 100%;
}

.frm-constraint .constraint-btn-submit,
.frm-constraint .ant-btn:hover,
.frm-constraint .ant-btn:focus {
  margin-top: 30px;
  background-color: var(--primary);
  color: var(--black);
  min-width: 80px;
  max-height: 26px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border: transparent;
  cursor: pointer;
  font-size: 13px;
}

.frm-constraint .constraint-btn-submit:hover {
  background-color: #40b3c3;
  color: white;
}

.frm-constraint .buttons {
  margin-top: 50px;
}

.frm-constraint .ant-modal-footer {
  padding: 0 20px 30px;
}

.frm-constraint .ant-modal-body {
  padding: 24px 24px 0 !important;
}

.constraint-footer-btn {
  text-align: center;
}

.constraint-footer-btn .constraint-btn-submit {
  font-size: 13px;
}

.constraint-hand-close {
  color: #c4c4c4;
  margin-left: 430px;
  margin-top: -6%;
  width: 12px;
  height: 12px;
}

.constraint-hand-back {
  color: #c4c4c4;
  margin-left: 50px;
  width: 12px;
  height: 12px;
}

.treeResourceModal {
  height: 600px;
  padding-top: 20px;
}

.tree-scroll {
  height: 400px;
  overflow: hidden;
  overflow-y: scroll;
}

.ant-tree-node-content-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
}
.resumResourceModal {
  height: 600px;
}

.current-resource-modal {
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  color: #121212;
}

.current-value-resource-modal {
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: var(--primary);
}

.blue-line {
  width: 0px !important;
  height: 400px;
  border: 0.5px solid #2c3421;
}

.input-search-resource {
  width: 100% !important;
  margin-left: 10px;
  margin-right: 10px;
}
