.loader-layout-style {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100vh !important;
}

.layout-sidebar-icon {
  max-width: 74px !important;
}

.ant-layout-sider {
  max-width: 74px !important;
  min-width: 74px !important;
  width: 74px !important;
}

.ant-menu-root.ant-menu-inline {
  width: 74px !important;
  background-color: #121212 !important;
}

.ant-menu-item .ant-menu-item-selected .layout-sidebar-icon {
  margin-top: 10px;
  background: #3e482d;
}

.ant-menu-item .ant-menu-item-selected .layout-sidebar-icon .st1 {
  margin-top: 10px !important;
}

.ant-layout-sider-children {
  background-color: #121212 !important;
}

.layout-sidebar-icon:hover .st1 {
  fill: #2c3421;
  height: 24px !important;
  width: 24px !important;
}

.layout-sidebar-icon:hover .st0 {
  fill: #2c3421;
  height: 24px !important;
  width: 24px !important;
}

.layout-sidebar-icon .st0 {
  display: inline-block !important;
  height: 24px !important;
  width: 24px !important;
}

.layout-sidebar-icon .st1 {
  display: inline-block !important;
  height: 24px !important;
  width: 24px !important;
}

.layout-sidebar-title div {
  margin-top: 5px;
}

.ant-menu-item-selected .layout-sidebar-title div .st0 {
  fill: #ffffff;
}

.ant-menu-item-selected .layout-sidebar-title div .st1 {
  fill: #ffffff;
}
