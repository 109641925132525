html {
  font-family: sans-serif;
  font-size: 12px;
}

.GridStaticCol {
  border-radius: 5px 0px 0px 5px;
  border: -0.5px solid #1212123b;
  border-right: none;
  display: inline-block;
}

.GridStaticCol__container {
  border-adius: 5px 0px 0px 5px;
  outline: 0.5px solid #1212123b;
}

.GridStaticHeader {
  border-left: 0.1px solid #1212124f;
  border-right: 0.1px solid #1212124f;
}

.Grid {
  border-right: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  border-left: 1px solid #c4c4c4;
  display: inline-block;
  overflow-x: auto !important;
  overflow-y: hidden;
}

.col-fixed-row {
  width: 100%;
  height: 100%;
  border-bottom: 0.1px solid #1212123b;
  box-sizing: border-box;
  padding: 5%;
  font-weight: 400;
}

.location-header-fix {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  color: #121212;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  border-right: 0.1px solid #e3e3e3;
  border-bottom: 0.1px solid #e3e3e3;
}

.grid-element-with-units {
  border-right: 0.1px solid #e3e3e3;
  border-bottom: 0.1px solid #e3e3e3;
  background-color: #87cd6a;
  color: #d2ecc8 !important;
  width: 100%;
  height: 100%;
  color: white;
  font-weight: bold;
  text-align: center;
  padding-top: 1%;
}

.grid-element-unassign-units {
  border-right: 0.1px solid #e3e3e3;
  border-bottom: 0.1px solid #e3e3e3;
  background-color: #e96060;
  color: #ffffff !important;
  width: 100%;
  height: 100%;
  color: white;
  font-weight: bold;
  text-align: center;
  padding-top: 1%;
}

.grid-element-without-units {
  border-right: 0.1px solid #e3e3e3;
  border-bottom: 0.1px solid #e3e3e3;
  width: 100%;
  height: 100%;
  font-weight: bold;
  text-align: center;
  padding-top: 1%;
  font-weight: 500;
}

.fixed-unit-header-with-activities {
  background-color: #c5e1b9;
  color: #121212;
  font-weight: bold;
  width: 100%;
  height: 100%;
  padding: 5px;
  text-align: center;
  border: 0.5px solid #e3e3e3;
}

.fixed-unit-header-activities-unassign {
  background-color: #f2a7a7;
  color: #121212;
  font-weight: bold;
  width: 100%;
  height: 100%;
  padding: 5px;
  text-align: center;
  border: 0.5px solid #e3e3e3;
}

.fixed-unit-header-without-activities {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-right: 0.5px solid #e3e3e3;
  border-bottom: 0.5px solid #e3e3e3;
  color: #121212;
}

.fixed-col-activity-with-units {
  background-color: #c5e1b9;
  color: #121212;
  width: 100%;
  height: 100%;
}

.fixed-col-activity-unassign-units {
  background-color: #f2a7a7;
  color: #121212;
  width: 100%;
  height: 100%;
}

.fixed-col-activity-without-units {
  width: 100%;
  height: 100%;
}

.separate-secctions {
  border-right: 2px solid #828282;
}

.activities-checkbox-container-virtualized {
  border-radius: 5px;
  padding-top: 5px !important;
  box-shadow: 0 3px 1px 1px rgba(0, 0, 0, 0.2);
  height: 100% !important;
}

.activities-checkbox-container-virtualized
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.activities-checkbox-container-virtualized.units
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.hide-style-pointer {
  cursor: pointer;
  position: relative;
  top: -2px;
  left: 4px;
}

.grid-container-style {
  border-radius: 5px;
  margin-top: 17px;
  margin-right: 17px !important;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 3px 1px 1px rgba(0, 0, 0, 0.2);
  height: calc(100vh - 281px) !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #25353a;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}
