@import '../../stylesheets/base';

.assignees-picker {
  &__name {
    margin-left: 3px;
    transition: color $short-transition-duration ease-in;
  }

  &__avatars-list {
    img,
    svg {
      transition: border-color $short-transition-duration ease-in;
      border-radius: 50%;
      border: 2px solid transparent;
      width: 19px;
      height: 19px;

      &:not(:first-child) {
        margin-left: -5px;
      }
    }
  }

  &__popover-option {
    display: flex;
  }

  &__popover-option-avatar {
    width: 15px;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
  }

  &__popover-option-name {
    font-size: $font-xs;
    width: 100%;
  }

  &--editable &__avatars-list:hover {
    cursor: pointer;

    img,
    svg {
      border-color: $brand-orange-40;
    }

    .assignees-picker__name {
      color: $brand-orange-40;
    }
  }
}
