.gridContainer {
  width: 100% !important;
}

.cell {
  border-left: 0.5px solid #e3e3e3;
  border-bottom: 0.5px solid #e3e3e3;

  display: flex;
  justify-content: center;
  align-items: center;
}

.cell.active {
  background-color: #c8e8fb;
}

.disabled {
  color: rgba(88, 88, 88, 0.3);
}

.factorContent {
  width: 60%;
  font-size: 10px;
  text-align: center;
  background-color: #c8e8fb;
  border-radius: 6px;
  cursor: pointer;
}

.inputChangeFactor {
  width: 50%;
}

.inputChangeFactor input {
  width: 100%;
  border-radius: 6px;
  border: 0.2px solid #c8e8fb;
}

.inputChangeFactor .invalid {
  border: 0.2px solid red;
  animation: Shake ease-in-out 0.2s;
}

@keyframes Shake {
  0% {
    transform: translateX(1px);
  }
  10% {
    transform: translateX(-2px);
  }
  20% {
    transform: translateX(0px);
  }
  30% {
    transform: translateX(2px);
  }
  40% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateX(2px);
  }
  60% {
    transform: translateX(1px);
  }
  70% {
    transform: translateX(1px);
  }
  80% {
    transform: translateX(-1px);
  }
  90% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(-2px);
  }
}
