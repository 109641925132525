.modal-assign .title-frm-add {
  font-size: 18px;
  font-weight: bold;
  color: #2c3421;
  border-bottom: 1px solid #2c3421;
  padding-bottom: 10px;
}

.modal-assign .title-close {
  color: #d1d1d1;
  float: right;
  cursor: pointer;
  font-size: 16px;
}

.modal-assign .back-arrow {
  display: inline;
  cursor: pointer;
}

.modal-assign .t-header {
  font-weight: bold;
  background: #f5f5f5;
  margin: 13px 0;
  font-size: 12px;
}

.modal-assign .row-assign {
  margin: 7px 0;
  font-size: 12px;
}

.modal-assign .add-to-project {
  font-size: 12px;
  position: relative;
  top: -2px;
}

.modal-assign .center-btn-modal-adduser {
  width: 100%;
  text-align: center;
}

.modal-assign .btn-continue-user-company,
.modal-assign .btn-continue-user-company:hover,
.modal-assign .btn-continue-user-company:active,
.modal-assign .btn-continue-user-company:visited,
.modal-assign .btn-continue-user-company:focus {
  margin-top: 40px;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.15rem 0.1rem;
  line-height: 1.5;
  display: inline-block;
  border: 0px;
  cursor: pointer;
  color: var(--black);
  width: 80px;
  font-size: 12px;
  height: 28px;
  border-radius: 5px;
  background-color: var(--primary);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin: 10px;
  border: none;
  outline: none;
}
