.manpower {
  height: 35px;
  display: grid;
  grid-template: 35px / 2.7fr 1.5fr 1fr;

  padding: 0 15px 0 37px;
  border: 1px solid #d1d1d1;

  & div {
    margin: auto 0;
    color: #121212;
    font-size: 12px;
  }
}

.manpower_title {
  margin-right: 10px;
  font-weight: 500;
}

.days__container {
  display: flex;
}

.days {
  display: inline-block;
  flex: 1 1 52px;

  color: #121212;
  font-size: 12px;
  font-weight: 300;
  text-align: center;

  border-right: 0.5px solid #d1d1d1;

  &:first-child {
    border-left: 0.5px solid #d1d1d1;
  }
}

.task_completed {
  display: flex;

  & span {
    flex: 1 1 52px;
  }

  & span:first-child {
    text-align: center;
    font-weight: 500;
    border-right: 0.5px solid #d1d1d1;
  }

  & span:last-child {
    flex-grow: 5;
  }
}
