@import '../../../../stylesheets/base';

.variance-cell {
  .red {
    color: $red;
  }

  .black {
    color: $black;
  }

  .green {
    color: $green;
  }
}
