%cell {
  color: #121212;
  border-bottom: 0;
  justify-content: center;
  text-align: center;
  overflow: hidden !important;
  white-space: nowrap;
  padding-left: 7px;
  padding-right: 7px;
}
.gridContainer {
  display: grid !important;
  grid-template: auto 1fr / 40% 50% 10%;
}
.cell {
  @extend %cell;
  &__sector {
    @extend %cell;
    background-color: #efefef;
  }
  &__resource {
    @extend %cell;
  }
  &__unidad {
    @extend %cell;
    background-color: #efefef;
  }
}
.gridContent {
  overflow: hidden !important;
}
.divGridContainer {
  max-height: 400px;
}
