.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #efefef;
  padding: 10px 20px;

  & .calendar__info {
    display: flex;
    font-size: 12px;
    color: #121212;
    font-weight: 400;

    & div:nth-child(2) {
      margin: 0 2px 0 10px;
    }
  }
}
