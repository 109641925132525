@import '../../stylesheets/base';
@import '../../assets/styles/variables.scss';

.drawer {
  transition:
    transform $default-transition-duration ease-in,
    opacity $long-transition-duration ease-in;
  background-color: $dark-green-sec;

  &.drawer--dark {
    background-color: $black;
  }

  &__wrapper {
    padding: 20px 15px;
  }

  &__header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $gray-2;
  }

  &__close-button {
    margin-left: auto;
    background-color: transparent;
    border-width: 0;
    cursor: pointer;
    padding-right: 0;
    &:hover {
      opacity: 0.7;
    }
    img {
      width: 16px !important;
      height: 16px !important;
    }
  }

  &__title {
    color: $white;
    margin-left: 10px;
    font-size: $font-lg;
    font-weight: 600;
    display: inline-block;
  }

  &__icon {
    transform: scale(1.2);
  }

  &__loading-bar {
    height: 0;
    opacity: 0;
    transition: opacity $short-transition-duration ease-in;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 6px;
      background: linear-gradient(
        90deg,
        transparent 20%,
        $primary 50%,
        transparent 80%
      );
      background-size: 200% 200%;
      animation: drawer-loading-animation 3s linear;
      animation-iteration-count: infinite;
    }
  }

  &--loading &__loading-bar {
    opacity: 1;
  }

  &--loading &__content {
    pointer-events: none;
  }

  &--right,
  &--left {
    min-width: 300px;
    height: 100%;
  }

  &--right {
    transform: translateX(100%);
  }

  &--left {
    transform: translateX(-100%);
  }

  &--bottom {
    min-height: 300px;
    width: 100%;
    transform: translateY(100%);
  }
}

.drawer--left.drawer--open,
.drawer--right.drawer--open {
  transform: translateX(0);
}

.drawer--bottom.drawer--open {
  transform: translateY(0);
}

@keyframes drawer-loading-animation {
  from {
    background-position: 150% 100%;
  }

  to {
    background-position: -50% 100%;
  }
}
