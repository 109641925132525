@import '../../../assets/styles/variables.scss';

.tooltip-design-system {
  &.theme--default {
    .ant-tooltip-content {
      .ant-tooltip-arrow {
        &::before {
          background-color: $dark-green;
        }
      }

      .ant-tooltip-inner {
        background-color: $dark-green;
        color: $white;
        font-size: 12px;
        line-height: 14px;
        font-weight: 300;
      }
    }
  }

  &.theme--dark {
    .ant-tooltip-content {
      .ant-tooltip-arrow {
        &::before {
          background-color: $dark-green;
        }
      }

      .ant-tooltip-inner {
        background-color: $dark-green-sec;
        color: $white;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
      }
    }
  }
}
