@import '../../../../../assets/styles/variables.scss';

.more-options-popover {
  cursor: pointer;
}

.more-options-popover__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  .content__item {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    gap: 10px;
    transition-duration: 0.3s;
    cursor: pointer;

    &:hover {
      background-color: $gray-2;
    }

    span {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: $black;
    }
  }
}
