@import '../../assets/styles/variables.scss';

.wrappView {
  margin-left: 1px;
  width: 100%;
}

.loader-spinner-projects {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.sectionProjects {
  top: 30px;
}

.section--project {
  margin-left: 40px;
  margin-top: 26px;
  padding-left: 27px;

  span {
    font: $medium-m-font;
  }
}

.divProjects {
  width: 100%;
  padding-left: 20px;
  margin-top: 20px;
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
}
