%cell {
  font-family: 'Roboto', arial, sans-serif;
  color: #ffffff;
  text-align: center;
  border-bottom: 0.2px solid #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.matrix {
  margin-left: 25px;
  margin-right: 25px;
  height: 100%;
  &__cell {
    @extend %cell;
    &-green {
      @extend %cell;
      background-color: #5dbf33;
    }
    &-blue {
      @extend %cell;
      background-color: #797979;
    }
    &-grey {
      @extend %cell;
      background-color: #efefef;
    }
  }
}
.gridValues {
  // overflow: hidden!important;
}
