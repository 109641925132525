.frm-cnc .title-frm-add {
  font-size: 18px;
  font-weight: bold;
  color: #2c3421;
  border-bottom: 1px solid #2c3421;
  padding-bottom: 10px;
}

.frm-cnc .title-close {
  color: #d1d1d1;
  float: right;
  cursor: pointer;
  font-size: 16px;
}

.frm-cnc .week-navigate {
  width: 300px;
  margin: 20px auto;
  cursor: pointer;
}

.frm-cnc .week-left {
  text-align: right;
  margin: 2px;
}

.frm-cnc .week-right {
  text-align: left;
  margin: 2px;
}

.frm-cnc .week {
  padding: 10px;
}

.frm-cnc .week-number {
  font-size: 12px;
}

.frm-cnc .range-date {
  font-size: 12px;
  color: #121212 !important;
}

.frm-cnc .div-week {
  margin: auto;
  display: flex;
  align-items: center;
}

.frm-cnc .frmCol {
  text-align: center;
}

.frm-cnc .div-week div {
  color: #2c3421;
}

.frm-cnc .btn-style {
  margin-right: 10px;
  margin-left: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #2c3421 !important;
  border-color: #2c3421 !important;
  border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  padding: 2px 5px 2px 5px !important;
  min-width: 80px;
  /* max-width: 103px; */
  height: 26px !important;
  font-family: 'Roboto-light', arial;
  color: white !important;
}

.frm-cnc .btn-style:hover {
  color: white !important;
}

.week-info {
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
}
.week-info-select,
.week-info:hover {
  background: #efefef;
  border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
}

.week-info span {
  padding: 0 10px;
}

.week-info-range {
  /* width: 40%; */
  text-align: center;
}
.week-info-number {
  color: #2c3421;
  /* width: 30%; */
  text-align: start;
}

.week-info-pending {
  color: var(--primary);
  /* width: 30%; */
  text-align: end;
}

.frm-cnc .filter {
  float: left;
}

.frm-cnc .info {
  float: right;
  color: var(--primary);
  padding: 0 10px;
}

.frm-cnc .filter-icon {
  color: #2c3421;
  padding: 0 10px;
}

.frm-cnc .list-task {
  display: block;
  margin-top: 50px;
  max-height: 390px;
  overflow: auto;
}

.frm-cnc .card-task {
  font-size: 12px;
  width: 95%;
  height: 60px;
  border: 1px solid #2c3421;
  display: inline-block;
  border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  cursor: pointer;
}

.frm-cnc .info-task {
  text-align: left;
  padding: 8px;
}

.frm-cnc .info-task div:first-child {
  color: #2c3421;
}

.frm-cnc .custom-check-box {
  top: -28px;
}

.frm-cnc .percentaje-tasks div {
  width: 50%;
  display: inline-block;
}

.frm-cnc .percentaje-tasks div:first-child {
  color: #2c3421;
  border-bottom: 1px solid #2c3421;
  border-left: 1px solid #2c3421;
  border-right: 1px solid #2c3421;
  border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px !important;
  -webkit-border-bottom-left-radius: 5px !important;
}

.frm-cnc .percentaje-tasks div:last-child {
  color: white;
  border-bottom: 1px solid #2c3421;
  border-left: 1px solid #2c3421;
  border-right: 1px solid #2c3421;
  background: #2c3421;
}

.frm-cnc .img-handshake {
  width: 14px;
  margin: 4px;
  top: -1px;
  position: relative;
}

.frm-cnc .img-progress {
  width: 14px;
  margin: 4px;
  top: -1px;
  position: relative;
  color: red;
  fill: #2c3421;
  filter: brightness(0) invert(1);
  -moz-filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
}

.frm-cnc .info-cnc-form {
  margin-top: 76px;
  text-align: left;
  padding: 0 25px;
}

.frm-cnc .info-cnc-form .title-cnc,
.frm-cnc .type-cnc .title-type-cnc {
  color: #2c3421;
}

.frm-cnc .type-cnc {
  margin-top: 20px;
  text-align: left;
  padding: 0 25px;
}

.frm-cnc .image-cnc {
  text-align: center;
  color: #efefef;
}

.frm-cnc .name-cnc {
  margin-top: 4px;
}

.frm-cnc .list-type {
  border: 1px solid #2c3421;
  border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  height: 180px;
  margin-top: 10px;
  overflow: auto;
  text-align: center;
  padding: 4px;
}

.frm-cnc .info-div {
  margin-top: 10px;
}

.frm-cnc .card-type {
  font-size: 12px;
  width: 95%;
  margin: 4px;
  padding: 6px 12px;
  height: 35px;
  border: 1px solid #2c3421;
  display: inline-block;
  border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  cursor: pointer;
  text-align: left;
}

.frm-cnc .inp-cnc-name {
  width: 100%;
}

.frm-cnc .div-btns {
  text-align: right;
}

.frm-cnc .btn-create-cnc {
  background: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  margin-right: 0px;
}

.frm-cnc .txt-type {
  margin-left: 10px;
  display: inline;
}

.frm-cnc .img-type-cnc {
  color: #2c3421;
  display: inline;
  font-size: 16px;
  position: relative;
  top: 2px;
}

.frm-cnc .card-selected {
  border: 1px solid var(--primary) !important;
}

.frm-cnc .no-tasks {
  color: #2c3421;
  text-align: center;
  margin-top: 170px;
}

.frm-cnc .lblErrorElement {
  color: red;
  visibility: visible;
  padding: 0;
  margin: 0;
  height: 14px;
  display: block;
}

.frm-cnc .hidden {
  visibility: hidden !important;
  padding: 0 !important;
  margin: 0 !important;
  height: 14px !important;
  display: block !important;
}

.frm-cnc .error {
  color: red;
}

.frm-cnc .ant-upload {
  height: 70px !important;
}

.week-navigation {
  width: 270px;
}

.frm-cnc .anticon-loading {
  margin: 0 !important;
}

.list-type .cat-icon {
  width: 20px;
  line-height: 27px;
}

.list-task .route-task {
  height: 22px;
  overflow: hidden;
  width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.week-navigation .no-pendings {
  width: 100%;
  text-align: center !important;
}

.filter-info .hide {
  display: none;
}
