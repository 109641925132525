.header {
  padding: 5px 15px 5px 37px;

  display: grid;
  grid-template: 20px / 2.7fr 1.5fr 1fr;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 17%);
  grid-column-gap: 5px;

  box-sizing: border-box;

  & > div {
    display: flex;
  }

  & .columns {
    display: inline-block;
    flex: 1 1 52px;

    color: #121212;
    font-size: 12px;
    font-weight: 500;
    text-align: center;

    border-right: 0.5px solid #d1d1d1;
    overflow: hidden;
    &.table_column {
      &:first-child {
        flex-grow: 4;
        border-left: none;
      }
    }
  }

  & .column_commited {
    flex-grow: 2;
  }

  & .columns.rfv {
    flex-grow: 5;
  }
}

.activity__row {
  margin: 0 15px 0 37px;
  height: calc(100vh - 247px);
  overflow: hidden scroll;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #c4c4c4;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #121212;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #121212;
  }
}

.week_widget {
  width: 400px;
  /* margin: 10px auto; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.loading-container {
  width: 100%;
  margin-top: 30vh;
}
