.calendarContainer {
  display: grid !important;
  grid-template: auto / repeat(3, 1fr);

  margin: 25px 0 12px 0;
  padding: 0 10px;
  box-sizing: border-box;
}

.calendar {
  justify-self: center;
  align-self: center;
}

.actions {
  width: 100%;
  /* grid items*/
  justify-self: end;
  align-self: end;

  /* flexbox */
  display: flex !important;
  justify-content: space-between;
  align-items: baseline;
}

.actions div span {
  margin-left: 8px;
}

/*
 * ===================
 * Assignment Section
 * ===================
 * */
.missGeoVictoria {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 80vh;
}

.assigmentSection {
  display: grid !important;
  grid-template-columns: 0 25% calc(75% - 30px);
  grid-column-gap: 15px;
  grid-template-rows: 75vh;
  box-sizing: border-box;
  padding: 0 10px 0 0;
  overflow: hidden;
}

.sectionTitle {
  font-size: 18px;
  margin: 0 0 23px 15px;
  color: var(--primary);
}

.resourcesTitle {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.resourcesTitle p {
  margin: 0 23px 0 15px;

  color: #858585;
  font-weight: 400;
  font-size: 13px;
  font-style: italic;
}

.resources {
  width: 100%;
}

.col {
  padding: 0px 5px 5px 5px;
  border-radius: 5px;
  box-shadow: 0 3px 1px 1px rgb(0 0 0 / 20%);
  height: 98%;
  overflow: hidden;
}

/*
 * ========================
 * Skeleton screen loading
 * ========================
 * */
.loading {
  position: relative;
  background-color: #e2e2e2;
  z-index: 10;
}

.loading::after {
  display: block;
  z-index: 1;
  content: '';
  position: absolute;

  width: 100%;
  height: 100%;
  transform: translateX(-100%);

  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  animation: Loading 1.5s infinite;
}

.calendar.loading {
  height: 58px;
  width: 120px;
  border-radius: 5px;
}

/* ==== Activities selection section  ==== */
.resources.loading {
  height: 1em;
  border-radius: 5px;

  overflow: hidden !important;
}

/* == Grid == */
.resourcesGrid {
  border-top: 0.5px solid #e3e3e3;
}

.resourcesGrid > div {
  overflow: hidden !important;
}

.workers.loading,
.resourcesGrid.loading {
  border-radius: 5px;
}

/*
 * ===> Animation
 * */
@keyframes Loading {
  to {
    transform: translateX(100%);
  }
}
