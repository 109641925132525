@import '../../../../../stylesheets/base';
@import '../../../../../assets/styles/variables.scss';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 23px;

  & .content__text {
    margin: 32px 0 20px 0;

    & p {
      text-align: center;
      color: $white;
      font-size: 16px;
      font-weight: 500;
      line-height: 10px;
    }
  }

  & .content__icon {
    & img {
      width: 48px;
      height: 48px;
    }
  }

  & .btn {
    border: none;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    height: 32px;

    &.cancel {
      color: $black;
      background-color: $white;
      margin-right: 5px;
    }

    &.close {
      background-color: $primary;
      color: $black;
      margin-left: 5px;
    }
  }
}

.facelifted-modal {
  :global .ant-modal-content {
    background-color: $black;
    border-radius: $large-border-radius;

    .ant-modal-header {
      margin: 0;
      background-color: $black;
      border-top-left-radius: $large-border-radius;
      border-top-right-radius: $large-border-radius;
      border-bottom-width: 0 !important;
      padding: 10px 20px;
    }

    .ant-modal-title {
      color: $white !important;
      font-weight: 700;

      i {
        margin-right: 8px;
      }
    }

    .ant-modal-close-x {
      height: auto;
      width: auto;
      line-height: initial;
      margin: 12px 18px;
      transform: scale(1.2, 1.1);

      path {
        fill: $primary;
      }
    }
  }

  :global .ant-modal-body {
    padding-bottom: 20px;

    * {
      color: $white;
      font-size: $font-xs;
    }
  }

  :global .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 24px;

    button {
      cursor: pointer;
      border-width: 0;
      border-radius: $default-border-radius;
      font-weight: 500;
      padding: 5px 15px;
      transition: background-color $short-transition-duration ease-in;
    }

    button:not([data-type='main']) {
      background-color: $white;

      &:hover {
        background-color: $gray-20;
      }
    }

    [data-type='main'] {
      background-color: $brand-orange-40;
      border-color: $brand-orange-40;
      color: $white;

      &:hover {
        background-color: $brand-orange-80;
      }
    }
  }

  :global .ant-radio-group {
    p {
      padding-left: 24px;
      font-weight: 300;
      color: $gray-20;
    }

    .ant-radio-inner {
      border-color: $gray-80;
      background-color: transparent;
      transform: scale(0.9);
    }
  }

  :global .ant-radio-checked .ant-radio-inner {
    border-color: $brand-orange-40 !important;

    &::after {
      background-color: $brand-orange-40;
    }

    &:hover {
      border-color: $brand-orange-80;
    }
  }

  :global .ant-radio-wrapper-disabled {
    opacity: 0.5;
  }

  input,
  textarea {
    border-radius: $default-border-radius;
    display: block;
    background-color: $bluish-gray-70;
    padding: 3px 6px;
    height: auto;
    line-height: 18px;
    border-width: 0;

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $bluish-gray-60;
      outline-width: 0;
    }
  }

  label {
    color: $gray-20;
  }
}

.saved-baselines-modal__grid-header {
  background-color: $white;
  border-top-left-radius: $default-border-radius;
  border-top-right-radius: $default-border-radius;

  :global .ant-col {
    padding: 6px 20px;
    color: $bluish-gray-80;
    font-size: $font-xs;

    &:not(:last-child)::after {
      content: '';
      border-right: 1px solid $gray-20;
      height: 10px;
      width: 0;
      display: block;
      position: absolute;
      top: 10px;
      right: 0;
    }
  }
}

.saved-baselines-modal__grid-content {
  padding: 0;
  overflow: auto;
  max-height: 400px;

  div {
    color: $white;
  }

  :global .ant-col {
    padding: 0 20px;
    height: 38px;
    line-height: 38px;
    white-space: nowrap;
    font-size: $font-xs;

    &:nth-child(odd) {
      background-color: $bluish-gray-70;
    }

    &:nth-child(even) {
      background-color: $bluish-gray-50;
    }
  }

  :global .ant-row:last-child {
    .ant-col:first-child {
      border-bottom-left-radius: $default-border-radius;
    }

    .ant-col:last-child {
      border-bottom-right-radius: $default-border-radius;
    }
  }

  :global .ant-row:not(:last-child) .ant-col:nth-child(even) {
    border-bottom: 1px solid $bluish-gray-60;
  }
}

.saved-baselines-modal__text-cell span {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.saved-baselines-modal__avatar-cell img {
  border-radius: 50%;
  width: 15px;
}

.saved-baselines-modal__actions-cell {
  align-items: center;

  &:global(.ant-col) {
    display: flex;
  }

  > div {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  > * {
    display: inline-block;
    font-size: 0;
    line-height: 30px;
    padding: 0 8px;
    margin: 0 7px;
  }
}

.new-baseline-modal :global .ant-modal-body {
  display: flex;

  :global(.ant-radio-wrapper) {
    white-space: normal;
  }

  h3 {
    font-weight: 300;
    margin-bottom: 20px;

    strong {
      font-weight: 600;
    }
  }

  label {
    display: block;
  }

  input,
  textarea {
    width: 100%;
    margin: 5px 0 15px;
  }

  textarea {
    min-height: 100px;
  }

  p {
    margin-bottom: 0;
  }
}

.new-baseline-modal :global .ant-modal-footer {
  padding-top: 0;
}

.new-baseline-modal__section {
  flex: 1 1 0;
}

.work-hours-modal {
  &__content {
    label {
      display: flex;
      align-items: center;

      input {
        margin-left: auto;
        padding-left: 5px;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}
