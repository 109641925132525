.admin-main-layout {
  margin-left: 0px;
}
.admin-main-slider {
  background-color: #efefef !important;
  width: 170px !important;
  height: 1000px !important;
}
.admin-main-contenct {
  margin-left: 100px;
}

.admin-admin-main-menu {
  width: 170px !important;
  background-color: red !important;
}

.admin-main-slider .ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right .admin-main-menu {
  width: 170px !important;
  background-color: #efefef !important;
  color: black !important;
}

.admin-main-slider .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #efefef !important;
}
