.gm-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 6px;
  margin-left: 7px;
  margin-right: 10px !important;
}

.massive-tag-class .ant-popover-inner {
  position: relative;
  top: -8px !important;
}

.massive-tag-class .select-search__input {
  position: relative;
  top: 1px;
  border-bottom: 1px solid #3a4f56;
  color: var(--white);
}

.massive-tag-class .select-search__input,
.massive-tag-class .select-search__select {
  background-color: var(--black);
}

.massive-tag-class .select-search__value::after {
  background-image: url('../../assets/img/gantt/search-new-orange.png') !important;
  background-repeat: no-repeat;
  background-size: 12.6px;
  background-position-x: 100%;
  background-position-y: 2px;
  width: 14px;
  height: 20px;
}

.massive-tag-class .create-sub-btn {
  color: var(--primary);
  position: relative;
  top: 1px;
}

.massive-tag-class .select-search--multiple {
  background-color: #3a4f56;
  height: 145px !important;
}

.massive-tag-class .remove-sub-text,
.massive-tag-class .remove-sub-x {
  color: var(--white);
}

.massive-tag-class .remove-option-sub-custom {
  left: 28px;
}

.custom-proplanner-tag .select-search__row {
  width: 100% !important;
}

.massive-tag-class .select-search--multiple .select-search__select {
  height: 190px !important;
}

.massive-tag-class .select-search {
  height: 210px !important;
  background-color: #25353a !important;
}

.massive-tag-class .select-search__group-header {
  position: relative;
  top: -5px;
}

.massive-tag-class .select-search__value::after {
  top: 8px;
}
