@import '../../assets/styles/variables.scss';

.modal-remove-task-lookahead {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .modal-remove-task-lookahead__icon {
    i {
      img {
        width: 48px;
        height: 48px;
      }
    }
  }

  .modal-remove-task-lookahead__text {
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
      font-size: 16px;
      line-height: 18px;
      color: $white;
      text-align: center;
    }
  }

  .modal-remove-task-lookahead__buttons {
    display: flex;
    gap: 10px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      padding: 0px 20px;
      border-radius: 5px;
      color: $black;
      border: 0;
      transition-duration: 0.3s;

      &.buttons__cancel {
        background-color: $cool-gray;

        &:hover {
          background-color: $gray-1;
        }
      }

      &.buttons__continue {
        background-color: $primary;

        &:hover {
          background-color: $dark-mint;
        }
      }
    }
  }
}
