@import '../../../../assets/styles/variables.scss';

.toolbar-filters-header {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  padding: 0 28px;
  z-index: 998;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;

  .filters-header__left {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 32px;

    .left__search {
      width: 200px;
      height: 32px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
        width: 200px;
        height: 32px;
        padding: 0 40px 0 16px;
        border-radius: 5px;
        background-color: $cool-gray;
        border: 1px solid $cool-gray;
        font-size: 13px;
        font-weight: 300;
        line-height: 15px;
        color: $dark-gray;
        transition: 0.3s;

        &:focus {
          border-color: $dark-mint;
        }
      }

      .search__icon {
        position: absolute;
        right: 16px;
      }
    }

    .left__dropdown {
      width: 162px;
    }
  }

  .filters-header__right {
    display: flex;
    align-items: center;
    gap: 5px;

    .right__create-project {
      button {
        height: 32px;
        padding: 0 20px;
        border-radius: 5px;
        background-color: $primary;
        border: 0;
        transition: 0.3s;
        display: flex;
        align-items: center;

        &:hover {
          background-color: $dark-mint;
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        span {
          font-size: 12px;
          font-weight: 400;
          color: $black;
        }

        .create-project__icon {
          font-size: 16px;
          margin-right: 5px;
        }
      }
    }
  }
}

.left_buttons {
  display: flex;
  align-items: center;
  gap: 5px;

  .left_buttons__button {
    width: 32px;
    height: 32px;
    background-color: $gray-2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    position: relative;

    .button__count {
      position: absolute;
      top: -5px;
      right: -5px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
      min-width: 15px;
      min-height: 15px;
      border-radius: 100%;
      background-color: $primary;
      font: $regular-xs-font;
      color: $black;
    }

    .button__icon {
      i {
        img {
          width: 16px;
        }
      }
    }
  }

  .left_buttons__clear {
    background-color: transparent;
    border: 0;
    color: $dark-mint;
    font: $regular-s-font;
    outline: none;
  }
}
