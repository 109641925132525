.row {
  height: 30px;
  display: flex;
}

.row .activity-name div {
  flex-basis: 30px;
  background-color: #efefef91;
  margin-right: 11px;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.row > span {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 52px;

  font-weight: 300;
  font-size: 12px;
  color: #121212;

  border-bottom: 0.2px solid #d1d1d154;
}

.row > span:first-child {
  display: flex;
  justify-content: flex-start;
  padding-left: 12px;
}

.row > span:nth-child(even) {
  background-color: #efefef4d;
}

.row > span:first-child {
  flex-grow: 4;
}

.row > .column__commited {
  flex-grow: 2;
}

.row > .column__commited {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
}

.row > .column__commited :global(.ant-input-number-input) {
  padding: 0 !important;
}

.row > .column__commited :global(.custom-input-number-planification) {
  width: 65% !important;
}

.row > .column__commited :global(span) {
  margin-left: 0;
}

.responsable {
  display: block;
  width: 16px;
  height: 16px;
  background-color: #efefef;

  border-radius: 100%;
  overflow: hidden;

  transition: transform 0.3s ease-in-out;
}

.responsable:not():first-child {
  border: 1px solid red;
}

.responsable:hover {
  cursor: pointer;
  transform: scale(1.5);
}

.responsable img {
  display: flex;
  height: 100%;
  width: 100%;
}

.responsable.no-img {
  background-color: #121212cf;
  color: #ffffff;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}

.taskName {
  font-style: normal;
  width: 80%;
  display: flex;
  align-items: center;
}

.taskName > span {
  cursor: default;
}

.border-subcontract {
  border-bottom: 0.6px solid #efefef;
}

.parent-task-color {
  background-color: #f5f5f5cc;
}

.total__users {
  font-weight: 300;
  font-size: 12px;
  color: #121212;
  font-style: normal;

  margin-left: 3px;
}
