.activities-view-takt {
  top: 50%;
  transform: translateY(-50%);
  position: relative;
}

.retakt-btn .icon-retakt {
  opacity: 0;
}

.retakt-btn:hover .icon-retakt {
  opacity: 1;
}

.header-retakt {
  margin-bottom: 30px;
  margin-top: 4px;
}

.main-retakt-container .ant-popover-inner {
  border-radius: 10px !important;
}

.re-takt-container {
  scroll-behavior: smooth;
}
