@import '../../../assets/styles/variables.scss';

.input-design-system {
  display: flex;
  flex-direction: column;

  .input-design-system__label {
    font: $regular-s-font;
    margin-bottom: 10px;
  }

  input {
    width: 100%;
    border-radius: 5px;
    padding: 0 10px;
    font: $regular-s-font;
  }

  .input-design-system__hint {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    height: 14px;
    margin-top: 5px;
  }

  &.theme--light {
    .input-design-system__label {
      color: $black;
    }

    .ant-input-affix-wrapper:not(.search-box):hover
      .ant-input:not(.ant-input-disabled) {
      border-color: $dark-mint;
      border-right-width: 1px !important;
    }

    input {
      background-color: $cool-gray;
      border: 1px solid $cool-gray;
      color: $black;

      &:focus,
      &:hover {
        border-color: $dark-mint;
      }

      &:disabled {
        opacity: $disabled-opacity;
        border: $cool-gray;
      }
    }

    .input-design-system__hint {
      color: $dark-gray;
    }

    &.error {
      .ant-input-affix-wrapper:not(.search-box):hover
        .ant-input:not(.ant-input-disabled) {
        border-color: $red;
      }

      input {
        border-color: $red;
        background-color: $lighter-red;
      }

      .input-design-system__hint {
        color: $red;
      }
    }

    .ant-input-suffix {
      i {
        color: $dark-mint;
      }
    }
  }

  &.theme--dark {
    .input-design-system__label {
      color: $white;
    }

    .ant-input-affix-wrapper:not(.search-box):hover
      .ant-input:not(.ant-input-disabled) {
      border-color: $primary;
      border-right-width: 1px !important;
    }

    input {
      background-color: $dark-green;
      border: 1px solid $dark-green;
      color: $white;

      &:focus,
      &:hover {
        border-color: $dark-mint;
      }

      &:disabled {
        opacity: $disabled-opacity;
        border: $dark-green;
      }
    }

    .input-design-system__hint {
      color: $white;
    }

    &.error {
      .ant-input-affix-wrapper:not(.search-box):hover
        .ant-input:not(.ant-input-disabled) {
        border-color: $light-red;
      }

      input {
        border-color: $light-red;
        background-color: $deep-dark-red;
      }

      .input-design-system__hint {
        color: $light-red;
      }
    }

    .ant-input-suffix {
      i {
        color: $white;
      }
    }
  }
}
