.grid-settings-user-general-plugin {
  display: grid;
  grid-template-columns: 12% 20% 20% 20%;
  margin-top: 10px;
  grid-gap: 15px;
}

.grid-settings .projects-title {
  color: #2c3421;
}

.grid-settings .div-projects {
  margin-left: 127px;
}

.image-settings-user {
  height: 110px;
  width: 120px;
  margin-right: 20px;
  border-radius: 50px;
}

.btn-handle-password {
  margin-top: 38px;
  text-align: center;
  user-select: none;
  padding: 0.15rem 0.1rem;
  line-height: 1.5;
  display: inline-block;
  cursor: pointer;
  color: var(--black);
  width: 60%;
  height: 32px;
  border-radius: 5px;
  background-color: var(--primary);
  transition: 0.3s;
  border: 0;
}

.btn-handle-password:focus {
  outline-color: transparent;
  outline-style: none;
}
.btn-handle-password:hover {
  transition: 0.3s;
  background-color: var(--primary);
}

.grid-settings-user-general-plugin .avatar-user-settings,
.grid-settings .avatar-user-settings {
  color: var(--black) !important;
}

.btn-handle-cambiar {
  margin-top: 5px;
  text-align: center;
  user-select: none;
  padding: 0.15rem 0.1rem;
  line-height: 1.5;
  display: inline-block;
  cursor: pointer;
  color: var(--black);
  height: 28px;
  border-radius: 5px;
  background-color: var(--primary);
  transition: 0.3s;
  padding: 0 10px;
  border: 0;
}

.btn-handle-cambiar:focus {
  outline-color: transparent;
  outline-style: none;
}
.btn-handle-cambiar:hover {
  transition: 0.3s;
  background-color: var(--primary);
}

@media (min-width: 1280px) {
  .image-settings-user {
    height: 80px;
    width: 90px;
    margin-right: 23px;
    border-radius: 50px;
  }
  .grid-settings-user-general-plugin {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 10px;
  }
  .camera-general-user {
    position: relative;
    left: 73px;
    bottom: 17px;
    background-color: var(--primary);
    border-radius: 64px;
  }
  .descripion-form {
    font-size: 12px;
  }
}

@media (min-width: 1542px) {
  .image-settings-user {
    height: 80px;
    width: 90px;
    margin-right: 23px;
    border-radius: 50px;
  }
  .grid-settings-user-general-plugin {
    display: grid;
    grid-template-columns: 10%;
    margin-top: 10px;
  }
  .camera-general-user {
    position: relative;
    left: 73px;
    bottom: 17px;
    background-color: var(--primary);
    border-radius: 64px;
  }
  .descripion-form {
    font-size: 12px;
  }
}

@media (min-width: 1577px) {
  .image-settings-user {
    height: 80px;
    width: 90px;
    margin-right: 23px;
    border-radius: 50px;
  }
  .grid-settings-user-general-plugin {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 10px;
  }
  .camera-general-user {
    position: relative;
    left: 73px;
    bottom: 17px;
    background-color: var(--primary);
    border-radius: 64px;
  }
  .descripion-form {
    font-size: 12px;
  }
}

@media (min-width: 1812px) {
  .image-settings-user {
    height: 80px;
    width: 90px;
    margin-right: 23px;
    border-radius: 50px;
  }
  .grid-settings-user-general-plugin {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 10px;
  }
  .camera-general-user {
    position: relative;
    left: 73px;
    bottom: 17px;
    background-color: var(--primary);
    border-radius: 64px;
  }
  .descripion-form {
    font-size: 12px;
  }
}

@media (min-width: 1799px) {
  .image-settings-user {
    height: 80px;
    width: 90px;
    margin-right: 23px;
    border-radius: 50px;
  }
  .grid-settings-user-general-plugin {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 10px;
  }
  .camera-general-user {
    position: relative;
    left: 73px;
    bottom: 17px;
    background-color: var(--primary);
    border-radius: 64px;
  }
  .descripion-form {
    font-size: 12px;
  }
}

@media (min-width: 2323px) {
  .image-settings-user {
    height: 80px;
    width: 90px;
    margin-right: 23px;
    border-radius: 50px;
  }
  .grid-settings-user-general-plugin {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 10px;
  }
  .camera-general-user {
    position: relative;
    left: 73px;
    bottom: 17px;
    background-color: var(--primary);
    border-radius: 64px;
  }
  .descripion-form {
    font-size: 12px;
  }
}

@media (min-width: 2187px) {
  .image-settings-user {
    height: 80px;
    width: 90px;
    margin-right: 23px;
    border-radius: 50px;
  }
  .grid-settings-user-general-plugin {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 10px;
  }
  .camera-general-user {
    position: relative;
    left: 73px;
    bottom: 17px;
    background-color: var(--primary);
    border-radius: 64px;
  }
  .descripion-form {
    font-size: 12px;
  }
}
