@import '../../stylesheets/base';

.modification-requests-drawer {
  @extend %inverted-scrollbar;

  overflow: auto;
  width: 450px;

  &__filter-buttons {
    width: 100%;

    li {
      display: flex;
      width: 100%;
    }

    button {
      flex: 1 1 0;
      padding: 0;
    }

    img {
      width: 18px;
    }
  }

  &__requests-accordion {
    margin-top: 20px;
  }

  &__no-data-message {
    font-size: $font-xs;
    color: $gray-20;
    margin-top: 20px;
    display: inline-block;
  }

  &--preview-mode {
    opacity: 0.1;

    &:hover {
      opacity: 0.9;
    }
  }
}
