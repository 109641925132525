/**
* =============
* ANTD popover
* =============
*/

.widget_custom .ant-popover-inner-content {
  padding: 6px 0 14px 0 !important;
  width: 118px;
}
