@import '../../../../assets/styles/variables.scss';

.signup__third-screen-b {
  width: 420px;
  position: relative;
  z-index: 1;
  background-color: $white;
  padding: 50px 50px 32px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .third-screen-b__logo {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .third-screen-b__body {
    display: flex;
    flex-direction: column;

    .body__title {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 30px 0;

      h6 {
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
        color: $black;
        margin: 0;
      }
    }

    .body__form {
      width: 100%;
      display: grid;
      grid-template-rows: repeat(5, auto) 40px;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px 20px;
      margin-bottom: 30px;

      .form__inputs {
        width: 150px;
      }

      .form__company {
        width: 100%;
        grid-row: 2/3;
        grid-column: 1/3;
      }

      .form__company-size,
      .form__company-type {
        width: 150px;
        display: flex;
        flex-direction: column;

        label {
          font-size: 12px;
          line-height: 14px;
          font-weight: 400;
          margin-bottom: 10px;
          color: $black;
        }
      }

      .form__password {
        width: 100%;
        grid-row: 4/5;
        grid-column: 1/3;
        margin-bottom: 10px;
      }

      .form__password2 {
        width: 100%;
        grid-row: 5/6;
        grid-column: 1/3;
      }

      .form__button {
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-row: 6/7;
        grid-column: 1/3;

        button {
          width: 320px;
          height: 40px;
          color: $black;
          background-color: $primary;
          font-size: 16px;
          line-height: 18px;
          font-weight: 400;
          border: 0;
          border-radius: 5px;
          transition-duration: $transition-duration-default;
          outline: none;

          &:hover {
            background-color: $dark-mint;
          }

          &:disabled {
            background-color: $gray-2;
            color: $gray-1;
          }
        }
      }
    }

    .body__hint {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 0 20px;

      span {
        font-size: 12px;
        line-height: 14px;
        font-weight: 300;
        color: $black;

        a {
          color: $black;
          text-decoration: underline;
        }
      }
    }
  }
}
