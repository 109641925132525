@import '../../../assets/styles/variables.scss';

.radio-design-system {
  .ant-radio-wrapper {
    display: flex;
    align-items: center;

    &:is(:not(:last-child)) {
      margin-bottom: 10px;
    }

    .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: none;
    }

    span.ant-radio + * {
      padding: 0 10px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
    }

    .ant-radio-inner {
      background-color: transparent;
    }
  }

  &.size--small {
    .ant-radio-wrapper {
      span.ant-radio + * {
        padding: 0 5px;
      }

      .ant-radio {
        .ant-radio-inner {
          width: 12px;
          height: 12px;

          &::after {
            top: 2px;
            left: 2px;
            width: 6px;
            height: 6px;
          }
        }
      }
    }
  }

  &.theme--light {
    .ant-radio-wrapper {
      span.ant-radio + * {
        color: $black;
      }

      .ant-radio {
        .ant-radio-inner {
          border-color: $gray-2;

          &::after {
            background-color: $dark-mint;
          }
        }

        &.ant-radio-checked {
          .ant-radio-inner {
            border-color: $dark-mint;
          }

          &::after {
            border-color: $dark-mint;
          }
        }
      }
    }
  }

  &.theme--dark {
    .ant-radio-wrapper {
      span.ant-radio + * {
        color: $white;
      }

      .ant-radio {
        .ant-radio-inner {
          border-color: $bed-rock;

          &::after {
            background-color: $primary;
          }
        }

        &.ant-radio-checked {
          .ant-radio-inner {
            border-color: $primary;
          }

          &::after {
            border-color: $primary;
          }
        }
      }
    }
  }
}
