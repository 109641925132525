.title-resources {
  color: #121212;
  padding: 15px 32px;
  top: 16px;
  position: relative;
  left: 13px;
}

.title-resources span {
  padding-left: 5px;
  font-size: 14px;
  font-weight: 300;
  line-height: 12px;
  color: #121212;
}

.card-resource {
  padding-left: 4px !important;
  height: auto !important;
}

.resource-fixed-tree-table-header div {
  font-size: 14px !important;
  background-color: white !important;
  /* padding: 0 47px !important; */
  max-height: 15px !important;
  /* overflow: hidden !important; */
  margin-top: 5px;
  margin-bottom: 5px !important;
  margin-left: 8px !important;
  margin-right: 0px;
  text-align: center;
}
