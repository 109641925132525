.grid-categoria {
  display: grid;
  grid-template-columns: 30% 70%;
  margin-bottom: 50px;
  grid-gap: 10px;
}
/* ICONS CATEGORY LEFT */
.grid-icons-category {
  display: grid;
  grid-template-columns: 60% 40%;
}

.grid-icons-category .flexbox-fix {
  display: none !important;
}

.picker-wrapp {
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius-topleft: 5px !important;
}
