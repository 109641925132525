.grid-add-user-modal {
  display: grid;
  grid-template-columns: 45% 45% 10%;
  margin-top: 20px;
}

.close-add-user {
  width: 100%;
  text-align: center;
  padding: 7px;
  font-size: 16px;
  padding: 8px;
  color: #f59d04;
}

.close-add-user span {
  cursor: pointer;
}

.frmModalAdd .title-frm-add {
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #2c3421;
  padding-bottom: 5px;
  margin-bottom: 12px;
}

.frmModalAdd .title-close {
  color: #d1d1d1;
  float: right;
  cursor: pointer;
  font-size: 16px;
}

.frmModalAdd .back-arrow {
  display: inline;
  cursor: pointer;
}

.frmModalAdd .input-add-subcontract {
  background-color: var(--dark-green);
  border: none;
  border: 1px solid #2c3421;
  border-radius: 5px;
  outline: none;
  height: 28px;
  width: 90%;
  font-size: 12px;
  margin: 10px 0 8px 0;
  padding: 5px;
  font-size: 12px;
}

.frmModalAdd .skip-link {
  color: #2c3421;
  cursor: pointer;
}

.frmModalAdd .img-subcontract {
  height: 70px;
  width: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.wrapp-sub-contract .ant-upload {
  height: 90px !important;
}

.wrapp-sub-contract .img-logo {
  width: 100%;
}

.frm-resources {
  position: relative;
  top: 15px;
}

.row-form {
  display: flex !important;
  align-items: flex-end;
}

.material-icon-frm {
  position: relative;
  top: 6px;
}

.frm-resources .col-frm {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.frm-resources .label-input-up {
  align-self: flex-start;
  margin: 0;
  padding: 0;
  margin-left: 2px;
  font-size: 10px !important;
}

.frm-resources .input-add {
  background-color: transparent;
  border: none;
  border: 1px solid #2c3421;
  border-radius: 5px;
  outline: none;
  height: 28px;
  width: 90%;
  font-size: 12px;
  margin: 0px 0 8px 0;
  padding: 5px;
  font-size: 12px;
}

.frm-resources .input-add-unit {
  width: 20%;
}
