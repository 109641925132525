.filters {
  margin: 0 10px 0 3px;

  & .search-box {
    display: flex;
    justify-content: space-between;
    position: relative;

    & .range-picker {
      position: absolute;
      top: 40px;
      z-index: 1;
    }

    & :global .ant-select-search {
      width: 290px;

      & :global .ant-select-search__field {
        width: 100% !important;

        & :global .ant-input-suffix {
          font-size: 12px;
        }

        & svg {
          fill: #121212;
        }
      }

      & :global input {
        background-color: #f5f5f5;
        border-radius: 5px;
        border: none;

        & :global input:placeholder {
          color: #121212;
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
  }

  & .calendar--btn {
    width: 32px;
    height: 32px;
    margin-right: 1px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    background-color: #f5f5f5;

    cursor: pointer;
  }
}
