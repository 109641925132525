.content_pdf {
  position: relative;
  margin-left: 15px;
}

.img_proyecto {
  position: relative;
  width: 30px;
  height: 30px;

  top: 19.26px;
}
.text_header {
  position: relative;
  margin-left: 40px;
  margin-top: -10px;
}

.title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 11px;
  /* identical to box height */

  color: #121212;
}

.title_project {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 9px;
  margin-top: -6px;
  color: #121212;
}
