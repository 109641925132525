/* Brand Colors */
$ipsum-blue: #25a8ba;
$ipsum-dark-blue: #155d77;
$ipsum-orange: #7dff8a;

/* Achromatic Colors */
$black: #333333;
$dark-gray: #121212;
$dark-gray-2: #747474;
$medium-gray: #a3a3a3;
$gray: #c4c4c4;
$light-gray: #d1d1d1;
$lighter-gray: #efefef;
$white: #ffffff;

/* Ipsum Colors Variants */
$ipsum-db-princ: #151e21;
$ipsum-db-sec: #3e482d;
$ipsum-grayblue: #3a4f56;
$ipsum-lgb: #485b61;
$ipsum-lightblue: #88dce7;
$ipsum-lb-princ: #ddf5f8;
$ipsum-d-orange: #bc5b10;
$ipsum-l-orange: #fdf3eb;

/* Other Colors */
$dark-blue: #0e5381;
$blue: #2c3421;
$light-blue: #b5ddf7;
$dark-green: #1f6600;
$green: #34af00;
$light-green: #70ff33;
$dark-red: #8e0101;
$red: #e50101;
$light-red: #fa7676;
$dark-yellow: #a16702;
$yellow: #f59d04;
$light-yellow: #fdd99b;
$dark-purple: #510153;
$purple: #9e00a1;
$light-purple: #f276f4;
$warning: #fefaf2;
$error: #fce6e6;
$dark-black: #000000;

/* Company / Tag Color */
$sb-red: #f7b3b3;
$sb-light-red: #ffc2c2;
$sb-orange: #ffceb3;
$sb-yellow: #fdebcd;
$sb-olive: #dfd8b5;
$sb-green: #b4e7b4;
$sb-dark-green: #b5d8b5;
$sb-greenish-blue: #b4cbcb;
$sb-cyan-blue: #b3e0e7;
$sb-light-blue: #b8cffb;
$sb-royal-blue: #b5bff6;
$sb-red-purple: #dcb4ef;
$sb-pink-purple: #f1b4d1;
$sb-pink: #ffc7e2;
$sb-gray: #cdcdcd;
$sb-dark-gray: #c2c2c2;

$disabled-opacity: 0.5;
$separation-line: #5f696d;
$border-color-check: #688a96;

@mixin web-kit-user-select() {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
