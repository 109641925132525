@import '../../stylesheets/base';
@import '../../assets/styles/variables.scss';

.modal-message {
  &__content {
    &--title {
      margin: 20px 0 40px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      h5 {
        margin: 0;
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
        color: $white;
      }
    }
  }

  &__buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    button {
      height: 32px;
      border: 0;
      border-radius: 5px;
      padding: 10px;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      transition: 0.3s;
      min-width: 130px;
      &.buttons--cancel {
        background-color: $cool-gray;
        color: $black;
      }
    }
  }
}
