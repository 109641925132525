@import '../../stylesheets/base';
@import '../../assets/styles/variables.scss';

.text-input {
  border-radius: $default-border-radius;
  padding: 3px 6px;
  height: auto;
  line-height: 18px;
  border-width: 0;
  display: block;

  &:hover:not(&--invalid),
  &:focus:not(&--invalid),
  &:focus-visible:not(&--invalid) {
    outline-width: 0;
  }

  &:disabled {
    opacity: $disabled-opacity;
  }

  &--dark {
    background-color: $dark-green;

    &:not(:disabled) {
      &:hover,
      &:focus,
      &:focus-visible {
        background-color: $dark-green;
      }
    }
  }

  &--invalid {
    outline: 1px solid $red;
  }

  &--no-spin {
    &[type='number']::-webkit-outer-spin-button,
    &[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin-left: 2px;
  }

  &__wrapper {
    position: relative;

    &--date input {
      padding-right: 40px;
    }
  }

  &__calendar {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: $white;
    width: 400px;
    padding: 10px;
    box-shadow: rgba($black, 0.2) 0 2px 8px 0;
    border-radius: $default-border-radius;
  }

  &__calendar-button {
    background-color: transparent;
    position: absolute;
    border-width: 0;
    right: 0;
    top: 1px;

    &:disabled {
      opacity: 0.3;
    }
  }
}
