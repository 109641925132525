@import '~antd/dist/antd.css';

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: #1890ff00 !important;
  opacity: 0.1;
}

/*
.ant-input:focus {
    border-right-width: 0px !important;
} */

.ant-input:focus {
  border-color: #40a9ff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
  box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
}

.ant-input-number-focused {
  border: 0px !important;
  border-color: #40a9ff;
  border-right-width: 0px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
  box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
}

.ant-input-affix-wrapper:not(.search-box):hover
  .ant-input:not(.ant-input-disabled) {
  border-color: #40a9ff;
  border-right-width: 0px !important;
}

.custom-input-number-planification:hover {
  border: 0px !important;
  border-color: #40a9ff;
  border-right-width: 0px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
  box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
}

.custom-input-number-planification:focus {
  border: 0px !important;
  border-color: #40a9ff;
  border-right-width: 0px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
  box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
}

.gantt_drag_marker,
.gantt_drag_marker .gantt_row.odd {
  background-color: transparent !important;
  color: transparent !important;
  visibility: hidden !important;
}

.odd-col {
  background-color: #1212120a !important;
  height: 100%;
}

.gantt-visualization .non-odd-col {
  background-color: #1212120a !important;
  height: 100%;
}

.gantt-visualization .odd-col {
  background-color: transparent !important;
  height: 100%;
}

.gantt_cell {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.gantt_drag_marker.gantt_grid_resize_area {
  background-color: hsla(0, 0%, 91%, 0.5) !important;
}

.gridHoverStyle,
.gridSelection,
.timelineSelection {
  background-color: #ecffee !important;
}

.gantt_grid_data .gantt_row.odd:hover,
.gantt_grid_data .gantt_row:hover {
  background-color: #ecffee;
}

.gantt_grid_data .gantt_row.gantt_selected,
.gantt_grid_data .gantt_row.odd.gantt_selected,
.gantt_task_row.gantt_selected {
  background-color: #ecffee;
}

.gantt_task_row.gantt_selected .gantt_task_cell {
  background-color: #ecffee;
}

.gantt_tree_icon.gantt_blank {
  width: 18px;
}

.gantt_tree_icon.gantt_folder_open {
  width: 0% !important;
}

.gantt_tree_icon.gantt_file {
  width: 0% !important;
}

.add-responsable-new {
  float: left;
  position: relative;
  top: 0px;
  left: 5px;
  cursor: pointer;
}

.opacity-hover-style {
  opacity: 0;
}

.opacity-hover-style:hover {
  opacity: 0.65;
  transition: opacity 0.25s ease-in-out;
}

.opacity-hover-style:focus {
  opacity: 1;
}

.opacity-hover-style:active {
  opacity: 1;
}

.new-gantt-chart .gantt_grid_data .gantt_cell {
  border-right: none;
  color: #454545;
  height: 30px !important;
}

.item-gantt {
  cursor: pointer;
  display: block;
  text-align: left;
  margin: 0;
  padding: 0px !important;
  height: 30px;
  font-size: 12px;
}

.custom-gantt-subcontract .text-subcontract {
  font-size: 12px;
}
.subcontract-border__hover {
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subcontract-border__hover:hover {
  background-color: #e9e9e9 !important;
}

.text-subcontract-item {
  align-content: center;
  text-align: center;
  position: relative;
  top: 7;
}

.custom-gantt-responsable-dropdown .select-search__option.is-selected {
  background: #2c3421 !important;
  color: #fff;
  height: 30px;
}

.custom-gantt-responsable-dropdown .select-search {
  width: 202px;
  position: relative;
  box-sizing: border-box;
}

.custom-gantt-responsable-dropdown .ant-popover-inner-content {
  border-radius: 20px !important;
}

.custom-proplanner-tag .select-search__option.is-selected {
  opacity: 0.6;
  color: inherit !important;
}

.custom-proplanner-tag-init .select-search {
  height: 90px !important;
  max-width: 150px !important;
}

.custom-proplanner-tag .select-search {
  width: 150px;
  height: 150px;
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.custom-proplanner-tag .ant-popover-inner-content {
  border-radius: 20px !important;
}

.custom-proplanner-tag .select-search__options {
  align-items: center;
}

.custom-proplanner-tag .select-search__row {
  width: 89px;
  height: 29px;
}

.custom-proplanner-tag .select-search--multiple .select-search__option {
  width: 150px !important;
  height: 29px;
  text-align: center;
}

.custom-proplanner-tag .select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  min-height: 1px !important;
  height: 114px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
  overflow: hidden auto;
}

.custom-proplanner-tag .select-search__option {
  padding-left: 0px !important;
}

.custom-proplanner-tag .select-search__row:not(:first-child) {
  border-top: 0px solid #eee !important;
}

.massive-responsible-class .select-search__option.is-selected,
.massive-responsible-class .select-search__option.is-selected:hover {
  background: white !important;
  color: #565656 !important;
}

.checked-for-gantt {
  opacity: 0.3;
}

/** fix vertical position when we added left button on name (lh) */
.gantt-visualization .checked-for-gantt {
  position: relative;
  top: 2px;
}

.checked-for-gantt:hover {
  opacity: 0.6;
  transition: opacity 0.25s ease-in-out;
}

.checked-for-gantt-clicked {
  opacity: 0.8;
}

.tag-proplanner {
  padding: 0.6em 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #4c4c4c;
  font-size: 12px;
  font-weight: 600;
  border-radius: 2px !important;
  position: relative;
  z-index: 1;
  margin-top: 5px;
}

.tag-proplanner:before {
  content: '';
  position: absolute;
  height: 13px;
  width: 13px;
  background: inherit;
  left: -8%;
  top: 52%;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  z-index: -1;
  border-radius: 3px;
}

.tag-class {
  margin: 0 3px 0 0;
}

.tag-class-pdf,
.tag-class-span {
  display: none;
  margin: 0 3px 0 0;
}

.custom-tags-proplanner {
  display: inline-flex;
}

.custom-proplanner-tag .select-search__group-header {
  background: transparent;
  padding-bottom: 0px;
}

.custom-proplanner-tag .select-search__group {
  /* display: flex; */
  background: transparent;
}

.custom-create-tag-to-select-checkbox {
  align-content: center;
  text-align: center;
  position: relative;
  top: -6px;
  font-size: 12px;
  height: 22px;
  width: 111px;
  padding-top: 7px;
  margin: 0px;
  left: 0;
  text-transform: capitalize;
}

.select-search-remove-all-div {
  color: #121212;
  margin-top: 3px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
  font-size: 12px !important;
}

.custom-proplanner-tag .select-search__value::after {
  background-image: url('./assets/img/gantt/search-new.png');
  background-repeat: no-repeat;
  background-size: 12.6px;
  background-position-x: 100%;
  background-position-y: 2px;
  width: 14px;
  height: 20px;
}

.custom-proplanner-tag .select-search__input {
  font-size: 12px;
  padding-right: 32px;
  height: 30px;
}

.custom-gantt-responsable-dropdown .select-search__input {
  font-size: 12px;
  padding-right: 37px;
}

.custom-gantt-subcontract .subcontracts-options {
  min-width: 148px;
  max-height: 200px;
}

.delete-task-gantt-btn {
  font-size: 15px;
  color: #333333;
  position: relative;
  margin-right: 6px;
  top: -3px;
  font-weight: 400;
  cursor: pointer;
}

.add-btn-task-gantt {
  font-size: 26px;
  color: #333333;
  font-weight: 400;
  cursor: pointer;
}

.uncomplete-btn-task-gantt {
  font-size: 13px;
  color: #333333;
  position: relative;
  margin-right: 4px;
  margin-left: 4px;
  top: -4px;
  left: -3px;
  font-weight: 400;
  cursor: pointer;
}

.complete-btn-task-gantt {
  font-size: 13px;
  color: #333333;
  position: relative;
  margin-right: 4px;
  margin-left: 4px;
  top: -4px;
  font-weight: 400;
  cursor: pointer;
}

.complete-btn-task-gantt:hover .complete-hover-gantt {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.complete-btn-task-gantt:hover .complete-empty-gantt {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.uncomplete-btn-task-gantt:hover .uncomplete-hover-gantt {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.uncomplete-btn-task-gantt:hover .uncomplete-empty-gantt {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.add-btn-task-gantt:hover .add-hover-gantt {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.add-btn-task-gantt:hover .add-empty-gantt {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.request-btn-task-gantt:hover .request-hover-gantt {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.request-btn-task-gantt:hover .request-empty-gantt {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.delete-task-gantt-btn:hover .delete-hover-gantt {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.delete-task-gantt-btn:hover .delete-empty-gantt {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.delete-empty-gantt {
  opacity: 1;
  position: relative;
  left: 0px;
  top: -1px;
}

.delete-hover-gantt {
  opacity: 0;
  position: relative;
  left: -19px;
  top: -1px;
  transform: scale(1.2);
}

.add-empty-gantt {
  opacity: 1;
  position: relative;
  left: 29px;
  top: -1px;
}

.add-hover-gantt {
  opacity: 0;
  position: relative;
  left: 8px;
  top: -1px;
  transform: scale(1.2);
}

.request-hover-gantt {
  opacity: 0;
  position: relative;
  left: -19px;
  top: 0px;
}

.uncomplete-empty-gantt {
  opacity: 1;
  position: relative;
  left: 14px;
}

.uncomplete-hover-gantt {
  opacity: 0;
  left: -6px;
  position: relative;
}

.complete-empty-gantt {
  opacity: 1;
  position: relative;
  left: 11px;
}

.complete-hover-gantt {
  opacity: 0;
  left: -8px;
  position: relative;
}

/** NEW CHART GANTT STYLES**/

.new-project-gantt-style:before {
  content: '';
  position: absolute;
  height: 0px;
  width: 0px;
  left: -1px;
  z-index: 0;
  top: -1px;
  border-top: 4px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 7px solid #0e5381 !important;
}

.new-project-gantt-style:after {
  content: '';
  height: 0px;
  position: relative;
  float: right;
  width: 0px;
  z-index: 0;
  top: -6px;
  left: 1px;
  border-top: 4px solid transparent;
  border-bottom: 10px solid transparent;

  border-right: 7px solid #0e5381 !important;
}

.new-project-gantt-style {
  height: 7px !important;
  background: #0e5381 !important;
  border-color: #0e5381 !important;
  border-radius: 0px !important;
}

.new-project-gantt-style .gantt_task_progress {
  background: #0e5381 !important;
  border-color: #0e5381 !important;
}

.gantt_task_line {
  min-width: 7px !important;
}

.new-project-gantt-style .gantt_task_line.gantt_project {
  border-radius: 0px !important;
}

.new-links-gantt-style .gantt_link_line_right {
  background-color: #c4c4c4;
}

.new-links-gantt-style .gantt_link_line_down {
  background-color: #c4c4c4;
}

.new-links-gantt-style .gantt_link_line_left {
  background-color: #c4c4c4;
}

.new-links-gantt-style .gantt_link_line_up {
  background-color: #c4c4c4;
}

.new-links-gantt-style .gantt_link_arrow_right {
  border-left-color: #c4c4c4;
}

.new-links-gantt-style .gantt_link_arrow_left {
  border-right-color: #c4c4c4;
  margin-left: -1px;
}

.new-gantt-chart .gantt_link_direction {
  border-color: #9e00a2;
}

.new-gantt-chart .gantt_task_cell {
  display: inline-block;
  height: 100%;
  border-right: 1px solid #c4c4c445 !important;
}

.new-links-gantt-style .gantt_link_arrow {
  border-width: 4px !important;
  margin-top: -2px !important;
}

.new-parent-gantt-style {
  height: 22px !important;
  border-radius: 6px !important;
}

.new-child-gantt-style {
  height: 22px !important;
  border: 0px solid !important;
  border-radius: 6px !important;
}

.new-child-gantt-style .gantt_task_progress {
  background-color: inherit;
  opacity: 1 !important;
}

.new-gantt-chart .gantt_task_line.gantt_milestone {
  background-color: #9e00a2 !important;
  border: 0px solid #9e00a2 !important;
  border-radius: 0px;
}

.new-gantt-chart .gantt_task_line.gantt_milestone .gantt_task_content {
  border-radius: 0px;
}

.new-gantt-chart .gantt_marker {
  background-color: rgba(37, 168, 186, 1) !important;
}

.new-gantt-chart .gantt_marker_content::before {
  content: '';
  position: absolute;
  height: 0px;
  width: 0px;
  left: -2px;
  z-index: 0;
  top: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #25a8ba !important;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(91deg);
}

.new-gantt-chart .gantt_task .gantt_task_scale .gantt_scale_cell {
  border-right: 1px solid #c4c4c47a !important;
  border-bottom: 1px solid #c4c4c47a;
  border-left: none;
  font-size: 10px;
  font-weight: 600;
}

.new-gantt-chart .gantt_grid_scale,
.gantt_task_scale {
  box-shadow: none !important;
}

.custom-gantt-subcontract .text-subcontract {
  max-width: 144px !important;
}

.custom-gantt-subcontract .pre-text-subcontract {
  height: 10px;
  width: 10px;
  background-color: red;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  display: none !important;
}

.new-project-gantt-style.top-fix-style {
  margin-top: 4px !important;
}

.new-gantt-chart .gantt_marker_content {
  border-radius: 3px;
  font-weight: 500;
  background-color: rgba(37, 168, 186, 0.7) !important;
  position: relative;
  width: 48px;
  top: 7px;
}

.gantt_tree_icon.gantt_folder_closed {
  width: 0% !important;
}

.hidden-template-for-export {
  visibility: hidden;
}

.gantt_task_line.gantt_bar_task.gantt_critical_task {
  background-color: #e8a3a3 !important;
}

.gantt_task_line.gantt_bar_task.gantt_critical_task
  .gantt_task_progress_wrapper
  .gantt_task_progress {
  background-color: #e50101 !important;
}

.gantt_task_line.gantt_bar_task.gantt_critical_task
  .gantt_side_content
  .on-lookahead-new-gantt-counter-less {
  border-color: #e50101 !important;
  color: #e50101 !important;
}

.gantt_task_line.gantt_bar_task.gantt_selected.gantt_critical_task {
  background-color: #e8a3a3 !important;
}

.gantt_critical_link .gantt_line_wrapper > div {
  background-color: #e50101;
}

.gantt_critical_link .gantt_link_arrow {
  border-color: #e50101;
}

.gantt_task_line.gantt_bar_task.gantt_no_critical_task {
  background-color: rgba(211, 211, 211) !important;
}

.gantt_task_line.gantt_bar_task.gantt_no_critical_task
  .gantt_task_progress_wrapper
  .gantt_task_progress {
  background-color: rgba(179, 179, 179) !important;
}

.gantt_task_line.gantt_bar_task.gantt_no_critical_task
  .gantt_side_content
  .on-lookahead-new-gantt-counter-less {
  border-color: #a7a7a7 !important;
  color: #a7a7a7 !important;
}

.gantt_task_line.gantt_bar_task.gantt_selected.gantt_no_critical_task {
  background-color: rgba(211, 211, 211) !important;
}

.gantt_no_critical_link .gantt_line_wrapper > div {
  background-color: #c4c4c4;
}

.gantt_no_critical_link .gantt_link_arrow {
  border-color: #c4c4c4;
}

.new-gantt-chart .gantt_side_content.gantt_left {
  overflow: initial !important;
}

.new-gantt-chart .gantt_side_content.gantt_right {
  overflow: visible !important;
}

.overdue-extra-text-gantt {
  color: #e50101d9;
  font-size: 13px;
}

.advancement-extra-text-gantt {
  color: #34af00d9;
  font-size: 13px;
}

.right-side-title-gantt {
  font-size: 13px;
  display: inline-block;
  max-width: 800px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.right-new-gantt {
  position: relative;
  top: -12px;
  display: flex;
}

.on-lookahead-new-gantt-counter {
  background-color: white;
  border-radius: 100px;
  padding: 3px;
  font-size: 9px;
  position: relative;
  top: -11px;
  left: 41px;
  font-weight: bold;
}

.on-lookahead-new-gantt-counter-less {
  background-color: #fff;
  border-radius: 100px;
  padding: 2px;
  padding-left: 4px;
  padding-right: 5px;
  position: relative;
  top: -10px;
  left: 40px;
  font-weight: 700;
}

.solapate-responsable-gantt {
  padding: 0px !important;
  margin-right: -6px;
}

.gantt_popup_title {
  background-color: #151f22 !important;
  color: #ffffff;
  font-weight: 500;
  border-bottom: 0px !important;
}

.gantt_modal_box {
  border: 0px solid white !important;
}

.fit-left-img-link {
  position: relative;
  top: 10px;
  left: 18px;
}

.title-fit-modal-link {
  float: left;
  position: relative;
  left: 8px;
  line-height: 36px;
  font-size: 16px;
  font-weight: 500;
}

.input-modal-link-new {
  padding-top: 10px;
  padding-bottom: 12px;
}

.arrow-link-description {
  position: relative;
  left: 11px;
}

.gantt_link-save-btn_button {
  float: right;
  left: 29%;
  position: relative;
  color: #ffffff;
  background-color: #7dff8a !important;
  border: 0px solid white !important;
  font-weight: 600;
}

.gantt_link-delete-btn_button {
  float: left;
  color: #121212;
  border: 0px solid white !important;
  font-weight: 600;
}

.fit-right-img-link {
  float: right !important;
  position: relative;
  top: 10px;
  left: 4px;
  cursor: pointer;
}

.gantt_popup_controls.dhtmlx_popup_controls {
  display: flex;
}

.gantt_link-cancel-btn_button {
  position: relative;
  left: 28%;
  border: 0px solid white !important;
  font-weight: 600;
}

/** gantt modal */
.gantt-deleted-modal {
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 320px;
}

.gantt_modal_box.gantt-deleted-modal {
  position: absolute !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
}

.gantt-deleted-modal .gantt_ok_button {
  position: relative;
  left: 110px;
  border: transparent;
  height: 32px;
  top: -8px !important;
}

.gantt-deleted-modal .gantt_cancel_button {
  position: relative;
  left: -110px;
  border: transparent;
  height: 32px;
  color: var(--black) !important;
  font-weight: 500;
  top: -8px !important;
}

.gantt-deleted-modal .gantt_cancel_button div,
.gantt-deleted-modal .gantt_ok_button div {
  font-size: 13px !important;
}

.gantt-deleted-modal .gantt_popup_controls.dhtmlx_popup_controls {
  display: flex;
  justify-content: center;
}

.gantt-deleted-modal .middle-info-description {
  width: 181px;
  margin-top: 13px;
}

.gantt-deleted-modal .gantt_popup_title {
  background-color: var(--black) !important;
  color: var(--white);
  font-weight: 500;
  border-bottom: 0px !important;
}

.gantt-deleted-modal {
  min-width: 290px;
}

.gantt-deleted-modal .middle-info-trash {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 12px;
  flex-direction: column;
  color: var(--white);
  margin-bottom: 8px;
}

.gantt-deleted-modal .gantt_popup_text {
  padding-top: 0;
}

.gantt_popup_button.dhtmlx_popup_button.gantt_ok_button.dhtmlx_ok_button {
  background-color: var(--primary);
  color: var(--black);
}

.gantt_popup_button.dhtmlx_popup_button.gantt_cancel_button.dhtmlx_cancel_button {
  background-color: var(--white);
  color: var(--black);
}

.middle-link-info {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 12px;
  padding-bottom: 22px;
  color: white;
}

.gantt_popup_text {
  background-color: var(--black) !important;
  margin: 0px !important;
  border-radius: 0px !important;
  padding: 5px;
}

.gantt_popup_controls.dhtmlx_popup_controls {
  background-color: var(--black) !important;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  padding-bottom: 19px;
  padding-left: 19px;
  padding-right: 19px;
}

.plus-counter-responsable-gantt {
  margin-left: 7px;
  font-weight: 300;
  color: #121212;
}

.custom-gantt-responsable-dropdown .select-search__option {
  height: 30px !important;
}

.custom-gantt-responsable-dropdown .select-search__value::after {
  background-image: url('./assets/img/gantt/search-new.png');
  background-repeat: no-repeat;
  background-size: 12.6px;
  background-position-x: 100%;
  background-position-y: 2px;
  width: 14px;
  height: 20px;
}

.custom-gantt-responsable-dropdown .select-search__value {
  font-size: 12px;
}

.fix-name-at-new-gantt {
  display: inline-block;
  width: 55%;
}

.new-gantt-chart .gantt_grid_scale {
  border-bottom: 1px solid #c4c4c47a !important;
}

.new-project-gantt-style .gantt_task_progress_wrapper {
  border: 0px solid rgba(48, 159, 233, 0.3) !important;
}

.custom-tag-modal .ant-modal-content {
  background-color: var(--black);
  border-radius: 8px;
}

.custom-tag-modal .ant-modal-body {
  padding: 0px;
}

.custom-tag-modal .input-add-subcontract {
  background-color: var(--dark-green);
  border: 0px;
  color: var(--white);
}

.custom-tag-modal .input-add-subcontract {
  background-color: var(--dark-green);
  border: 0px;
  color: var(--white);
}

.custom-tag-modal .ant-input:focus {
  border-right-width: 0px !important;
}

.custom-tag-modal .ant-input:hover {
  border-right-width: 0px !important;
}

.custom-tag-modal .title-frm-add {
  background-color: var(--black);
  color: var(--white);
  border-bottom: 0px !important;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
}

.right-add-tag-center {
  float: right;
}

.custom-tag-modal .ant-modal-footer {
  height: 55px;
}

.btn-cancel-tag {
  border-radius: 5px;
  color: #121212;
  background-color: #efefef;
  border: 0px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 10px;
  padding-left: 10px;
}

.btn-add-tag {
  border-radius: 5px;
  color: var(--black);
  background-color: var(--primary);
  border: 0px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 10px;
  padding-left: 10px;
}

.custom-tag-modal .ant-upload,
.ant-upload-select-picture-card {
  background-color: var(--dark-green) !important;
  border-radius: 10px;
}

.custom-tag-modal .img-subcontract {
  background: transparent;
}

.custom-tag-modal .ant-upload {
  height: 74px !important;
  position: relative;
  top: 5px;
}

.custom-tag-modal .upload-subcontract .ant-upload {
  height: 65px !important;
}

.custom-tag-modal .img-logo {
  width: 75% !important;
}

.custom-date-for-inline-gantt {
  position: relative;
  top: -24px;
  left: 3px;
  background-color: var(--white);
  width: 100px;
}

.select-search__select {
  max-height: 260px !important;
  min-height: 160px !important;
}

.odd-col input {
  background-color: #12121200 !important;
}

.add-subcontract-inline {
  font-size: 12px;
}

.custom-proplanner-tag .select-search__select {
  max-height: 260px !important;
  min-height: 30px !important;
}

/** subcontract  popover */
.remove-subcontract {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -10px;
  height: 10px;
}

.remove-subcontract .text-remove {
  font-size: 12px;
  cursor: pointer;
}

.custom-subcontract .select-search__select {
  top: 15px;
}

.custom-subcontract .select-search__value {
  top: -11px;
}

.custom-subcontract .subcontract-create {
  position: relative !important;
  top: 46px !important;
  font-size: 12px;
  z-index: 99;
}

/* .custom-subcontract .select-search__value{
    border: 1px solid red!important;
} */

.custom-subcontract .select-search__option.is-selected {
  color: #121212;
  font-weight: bold;
}

.custom-subcontract .select-search {
  width: 202px;
  position: relative;
  box-sizing: border-box;
}

.custom-subcontract .ant-popover-inner-content {
  border-radius: 20px !important;
}

.custom-subcontract .ant-popover-inner-content .select-search {
  /* select-search select-search--multiple */
  position: relative;
  top: -20px;
}

.custom-subcontract .select-search__input {
  font-size: 12px;
  padding-right: 37px;
}

.custom-subcontract .select-search__option {
  height: 30px !important;
  text-align: center;
}

.custom-subcontract .select-search__value::after {
  background-image: url('./assets/img/gantt/search-new.png');
  background-repeat: no-repeat;
  background-size: 12.6px;
  background-position-x: 100%;
  background-position-y: 2px;
  width: 14px;
  height: 20px;
}

.custom-subcontract .select-search__value {
  font-size: 12px;
}

.custom-subcontract .select-search__select {
  max-height: 260px !important;
  min-height: 30px !important;
}

.custom-gantt-responsable-dropdown .name-responsable-pdf {
  margin: 0 5px;
  display: none;
}

.string-h {
  display: none;
}

.gantt_data_area .is_parent span {
  position: relative !important;
  top: -5px !important;
}

.gantt_data_area .is_milestone {
  position: relative !important;
  top: 0px !important;
}

/** resolutions css new */
.xxs-card {
  width: 50% !important;
}

.xs-card {
  width: 50% !important;
}

.sm-card {
  width: 33.333333333333333% !important;
}

.md-card {
  width: 33.333333333333333% !important;
}

.lg-card {
  width: 25% !important;
}

.lgm-card {
  width: 25% !important;
}

.xl-card {
  width: 20% !important;
}

.xxl-card {
  width: 16.6666666666666667% !important;
}

.xxxl-card {
  width: 14.285714286% !important;
}

.xxxlm-card {
  width: 12.5% !important;
}

.xxxxl-card {
  width: 10% !important;
}

.xxxxxl-card {
  width: 7.692307692% !important;
}

:root {
  --ipsum-orange: #7dff8a;
  --white-color: #ffffff;
  --grey-color: #efefef;
  --grey-dark-color: #a3a3a3;
  --background-modal: #25353a;
  --background-header-modal: #151e21;
  --select-radio-color: #314045;
  --scroll-modal-color: #c4c4c4;
  --background-color-input-modal: #3a4f56;
  --border-radius-input-modal: 5px;
  --border-radius-modal: 10px;
  --opacity-disabled-modal: 0.5;
  --hr-color: #121212;
  --border-checkbox: #688a96;
}

.ant-btn-primary,
.ant-btn-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--black);
}

.submittal-icon {
  position: absolute;
  width: 13px;
  height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden-icon {
  display: none;
}

.today-custom-timeline {
  width: 1px !important;
  background-color: #34af00 !important;
}
