.card-drawer .anticon-close svg {
  color: var(--white) !important;
}

.card-drawer .card-drawer {
  color: var(--red);
}

.card-drawer .card-button {
  color: var(--white) !important;
  margin: 0 10px;
  border-radius: 5px !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  height: 26px !important;
  font-family: 'Roboto-light', arial;
}

.card-drawer .card-orange {
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
}

.card-drawer .card-restricted,
.card-drawer .card-Restricted {
  background-color: #e50101 !important;
  border: 1px solid #e50101 !important;
}

.card-drawer .card-Debit {
  background-color: var(--black) !important;
  border: 1px solid var(--black) !important;
}

.card-drawer .card-Can {
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
}

.card-drawer .card-Will {
  background-color: #2c3421 !important;
  border: 1px solid #2c3421 !important;
}

.card-drawer .card-Committed {
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
}

.card-drawer .card-header div {
  display: inline;
}

.card-drawer .card-header {
  font-size: 12px;
  padding: 15px;
  margin: -24px;
  background: var(--dark-green-sec);
}

.card-drawer .card-avatar {
  height: 50px;
  float: left;
  margin: 0 12px;
  position: relative;
  top: -11px;
}

.card-avatar .img-responsable-lookahead {
  width: 40px;
  height: 40px;
}

.card-avatar-cncs .img-responsable-lookahead {
  width: 22px;
  height: 22px;
  position: relative;
  top: -2px;
}

.card-drawer .card-route {
  display: block !important;
  color: var(--cool-gray);
}

.card-drawer .card-info {
  display: block;
}

.card-drawer .card-dates {
  float: right;
  padding: 4px;
  margin-right: 50px;
  top: -4px;
  position: relative;
}

.card-drawer .card-dates div.fini,
.card-drawer .card-dates div.duration,
.card-drawer .card-dates div.fend {
  margin: 0 12px;
}

.card-drawer .card-body {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.card-drawer .subcontract-border {
  padding: 3px 10px !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  position: relative !important;
  font-size: 12px !important;
}

.card-drawer .fix-inline {
  display: inline !important;
}

.card-drawer .card-priority {
  margin: 0 10px;
  position: relative;
  top: 4px;
}

.card-drawer .card-column {
  /* width: 33.33%; */
}

.card-drawer .card-column .ant-input-number-input {
  height: auto;
  position: relative;
  top: 5px;
}

.card-drawer .card-column .vertical-center-card {
  position: relative;
  top: 2px;
}

.card-drawer .card-first {
  width: 33.33%;
}

.card-drawer .card-second {
  width: 66.67%;
}

.card-drawer .card-third {
  width: 30%;
}

.card-drawer .column-progress {
  float: right;
}

.card-drawer .progress-real {
  display: inline-flex;
  font-size: 14px;
  font-weight: bold;
}

.card-drawer .progress-real span {
  font-size: 16px;
}

.card-drawer .progress-commitment {
  display: inline;
}

.card-drawer .card-icon-hand-shake {
  margin: 6px 6px 0 0;
  position: relative;
  top: 1px;
}

.card-drawer .btn-commitment {
  padding: 1px 10px !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  position: relative !important;
  font-size: 16px !important;
  border: 1px solid var(--primary);
  font-weight: bold;
}

.card-drawer .card-subcontract {
  position: relative;
  top: 5px;
}

.card-drawer .buttons-tab {
  padding: 5px 30px;
}

.card-drawer .icon-btn-tab {
  margin-right: 7px;
}

.card-drawer .btn-tab {
  display: inline-block;
  padding: 3px 10px !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  position: relative !important;
  font-size: 12px !important;
  margin: 0 10px;
  cursor: pointer;
  min-width: 100px !important;
  text-align: center;
}

.card-drawer .btn-tab-active {
  background: #595959 !important;
}

.card-drawer .btn-tab-active .icon-btn-tab {
  color: var(--primary);
}

.card-drawer .card-constraints,
.card-drawer .card-cncs {
  margin: 0 12px;
  height: 178px;
  overflow: auto;
  width: 70%;
}

.card-drawer .card-logs {
  margin: 0 12px;
  height: 178px;
  overflow: auto;
  width: 100%;
}

card-logs .card-drawer .card-constraints::-webkit-scrollbar,
.card-drawer .card-logs::-webkit-scrollbar {
  width: 5px;
}

.card-drawer .card-constraints::-webkit-scrollbar-track,
.card-drawer .card-logs::-webkit-scrollbar-track {
  background: #ddd;
}

.card-drawer .card-constraints::-webkit-scrollbar-thumb,
.card-drawer .card-logs::-webkit-scrollbar-thumb {
  background: #666;
}

.card-drawer .card-cncs {
  margin: 0 12px;
}

.card-drawer .card-constraints .col-card-constraint {
  display: inline-grid;
}

.card-drawer .card-cncs .col-card-cnc {
  display: inline-grid;
}

.card-drawer .card-constraints .user {
  width: 10%;
}

.card-drawer .card-constraints .name {
  width: 35%;
}

.card-drawer .card-constraints .status {
  width: 25%;
  height: 100%;
}

.card-drawer .card-cncs .status {
  width: 25%;
  height: 100%;
}

.card-drawer .card-cncs .card-cnc-status {
  text-align: center;
  padding-top: 8px;
}

.card-drawer .card-constraints .commitment-date {
  width: 30%;
  text-align: center;
}

.card-drawer .card-constraints .row-constraint {
  padding: 0 12px;
  height: 35px;
  font-size: 12px;
}

.card-drawer .card-constraints .row-constraint:hover,
.card-drawer .card-cncs .row-cncs:hover,
.card-drawer .card-logs .row-logs:hover {
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  background: #595959 !important;
}

.card-drawer .card-constraints .card-constraint-status {
  text-align: center;
  padding-top: 8px;
}

.card-drawer .card-constraints .card-btn-date-commitment {
  border: 1px solid #3e3e3e !important;
}

.card-drawer .hidden {
  display: none !important;
}

.card-drawer .card-cncs .row-cncs {
  padding: 0 12px;
  height: 35px;
  font-size: 12px;
}

.card-drawer .card-constraints .col-center-wide,
.card-drawer .card-cncs .col-center-wide {
  width: 100%;
  padding: 7px;
  text-align: center;
  color: #d1d1d1;
}

.card-drawer .card-logs {
  margin: 0 12px;
  margin-top: 18px;
}

.card-drawer .card-custom-input {
  max-width: 300px !important;
}

.card-drawer .card-custom-input-number {
  width: 60px !important;
  display: inline-flex !important;
}

.card-drawer .card-cncs .week {
  width: 20%;
}

.card-drawer .card-cncs .percentage {
  width: 20%;
}

.card-drawer .card-cncs .user {
  width: 5%;
}

.card-drawer .card-cncs .name {
  width: 35%;
}

.card-drawer .card-cncs .status {
  width: 20%;
}

.card-drawer .card-logs .row-logs {
  padding: 2px 12px;
  height: 35px;
  font-size: 12px;
  margin: 8px 0px;
  height: 22px;
  overflow: hidden;
  width: 426px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-drawer .card-logs .col-center-wide {
  width: 100%;
  padding: 7px;
  text-align: center;
  color: #d1d1d1;
}

.card-drawer .card-logs .col-card-log {
  display: inline-grid;
  padding: 1px;
}

.card-drawer .card-log-user {
  color: var(--primary);
}

.card-drawer .delete-subcontract {
  color: var(--red);
  margin-left: 5px;
}

.card-drawer .subcontract-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-drawer .ant-drawer-wrapper-body {
  overflow: hidden;
  background-color: var(--black) !important;
}

.card-drawer .card-progress-bar {
  float: left;
  width: 100%;
}

.card-drawer .cost-card {
  display: inline !important;
}
