@import '../../../assets/styles/variables.scss';

.modal--export--pdf {
  width: 100%;
  display: flex;
  flex-direction: column;

  .modal--export--pdf__alert {
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: $white;
    border-radius: 5px;
    padding: 8px 20px;
    margin-bottom: 20px;

    .alert__text {
      p {
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        color: $black;
        margin: 0;
      }
    }
  }

  .modal--export--pdf__description {
    width: 100%;
    text-align: center;
    left: 0;

    label {
      color: $white;
    }
  }

  .modal--export--pdf__options {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .modal--export--pdf__footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 32px;
      border: 0;
      padding: 0 20px;
      border-radius: 5px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: $black;
      transition-duration: 0.3s;

      &.footer__cancel {
        background-color: $cool-gray;

        &:hover {
          background-color: $gray;
        }
      }

      &.footer__download {
        background-color: $primary;

        &:hover {
          background-color: $dark-mint;
        }

        &:disabled {
          cursor: not-allowed;
        }

        .download__spinner {
          display: flex;

          i {
            svg {
              fill: $black;
            }
          }
        }
      }
    }
  }
}

.option-print--datepicker {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: $white;
    margin: 0;
  }

  .select__picker {
    flex: 1;
    text-align: center !important;
  }
}

.option-print--weeks {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: $white;
    margin: 0;
  }

  .format__dropdown {
    flex: 1;
  }
}
