@import '../../../../assets/styles/variables.scss';

.container__dropdown {
  max-height: 250px;
  overflow-y: scroll;
  position: fixed;
  z-index: 1001;
  border-radius: 5px;
  padding: 10px 0;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  &.theme--default {
    color: $white;
    background-color: $dark-green;

    .dropdown__item {
      &:hover {
        background-color: $black;
      }

      &.disabled {
        opacity: $disabled-opacity;
      }
    }
  }

  &.theme--dark {
    color: $white;
    background-color: $black;

    .dropdown__item {
      &:hover {
        background-color: $dark-green;
      }
    }
  }

  &.selector--size--default {
    top: 40px;
  }

  &.selector--size--small {
    top: 34px;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 36px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $white;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 3px;
  }

  .dropdown__item {
    width: 100%;
    height: 30px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition-duration: 0.3s;

    span {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;

      .span__icon {
        &.position--left {
          order: 0;
          margin-right: 10px;
        }

        &.position--right {
          order: 1;
          margin-left: 10px;
        }

        i {
          img {
            width: 14px;
          }
        }
      }
    }

    .item__dropdown {
      opacity: 0;
      visibility: hidden;
      transition-duration: 0.3s;
      transition-delay: 0.2s;
      margin-left: 20px;
    }

    &.active {
      background-color: $primary;

      span {
        color: $black;
      }

      &:hover {
        span {
          color: $white;
        }
      }
    }

    &:hover,
    &.active {
      span {
        .span__icon {
          i {
            img {
              filter: brightness(0) invert(1) !important;
            }
          }
        }
      }
    }

    &.active,
    &:hover {
      .item__dropdown {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .set__item {
    width: 100%;
    height: 37px;
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition-duration: 0.3s;
    span {
      font-size: 10px;
    }
    .line__item {
      width: 100%;
      height: 1px;
      margin: 0px 0;
      background-color: $separation-line;
      opacity: $disabled-opacity;
    }
  }
}
