.lookahead-btn-style {
  margin-right: 10px;
  margin-left: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  border-radius: 5px !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  padding: 0px 12px !important;
  min-width: 80px;
  /* max-width: 103px; */
  height: 26px !important;
  font-family: 'Roboto-light', arial;
  z-index: 1;
}

.lookahead-disabled-btn-style {
  background-color: #ffffff00 !important;
  border-color: #ffffff00 !important;
  color: #2c3421 !important;
  font-weight: 400 !important;
  text-align: center;
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045) !important;
}

.lookahead-container-header {
  background-color: #ffffff;
  height: 57px;
  box-shadow: 5px 5px 6px 1px #dfdfdf;
}

.fixed-router-btns {
  position: fixed !important;
  width: 100%;
  z-index: 1;
}

.alert-wide {
  margin: 8px !important;
}
