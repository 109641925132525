.non-task-view-gantt {
  background-color: #99d6ff !important;
  border-radius: 0px !important;
  height: 7px !important;
}

.task-gantt-visualization {
  border: 1px solid transparent !important;
}

.custom-gantt-visualization-grid {
  border-color: #c4c4c438;
}

.custom-gantt-visualization-grid.grouppable {
  background-color: #121212;
  color: #fff;
}

.custom-gantt-visualization-grid.grouppable .text-btn-gantt-view,
.custom-gantt-visualization-grid.grouppable .gantt_tree_content {
  color: #fff !important;
}

.custom-gantt-visualization-grid.grouppable:hover .text-btn-gantt-view,
.custom-gantt-visualization-grid.grouppable:hover .gantt_tree_content {
  color: #121212 !important;
}

.personalized-weekend-class-visua {
  background-color: #e9e9e9 !important;
}

.gantt_task_row.gantt_selected .personalized-weekend-class-visua {
  background-color: #e9e9e9 !important;
  border-right-color: #e9e9e9 !important;
  z-index: 0 !important;
}

.spin-hide-export {
  position: absolute;
  z-index: 1000;
  width: 100%;
  justify-content: center;
  text-align: center;
  background-color: white;
  height: 100%;
  padding-top: 15%;
}

.gantt-visualization .vertical-center {
  position: relative;
  top: 1px;
}

.leanStatusFlag {
  cursor: pointer;
}

.leanStatusOptions {
  min-width: 150px;
  min-height: 100%;
}

.leanStatusOptions .item:first-child {
  border-top: 1px solid #e8e8e8;
}

.leanStatusOptions .item {
  cursor: pointer;
  border-bottom: 1px solid #e8e8e8;
  display: block;
  text-align: left;
  margin: 0;
  padding: 5px 40px 2px 10px;
  height: 36px;
}

.leanStatusOptions .item:hover {
  background: #f9f9f9;
}

.popoverLeanStatus .ant-popover-inner-content {
  padding: 0;
}

.gantt-visualization .popover-subcontract {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.gantt-visualization .subcontract-border {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.gantt-visualization .subcontract-border .text-subcontract {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  top: -1px;
}

.gantt-visualization .subcontract-border-fix {
  height: 90%;
}

.gantt-visualization .gantt-number {
  width: 55% !important;
}

.gantt-visualization .cost-fix-gantt {
  padding-left: 0 !important;
}

.gantt-visualization .icon-save {
  margin-left: 2px !important;
}

.gantt-visualization .vertical-center-dates {
  top: 0%;
}

.gantt-visualization .progress-gantt-view .range-fixed-lookahead {
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  left: 0% !important;
  top: 0% !important;
}

.gantt-visualization
  .progress-gantt-view
  .range-fixed-lookahead
  .percentage-label-custom {
  position: static;
}

.gantt-visualization .progress-gantt-view .progress-custom-props {
  padding: 0 !important;
  left: 0 !important;
}
.gantt-visualization .progress-gantt-view .hide-bar .ant-progress-outer {
  display: none;
}

.gantt-visualization .progress-gantt-view .progress-label-gantt {
  width: 100%;
}

.gantt-visualization .progress-gantt-view .bar-progress-gantt {
  text-align: right;
  margin-right: 4px;
}

.gantt-visualization .progress-gantt-view .input-progress-gantt {
  text-align: left;
  margin-left: 4px;
}

.gantt-visualization .progress-custom-props .ant-progress-inner {
  background: #d1d1d1 !important;
}

.gantt-visualization .progress-label-gantt .custom-input-number-planification {
  width: 55% !important;
  height: 25px !important;
  top: -2px !important;
}
.gantt-visualization
  .progress-label-gantt
  .custom-input-number-planification
  .ant-input-number-input-wrap {
  position: relative;
  top: 2px;
}

.gantt-visualization .collapse-expand-gantt {
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}
.gantt-visualization .expand-gantt {
  font-size: 13px;
}

.gantt-visualization .expand-task {
  margin-right: 5px;
  position: relative;
  left: -1px;
}

.gantt-visualization .custom-adjust-visual-tree {
  position: absolute !important;
  z-index: 99999;
  left: 28px;
  top: 10px !important;
}

.gantt-visualization .gantt_tree_content {
  width: 100% !important;
}

.gantt-visualization .input-description {
  font-size: 12px;
  color: #d1d1d1;
  border: 1px solid #d1d1d1;
  padding: 3px 7px;
}

.gantt-visualization .ant-calendar-picker-input {
  text-align: center;
}
.gantt-visualization .ant-calendar-picker-icon {
  display: none;
}

.gantt-visualization .custom-date-picker-planification-tv {
  max-width: 100px !important;
}

.gantt-visualization .custom-date-picker-planification-tv .ant-input {
  border: 0px solid #d9d9d9;
  height: 25px;
  font-size: 13px;
}

.gantt-visualization .custom-date-picker-planification-tv .ant-input:hover {
  border-color: #40a9ff;
  border-right-width: 0px !important;
}

.gantt-visualization .lookahead-copy-icon {
  width: 15px;
  height: 15px;
  margin: 5;
}

.gantt-visualization
  .gantt_grid_data
  .gantt_row:hover
  .gantt_cell
  .task-actions {
  display: inline;
}

.gantt-visualization .gantt_grid_data .gantt_row .gantt_cell .task-actions {
  display: none;
}

.gantt-visualization .act-correlative-id {
  margin-left: 4px;
  display: inline-block;
}

.gantt-visualization .column-disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.gantt-visualization .del-subcontract-tv {
  position: relative;
  right: 15px;
}

.gantt-visualization .main-custom-style {
  background-color: #f7f7f7;
  height: 34px !important;
  z-index: 10 !important;
  font-size: 12px !important;
}

.gantt-visualization .main-custom-style .editable-ipsum-custom {
  font-size: 12px !important;
}

.gantt-visualization .main-custom-style .act-correlative-id {
  font-size: 12px !important;
}

.gantt-visualization .milestone-custom-style {
  background-color: #e0c9ed;
}

/** NEW CHART TIMELINE STYLES**/

.gantt-visualization .main-bar-custom-style:before {
  content: '';
  position: absolute;
  height: 0px;
  width: 0px;
  left: -1px;
  z-index: 0;
  top: -1px;
  border-top: 4px solid transparent;
  border-bottom: 10px solid transparent;

  border-left: 10px solid #0e5381 !important;
}
.gantt-visualization .main-bar-custom-style:after {
  content: '';
  height: 0px;
  position: relative;
  float: right;
  width: 0px;
  z-index: 0;
  top: -6px;
  left: 1px;
  border-top: 4px solid transparent;
  border-bottom: 10px solid transparent;

  border-right: 10px solid #0e5381 !important;
}

.gantt-visualization .main-bar-custom-style {
  height: 7px !important;
  background: #0e5381 !important;
  border-color: #0e5381 !important;
  border-radius: 0px !important;
}

.gantt-visualization .main-bar-custom-style .gantt_task_progress {
  background: #0e5381 !important;
  border-color: #0e5381 !important;
}

.gantt-visualization .main-bar-custom-style .gantt_task_line.gantt_project {
  border-radius: 0px !important;
}

.gantt-visualization .activitytask-bar-custom-style {
  height: 24px !important;
  border: 0px solid !important;
  border-radius: 6px;
  z-index: 1;
}

.gantt-visualization .activitytask-bar-custom-style .gantt_task_progress {
  background-color: inherit;
  opacity: 1 !important;
}

.gantt-visualization .parent-task-style .gantt_task_progress_wrapper {
  border-width: 0px !important;
  border-top-left-radius: 3px;
}

.gantt-visualization .parent-task-style {
  height: 16px !important;
  border-width: 1px !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.gantt-visualization .parent-task-style:before {
  content: '';
  position: absolute;
  height: 0px;
  width: 0px;
  left: 1px;
  z-index: 0;
  top: 12px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid var(--borderMarkersColor) !important;
  transform: rotate(90deg);
}

.gantt-visualization .parent-task-style:after {
  content: '';
  height: 0px;
  position: relative;
  float: right;
  width: 0px;
  z-index: 0;
  top: -37px;
  left: -1px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid var(--borderMarkersColor) !important;
  transform: rotate(270deg);
}

.gantt-visualization .without-inside-border:after {
  top: -39px;
}

.gantt-visualization .hide-parent-task-markers:after {
  visibility: hidden;
}

.gantt-visualization .hide-parent-task-markers:before {
  visibility: hidden;
}

.gantt-visualization .lookahead-date-marker {
  background-color: #984bc4 !important;
  width: 1px;
}

.gantt-visualization .gantt_marker_content::before {
  content: '';
  position: absolute;
  height: 0px;
  width: 0px;
  left: -2px;
  z-index: 0;
  top: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #34af00 !important;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(91deg);
}

.gantt-visualization .gantt_marker_content {
  border-radius: 3px;
  font-weight: 600;
  background-color: #34af00b0 !important;
  position: relative;
  width: 48px;
  top: 7px;
}

.gantt-visualization .today-custom-timeline {
  width: 1px !important;
  background-color: #34af00 !important;
}

.gantt-visualization .fit-vertical-position {
  position: relative;
  top: -7px;
}

.gantt-visualization .fit-vertical-position.name-with-border {
  position: relative;
  top: 6px;
}

.gantt-visualization .fit-vertical-position.name-without-border {
  position: relative;
  top: 6px;
}

.gantt-visualization .weekly-range {
  background-color: #25a8ba1a !important;
  z-index: 0;
  position: relative;
  border-left: 2px solid #25a8ba;
  border-right: 2px solid #25a8ba;
}

.gantt-visualization .weekly-range-commited {
  background-color: #7dff8a1a !important;
  z-index: 0;
  position: relative;
  border-left: 2px solid var(--primary);
  border-right: 2px solid var(--primary);
}

.gantt-visualization .force-border-to-weekly-commited-range-task {
  border: 2px solid var(--primary) !important;
}

.gantt-visualization .force-border-to-weekly-restricted-task {
  border: 2px solid red !important;
}

.gantt-visualization .force-border-to-weekly-range-task {
  border: 2px solid #25a8ba !important;
}

.gantt-visualization
  .force-border-to-weekly-range-task
  .gantt_task_progress_wrapper {
  border: 0px solid rgba(48, 159, 233, 0.3) !important;
}

.lookahead-weekly-modal .ant-modal-content {
  background-color: var(--black) !important;
  border-radius: 10px;
}

.lookahead-weekly-modal .ant-modal-header {
  background-color: #25353a !important;
  border-bottom: 0px solid #e8e8e8 !important;
}

.lookahead-weekly-modal .ant-modal-close-x {
  color: var(--white);
}

.lookahead-weekly-modal .ant-modal-footer {
  padding: 0px 0px;
}

.col-content-img-container {
  text-align: center;
}

.col-content-msg-container {
  color: var(--white);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.col-btn-modal-container {
  text-align: right;
}

.cancel-btn-modal-weekly {
  background: #efefef;
  border-radius: 5px;
  border-color: transparent;
  position: relative;
  left: -5px;
  font-weight: 500;
  padding: 5px;
}

.confirm-commit-modal-btn {
  background: var(--primary);
  border-radius: 5px;
  border-color: transparent;
  color: var(--black);
  position: relative;
  left: 5px;
  font-weight: 500;
  padding: 5px;
}

.weekly-lookahead-integration-btn button {
  color: #2c3421;
}

.weekly-lookahead-integration-btn button:hover {
  color: #ffffff;
}

.gantt-visualization .gantt_side_content.gantt_left {
  top: 0px !important;
  left: -26px;
  height: 24px;
}

.gantt-visualization .roadblock-img {
  position: relative;
  top: -4px;
}

.gantt-visualization .roadblock-img-non-border {
  position: relative;
  top: -2px;
}

.gantt-visualization .gantt_side_content.gantt_right {
  position: relative;
  top: -25px !important;
  width: 189px;
  height: 30px;
}

.gantt-visualization .name-with-border {
  position: relative;
  left: -9px;
}

.gantt-visualization .name-without-border {
  position: relative;
  left: -9px;
}

.gantt-visualization .margin-l5 {
  margin-left: 6px;
}

.columnExportPDFHide {
  visibility: hidden;
}
