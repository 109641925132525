@import '../../../components/DesignSystem/UiKit/UiKit.scss';

.sso-procore {
  width: 100%;
  height: 40px;
  display: flex;
  cursor: pointer;

  .sso-procore__icon {
    width: 40px;
    margin-right: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $dark-black;
    border-radius: 5px 0px 0px 5px;
    transition-duration: 0.3s;
  }

  .sso-procore__label {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $dark-black;
    border-radius: 0px 5px 5px 0px;
    transition-duration: 0.3s;

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: $white;
    }
  }

  &:hover {
    .sso-procore__icon,
    .sso-procore__label {
      background-color: $black;
    }
  }

  &.disabled {
    .sso-procore__icon,
    .sso-procore__label {
      background-color: $light-gray;

      span {
        color: $medium-gray;
      }
    }
  }
}
