.constraint__container {
  display: flex;
  flex-direction: column;
}

.constraint__container .container__title h6 {
  color: var(--white);
  margin: 0 0 10px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.constraint__container .container__list {
  width: 100%;
  height: 248px;
  display: flex;
  flex-direction: column;
}

.constraint__container .container__list.list--full.list--scroll {
  overflow-y: scroll;
}

.constraint__container
  .container__list.list--full.list--scroll::-webkit-scrollbar {
  width: 4px;
  height: 36px;
}

.constraint__container
  .container__list.list--full.list--scroll::-webkit-scrollbar-thumb {
  background-color: var(--gray-1);
  border-radius: 3px;
}

.constraint__container
  .container__list.list--full.list--scroll::-webkit-scrollbar-track {
  background-color: var(--dark-green-sec);
  border-radius: 3px;
}

.constraint__container .container__list.list--empty {
  justify-content: center;
  align-items: center;
}
