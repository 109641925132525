@import '../../assets/styles/variables.scss';

.popover-select-project {
  width: 175px;
  height: 24px;
  border-radius: 5px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: $dark-green;
  border: 1px solid $dark-green;
  cursor: pointer;
  transition-duration: 0.3s;

  &.ant-popover-open {
    border: 1px solid $primary;
  }

  .popover-select-project__label {
    display: flex;
    align-items: center;

    span {
      font: $regular-s-font;
      color: $white;
    }
  }

  .popover-select-project__icon {
    i {
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
}

.popover-select-schedule {
  width: 175px;
  height: 24px;
  border-radius: 5px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: $dark-green;
  border: 1px solid $dark-green;
  cursor: pointer;
  transition-duration: 0.3s;

  &.ant-popover-open {
    border: 1px solid $primary;
  }

  .popover-select-schedule__label {
    display: flex;
    align-items: center;

    span {
      font: $regular-s-font;
      color: $white;
    }
  }

  .popover-select-schedule__icon {
    i {
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
}

.toolbar__container {
  .toolbar__account {
    display: flex;
    align-items: center;

    .titles {
      position: relative;
      display: inline-flex;
      flex-direction: column;
      text-align: right;
      color: white;
      margin-right: 25px;
      cursor: default;
    }

    .user__name {
      font: $regular-m-font;
    }

    .user__role {
      font: $regular-xs-font;
    }
  }
}

.menu__notifications {
  .ant-dropdown-menu-item > a,
  .ant-dropdown-menu-submenu-title > a {
    margin: 0 !important;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
  }
  .notification__row {
    display: flex;

    .notification-more {
      padding-top: 4px;
      margin: auto;
      text-decoration: underline;
    }
  }
}
