.resources-section {
  &__container {
    overflow: auto;
  }

  &__title {
    margin-top: 20px;
    font-size: 15px;
    font-weight: 500;
  }
}
