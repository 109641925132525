@import '../../../assets/styles/variables.scss';

.signup__second-screen {
  width: 420px;
  position: relative;
  z-index: 1;
  background-color: $white;
  padding: 50px 50px 32px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .second-screen__logo {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .second-screen__body {
    display: flex;
    flex-direction: column;

    .body__title {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 30px 0;

      h6 {
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
        color: $black;
        margin: 0;
        text-align: left;
      }
    }

    .body__icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;

      img {
        width: 60px;
      }
    }

    .body__hint {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 10px;

      span {
        font-size: 16px;
        line-height: 18px;
        font-weight: 300;
        color: $black;

        a {
          text-decoration: underline;
          color: $black;
        }
      }
    }
  }
}
