.btnDate,
.btnSave {
  background: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  color: var(--black) !important;
  height: 22px !important;
}

.btnDate {
  margin: auto !important;
  padding: 0 12px !important;
}

.btnDate span {
  font-size: 12px !important;
}

.tableConstraints > .rowTable ~ .rowTable:not(:first-child) {
  margin-top: 0px !important;
  height: 40px;
}

.tableConstraints > .rowTable {
  margin-top: 1px !important;
  height: 40px;
}

.tableConstraints {
  margin: 0px 32px;
  border-radius: 5px !important;
  box-shadow: 2px 2px 6px 1px #dfdfdf;
  -moz-box-shadow: 2px 2px 6px 1px #dfdfdf;
  -webkit-box-shadow: 2px 2px 6px 1px #dfdfdf;
  padding-bottom: 0px;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.tableConstraints .emptyConstraints {
  margin: 0;
  padding: 20px 0 0;
}

.priorityFlag {
  cursor: pointer;
}

.priorityOptions {
  min-width: 150px;
  min-height: 100%;
}

.priorityOptions .item:first-child {
  border-top: 1px solid #e8e8e8;
}

.priorityOptions .item {
  cursor: pointer;
  border-bottom: 1px solid #e8e8e8;
  display: block;
  text-align: left;
  margin: 0;
  padding: 5px 40px 2px 10px;
  height: 36px;
}

.priorityOptions .item:hover {
  background: #f9f9f9;
}

.popoverPriority .ant-popover-inner-content {
  padding: 0;
}

.associated-task {
  color: #2c3421;
}

.popoverConstraint {
  width: 244px !important;
  text-align: center !important;
}

.popoverConstraint button {
  margin-top: 10px;
}

.popoverConstraint .ant-calendar-picker-clear {
  right: 5px;
}

.img-modal-row {
  cursor: pointer;
}

.priorityOptions .cat-icon {
  width: 20px;
  line-height: 27px;
}

.vertical-center .cat-icon {
  width: 20px;
  line-height: 25px;
  top: -1px;
  position: relative;
  margin-right: 3px;
}

.text-tasks {
  height: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100% !important;
  top: 15px !important;
  display: inline-block;
}
