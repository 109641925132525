.order-main-container {
  background: white;
  box-shadow: 0px 5px 6px 1px #00000036;
  border-radius: 8px;
  width: 450px;
}

.full-width-style {
  width: 100% !important;
  padding: 10px;
}

.switch-col-style {
  color: #828282;
  font-size: 13px !important;
  font-weight: 400 !important;
  font-family:
    Roboto,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
}

.ant-switch-checked {
  background-color: var(--dark-mint) !important;
}

.delete-filter-style {
  cursor: pointer;
  color: #ee893c !important;
}

.single-filter-row {
  margin-bottom: 5px !important;
}

.aligned-center {
  text-align: center;
}

.ant-input-affix-wrapper .ant-input-suffix {
  margin-bottom: 5px;
}

.icon-rotated {
  transform: rotate(90deg);
}

.width-half {
  width: 100px !important;
}
