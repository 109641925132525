.avantar {
  width: 40px;
  height: 40px;
  float: left;
}

.content-title {
  font-family: 'Roboto', arial, sans-serif;
  margin-left: 56px !important;
  top: 0px;
  position: relative;
}

.user-title {
  font-family: 'Roboto', arial, sans-serif;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: #121212;
  margin: 0px;
}
.tablescroll {
  font-family: 'Roboto', arial, sans-serif !important;
}
.tablescroll > tr > td {
  font-family: 'Roboto', arial, sans-serif;
  padding: 8px 8px !important;
}

.tablescroll > tr > th,
.ant-table-tbody > tr > td {
  padding: 8px 8px !important;
  overflow-wrap: break-word;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 8px 8px !important;
  overflow-wrap: break-word;
}
.user-position-sub {
  font-family: 'Roboto', arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  color: #121212;
  margin: 0px;
}
.user-position {
  font-family: 'Roboto', arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 14px;
  align-items: center;
  color: #121212;
  margin: 0px;
}

.ant-progress-inner {
  background-color: #fff !important;
}

.user_h1 {
  margin-left: 20px;
  font-family: 'Roboto', arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  color: #121212;
  margin: 0px;
  margin-left: 10px;
}
.user_hr {
  width: 98%;
  font-family: 'Roboto', arial, sans-serif;
  height: 0.3px;
  background-color: #2c3421;
  margin: 10px;
}

.tablescroll {
  overflow: hidden !important;
}
.filer_user {
  font-family: 'Roboto', arial, sans-serif;
  position: absolute !important;
  top: -14px;
  /* margin-right: 200px; */
  right: -5px;
  width: 100px;
}
.contenctPcrForUser {
}

.other-title {
  text-align: center !important;
}
