/**
 * =================
 * List workers
 * =================
 * */
.listContainer {
  overflow: auto;
  width: 102% !important;
}

.workerContiner {
  border-bottom: 0.5px solid #e3e3e3;
  height: 100%;

  display: flex;
  justify-content: flex-start;
}

.workerName {
  width: 166px;
}

.workerCrewWidth {
  width: 181px;
}

.workerRut {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 94px;
  font-size: 10px;
  border-left: 0.5px solid #e3e3e3;
  border-right: 0.5px solid #e3e3e3;
}

.workerHour {
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.workerRut,
.workerHour {
  text-align: center;
}

.crewName.isCrew {
  font-size: 14px;
  color: #1890ff;
}

.crewName {
  font-size: 10px;
  padding-left: 10px;
}

.disabled {
  color: rgba(88, 88, 88, 0.3);
}
