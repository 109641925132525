@import '../../../../assets/styles/variables.scss';

@mixin scrollbar() {
  &::-webkit-scrollbar {
    width: 4px;
    height: 36px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bed-rock-t2;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: $black;
    border-radius: 3px;
  }
}

.modal-tasks-deleted {
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 396px 250px auto;
  gap: 5px;

  @include scrollbar();

  .modal-tasks-deleted__detail {
    width: 100%;
    display: flex;
    flex-direction: column;

    .detail__header {
      width: 100%;
      height: 30px;
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 2fr 1fr 1.5fr;
      align-items: center;
      text-align: center;
      background-color: $gray-2;
      border-radius: 5px 0 0 0;

      span {
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
        color: $black;

        &:is(:not(:last-child)) {
          border-right: 1px solid $gray-1;
        }
      }
    }

    .detail__body {
      display: flex;
      flex-direction: column;
      width: 100%;
      border: 1px solid $bed-rock;
      border-radius: 0 0 0 5px;

      .body__item {
        width: 100%;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 2fr 1fr 1.5fr;

        &:is(:not(:last-child)) {
          border-bottom: 1px solid $bed-rock;
        }

        div {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          font-size: 12px;
          line-height: 14px;
          font-weight: 400;
          color: $white;

          &:nth-child(even) {
            background-color: $bed-rock-t2;
          }

          &:nth-child(odd) {
            background-color: $dark-green;
          }

          &:nth-child(1) {
            padding: 0 10px;
          }

          &:nth-child(2),
          &:nth-child(3) {
            justify-content: center;
            text-align: center;
          }
        }
      }
    }
  }

  .modal-tasks-deleted__date {
    width: 100%;
    display: flex;
    flex-direction: column;

    .date__header {
      width: 100%;
      height: 30px;
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      text-align: center;
      background-color: $gray-2;

      span {
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
        color: $black;

        &:is(:not(:last-child)) {
          border-right: 1px solid $gray-1;
        }
      }
    }

    .date__body {
      display: flex;
      flex-direction: column;
      width: 100%;
      border: 1px solid $bed-rock;

      .body__item {
        width: 100%;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(2, 1fr);

        &:is(:not(:last-child)) {
          border-bottom: 1px solid $bed-rock;
        }

        div {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 12px;
          line-height: 14px;
          font-weight: 400;
          color: $white;

          &:nth-child(even) {
            background-color: $dark-green;
          }

          &:nth-child(odd) {
            background-color: $bed-rock-t2;
          }
        }
      }
    }
  }

  .modal-tasks-deleted__status {
    width: 100%;
    display: flex;
    flex-direction: column;

    .status__header {
      width: 100%;
      height: 30px;
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto;
      align-items: center;
      text-align: center;
      background-color: $gray-2;
      border-radius: 0 5px 0 0;

      span {
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
        color: $black;
      }
    }

    .status__body {
      display: flex;
      flex-direction: column;
      width: 100%;
      border: 1px solid $bed-rock;
      border-radius: 0 0 5px 0;

      .body__item {
        width: 100%;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: auto;

        &:is(:not(:last-child)) {
          border-bottom: 1px solid $bed-rock;
        }

        div {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          line-height: 14px;
          font-weight: 400;
          color: $white;
          background-color: $bed-rock-t2;
        }
      }
    }
  }
}

.modal-tasks-deleted-empty {
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .modal-tasks-deleted-empty__icon {
    display: flex;
    margin-bottom: 20px;
  }

  .modal-tasks-deleted-empty__text {
    span {
      font-size: 12px;
      line-height: 14px;
      color: $white;
      font-weight: 400;
    }
  }
}
