.btnDate,
.btnSave {
  background: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  color: var(--black) !important;
  height: 22px !important;
}

.btnDate {
  margin: auto !important;
  padding: 0 12px !important;
}

.btnDate span {
  font-size: 12px !important;
}

.constraint-desc {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tableConstraints > .rowTable ~ .rowTable:not(:first-child) {
  margin-top: 0px !important;
  height: 40px;
}

.tableConstraints > .rowTable {
  margin-top: 1px !important;
  height: 40px;
}

.tableConstraints {
  margin: 0px 32px;
  border-radius: 5px !important;
  box-shadow: 2px 2px 6px 1px #dfdfdf;
  -moz-box-shadow: 2px 2px 6px 1px #dfdfdf;
  -webkit-box-shadow: 2px 2px 6px 1px #dfdfdf;
  padding-bottom: 0px;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.tableConstraints .emptyConstraints {
  margin: 0;
  padding: 20px 0 0;
}

.priorityFlag {
  cursor: pointer;
}

.priorityOptions {
  min-width: 150px;
  min-height: 100%;
}

.priorityOptions .item:first-child {
  border-top: 1px solid #e8e8e8;
}

.priorityOptions .item {
  cursor: pointer;
  border-bottom: 1px solid #e8e8e8;
  display: block;
  text-align: left;
  margin: 0;
  padding: 8px 40px 2px 10px;
  height: 36px;
}

.priorityOptions .item:hover {
  background: #f9f9f9;
}

.popoverPriority .ant-popover-inner-content {
  padding: 0;
}

.associated-task {
  color: #2c3421;
}

.popoverConstraint {
  width: 244px !important;
  text-align: center !important;
}

.popoverConstraint button {
  margin-top: 10px;
}

.popoverConstraint .ant-calendar-picker-clear {
  right: 5px;
}

.constraint-add-btn {
  color: #d1d1d1;
  margin-left: 55px;
  margin-bottom: 12px;
  cursor: pointer;
  width: 256px;
  margin-top: 10px;
  transition: 0.3s;
  font-size: 13px;
  line-height: 15px;
}

.constraint-add-btn:hover {
  color: black;
}

.constraint-add-btn .anticon-plus {
  margin-right: 3px;
}

.constraint-activity-tasks {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #2c3421;
  margin: 0;
  position: relative;
  top: 20%;
  -ms-transform: translateY(-25%);
  transform: translateY(-25%);
  height: 22px;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 148px;
  top: 18px;
}

.constraints-commitment-btn {
  background: var(--primary) !important;
  border-radius: 5px solid var(--primary) !important;
  min-height: 24px !important;
  color: var(--black) !important;
  min-width: 80px;
}

.constraints-commitment-btn .icon-hand-shake {
  margin-right: 5px !important;
  margin-left: 5px;
  align-items: center !important;
  margin-top: 2px;
}

.constraints-commitment-btn .constraint-btn-span {
  font-size: 12px;
}

.tableConstraints .constraints-commitment-btn {
  position: relative;
  top: -4px;
}
.popoverConstraint .ant-calendar-picker-icon {
  margin-right: 21px !important;
}

.img-responsable-lookahead {
  border-radius: 100%;
  height: 22px;
  width: 22px;
  margin: 0 3px 2px 0 !important;
}

.img-responsable-lookahead.no-img {
  background-color: #121212cf;
  color: #ffffff;

  font-size: 10px;
  font-weight: 500;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.img-responsable-lookahead:hover {
  transform: scale(1.3);
}
.lookahead-delete-icon {
  width: 15px;
  height: 15px;
  margin: 5;
}

.lookahead-delete-icon:hover {
  transform: scale(1.1);
  color: #ff0000cf;
}
