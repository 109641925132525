/* Brand Colors */
$white: #fff;
$black: #121212;
$primary: #7dff8a;

/* Achromatic Colors */
$gray-1: #b3b3b3;
$gray-2: #d8d8d8;
$gray: #c4c4c4;
$cool-gray: #f0f4f5;
$l-gray-4: #e7e7e7;
$l-gray-3: #f4f4f4;
$l-gray-2: #f7f7f7;
$l-gray-1: #fcfcfc;

/* Outbuild Colors Variants */
$dark-green-sec: #252b1d;
$dark-green: #3e482d;
$bed-rock: #7d8671;
$bed-rock-t1: #616a54;
$bed-rock-t2: #2c3421;
$bed-rock-t3: #151b0e;
$bed-rock-t4: #090d03;
$bed-rock-border: #252b1e;
$mist: #cce2d6;
$clear-bed-rock: #c3cfbf;

/* Other Colors */
$dark-mint: #53c255;
$light-green: #bdffc4;
$lighter-green: #ecffee;
$blue: #498e98;
$light-blue: #a3c6cb;
$lighter-blue: #c9dee1;
$lighter-blue-v2: #f0f7f8;
$dark-blue-green: #0e5381;
$deep-dark-red: #4c2a2e;
$dark-red: #8e0101;
$red: #e50101;
$light-red: #fa7676;
$lighter-red: #fce6e6;
$yellow: #fdd551;
$light-yellow: #fdd99b;
$lighter-yellow: #fefbf0;
$orange: #f26d0c;
$light-orange: #fcd4b7;
$lighter-orange: #fdefe5;
$dark-gray: #747474;

/* Company / Tag Colors */
$sb-red: #f7b3b3;
$sb-light-red: #ffc2c2;
$sb-orange: #ffceb3;
$sb-yellow: #fdebcd;
$sb-olive: #dfd8b5;
$sb-green: #b4e7b4;
$sb-dark-green: #b5d8b5;
$sb-greenish-blue: #b4cbcb;
$sb-cyan-blue: #b3e0e7;
$sb-light-blue: #b8cffb;
$sb-royal-blue: #b5bff6;
$sb-red-purple: #dcb4ef;
$sb-pink-purple: #f1b4d1;
$sb-pink: #ffc7e2;
$sb-gray: #cdcdcd;
$sb-dark-gray: #c2c2c2;
$sb-dark-red: #f7b3b3;
$sb-light-yellow: #fffcb3;
$sb-light-green: #b4e7b4;
$sb-green-blue: #b5d8b5;
$sb-cyan: #b3e0e7;
$sb-dark-blue: #b4bdcb;
$sb-dark-purple: #c0b5d3;
$sb-light-pink: #fedded;

/* Other */
$disabled-opacity: 0.5;
$separation-line: #5f696d;
$border-color-check: #688a96;
$transition-duration-default: 0.3s;
$background-input-dark: #485b61;
$primary-50: #7dff8a50;
$red-20: #e5010120;
$dark-black-10: #00000001;
$dropdown-shadow: rgba(0, 0, 0, 0.1);
$alert-shadow: rgba(88, 88, 88, 0.2);
$avatar-company-name: #0a535e;
$disabled-button-bg: #d1d1d1;
$disabled-button-text: #a3a3a3;
$active-row: #ecffee;

/* Font Family */
$base-family-font:
  'Roboto',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  sans-serif;

/* Fonts Weights */
$light-weight-font: 100;
$regular-weight-font: 400;
$medium-weight-font: 500;
$bold-weight-font: 700;

/* Fonts Sizes */
$xs-size-font: 10px;
$s-size-font: 13px;
$m-size-font: 16px;
$l-size-font: 20px;
$xl-size-font: 24px;
$xxl-size-font: 30px;

/* Fonts Light */
$light-xs-font: $light-weight-font $xs-size-font $base-family-font;
$light-s-font: $light-weight-font $s-size-font $base-family-font;
$light-m-font: $light-weight-font $m-size-font $base-family-font;
$light-l-font: $light-weight-font $l-size-font $base-family-font;
$light-xl-font: $light-weight-font $xl-size-font $base-family-font;
$light-xxl-font: $light-weight-font $xxl-size-font $base-family-font;

/* Fonts Regular */
$regular-xs-font: $regular-weight-font $xs-size-font $base-family-font;
$regular-s-font: $regular-weight-font $s-size-font $base-family-font;
$regular-m-font: $regular-weight-font $m-size-font $base-family-font;
$regular-l-font: $regular-weight-font $l-size-font $base-family-font;
$regular-xl-font: $regular-weight-font $xl-size-font $base-family-font;
$regular-xxl-font: $regular-weight-font $xxl-size-font $base-family-font;

/* Fonts Medium */
$medium-xs-font: $medium-weight-font $xs-size-font $base-family-font;
$medium-s-font: $medium-weight-font $s-size-font $base-family-font;
$medium-m-font: $medium-weight-font $m-size-font $base-family-font;
$medium-l-font: $medium-weight-font $l-size-font $base-family-font;
$medium-xl-font: $medium-weight-font $xl-size-font $base-family-font;
$medium-xxl-font: $medium-weight-font $xxl-size-font $base-family-font;

/* Fonts Bold */
$bold-xs-font: $bold-weight-font $xs-size-font $base-family-font;
$bold-s-font: $bold-weight-font $s-size-font $base-family-font;
$bold-m-font: $bold-weight-font $m-size-font $base-family-font;
$bold-l-font: $bold-weight-font $l-size-font $base-family-font;
$bold-xl-font: $bold-weight-font $xl-size-font $base-family-font;
$bold-xxl-font: $bold-weight-font $xxl-size-font $base-family-font;

/* Fonts Other Variants */
$h1-font: $medium-xxl-font;
$h2-font: $medium-xl-font;
$h3-font: $medium-l-font;
$h4-font: $medium-m-font;
$h5-font: $bold-s-font;
$h6-font: $bold-xs-font;
$subtitle-font: $medium-s-font;
$body-font: $regular-s-font;
$body-2-font: $regular-m-font;
$caption-font: $regular-xs-font;

:export {
  WHITE: $white;
  BLACK: $black;
  PRIMARY: $primary;
  GRAY_1: $gray-1;
  GRAY_2: $gray-2;
  GRAY: $gray;
  COOL_GRAY: $cool-gray;
  L_GRAY_4: $l-gray-4;
  L_GRAY_3: $l-gray-3;
  L_GRAY_2: $l-gray-2;
  L_GRAY_1: $l-gray-1;
  DARK_GREEN_SEC: $dark-green-sec;
  DARK_GREEN: $dark-green;
  BED_ROCK: $bed-rock;
  BED_ROCK_T1: $bed-rock-t1;
  BED_ROCK_T2: $bed-rock-t2;
  BED_ROCK_T3: $bed-rock-t3;
  BED_ROCK_T4: $bed-rock-t4;
  BED_ROCK_BORDER: $bed-rock-border;
  MIST: $mist;
  CLEAR_BED_ROCK: $clear-bed-rock;
  DARK_MINT: $dark-mint;
  LIGHT_GREEN: $light-green;
  LIGHTER_GREEN: $lighter-green;
  BLUE: $blue;
  LIGHT_BLUE: $light-blue;
  LIGHTER_BLUE: $lighter-blue;
  LIGHTER_BLUE_V2: $lighter-blue-v2;
  DARK_BLUE_GREEN: $dark-blue-green;
  DEEP_DARK_RED: $deep-dark-red;
  DARK_RED: $dark-red;
  RED: $red;
  LIGHT_RED: $light-red;
  LIGHTER_RED: $lighter-red;
  YELLOW: $yellow;
  LIGHT_YELLOW: $light-yellow;
  LIGHTER_YELLOW: $lighter-yellow;
  ORANGE: $orange;
  LIGHT_ORANGE: $light-orange;
  LIGHTER_ORANGE: $lighter-orange;
  DARK_GRAY: $dark-gray;
  SB_RED: $sb-red;
  SB_LIGHT_RED: $sb-light-red;
  SB_ORANGE: $sb-orange;
  SB_YELLOW: $sb-yellow;
  SB_OLIVE: $sb-olive;
  SB_GREEN: $sb-green;
  SB_DARK_GREEN: $sb-dark-green;
  SB_GREENISH_BLUE: $sb-greenish-blue;
  SB_CYAN_BLUE: $sb-cyan-blue;
  SB_LIGHT_BLUE: $sb-light-blue;
  SB_ROYAL_BLUE: $sb-royal-blue;
  SB_RED_PURPLE: $sb-red-purple;
  SB_PINK_PURPLE: $sb-pink-purple;
  SB_PINK: $sb-pink;
  SB_GRAY: $sb-gray;
  SB_DARK_GRAY: $sb-dark-gray;
  DISABLED_OPACITY: $disabled-opacity;
  SEPARATION_LINE: $separation-line;
  BORDER_COLOR_CHECK: $border-color-check;
  TRANSITION_DURATION_DEFAULT: $transition-duration-default;
  BACKGROUND_INPUT_DARK: $background-input-dark;
  PRIMARY_50: $primary-50;
  RED_20: $red-20;
  DARK_BLACK_10: $dark-black-10;
  DROPDOWN_SHADOW: $dropdown-shadow;
  ALERT_SHADOW: $alert-shadow;
  AVATAR_COMPANY_NAME: $avatar-company-name;
  DISABLED_BUTTON_BG: $disabled-button-bg;
  DISABLED_BUTTON_TEXT: $disabled-button-text;
  ACTIVE_ROW: $active-row;
  SB_DARK_RED: $sb-dark-red;
  SB_LIGHT_YELLOW: $sb-light-yellow;
  SB_LIGHT_GREEN: $sb-light-green;
  SB_GREEN_BLUE: $sb-green-blue;
  SB_CYAN: $sb-cyan;
  SB_DARK_BLUE: $sb-dark-blue;
  SB_DARK_PURPLE: $sb-dark-purple;
  SB_LIGHT_PINK: $sb-light-pink;
}
