@import '../../../../../assets/styles/variables.scss';

.tab--page {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  gap: 10px;

  .tab--page__title {
    display: flex;

    h5 {
      line-height: 14px;
      font: $medium-s-font;
      color: $white;
      margin: 0;
    }
  }

  .tab--page__format {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      font: $regular-s-font;
      line-height: 14px;
      color: $white;
    }

    .format__dropdown {
      width: 160px;
    }
  }

  .tab--page__scaling {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px 20px;
    border-radius: 5px;
    border: 1px solid $bed-rock;

    label {
      line-height: 14px;
      font: $medium-s-font;
      color: $white;
    }
  }

  .tab--page__trim {
    display: flex;
  }
}
