@import '../../stylesheets/base';
@import '../../assets/styles/variables.scss';

.tab-panel {
  overflow: visible;

  :global .ant-tabs-nav {
    width: 100%;

    > div {
      display: flex;
    }
  }

  /* Increasing specificity to override Ant's default style */
  :global .ant-tabs-nav-scroll > .ant-tabs-nav > div > .ant-tabs-tab {
    background-color: $black;
    flex: 1 0 auto;
    text-align: center;
    margin-right: 0;
    color: $white;
    border-width: 0;
    transition:
      border-top $default-transition-duration ease-in,
      background-color $default-transition-duration ease-in;

    &:not(:first-child) {
      border-top-left-radius: 0;
    }

    &:not(:last-child) {
      border-right: 1px solid $dark-green-sec;
      border-top-right-radius: 0;
    }

    &.ant-tabs-tab-active {
      background-color: $black;
      border-top: 4px solid $primary;
    }

    &.ant-tabs-tab-disabled {
      color: $black;
      opacity: $disabled-opacity;
    }
  }
}
