@import '../../../assets/styles/variables.scss';

.button-design-system {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font: $regular-s-font;
  color: $black;
  box-sizing: border-box;
  border: 0;
  border-radius: 5px;
  transition-duration: 0.3s;

  &.size--small {
    height: 24px;
    padding: 0 10px;

    i {
      img {
        width: 12px;
        height: 12px;
      }
    }
  }

  &.size--default {
    height: 30px;
    padding: 0 20px;

    i {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  &.size--large {
    font: $regular-m-font;
    height: 40px;
    padding: 0 30px;
    gap: 10px;

    i {
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &.type--primary {
    background-color: $primary;

    &:hover {
      background-color: $dark-mint;
    }
  }

  &.type--secondary {
    background-color: $cool-gray;

    &:hover {
      background-color: $gray-1;
    }
  }

  &.type--link {
    background-color: transparent;
    color: $primary;

    &:hover {
      color: $dark-mint;
    }
  }

  &:disabled {
    opacity: $disabled-opacity;
    cursor: not-allowed;
  }
}
