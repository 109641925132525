@import '../../../assets/styles/variables.scss';

.dropdown-design-system-v2,
.dropdown-design-system-v2__submenu {
  ul {
    border-radius: 5px;
    padding: 10px 0;
    box-shadow: 2px 2px 4px 0px $dropdown-shadow;

    li {
      width: 100%;
      position: relative;
      transition-duration: 0.3s;

      &:is(:not(.divider)) {
        height: 30px;
        padding: 7px 20px 0 !important;
      }

      &.divider {
        height: 20px;
        padding-top: 10px !important;
      }

      &.group {
        height: 40px;
      }

      &.icon {
        padding-top: 3px !important;
      }

      .item__content {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .content__info {
          display: flex;
          align-items: center;
          gap: 10px;

          span {
            font: $regular-s-font;
          }

          .content__icon {
            i {
              img {
                width: 14px;
                height: 14px;
              }
            }
          }
        }

        .content__group {
          width: 100%;
          height: 1px;
        }
      }

      &.ant-dropdown-menu-item-disabled {
        opacity: $disabled-opacity;
        cursor: not-allowed;
      }

      &.ant-dropdown-menu-submenu {
        .ant-dropdown-menu-submenu-title {
          margin-top: 0 !important;
          padding: 0 !important;
          font: $regular-s-font;
        }
      }

      .ant-dropdown-menu-submenu-arrow {
        i {
          svg {
            fill: $gray-1;
          }
        }
      }
    }
  }

  &.theme--light {
    ul {
      background-color: $white;
      border: 1px solid $gray-2;

      li {
        color: $black;

        .item__content {
          .content__divider {
            width: 100%;
            height: 1px;
            background-color: $cool-gray;
          }
        }

        &:hover:is(:not(.divider)):is(:not(.group)):is(:not(.active)) {
          background-color: $cool-gray !important;
        }

        &.active,
        &.active:hover {
          background-color: $primary !important;
        }

        &.group {
          .item__content {
            .content__info {
              span {
                font: $medium-s-font;
                color: $dark-gray;
              }
            }
          }

          .content__group {
            background-color: $gray-2;
          }
        }

        &.ant-dropdown-menu-submenu {
          .ant-dropdown-menu-submenu-title {
            color: $black;
          }
        }
      }
    }
  }

  &.theme--dark {
    ul {
      background-color: $dark-green;
      border: 1px solid $bed-rock;

      li {
        color: $white;

        .item__content {
          .content__divider {
            width: 100%;
            height: 1px;
            background-color: $bed-rock;
          }
        }

        &:hover:is(:not(.divider)):is(:not(.group)):is(:not(.active)) {
          background-color: $black !important;
        }

        &.active,
        &.active:hover {
          color: $black;
          background-color: $primary !important;
        }

        &.group {
          .item__content {
            .content__info {
              span {
                font: $medium-s-font;
                color: $cool-gray;
              }
            }
          }

          .content__group {
            background-color: $bed-rock;
          }
        }

        &.ant-dropdown-menu-submenu {
          .ant-dropdown-menu-submenu-title {
            color: $white;
          }
        }
      }
    }
  }
}

.dropdown-design-system-v2__submenu {
  ul {
    li {
      &.group {
        height: 40px;
      }

      &.divider {
        .subitem__content {
          .content__divider {
            width: 100%;
            height: 1px;
          }
        }
      }

      .subitem__content {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .content__info {
          display: flex;
          align-items: center;
          gap: 10px;

          span {
            font: $regular-s-font;
          }

          .content__icon {
            i {
              margin: 0 !important;

              img {
                width: 14px;
                height: 14px;
              }
            }
          }
        }

        .content__group {
          width: 100%;
          height: 1px;
        }
      }
    }
  }

  &.theme--light {
    li {
      &.divider {
        .subitem__content {
          .content__divider {
            background-color: $cool-gray;
          }
        }
      }

      &.group {
        .subitem__content {
          .content__info {
            span {
              font: $medium-s-font;
              color: $dark-gray;
            }
          }
        }

        .content__group {
          background-color: $gray-2;
        }
      }

      &:hover:is(.divider),
      &:hover:is(.group) {
        background-color: transparent !important;
      }

      &.active,
      &.active:hover {
        background-color: $primary !important;
      }
    }
  }

  &.theme--dark {
    li {
      &.divider {
        .subitem__content {
          .content__divider {
            background-color: $bed-rock;
          }
        }
      }

      &.group {
        .subitem__content {
          .content__info {
            span {
              font: $medium-s-font;
              color: $cool-gray;
            }
          }
        }

        .content__group {
          background-color: $bed-rock;
        }
      }

      &:hover:is(.divider),
      &:hover:is(.group) {
        background-color: transparent !important;
      }

      &.active,
      &.active:hover {
        background-color: $primary !important;
      }
    }
  }
}

.dropdown-design-system-v2__label {
  width: 100%;
  border-radius: 5px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition-duration: 0.3s;

  &.theme--light {
    background-color: $cool-gray;
    color: $black;

    &.active {
      border: 1px solid $dark-mint;
    }

    &.error {
      border: 1px solid $red;
      background-color: $lighter-red;
    }

    &.v2 {
      background-color: $gray-2;
    }
  }

  &.theme--dark {
    background-color: $dark-green;
    color: $white;

    &.active {
      border: 1px solid $primary;
    }

    &.error {
      border: 1px solid $light-red;
      background-color: $deep-dark-red;
    }

    &.v2 {
      background-color: $gray-2;
      color: $black;
    }
  }

  .label__info {
    display: flex;
    align-items: center;
    gap: 5px;

    .info__icon {
      i {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    span {
      font: $regular-s-font;
    }
  }

  .label__icon {
    i {
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
}
