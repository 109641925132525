.container {
  margin: 16px 10px 0 7px;
  display: flex;
  flex-direction: column;
  position: relative;

  & .activities-selected {
    margin-bottom: 17px;
    display: flex;
    justify-content: space-between;
  }

  & .tasks {
    width: calc(100% + 15px);
    height: calc(100vh - 420px);
    overflow: auto hidden;
    flex-grow: 1;

    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #c4c4c4;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #121212;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #121212;
    }

    & div::-webkit-scrollbar {
      width: 4px;
    }

    & div::-webkit-scrollbar-track {
      background: #c4c4c4;
      border-radius: 3px;
    }

    & div::-webkit-scrollbar-thumb {
      background: #121212;
      border-radius: 3px;
    }

    & div::-webkit-scrollbar-thumb:hover {
      background: #121212;
    }
  }
}

.showing-tasks {
  color: #a3a3a3;
  font-size: 12px;
  font-weight: 500;
}

.collapse {
  font-size: 12px;
  font-weight: 400;
  color: #2c3421;

  margin-right: 1px;
}

.collapse-icon {
  margin-right: 7px;
  display: inline-block;
  transition: transform;

  &.expand-icon {
    transform: rotate(180deg);
  }
}

.btn {
  width: 100%;
  margin: 17px 0;
  padding: 9px 53px;

  bottom: 40px;

  color: #fff;
  background-color: #2c3421;
  border-radius: 5px;
  border: none;
  outline: none;

  font-size: 12px;
  font-weight: 500;

  &.btn-disable {
    cursor: no-drop;
    color: #121212;
    background-color: #efefef;
  }
}

.checkbox__activity {
  display: flex;
  justify-content: space-between;
  margin-right: 18px;

  & :global .ant-checkbox-checked::after {
    border: none !important;
  }

  & :global .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--dark-mint) !important;
    border-color: var(--dark-mint) !important;
  }

  & :global .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid #121212;
    border-top: 0;
    border-left: 0;
  }

  & .correlative_id {
    margin-left: 8.5px;
  }

  & .task-pipe {
    margin: 0 5px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.rotate-icon {
  transition: transform;
  transform: rotate(180deg);
}

.higger-task-selected {
  color: #e50101;
}

.font-activity {
  font-weight: 300;
}

.font-bold {
  font-weight: 500;
}
