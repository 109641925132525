.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.childed-title-style {
  font-size: 23px !important;
  font-weight: 600 !important;
  color: var(--black) !important;
  margin-top: 0%;
}

.sub-title {
  font-size: 19px !important;
  font-weight: 0px !important;
  color: '#333334' !important;
  margin-top: 30px;
}

.common-btn-style {
  margin-right: 15px !important;
  margin-top: 15px !important;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: var(--black) !important;
  font-weight: 700 !important;
  font-size: 13px !important;
  padding: 0px 1% 0px 1% !important;
  width: 15%;
}

.bottom-separator {
  background-color: #dcdcdc;
  width: 60%;
  border-radius: 160px;
  margin-top: 20px;
  border-color: #0000ff00;
  padding: 0px !important;
  height: 1px !important;
  border: 1px;
}

.back-btn-navigator {
  font-size: 24px;
  margin-left: 15px;
  margin-top: 7px;
}

.label-btn-navigator {
  position: relative;
  font-size: 24px;
  top: -2px;
  left: 5px;
}

.common-disabled-btn-style {
  background-color: white !important;
  border-color: white !important;
  color: var(--primary) !important;
  font-weight: 400 !important;
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045) !important;
}
