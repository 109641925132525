.shadow-container {
  box-shadow: 4px 8px 23px rgba(0, 0, 0, 0.05) !important;
}

.push-content-custom {
  position: relative;
  top: 65px;
}

.fixed-filter-header {
  position: fixed !important;
  width: 100% !important;
  background-color: white !important;
  z-index: 10000;
}

.lookahead-tables-fix {
  position: relative;
  top: 70px;
}

.style-for-fixed-header-titles {
  margin-bottom: 15px !important;
  margin-right: 70px !important;
  top: 182px !important;
  width: 100% !important;
  background-color: white !important;
  z-index: 10000;
  padding-right: 152px !important;
  position: fixed !important;
}

.custom-resize-col-lookahead {
  max-width: 1000px !important;
  max-height: 21px !important;
}

.loader-spinner-lookahead-header {
  display: flex !important;
  justify-content: center !important;
}

.loader-spinner-lookahead-table {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 65vh !important;
}

.fixed-tree-table-header {
  background-color: white !important;
  padding: 0 47px !important;
  max-height: 20px !important;
  overflow: hidden !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.custom-resize-col-lookahead:hover {
  border-right: solid 1px #d8d8d8;
}

.weekly-plan .icon-hand-shake-massive {
  width: 16px;
  height: 16px;
  margin-left: 0px;
  margin-right: 0px;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.weekly-plan-on-filter-header {
  display: inline;
  margin: 0 10px;
  cursor: pointer;
}

.weekly-plan .week-navigate {
  width: 300px;
  position: relative;
  top: -12px;
}

.weekly-plan .week-left {
  text-align: right;
  margin: 2px;
}

.weekly-plan .week-right {
  text-align: left;
  margin: 2px;
}

.weekly-plan .week {
  padding: 10px;
}

.weekly-plan .week-number {
  font-size: 12px;
  text-align: center;
}

.weekly-plan .range-date {
  font-size: 12px;
  color: #121212 !important;
}

.weekly-plan .div-week {
  margin: auto;
  display: flex;
  align-items: center;
}

.weekly-plan .div-week div {
  color: #2c3421;
}

.weekly-plan .range-date {
  text-align: center !important;
}

.only-number-progress-massive {
  color: #121212;
  width: 50px !important;
  margin: 10px;
  border: 1px solid #121212 !important;
}

.div-number-progress-massive {
  text-align: center !important;
}

.weekly-plan .delete-subcontract {
  color: red;
  margin-left: 5px;
}

.weekly-plan .subcontract-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
