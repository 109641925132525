@import '../../../assets/styles/variables.scss';

@mixin font-base($weight: 400, $line-height: 14px, $color: $black) {
  font-size: 12px;
  font-weight: $weight;
  line-height: $line-height;
  color: $color;
}

@mixin chevron-base($border: $dark-mint) {
  width: 10px;
  height: 10px;
  border: 0 solid $border;
  border-width: 3px 0 0 3px;
}

.datepicker-design-system {
  .ant-calendar {
    box-shadow: none;
    border-radius: 5px;
  }

  &.theme--light {
    .ant-calendar {
      background-color: $white;
      border-color: $gray-2;

      .ant-calendar-panel {
        .ant-calendar-input-wrap {
          border: 0;

          .ant-calendar-date-input-wrap {
            .ant-calendar-input {
              @include font-base();
            }
          }
        }

        .ant-calendar-date-panel {
          .ant-calendar-header {
            border: 0;

            .ant-calendar-prev-year-btn,
            .ant-calendar-next-year-btn {
              &::before,
              &::after {
                @include chevron-base();
              }
            }

            .ant-calendar-prev-month-btn,
            .ant-calendar-next-month-btn {
              &::before {
                @include chevron-base();
              }
            }

            .ant-calendar-my-select {
              .ant-calendar-month-select,
              .ant-calendar-year-select {
                @include font-base(500);
              }
            }

            .ant-calendar-year-panel {
              background-color: $white;

              .ant-calendar-year-panel-header {
                border: 0;

                .ant-calendar-year-panel-prev-decade-btn,
                .ant-calendar-year-panel-next-decade-btn {
                  &::before,
                  &::after {
                    @include chevron-base();
                  }
                }

                .ant-calendar-year-panel-decade-select {
                  .ant-calendar-year-panel-decade-select-content {
                    @include font-base(500);
                  }
                }
              }

              .ant-calendar-year-panel-body {
                .ant-calendar-year-panel-table {
                  .ant-calendar-year-panel-tbody {
                    tr {
                      td {
                        .ant-calendar-year-panel-year {
                          @include font-base(400, 22px);

                          &:hover {
                            background-color: $light-green !important;
                          }
                        }

                        &.ant-calendar-year-panel-cell.ant-calendar-year-panel-selected-cell {
                          .ant-calendar-year-panel-year {
                            background-color: $dark-mint;
                            color: $white;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .ant-calendar-decade-panel {
              .ant-calendar-decade-panel-header {
                border: 0;

                .ant-calendar-decade-panel-prev-century-btn,
                .ant-calendar-decade-panel-next-century-btn {
                  &::before,
                  &::after {
                    @include chevron-base();
                  }
                }

                .ant-calendar-decade-panel-century {
                  @include font-base(500, 40px);
                }
              }

              .ant-calendar-decade-panel-body {
                .ant-calendar-decade-panel-table {
                  .ant-calendar-decade-panel-tbody {
                    tr {
                      td {
                        .ant-calendar-decade-panel-decade {
                          @include font-base(400, 22px);

                          &:hover {
                            background-color: $light-green !important;
                          }
                        }

                        &.ant-calendar-decade-panel-cell.ant-calendar-decade-panel-selected-cell {
                          .ant-calendar-decade-panel-decade {
                            background-color: $dark-mint;
                            color: $white;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .ant-calendar-month-panel {
              .ant-calendar-month-panel-header {
                border: 0;

                .ant-calendar-month-panel-prev-year-btn,
                .ant-calendar-month-panel-next-year-btn {
                  &::before,
                  &::after {
                    @include chevron-base();
                  }
                }

                .ant-calendar-month-panel-year-select {
                  @include font-base(500);
                }
              }

              .ant-calendar-month-panel-body {
                .ant-calendar-month-panel-table {
                  .ant-calendar-month-panel-tbody {
                    tr {
                      td {
                        .ant-calendar-month-panel-month {
                          @include font-base(400, 22px);

                          &:hover {
                            background-color: $light-green !important;
                          }
                        }

                        &.ant-calendar-month-panel-cell.ant-calendar-month-panel-selected-cell {
                          .ant-calendar-month-panel-month {
                            background-color: $dark-mint;
                            color: $white;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .ant-calendar-body {
            .ant-calendar-table {
              .ant-calendar-column-header-inner {
                @include font-base(500, 22px, $dark-mint);
              }

              .ant-calendar-tbody {
                .ant-calendar-date {
                  @include font-base(400, 22px);

                  &:hover,
                  &:active {
                    background-color: $light-green !important;
                  }
                }

                .ant-calendar-last-month-cell .ant-calendar-date {
                  color: $gray-1;
                }

                .ant-calendar-cell.ant-calendar-today,
                .ant-calendar-selected-day.ant-calendar-date,
                .ant-calendar-cell.ant-calendar-selected-day {
                  .ant-calendar-date {
                    border: 0;
                    background-color: transparent;
                  }
                }

                .ant-calendar-cell.ant-calendar-selected-date.ant-calendar-selected-day {
                  .ant-calendar-date {
                    border: 0;
                    background-color: $dark-mint;
                    color: $white;
                    border-radius: 5px;
                  }
                }
              }
            }
          }

          .ant-calendar-footer {
            border: 0;

            .ant-calendar-footer-btn {
              .ant-calendar-today-btn {
                @include font-base(500);
              }
            }
          }
        }
      }
    }
  }

  &.theme--dark {
    .ant-calendar {
      background-color: $black;
      border-color: $black;

      .ant-calendar-panel {
        .ant-calendar-input-wrap {
          border: 0;

          .ant-calendar-date-input-wrap {
            .ant-calendar-input {
              @include font-base(400, 14px, $white);
              background-color: $black;
            }
          }
        }

        .ant-calendar-date-panel {
          .ant-calendar-header {
            border: 0;

            .ant-calendar-prev-year-btn,
            .ant-calendar-next-year-btn {
              &::before,
              &::after {
                @include chevron-base($primary);
              }
            }

            .ant-calendar-prev-month-btn,
            .ant-calendar-next-month-btn {
              &::before {
                @include chevron-base($primary);
              }
            }

            .ant-calendar-my-select {
              .ant-calendar-month-select,
              .ant-calendar-year-select {
                @include font-base(500, 14px, $white);
              }
            }

            .ant-calendar-year-panel {
              background-color: $black;

              .ant-calendar-year-panel-header {
                border: 0;

                .ant-calendar-year-panel-prev-decade-btn,
                .ant-calendar-year-panel-next-decade-btn {
                  &::before,
                  &::after {
                    @include chevron-base($primary);
                  }
                }

                .ant-calendar-year-panel-decade-select {
                  .ant-calendar-year-panel-decade-select-content {
                    @include font-base(500, 14px, $white);
                  }
                }
              }

              .ant-calendar-year-panel-body {
                .ant-calendar-year-panel-table {
                  .ant-calendar-year-panel-tbody {
                    tr {
                      td {
                        .ant-calendar-year-panel-year {
                          @include font-base(400, 22px, $white);

                          &:hover {
                            background-color: $primary-50 !important;
                            color: $black;
                          }
                        }

                        &.ant-calendar-year-panel-cell.ant-calendar-year-panel-selected-cell {
                          .ant-calendar-year-panel-year {
                            background-color: $primary;
                            color: $black;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .ant-calendar-decade-panel {
              background-color: $black;

              .ant-calendar-decade-panel-header {
                border: 0;

                .ant-calendar-decade-panel-prev-century-btn,
                .ant-calendar-decade-panel-next-century-btn {
                  &::before,
                  &::after {
                    @include chevron-base($primary);
                  }
                }

                .ant-calendar-decade-panel-century {
                  @include font-base(500, 40px, $white);
                }
              }

              .ant-calendar-decade-panel-body {
                .ant-calendar-decade-panel-table {
                  .ant-calendar-decade-panel-tbody {
                    tr {
                      td {
                        .ant-calendar-decade-panel-decade {
                          @include font-base(400, 22px, $white);

                          &:hover {
                            background-color: $primary-50 !important;
                            color: $black;
                          }
                        }

                        &.ant-calendar-decade-panel-cell.ant-calendar-decade-panel-selected-cell {
                          .ant-calendar-decade-panel-decade {
                            background-color: $primary;
                            color: $black;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .ant-calendar-month-panel {
              background-color: $black;

              .ant-calendar-month-panel-header {
                border: 0;

                .ant-calendar-month-panel-prev-year-btn,
                .ant-calendar-month-panel-next-year-btn {
                  &::before,
                  &::after {
                    @include chevron-base($primary);
                  }
                }

                .ant-calendar-month-panel-year-select {
                  @include font-base(500, 14px, $white);
                }
              }

              .ant-calendar-month-panel-body {
                .ant-calendar-month-panel-table {
                  .ant-calendar-month-panel-tbody {
                    tr {
                      td {
                        .ant-calendar-month-panel-month {
                          @include font-base(400, 22px, $white);

                          &:hover {
                            background-color: $primary-50 !important;
                          }
                        }

                        &.ant-calendar-month-panel-cell.ant-calendar-month-panel-selected-cell {
                          .ant-calendar-month-panel-month {
                            background-color: $primary;
                            color: $black;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .ant-calendar-body {
            .ant-calendar-table {
              .ant-calendar-column-header-inner {
                @include font-base(500, 22px, $primary);
              }

              .ant-calendar-tbody {
                .ant-calendar-date {
                  @include font-base(400, 22px, $white);

                  &:hover,
                  &:active {
                    background-color: $primary-50 !important;
                    color: $black;
                  }
                }

                .ant-calendar-last-month-cell .ant-calendar-date {
                  color: $gray-1;
                }

                .ant-calendar-cell.ant-calendar-today,
                .ant-calendar-selected-day.ant-calendar-date,
                .ant-calendar-cell.ant-calendar-selected-day {
                  .ant-calendar-date {
                    border: 0;
                    background-color: transparent;
                  }
                }

                .ant-calendar-cell.ant-calendar-selected-date.ant-calendar-selected-day {
                  .ant-calendar-date {
                    border: 0;
                    background-color: $primary;
                    color: $black;
                    border-radius: 5px;
                  }
                }
              }
            }
          }

          .ant-calendar-footer {
            border: 0;

            .ant-calendar-footer-btn {
              .ant-calendar-today-btn {
                @include font-base(500, 14px, $white);
              }
            }
          }
        }
      }
    }
  }
}

.datepicker-input-design-system {
  width: 100%;
  height: 32px;
  border-radius: 5px;

  div {
    border-radius: 5px;
  }

  input {
    border-radius: 5px;
    height: 32px;
  }

  i,
  i:hover {
    background-color: transparent;
    color: $dark-green;
  }

  &.theme--light {
    input {
      border: 1px solid $cool-gray;
      background-color: $cool-gray;
      @include font-base(300);
    }

    &:hover {
      .ant-calendar-picker-input {
        &:not(.ant-input-disabled) {
          border-color: $dark-mint !important;
        }
      }
    }
  }

  &.theme--dark {
    input {
      border: 1px solid $dark-green;
      background-color: $dark-green;
      @include font-base(300, 14px, $white);
    }

    i,
    i:hover {
      background-color: transparent;
      color: $white;
    }

    &:hover {
      .ant-calendar-picker-input {
        &:not(.ant-input-disabled) {
          border-color: $primary !important;
        }
      }
    }
  }

  &.error {
    &.theme--light {
      input {
        background-color: $light-red;
        border: 1px solid $red;
      }
    }

    &.theme--dark {
      input {
        background-color: $deep-dark-red;
        border: 1px solid $light-red;
      }
    }

    &:hover {
      &.theme--light {
        .ant-calendar-picker-input {
          &:not(.ant-input-disabled) {
            border-color: $red !important;
          }
        }
      }

      &.theme--dark {
        .ant-calendar-picker-input {
          &:not(.ant-input-disabled) {
            border-color: $light-red !important;
          }
        }
      }
    }
  }
}
