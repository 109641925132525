@import '../../assets/styles/variables.scss';

.select-view-popover {
  width: 170px;
  height: 32px;
  background-color: $gray-2;
  border: 1px solid $gray-2;
  border-radius: 5px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition-duration: 0.3s;

  .select-view-popover__left {
    display: flex;
    align-items: center;
    gap: 5px;

    span {
      font: $regular-s-font;
      color: $black;
    }
  }

  .select-view-popover__right {
    i {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  &.ant-popover-open {
    border: 1px solid $dark-mint;
  }
}

.content-view-name-modal {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .content-view-name-modal__input {
    .input-design-system__hint {
      display: none;
    }
  }

  .content-view-name-modal__visibility {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .visibility__title {
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        font: $medium-s-font;
        color: $white;
      }

      .title__icon {
        cursor: pointer;

        i {
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  .content-view-name-modal__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}

.content-rename-view-modal {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .content-rename-view-modal__input {
    .input-design-system__hint {
      display: none;
    }
  }

  .content-rename-view-modal__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}

.content-delete-view-modal {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .content-delete-view-modal__icon,
  .content-delete-view-modal__title,
  .content-delete-view-modal__input,
  .content-delete-view-modal__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content-delete-view-modal__icon {
    i {
      img {
        width: 48px;
        height: 48px;
      }
    }
  }

  .content-delete-view-modal__title {
    flex-direction: column;

    h3 {
      margin: 0;
      font: $medium-m-font;
      color: $white;
      text-align: center;
    }
  }

  .content-delete-view-modal__input {
    flex-direction: column;
    gap: 10px;

    span {
      font: $medium-m-font;
      color: $white;

      b {
        color: $primary;
      }
    }

    .input__form {
      width: 200px;

      .input-design-system__hint {
        display: none;
      }
    }
  }

  .content-delete-view-modal__buttons {
    gap: 10px;
  }
}
