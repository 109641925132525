.link-style {
  margin-right: 10px;
}

.companyContainer {
  display: flex;
  align-items: center;
}

.toolbar-container {
  background-color: #121212 !important;
  min-height: 72px;
  padding: 12px 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gutter-example .ant-row > div {
  background: transparent;
  border: 0;
}
.gutter-box {
  background: #00a0e9;
  padding: 5px 0;
}
.search-btn {
  background-color: #686363 !important;
  border-color: #686363 !important;
  color: white !important;
  position: absolute !important;
  top: 6px !important;
  left: 100px !important;
}
.help-center-icon .notification-btn {
  top: 0 !important;
}

.notification-btn {
  background-color: var(--dark-green-sec) !important;
  border-color: var(--dark-green-sec) !important;
  color: var(--white) !important;
  margin-top: 0px;
  border-radius: 5px !important;
  height: 30px !important;
  width: 30px !important;
  top: -1px !important;
  padding: 0;
}

.notification-btn .anticon-bell {
  font-size: 15px;
  position: relative;
  top: 0px;
  color: #f5f5f5;
}

.notification-btn .anticon-question {
  font-size: 15px;
  position: relative;
  top: 0px;
  color: #f5f5f5;
}

.calendar-btn {
  background-color: #686363 !important;
  border-color: #686363 !important;
  color: white !important;
  margin-top: 7px;
  margin-left: 7px;
  border-radius: 10px !important;
}

.logo-style {
  width: 42px !important;
  height: 42px;
  border-radius: 6px !important;
  position: absolute !important;
  left: 14px !important;
}

.select-project-style {
  top: 8px;
}
.toolbar-container .selectors {
  display: flex;
  align-items: center;
  gap: 10px;
}

.toolbar-container .selectors .ant-select-selection--single {
  background: #25353a !important;
  border-color: #888888 !important;
  color: white !important;
  font-size: 13px !important;
  height: 26px !important;
  width: 114px;
  margin: 0px 10px 10px 10px;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  padding: 5px 5px 5px 5px;
}

.ant-select-selection {
  border-radius: 5px !important;
}

.toolbar-container .ant-select-arrow svg {
  width: 8px;
  height: 8px;
  color: #ffffff !important;
}

.ant-badge-count {
  background-color: var(--primary);
  color: var(--black);
}

.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  top: 9px !important;
  right: 2px !important;
}

.ant-badge-dot {
  background: #ed6536 !important;
  box-shadow: 0 0 0 1px #ed6536 !important;
}

.avatar-btn {
  margin-left: 7px !important;
  margin-bottom: 2px !important;
  margin-right: 10px !important;
}

.username-label {
  font-weight: bold;
  position: absolute !important;
  top: -25px;
}

.labels-container {
  color: white !important;
  font-size: 10px !important;
  position: relative;
  top: 5px;
}

.role-label {
  position: absolute;
  top: -10px;
}

.logout-label {
  position: absolute;
  top: 3px;
}

.logout-label button {
  padding: 0;
  margin: 0;
  height: auto;
  font-size: 12px;
  color: white;
}

.toolbar-container .ant-select-selection-selected-value {
  color: #ffffff;
  font-size: 13px;
  line-height: 15px;
}

.toolbar-container .ant-select {
  margin-right: 20px;
}

.toolbar-container .selectors .ant-select-selection--single {
  width: auto !important;
}

.help-center .ant-drawer-body {
  padding: 0px !important;
}

.search-help-center {
  height: 34px;
  padding: 18px;
  top: 138px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 99px;
  border-color: transparent;
  width: 67%;
}

.search-help-center::placeholder {
  font-size: 13px;
  text-align: center;
}

.single-question-style {
  height: 45px;
  padding-left: 13px;
  margin-top: 5px;
  padding-top: 13px;
  margin-bottom: 16px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  cursor: pointer;
}

.container-questions {
  min-height: 0px !important;
  max-height: 300px !important;
  height: 300px !important;
  overflow: auto;
}

.more-resources-title {
  font-weight: 500;
  font-size: 15px;
  margin-top: 10px;
}

.launch-other-center {
  transform: rotate(44deg);
}

.single-other-help {
  margin-left: 27px;
  height: 95px;
  padding-top: 18px;
  text-align: center;
  background: #ffffff;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  cursor: pointer;
}

.other-resources-label {
  font-size: 12px;
}

.title-tutorials-style {
  font-weight: bold;
  color: white;
  font-size: 20px;
}

.rows-downside-tutorials-content {
  position: relative;
  top: -55px;
}

.tutorials-titles {
  color: #121212;
  font-weight: bold;
  font-size: 17px;
}

.single-vid-container {
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-top: 10px;
  height: 250px;
  cursor: pointer;
  margin-right: 10px;
  padding-top: 25px;
}

.notification-count .ant-badge-count {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  top: -5px !important;
  right: -3px !important;
}

.div-notification {
  width: 55px;
  display: inline-block;
  text-align: center;
  position: relative;
  top: -3px !important;
}

.help-center-icon {
  position: relative;
  top: -2px !important;
}

.notification-row {
  display: flex;
}

.notification-icon {
  padding: 8px;
  margin-right: 8px;
  /* justify-content: center;
  align-self: center; */
  color: red;
}

.notification-svg {
  font-weight: bold;
}

.notification-nodata {
  margin: 0 8px;
}

.notification-description {
  justify-content: center;
  align-self: center;
  white-space: break-spaces;
  font-size: 13px !important;
  font-weight: 400 !important;
  font-family:
    Roboto,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
}

.constraint-icon {
  font-size: 30px;
}

.rounded-img-user {
  border-radius: 50px;
}

.rounded-img-user:hover {
  transform: scale(1.5);
}

.notification-dropdown ul {
  max-width: 400px;
}

.notification-dropdown ul li {
  cursor: pointer;
  opacity: 1;
  box-shadow: none;
  padding: 0 !important;
}

.ant-dropdown-menu {
  padding: 0 0;
  max-height: 300px;
  overflow-y: auto;
}

.notification-dropdown ul {
  overflow-y: auto;
  overflow-x: auto;
}

.notification-dropdown ul li.no-read {
  background-color: rgba(48, 159, 233, 0.3);
}

.notification-dropdown ul li.no-read:hover {
  background: rgba(27, 105, 158, 0.3) !important;
}

.notification-dropdown ul li.read:hover {
  background: rgb(219, 213, 213) !important;
}

.notification-description .desc-title {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 320px;
  line-height: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #121212;
  font-weight: 500;
}

.notification-description .desc-notif {
  line-height: 15px;
}
.notification-description .link {
  text-decoration: underline;
  line-height: 18px;
}
.notification-description .date {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 15px;
  color: #939393;
  margin-top: 4px;
}
.notification-description .due {
  color: orange;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 15px;
}

.notification-description .have {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 15px;
  color: #121212;
}

.menu-notifications .ant-dropdown-menu-item > a,
.menu-notifications .ant-dropdown-menu-submenu-title > a {
  margin: 0 !important;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

.duplicate-stage-icon img {
  width: 28.5px;
  height: 33px;
  position: relative;
}

.notification-with-title-icon {
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 15px;
  color: #121212;
  min-height: 81px;
}

.notification-with-title-icon .ant-notification-notice-message {
  font-weight: 400;
  size: 15px;
  line-height: 17.58px;
}

.notification-with-title-icon .ant-notification-notice-description {
  font-weight: 300;
  size: 12px;
  line-height: 14.06px;
  width: 265px;
  position: relative;
  top: 4px;
  left: 0px;
}

.notification-with-title-icon .ant-notification-notice-icon img {
  top: 7px;
}
