.constraint__form-modal .ant-modal-content {
  border-radius: var(--border-radius-modal);
  background-color: var(--background-modal);
}

.constraint__form-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}

.constraint__form--modal
  .ant-modal-content
  .ant-modal-body
  .form-modal__container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.constraint__form-modal
  .ant-modal-content
  .ant-modal-body
  .form-modal__container
  .container__loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-modal);
}

.constraint__form-modal
  .ant-modal-content
  .ant-modal-body
  .form-modal__container
  .container__loading
  span
  svg {
  fill: var(--primary);
  width: 45px;
  height: 45px;
}

.constraint__form-modal
  .ant-modal-content
  .ant-modal-body
  .form-modal__container
  .container__header {
  width: 100%;
  height: 40px;
  background-color: var(--dark-green-sec);
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--border-radius-modal) var(--border-radius-modal) 0 0;
}

.constraint__form-modal
  .ant-modal-content
  .ant-modal-body
  .form-modal__container
  .container__header
  .header__title {
  display: flex;
  align-items: center;
}

.constraint__form-modal
  .ant-modal-content
  .ant-modal-body
  .form-modal__container
  .container__header
  .header__title
  i {
  margin-right: 10px;
}

.constraint__form-modal
  .ant-modal-content
  .ant-modal-body
  .form-modal__container
  .container__header
  .header__title
  i
  img {
  top: 0;
}

.constraint__form-modal
  .ant-modal-content
  .ant-modal-body
  .form-modal__container
  .container__header
  .header__title
  h6 {
  margin: 0;
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.constraint__form-modal
  .ant-modal-content
  .ant-modal-body
  .form-modal__container
  .container__header
  .header__close {
  cursor: pointer;
  color: var(--white);
}

.constraint__form-modal
  .ant-modal-content
  .ant-modal-body
  .form-modal__container
  .container__body {
  padding: 20px 20px 22px;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: auto 482px;
  grid-gap: 20px 15px;
  position: relative;
  background-color: var(--black);
}

.constraint__form-modal
  .ant-modal-content
  .ant-modal-body
  .form-modal__container
  .container__body
  .body__list-types {
  grid-row: 1/2;
  grid-column: 1/2;
}

.constraint__form-modal
  .ant-modal-content
  .ant-modal-body
  .form-modal__container
  .container__body
  .body__create-type {
  grid-row: 2/3;
  grid-column: 1/2;
  display: flex;
  justify-content: center;
}

.constraint__form-modal
  .ant-modal-content
  .ant-modal-body
  .form-modal__container
  .container__body
  .body__create-type
  h6 {
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: var(--primary);
  cursor: pointer;
}

.constraint__form-modal
  .ant-modal-content
  .ant-modal-body
  .form-modal__container
  .container__body
  .body__form {
  grid-row: 1/2;
  grid-column: 2/3;
}

.constraint__form-modal
  .ant-modal-content
  .ant-modal-body
  .form-modal__container
  .container__body
  .body__buttons {
  grid-row: 2/3;
  grid-column: 2/3;
  display: flex;
  justify-content: flex-end;
}

.constraint__form-modal
  .ant-modal-content
  .ant-modal-body
  .form-modal__container
  .container__body
  .body__buttons
  button {
  height: 32px;
  border-radius: var(--border-radius-input-modal);
  border: 0;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.constraint__form-modal
  .ant-modal-content
  .ant-modal-body
  .form-modal__container
  .container__body
  .body__buttons
  .buttons__cancel {
  margin-right: 10px;
  background-color: var(--cool-gray);
  color: var(--hr-color);
}

.constraint__form-modal
  .ant-modal-content
  .ant-modal-body
  .form-modal__container
  .container__body
  .body__buttons
  .buttons__create {
  width: 56px;
  background-color: var(--primary);
  color: var(--black);
}

.constraint__form-modal
  .ant-modal-content
  .ant-modal-body
  .form-modal__container
  .container__body
  .body__buttons
  .buttons__create:disabled {
  cursor: not-allowed;
  opacity: var(--disabled-opacity);
}
