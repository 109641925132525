@import '../../assets/styles/variables.scss';

.search-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  cursor: text;
  height: 32px;
  width: 200px;
  padding: 8px 15px;
  transition: border-color $transition-duration-default ease-in;
  border-radius: 5px;
  border: 1px solid transparent;

  input {
    width: 100%;
    font: $regular-s-font;
    border: none;
    background: none;
  }
  i {
    user-select: none;
    cursor: pointer;
  }

  .icon img {
    width: 16px;
    height: 16px;
  }

  &.theme--light {
    background-color: $cool-gray;
    input {
      color: $black;
      &::placeholder {
        color: $dark-gray;
      }
    }
  }

  &.theme--dark {
    background: $dark-green;
    input {
      color: $white;
      &::placeholder {
        color: $white;
      }
    }
  }

  &:hover,
  &:focus-within {
    border: 1px solid $dark-mint;
  }
}
