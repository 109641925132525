@import '../../../assets/styles/variables.scss';

$modal-border-radius: 5px;
$input-padding: 10px;
$gap: 15px;

.modal--view--info {
  .toggle {
    color: $primary !important;
    display: flex;
    font-weight: 400 !important;
    gap: 10px;
    margin-bottom: 20px !important;
    cursor: pointer;
  }

  .form__input--more-details__image {
    display: flex;
    flex-direction: column;

    label {
      color: $white;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      margin-bottom: 10px;
    }
  }

  .form__input_project_type_field,
  .form__input_currency_field,
  .form__input_unit_field,
  .form__input_budget_field,
  .form__input_size_field,
  .form__input_location_field {
    display: flex;
    flex-direction: column;
    min-height: 67px;

    label {
      color: $white;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      margin-bottom: 10px;
    }
  }

  .form__input--more-details
    > .form__input--more-details__inputs
    > .form__input--more-details__inputs__container
    > .form__input_project_type_field,
  .form__input_location_field > .dropdown-design-system__label {
    width: 180px;
  }

  .form__input--more-details
    > .form__input--more-details__inputs
    > .form__input--more-details__inputs__container
    > .input-design-system,
  .form__input_size_field,
  .form__input_budget_field {
    width: 240px;
  }

  .form__input--more-details
    > .form__input--more-details__inputs
    > .form__input--more-details__inputs__container
    > .form__input_currency_field,
  .form__input_unit_field > .dropdown-design-system__label {
    width: 120px;
  }

  .modal--view--info__footer {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: $gap;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 32px;
      border: 0;
      padding: 0 20px;
      border-radius: 5px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: $black;
      transition-duration: 0.3s;

      &.footer__cancel {
        background-color: $cool-gray;

        &:hover {
          background-color: $gray;
        }
      }

      &.footer__save {
        background-color: $primary;

        &:hover:not(:disabled) {
          background-color: $dark-mint;
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }

        .download__spinner {
          display: flex;

          i {
            svg {
              fill: $black;
            }
          }
        }
      }
    }
  }

  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    background-color: $dark-green !important;
    border: none;
    height: 160px;
    overflow: hidden;
    position: relative;
    width: 240px;
    padding: 0px;
    border-radius: 10px;
  }

  .ant-upload,
  .ant-upload-select-picture-card {
    background-color: $dark-green !important;
    border: none;
    height: 160px;
    overflow: hidden;
    position: relative;
    width: 240px;
    max-width: 240px !important;
    min-height: 160px !important;
    max-height: 160px !important;
    border-radius: 10px;
  }

  .form__input--more-details {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 380px;
  }

  .form__input--more-details__inputs__container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  input {
    background-color: $dark-green !important;
    border: 1px solid $dark-green !important;
    color: $white !important;
    border-radius: 5px;
    padding: 0 10px;

    &:focus,
    &:hover {
      border-color: $dark-mint !important;
    }

    &:disabled {
      opacity: $disabled-opacity !important;
      border: $dark-green !important;
    }
  }
}

.rotate {
  img {
    color: $primary !important;
    transform: rotate(180deg);
  }
}

.project-image-uploader {
  background-color: $dark-green !important;
  background-image: url('../../../assets/img/icons/icon--photo.svg');
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 10px;
  height: 160px;
  overflow: hidden;
  position: relative;
  width: 240px;
}

.image-upload-container {
  align-items: center;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.camera-icon-button {
  align-items: center;
  background-color: $primary;
  border: none;
  border-radius: 50%;
  bottom: 10px;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  padding: 10px 9px;
  position: absolute;
  right: 10px;
  width: 36px;
}

.delete-icon-button {
  align-items: center;
  background-color: $primary;
  border: none;
  border-radius: 20px;
  opacity: 0.7;
  background: $black;
  top: 10px;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  padding: 10px 9px;
  position: absolute;
  right: 10px;
  width: 36px;
}
