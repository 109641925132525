.iconEditable {
  position: relative;
  left: -15px;
}

.progress {
  cursor: pointer;
  user-select: none;
  position: relative;
  left: -14px;
}

.input {
  width: 30px;
  height: 15px;
  position: relative;
  left: 8px;
  background-color: transparent;
  font-size: 12px;
  border: none;
}
