@import '../../assets/styles/variables.scss';

.CompanyImage {
  &__img {
    object-fit: contain;
    cursor: pointer;
    background: $white;
  }

  &__default {
    display: flex;
    align-items: center;
    justify-content: center;
    font: $medium-s-font;
    background-color: $gray-1;
    color: $black;
    cursor: pointer;
    user-select: none;
  }

  &__border {
    border: 1px solid $primary;
  }
}
