.container {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0 10px;
  box-sizing: border-box;

  border-top: 1px solid #cecece;
  border-right: 1px solid #cecece;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #c4c4c4;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #121212;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #121212;
  }
}

.responsiveLayout {
  &__header {
    height: 13% !important;
  }

  @media (max-width: 1920px) and (min-width: 1400px) {
    &__header {
      height: 8% !important;
    }
  }
}

.activityTree__header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  margin-bottom: 15px;
  padding: 10px 5px 0 5px;

  border-bottom: 0.5px solid #2c3421;

  &__title {
    display: inline-block;
    margin-bottom: 15px;

    & i {
      margin-left: 2px;
    }
  }

  &__text {
    font-size: 16px;
    font-weight: 500;
    color: #121212;

    margin-left: 12px;
  }

  &--close-btn {
  }
}
