%cell {
  color: #121212;
  border-bottom: 0;
  justify-content: center;
  text-align: center;
  overflow: hidden !important;
  white-space: nowrap;
  padding-left: 7px;
  padding-right: 7px;
}

%cell_data {
  font-family: 'Roboto', arial, sans-serif;
  color: #121212;
  text-align: center;
  border-bottom: 0.2px solid #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gridContainer {
  display: grid !important;
  grid-template: auto 1fr / 50% 50%;
}
.cell {
  @extend %cell;
  &__resource {
    @extend %cell;
  }
  &__unidad {
    @extend %cell;
    background-color: #efefef;
  }
}
.gridContent {
  overflow: hidden !important;
  min-height: 300px;
}
.divGridContainer {
  margin-top: 20px;
  max-height: 200px;
}
.matrix {
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 20px;
  height: 100%;
  &__cell {
    @extend %cell_data;
  }
  &__content {
    overflow: hidden !important;
  }
}
