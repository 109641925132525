.gridContainer {
  height: 100% !important;

  display: grid !important;
  grid-template: auto 1fr / 35% 60% 5%;
}

.gridWorkers {
  border-radius: 5px 0px 0px 5px;
  outline: 0.5px solid #1212123b;

  width: 100%;
  overflow: hidden;
}

.noAssistant {
  height: 70vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.workersTitle {
  width: 340px;
  height: 100%;
  display: flex;
  border-top: 0.5px solid #e3e3e3;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.totalHourHeader {
  display: flex;
  height: 35px;
  justify-content: center;
  align-items: center;

  background-color: #151e21;
  color: #fff;
}

.totalHourHeader span {
  font-size: 12px;
}

.name {
  width: 180px;
}

.rut {
  border-left: 0.5px solid #e3e3e3;
  border-right: 0.5px solid #e3e3e3;
  width: 94px;
}

.hour {
  width: 75px;
}
