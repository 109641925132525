@import '../../assets/styles/variables.scss';

.filter-main-container {
  background: white;
  box-shadow: 0px 5px 6px 1px #00000036;
  border-radius: 8px;
  width: 430px;
  font: $regular-s-font;

  .gantt-filter-left-filter {
  }

  .full-width-style {
    .switch-col-style {
      color: #828282;
      span {
      }

      .ant-switch {
      }

      .ant-dropdown {
        a {
          .anticon-plus {
          }
        }
      }
    }
  }
}

.columns-list {
  font: $regular-s-font;
}
