.constraint-select__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.constraint-select__container .container__roadblock-name {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.constraint-select__container .container__roadblock-name label,
.constraint-select__container
  .container__others-inputs
  .inputs__responsible
  label,
.constraint-select__container
  .container__others-inputs
  .inputs__date-committed
  label,
.constraint-select__container .container__select-tasks label {
  color: var(--white);
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.constraint-select__container .container__roadblock-name label.disabled,
.constraint-select__container
  .container__others-inputs
  .inputs__responsible
  label.disabled,
.constraint-select__container
  .container__others-inputs
  .inputs__date-committed
  label.disabled,
.constraint-select__container
  .container__roadblock-name
  .roadblock-name__input.ant-input-disabled,
.constraint-select__container .container__select-tasks label.disabled {
  opacity: var(--disabled-opacity);
}

.constraint-select__container
  .container__roadblock-name
  .roadblock-name__input {
  height: 54px;
  overflow: hidden;
  resize: none;
  background-color: var(--dark-green);
  border-radius: var(--border-radius-input-modal);
  border: 0;
  color: var(--white);
}

.constraint-select__container .container__others-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.constraint-select__container .container__others-inputs .inputs__responsible,
.constraint-select__container
  .container__others-inputs
  .inputs__date-committed {
  display: flex;
  flex-direction: column;
}
