.frm-iterate .title-frm-add {
  font-size: 18px;
  font-weight: bold;
  color: #2c3421;
  border-bottom: 1px solid #2c3421;
  padding-bottom: 10px;
}

.frm-iterate .title-close {
  color: #d1d1d1;
  float: right;
  cursor: pointer;
  font-size: 16px;
}

.frm-iterate .info-cnc-form .title-cnc {
  color: #2c3421;
}

.frm-iterate .row-title {
  color: #2c3421;
  align-self: center;
}

.frm-iterate .fixOnlyNumber {
  border: 1px solid #2c3421;
  padding: 0 !important;
  width: 40px !important;
  position: relative !important;
  padding-bottom: 0px !important;
  margin: 0 15px 0 5px !important;
  text-align: center;
  border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
}

.frm-iterate .input-every {
  margin: 0 10px !important;
}

.frm-iterate .date-iterate {
  margin-left: 5px;
}

.frm-iterate .select-iterate .ant-select-selection--single,
.frm-iterate .date-iterate .ant-calendar-picker-input {
  border: 1px solid #2c3421;
}

.frm-iterate .row-iterate {
  display: flex;
  margin: 20px 0;
}

.frm-iterate .end-option {
  display: block;
  height: 40px;
  line-height: 30px;
}

.btn-iteration,
.btn-iteration:hover {
  margin-top: 5px !important;
  margin-bottom: 15px !important;
  background-color: var(--primary) !important;
  color: var(--black) !important;
  font-size: 20px !important;
  width: 100px !important;
  border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  border: transparent !important;
  padding: 1px !important;
  cursor: pointer !important;
}

.frm-iterate .option-label {
  display: inline-block;
  width: 60px;
}

.addExcDay {
  padding: 10px;
  text-align: left;
  cursor: pointer;
  font-size: 12px;
}

.dateField .ant-calendar-picker-input {
  font-size: 12px;
}

.dateField {
  width: 100%;
  text-align: center;
  align-items: center;
  margin: auto;
  min-width: 90px;
  max-width: 100px;
}

.swicth-day {
  min-width: 90px;
}

.table-items .input-table-edit {
  font-size: 12px !important;
}

.table-items .ant-table-thead > tr > th,
.table-items .ant-table-tbody > tr > td {
  padding: 6px !important;
}

.table-switch {
  font-size: 12px;
  width: 40px !important;
}

.swicth-day .ant-radio-button-wrapper:first-child {
  border-left: 1px solid #2c3421;
}

.table-items .hourField .ant-select-selection--single {
  font-size: 12px;
  border: 1px solid #2c3421;
}

.table-items thead[class*='ant-table-thead'] th {
  background-color: var(--white);
  color: var(--black);
  font-size: 13px;
}

.table-items .ant-calendar-picker-input {
  border: 1px solid#2C3421;
}

.table-items .ant-table-row:hover > td {
  background: unset !important;
}

.swicth-day
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--white);
  background: #2c3421;
  border-color: #2c3421;
}

.exceptionsActions {
  font-size: 13px;
  margin: 0 3px;
  color: var(--primary) !important;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
  img {
    width: 14px !important;
  }
}

.plusIcon img {
  width: 12px !important;
  margin-bottom: 0.5px;
  margin-right: 4px;
}
