.gm-duration {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 12px !important;
}

.gm-duration-pop .ant-popover-inner-content {
  background: var(--black);
  border-radius: 5px;
  padding: 0;
}

.gm-duration-pop .ant-popover-arrow {
  display: none;
}

.gm-duration-pop .row-fields {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  position: relative;
  top: -4px;
}

.gm-duration-pop .row-fields span {
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  margin-right: 10px;
}

.gm-input-number {
  color: #efefef !important;
  width: 34px !important;
  height: 32px !important;
  border: 1px solid #2d3f45 !important;
  background-color: #2d3f45;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 12px;
  opacity: 0.5;
}

.gm-duration .fields-duration {
  margin-top: -6px;
  padding-top: 10px !important;
}

.wrapper-duration {
  width: 130px;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-top: -8px;
}

.wrapper-duration .progress-massive-btn {
  width: 77px !important;
  height: 33px !important;
}

.wrapper-duration .frm-duration {
  width: 90px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.wrapper-duration .row-buttons button {
  display: flex;
  border: 0;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: var(--black);
  font-weight: 500;
  line-height: 15.23px;
  background-color: var(--primary);
  width: 73px;
  height: 33px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.gm-duration-pop {
  padding-top: 12px;
}

.massive-notification-icon {
  position: relative;
  top: 1px;
}

.massive-notification-icon img {
  width: 36px;
  height: 36px;
  position: relative;
  top: -6px;
  left: -10px;
}

.massive-notification .ant-notification-close-icon {
  width: 16px;
  height: 16px;
  position: relative;
  top: 4px;
}

.massive-notification .ant-notification-notice-description {
  font-size: 15px;
  position: relative;
  top: -19px;
}

.massive-notification .ant-notification-notice-description {
  left: -5px;
}
