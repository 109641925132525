@media (max-width: 994px) and (min-width: 0px) {
  .attach-gantt-btn-style {
    left: -5%;
  }

  .ant-upload-list-item-info > span {
    left: -5%;
  }
}

@media (max-width: 1320px) and (min-width: 995px) {
  .attach-gantt-btn-style {
    left: 5%;
  }

  .ant-upload-list-item-info > span {
    left: 5%;
  }
}

@media (max-width: 1750px) and (min-width: 1321px) {
  .attach-gantt-btn-style {
    left: 15%;
  }

  .ant-upload-list-item-info > span {
    left: 15%;
  }
}

@media (max-width: 2135px) and (min-width: 1751px) {
  .attach-gantt-btn-style {
    left: 50%;
  }

  .ant-upload-list-item-info > span {
    left: 33%;
  }
}

@media (min-width: 2136px) {
  .attach-gantt-btn-style {
    left: 80%;
  }

  .ant-upload-list-item-info > span {
    left: 80%;
  }
}
