@import '../../../assets/styles/variables.scss';

.rename-stage__content {
  display: flex;
  flex-direction: column;

  .content__form {
    display: flex;
    flex-direction: column;

    label {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: $white;
      margin-bottom: 10px;
    }

    input {
      height: 24px;
      border-radius: 5px;
      border: 0;
      padding: 0 10px;
      background-color: $dark-green;
      color: $white;
      font-size: 12px;
      line-height: 14px;
      font-weight: 300;
    }
  }

  .content__buttons {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;

    button {
      height: 32px;
      border: 0;
      border-radius: 5px;
      padding: 0 10px;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      transition: 0.3s;

      &.buttons__cancel {
        background-color: $cool-gray;
        color: $black;
      }

      &.buttons__save {
        background-color: $primary;
        color: $black;
        margin-left: 8px;

        &:disabled {
          opacity: $disabled-opacity;
          cursor: no-drop;
        }
      }
    }
  }
}
