.frm-process .title-frm {
  font-size: 15px;
  color: #2c3421;
  font-weight: bold;
  line-height: 18px;
  text-align: left;
  margin-top: 10px;
  border-bottom: 0.3px solid;
  padding-bottom: 15px;
}

.frm-process .hand-close {
  float: right;
  color: #c4c4c4;
  margin-right: 3px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.frm-process .tab-left {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  padding: 20px 0;
  background: #121212;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  flex-direction: column;
}

.frm-process .ant-modal-body {
  padding: 0 !important;
}

.frm-process .list-left {
  width: 100%;
  text-align: left;
  padding: 15px 20px;
  display: flex;
  align-items: center;
}

.frm-process .reviewed {
  color: #34af00;
}

.frm-process .current {
  color: var(--primary);
}

.frm-process .option-text {
  display: inline;
}

.frm-process .option-text {
  display: inline;
}

.frm-process .option-subtitle {
  font-size: 10px;
}

.frm-process .ant-modal-content {
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
}

.frm-process .img-upload {
  width: 17px;
  margin-right: 6px;
}

.frm-process .tab-right {
  padding: 10px;
}

.frm-process .tab-right .title {
  display: inline;
}

.frm-process .process-title {
  border-bottom: 1px solid #2c3421;
  padding-bottom: 6px;
}
.frm-process .title-info {
  color: #2c3421;
}

.frm-process .title-info {
  padding: 12px 0;
}

.frm-process .list-items {
  height: 100%;
  max-height: 243px;
  min-height: 243px;
  overflow: auto;
}

.frm-process .calendars {
  max-height: 244px;
  min-height: 244px;
}

.frm-process .calendars-with-msg {
  max-height: 186px;
  min-height: 186px;
}

.frm-process .select-resource {
  width: 120px;
  border: none;
  background: white;
  color: #2c3421;
}

.frm-process .list-items .item-info {
  display: flex;
  justify-content: space-between;
  border: 1px solid #2c3421;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  padding: 5px;
  font-size: 12px;
  margin: 9px 14px 0px 0px;
}

.frm-process .list-items .item-info div {
  display: inline;
  padding: 4px;
}

.frm-process .buttons {
  height: 70px;
  text-align: center;
}

.frm-process .btn-continue,
.frm-process .btn-continue:hover,
.frm-process .btn-continue:active,
.frm-process .btn-continue:focus {
  background: var(--primary);
  color: var(--black);
  border: 1px solid var(--primary);
  margin: 0 4px;
  font-size: 13px;
  max-height: 25px;
}
.frm-process .btn-previous,
.frm-process .btn-previous:hover,
.frm-process .btn-previous:active,
.frm-process .btn-previous:focus {
  background: var(--black);
  color: var(--primary);
  border: 1px solid var(--primary);
  margin: 0 4px;
  font-size: 13px;
  max-height: 25px;
}

.frm-process .buttons {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  flex-direction: row;
}

.frm-process .back-white {
  background: white;
  width: 25px;
  border-radius: 164px;
  height: 20px;
  padding: 3px;
  margin-right: 13px;
}

.frm-process .option-icon {
  font-size: 28px;
  margin-right: 10px;
}

.frm-process .back-white .option-icon {
  position: relative;
  top: -7px;
  left: -3px;
}

.frm-process .item-number {
  min-width: 55px;
}

.frm-process .item-name {
  height: 25px;
}

.frm-process .item-name-overflow {
  height: 25px;
  text-overflow: ellipsis;
  width: 136px;
  word-break: break-all;
  overflow: hidden;
}

.frm-process .skip-link {
  color: #2c3421 !important;
  font-size: 12px;
  padding: 4px;
  cursor: pointer;
}

.frm-process .hidden {
  display: none;
}

.frm-process .imp-loading {
  padding: 39% 37% !important;
  text-align: center;
}

.frm-process .alert-msg {
  background: #face81;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px;
  gap: 10px;
  height: 58px;
  margin: 10px 24px 14px 0;
}

.frm-process .alert-msg .icon-alert img {
  height: 30px;
}

.frm-process .alert-msg .alert-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #121212;
}
