/* .fixed-lookahead-planification-filter-header{
    position: fixed !important;
    background-color: white;
    z-index: 10;
    top: 122px !important;
} */

.resources-filter-header {
  height: 60px !important;
  padding: 25px !important;
  background-color: #fff;
}

.resources-view-planification {
  width: 110% !important;
}

.resources-view-planification .ant-select-selection--single {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 12px !important;
  border: none;
  border-bottom: 0px solid #00a8b9 !important;
  border-radius: 0px !important;
  height: 32px !important;
  position: relative;
  top: -4px;
}

.resources-view-planification .ant-select-arrow,
.viewSelect .ant-select-selection__clear {
  color: #00a8b9 !important;
}

.resources-view-planification .ant-select-arrow-icon {
  left: 1px;
  color: #00a8b9 !important;
}

.resources-view-dropdown-planification {
  width: 12.5% !important;
}

.resources-view-dropdown-planification .ant-select-dropdown-menu li {
  padding-left: 11px;
}

.resources-view-dropdown-planification .ant-select-dropdown-menu-item svg {
  color: #f18640 !important;
  margin-right: 5px;
}

.resources-search-on-page-bar .ant-input:hover {
  border-color: #40a9ff00;
  border-radius: 1px !important;
  border-bottom: 1px solid #40a9ff !important;
  border-right-width: 0px !important;
}

.resources-search-on-page-bar .ant-input:focus {
  border-color: #40a9ff00;
  border-right-width: 0px !important;
  outline: 0;
  box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
}

.resources-search-on-page-bar .ant-input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 138px;
  height: 15px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 0px solid #d9d9d9 !important;
  border-bottom: 0.5px solid #25a8ba !important;
  border-radius: 1px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.resources-search-on-page-bar {
  float: right !important;
  top: -4px !important;
  position: relative !important;
  width: 138px !important;
}

.counter-search-on-page {
  font-size: 10px;
  left: -9px;
  position: relative;
  top: 1px;
  color: #a2a2a2;
}

.resources-search-on-page-bar .ant-input-search-icon {
  width: 12px;
  height: 12px;
  margin-bottom: 5px;
  color: #25a8ba;
}

.resource-filter-header {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.resource-view-grid-icon {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.resources-view-planification .ant-select-arrow .ant-select-arrow-icon svg {
  color: var(--primary);
  color: var(--primary) !important;
  width: 8px;
  height: 8px;
  margin-right: 70px !important;
}
