@import '../../../../../assets/styles/variables.scss';

.tab--personalization {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  gap: 10px;

  .tab--personalization__title {
    display: flex;

    h5 {
      font: $medium-s-font;
      line-height: 14px;
      color: $white;
      margin: 0;
    }
  }

  .tab--personalization__select label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font: $regular-s-font;
    line-height: 14px;
    color: $white;

    .select__picker {
      width: 150px;
    }
  }

  .tab--personalization__todayline {
    display: flex;
    margin-bottom: 10px;
  }

  .tab--personalization__visualization {
    & > :not(:last-child) {
      margin-block-end: 8px;
    }
  }
}
