.container {
  position: relative;
}

.list-container {
  max-height: 200px;
  overflow-y: auto;

  list-style: none;
  background-color: #ffffff;
  padding: 0;

  box-shadow: rgb(51 51 51 / 30%) 0 2px 8px 0;

  position: absolute;
  top: 10px;
  right: 0;
  left: 0;

  z-index: 1;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #c4c4c4;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #121212;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #121212;
  }
}

.suggestion {
  line-height: 32px;
  padding: 0 20px;
  cursor: pointer;

  &:hover {
    background-color: #efefef;
  }
}

.active-suggestion {
  background-color: #2c3421;
  color: #ffffff;
}
