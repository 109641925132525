.itemHeader,
.itemFooter {
  padding: 8px 20px 20px 20px;
}
.itemHeader div,
.itemFooter div {
  display: inline;
}

.itemHeader .titleCalendar {
  float: left;
  font-size: 18px;
  color: #2c3421;
}

.itemHeader .actions {
  float: right;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.itemHeader .actions div:first-child {
  margin-right: 10px;
}

.itemSubTitle {
  width: 100%;
  float: left;
  text-align: left;
  padding: 0px 20px;
}

.moreActions {
  -webkit-transform: rotate(90deg) !important;
  -moz-transform: rotate(90deg) !important;
  -o-transform: rotate(90deg) !important;
  -ms-transform: rotate(90deg) !important;
  transform: rotate(90deg) !important;
  font-size: 28px;
  padding: 0px;
}

.itemFooter .created {
  float: left;
  color: #121212;
}

.itemFooter .count {
  float: right;
}

.itemFooter,
.itemSubTitle {
  font-size: 12px;
  color: var(--black) !important;
}

.itemFooter {
  padding-top: 46px;
}

.itemFooter .count {
  color: var(--dark-mint) !important;
}

.itemFooter .count div:first-child {
  margin-right: 5px;
}

.itemFooter .counterIcon {
  font-size: 14px;
}
