.indicator_label {
  display: flex;
  justify-content: space-between;
  flex-flow: column nowrap;

  height: 100%;
  margin-right: 14.69px;
  font-size: 12px;
  font-weight: 300;
}

.task_indicator {
  display: inline-block;
  height: 14px;
}

.task_indicator.task_info {
  font-weight: 500;
}

.task_info :global img {
  filter: none !important;
}

.indicator-container-btn {
  position: relative;
}

.box-indicators {
  width: 595px;
  height: 46px;
  padding: 7px 15px 7px 7px;

  display: grid;
  grid-template-columns: 0.5fr 2fr 0.1fr;
  align-items: center;
  grid-column-gap: 10px;
  padding: 7px 15px;

  background-color: #fff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  position: absolute;
  right: 15px;
}

.box-indicators:lang(es) {
  width: calc(595px + 30px);
}

.box-indicators--hide {
  visibility: hidden;
}

.indicators-status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.status-item {
  font-size: 10px;
  font-weight: 400;
  color: #121212;

  display: flex;
}

.status-item > span {
  margin-right: 8px;
}

.status-item > span:nth-child(3):lang(es) {
  width: 70px;
}

.indicator-status-value {
  font-size: 16px;
  font-weight: 700;
  width: 46px;
}

.status-separator {
  height: 22px;
  border: 0.5px solid #c4c4c4;
  margin: 0 10px 0 0;
}

.lean {
  display: flex;
}

.indicator-action-btn {
  border: none;
  border-radius: 0;
  font-size: 12px;
  font-weight: 400;

  background-color: #e9e9e9;
  color: #121212;
  width: 84px;
  height: 22px;
}

.indicator-units {
  border-radius: 5px 0px 0px 5px;
  margin-right: 2px;
}

.indicator-percentage {
  border-radius: 0px 5px 5px 0px;
}

.indicator-action-active {
  color: #fff;
  background-color: #2c3421;
}
