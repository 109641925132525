.cards-setting-user .cardProject {
  margin-right: 12px;
}

.cards-setting-user {
  margin-left: 127px;
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
}
.card-user {
  height: auto;
  width: auto;
  -webkit-box-shadow: 1px 3px 5px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 3px 5px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 3px 5px -2px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
}
.img-user-card {
  height: 90px;
  width: 100%;
}
.input-title-card {
  font-size: 12px;
  width: 100%;
  border: 0;
}

.locate-card {
  font-size: 10px;
  margin-top: 3px;
}
.input-locate-card {
  font-size: 10px;
  width: 100%;
  border: 0;
}

.porcentaje {
  font-size: 10px;
  display: inline-block;
}
.grid-calendar {
  display: grid;
  grid-template-columns: 30% 40% 30%;
  margin-left: 5px;
}

.margin-card-user {
  margin-left: 5px;
}

.grid-card-footer {
  display: grid;
  grid-template-columns: 52% 60%;
  margin-left: 5px;
}

.txt-footer {
  font-size: 8.3px;
  display: inline-block;
}

@media (min-width: 1920px) {
  .txt-footer {
    font-size: 13px;
    display: inline-block;
  }
  .grid-card-footer {
    display: grid;
    grid-template-columns: 52% 60%;
    margin-left: 7px;
  }
  .porcentaje {
    font-size: 13px;
    display: inline-block;
  }
}
