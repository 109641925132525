.content_place_holder_text {
  margin-top: 100px;
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
}

.text_click {
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary);
  padding: 0 !important;
  white-space: nowrap;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  float: left;
}

.item_list {
  padding: 0 !important;
  margin-left: 10px;
  margin-right: 10px;
  height: 25px !important;
}

.item_list .item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
}

.title_velocity_chart {
  margin-left: 15px;
  margin: 15px;
  font-size: 20px;
}

.count_activitys {
  margin: 15px !important;
}

.text_cut {
  white-space: nowrap;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  float: left;
}

.text_cut:hover {
  white-space: nowrap;
  width: 250px;
  color: var(--primary);
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  float: left;
}

.img_option {
  margin-top: 15px;
  width: 15px;
  height: 15px;
}

.filter_curve {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.dot {
  height: 15px;
  width: 15px;
  //background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-top: 3px;
  margin-left: 10px;
}

.img_icon_curve_takt {
  width: 15px;
  height: 10px;
  margin-left: 5px;
}
