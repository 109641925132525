.create-sub-btn {
  left: -27px;
  position: relative;
  width: 120px;
  font-size: 12px;
  text-transform: none;
}

.custom-sub-dropdown .item-gantt {
  height: 29px;
  width: 146px;
}

.custom-sub-dropdown span {
  display: block !important;
}

.remove-sub-x {
  position: relative;
  left: -5px;
}

.remove-sub-text {
  color: #121212;
  font-size: 12px;
  position: relative;
  text-transform: none !important;
}

.remove-option-sub-custom {
  display: inline-flex !important;
  position: relative;
  left: 22px;
  top: 4px;
  cursor: pointer;
}

.item-custom-selected-sub {
  display: inline !important;
}

.custom-sub-dropdown .subcontract-border {
  width: 100% !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
