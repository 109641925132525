@import '../../../../assets/styles/variables.scss';

.sector_title {
  font: $medium-s-font !important;
}

.days {
  font: $regular-s-font !important;
}

.title {
  font: $regular-s-font !important;
}
