.text-btn-gantt-view-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-btn-gantt-view-main {
  display: flex;
  align-items: center;
  justify-content: center;
}
