.gm-responsible {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 6px;
  margin-left: 7px;
  margin-right: 14px !important;
}

.massive-responsible-class .select-search--multiple .select-search__select {
  height: 190px !important;
}

.massive-responsible-class .select-search {
  height: 210px !important;
  background-color: #25353a !important;
}

.massive-responsible-class .remove-sub-text,
.massive-responsible-class .remove-sub-x {
  color: var(--white);
}
.massive-responsible-class .select-search__input {
  position: relative;
  top: 1px;
  border-bottom: 1px solid #3a4f56;
  color: var(--white);
}

.massive-responsible-class .select-search__input,
.massive-responsible-class .select-search__select {
  background-color: var(--black);
}

.massive-responsible-class .select-search__value::after {
  background-image: url('../../assets/img/gantt/search-new-orange.png') !important;
  background-repeat: no-repeat;
  background-size: 12.6px;
  background-position-x: 100%;
  background-position-y: 2px;
  width: 14px;
  height: 20px;
}

.massive-responsible-class .create-sub-btn {
  color: var(--primary);
  position: relative;
  top: -2px;
}

.massive-responsible-class .select-search--multiple {
  background-color: #3a4f56;
  /* height: 130px!important; */
}

.massive-responsible-class .remove-sub-text,
.massive-responsible-class .remove-sub-x {
  color: var(--white);
}

.massive-responsible-class .remove-option-sub-custom {
  left: 37px;
}

.massive-responsible-class .select-search__row {
  width: 100%;
  background-color: #25353a;
}

.massive-responsible-class .select-search--multiple .select-search__option {
  background-color: var(--black);
  color: var(--white);
}

.massive-responsible-class
  .select-search--multiple
  .select-search__option:hover {
  background-color: #25353a !important;
}

.massive-responsible-class .ant-popover-content {
  position: relative;
  top: -8px;
}

.massive-responsible-class .avatar-dropdown-massive {
  color: var(--white);
  background-color: #595959 !important;
  font-size: 12px !important;
  position: relative;
  left: 3px;
  width: 21px !important;
}

.massive-responsible-class .cut-text-and-dot {
  text-align: left;
  position: relative;
  left: 20px;
}

.massive-responsible-class .img-massive-responsable {
  margin-right: 1px !important;
}

.massive-responsible-class .select-search__row {
  margin-left: 5px;
}

.apply-all-button button {
  border: 0;
  font-size: 13px;
  left: -8px;
  color: var(--black);
  font-weight: 500;
  line-height: 15.23px;
  background-color: var(--primary);
  width: 123px;
  height: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.massive-responsible-class .select-search__value::after {
  top: 8px;
}
