#root > div {
  overflow-x: hidden;
  overflow-y: hidden !important;
}

.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right {
  width: 205px !important;
  background-color: #25353a !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #3e482d !important;
}

.ant-menu-submenu-selected {
  color: #e6e6e6 !important;
}

.ant-menu-inline .ant-menu-item:after {
  border-right: none !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: #151e21 !important;
}

.ant-menu .anticon {
  position: relative;
  top: -2px;
}

.ant-menu-item-group,
.ant-menu-sub,
.ant-menu {
  background-color: #151e21 !important;
  color: #e6e6e6 !important;
}

.liItem {
  margin-top: 7x !important;
}

.ant-menu-submenu-title {
  padding-left: 32px !important;
}

.ant-menu-submenu-inline:hover,
.ant-menu-submenu-open:hover,
.ant-menu-submenu-title:hover,
.titleItem:hover {
  color: #e6e6e6 !important;
}

.ant-menu .ant-menu-item-selected {
  border-left: 1px solid white !important;
}

.ant-layout-sider,
.ant-layout-sider-dark {
  background-color: #151e21 !important;
}

.ant-menu-submenu-arrow {
  display: none;
}

/* .ant-menu li.ant-menu-item{
    color:#E6E6E6!important;
    border-left: 1px solid #3FB7C5;
    margin-left: 54px!important;
    padding-left: 10px!important;
    max-width: 152px!important;
    height: 30px!important;
    line-height: 31px!important;
    padding-bottom: 0!important;
    margin-bottom: 0!important;
    margin-top: 0!important;
} */

.ant-menu-inline li:first-child {
  margin-top: 7px !important;
}

.ant-menu {
  width: 100% !important;
}

/* .ant-menu-submenu-open{
    background-color: #575757;
} */

.siderCollapsed {
  max-width: 70px !important;
  min-width: 70px !important;
  width: 70px !important;
}
.siderCollapsed ul.ant-menu {
  width: 52px !important;
}
.siderCollapsed ul.ant-menu {
  overflow: hidden;
  text-overflow: inherit;
}
.ant-layout-sider-trigger {
  width: 70px;
  background-color: #121212 !important;
}
.ant-layout-sider-trigger:before {
  content: '>';
}

.trigger-open {
  width: 206px !important;
}

.trigger-open:before {
  content: '<';
}

/* #layoutWide .ant-layout-sider-children{
    background-color: #25A8BA;
} */

.ant-layout-content {
  width: 100%;
}

html,
body,
#root,
#root > div,
#layoutWide {
  height: 100%;
  margin: 0;
}

#layoutWide .ant-layout-sider,
#layoutWide .ant-layout-sider aside {
  min-width: 40% !important;
}

.ant-layout > main > div {
  height: 100%;
}

.divSection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  max-width: 700px;
  margin: 0 auto;
  padding: 0 20px 20px;
  text-align: center;
  margin-bottom: 10px;
}

.divSection h3 {
  color: #121212;
}

.formMiddle {
  max-width: 850px !important;
}

.divSection .ant-input-number {
  box-sizing: border-box;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border: 1px solid white;
  margin: auto;
  margin-bottom: 8px;
  font-size: 18px;
  border-bottom: 1px solid #77c8d4;
  width: 64%;
  top: 9px;
  height: 41px;
}

.divSection .ant-input-number:hover,
.divSection .ant-input-number:focus {
  border-color: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.divSection input[type='email'],
.divSection input[type='text'],
.divSection input[type='password'],
.divSection input[type='number'] {
  display: block;
  box-sizing: border-box;
  width: 70%;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border: 1px solid white;
  padding: 10px 0;
  margin: auto;
  margin-bottom: 2px;
  font-size: 18px;
  top: 1px !important;
  border-bottom: 1px solid #77c8d4;
}
.divSection input[type='email'] {
  top: 2px !important;
}

.divSection .centerTxt {
  text-align: center;
}

.divSection input.txtSubform {
  width: 80%;
}

.headerMain {
  font-weight: bold;
  color: #121212;
  font-size: xx-large;
  margin-bottom: 40px;
  text-align: center;
}

.divSection .ant-ckeckbox {
  color: #969696;
  display: inline;
}

.divSection .labelFrm {
  margin-top: 20px;
  color: #969696;
  display: block;
}
.divSection .ant-input-number-input {
  padding: 0;
}

.divSection .ant-ckeckbox span.ant-checkbox {
  border: 1px solid #5bbecb;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.divSection .ant-ckeckbox span a,
.divSection span a {
  color: #40b3c3;
}

.divSection .btnSubmit,
.divSection .ant-btn {
  height: auto;
  margin-top: 30px;
  background-color: var(--primary);
  color: var(--black);
  font-size: 20px;
  width: 30%;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border: transparent;
  padding: 12px;
  cursor: pointer;
}

.divSection .txtResetForm {
  width: 25% !important;
}

.divSection .ant-btn:hover,
.divSection .ant-btn:focus,
.divSection .ant-btn:active,
.divSection .ant-btn.active {
  height: auto !important;
  margin-top: 30px !important;
  background-color: var(--primary);
  color: var(--black);
  font-size: 20px;
  width: 30%;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border: transparent;
  padding: 12px;
  cursor: pointer;
}

.divSection .btnSubmit:hover,
.divSection .ant-btn:hover {
  background-color: #25a8ba;
  color: var(--white);
}

.imgDegrade {
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  width: 85px;
  height: 85px;
  margin: 0 auto 30px;
  background-image: url('../assets/img/logo.png');
  background-repeat: no-repeat;
  background-size: 85px 85px;
}

.frmWide {
  width: 100%;
  text-align: center;
}

.divSection .lblerror {
  color: red;
  margin: 0;
  display: block;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 12px;
}

.divSection .ta-left {
  text-align: left !important;
  margin-left: 2px !important;
}

.divSection .ta-left-widecenter {
  width: 70%;
  text-align: left;
  padding: 0 101px;
}

.divSection .widecenter {
  width: 100% !important;
  text-align: left !important;
  padding-left: 100px;
}

.divSection .selectinput {
  position: relative;
  top: -16px;
}

.divSection .lblAgree {
  color: #1eb021;
  display: none;
  margin: 0;
  font-weight: bold;
  font-size: 12px;
}

.divSection a.linkFrm {
  display: block;
  color: #25a8ba;
}

.divErrorDivSection {
  width: 100%;
}

.refLink,
.refLink:hover {
  color: #40b3c3;
}

/* wide */

.divSection .FrmAvatar {
  cursor: pointer;
  background-color: #4cb7c6 !important;
}
.divSection .FrmAvatarUser {
  left: 50px !important;
}

.titleWide {
  font-size: 40px;
  color: #aeaeae;
  border-bottom: 1px solid #25a8ba;
  font-weight: normal;
  background-color: white;
  height: 70px;
}

.titleWideProject {
  font-size: 40px;
  color: #121212;
  padding-bottom: 25px;
  margin: 25px 4%;
  font-weight: normal;
  background: white !important;
  z-index: 9999;
}

.w50 {
  width: 50% !important;
}
.w100 {
  width: 100% !important;
}
.inline {
  display: inline !important;
}

.w35 {
  width: 35% !important;
}

.w30 {
  width: 30% !important;
}

.w28 {
  width: 28% !important;
}

.fixCur {
  left: -1px;
}

.fixCurrency {
  left: -1px;
  top: -8px;
}

.fixSelectCur {
  top: -8px;
}

.fixNumber {
  top: 10px !important;
}

#frmProjectConfirmation .oneLineTxt {
  display: inline !important;
  margin-left: 18px !important;
  width: 57% !important;
}

.select-frm {
  width: 100%;
  height: 48px;
  border: 1px solid white;
  background-color: white;
  border-bottom: 1px solid #77c8d4;
  font-size: 18px;
  padding-left: 10px;
}

.divSection .ant-select-selection--single {
  top: 0px !important;
  width: 100% !important;
  height: 51px !important;
  border: 1px solid white !important;
  border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  background-color: white !important;
  border-bottom: 1px solid #77c8d4 !important;
  font-size: 18px !important;
  padding-left: 2px !important;
  color: #121212 !important;
}

.divSection .ant-select-selection {
  border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
}

.divSection .ant-select-arrow {
  color: #121212 !important;
  top: 25px;
}
.divSection .ant-select-selection__rendered {
  top: 10px;
  margin-left: -2px !important;
}
.divSection .ant-select-selection-selected-value {
  color: rgba(0, 0, 0, 0.65) !important;
}

.ta-left {
  text-align: left !important;
}

.lblerrorfrm {
  color: red !important;
  margin: 0 !important;
  text-align: center !important;
  display: block !important;
}

.addProject {
  width: 119px;
  height: 100px;
  display: block;
  padding-left: 0;
  margin-left: 68px;
  margin-top: 100px;
  float: left;
}

.ant-layout {
  background-color: white !important;
  height: 100%;
  overflow: hidden;
}

@keyframes autofill {
  to {
    background: transparent;
  }
}

@-webkit-keyframes autofill {
  to {
    background: transparent;
  }
}

input:-webkit-autofill {
  animation-name: autofill;
  -webkit-animation-name: autofill;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}

.divSection .mt-10 {
  margin-top: 10px !important;
}

.divSection .mt-15 {
  margin-top: 15px !important;
}

.divSection .mt-20 {
  margin-top: 20px !important;
}

.divSection .mt-30 {
  margin-top: 30px !important;
}

.divSection .mt-50 {
  margin-top: 50px !important;
}

.divSection .ml-20 {
  margin-left: 20px !important;
}

.divSection .ml-12 {
  margin-left: 12px !important;
}

.divSection .skipLink {
  margin-left: 24px;
  text-decoration: underline;
  font-size: 18px;
}

.divSection .p10 {
  padding: 10px !important;
}

.divSection .ant-input-number-handler-wrap {
  border: 1px solid rgba(0, 0, 0, 0.45) !important;
  border-radius: 0 !important;
  top: -1px !important;
}

.divSection .ant-input-number-focused:hover,
.divSection .ant-input-number:hover {
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  border-bottom: 1px solid #77c8d4 !important;
}

.divSection .ant-input-number-focused {
  border-color: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.divSection .ant-select-focused .ant-select-selection,
.divSection .ant-select-selection:focus,
.divSection .ant-select-selection:active,
.divSection .ant-select-open .ant-select-selection,
.divSection .ant-input:focus {
  border-color: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

input:focus,
select:focus {
  outline: none;
}

input:-webkit-autofill::first-line {
  font-size: 18px;
}

.divSection .ant-input {
  min-height: 50px;
}

.divSection ::placeholder {
  color: #6c6c6c !important;
  opacity: 1; /* Firefox */
}

.divSection :-ms-input-placeholder {
  color: #6c6c6c !important;
}

.divSection ::-ms-input-placeholder {
  color: #6c6c6c !important;
}

.divSection .FrmCompany {
  cursor: pointer;
}

.divSection .FrmLogoStages {
  position: relative;
  right: 85px;
}

.divSection .FrmCamera {
  color: #f18640;
  top: 65px;
  right: 27px;
  background-color: transparent !important;
}

.divSection .FrmPlus {
  color: #f18640;
  top: 52px;
  right: -6px;
  background-color: transparent !important;
}

.ant-select-selection__placeholder {
  color: #6c6c6c !important;
}

.backArrowWide {
  width: 85%;
  padding: 2px 0;
  margin: auto 4%;
  margin-bottom: 20px !important;
  z-index: -9999;
}

.backArrowWide .label-btn-navigator {
  cursor: pointer;
}

.positionSelect {
  width: 600px !important;
}

.currencySelect {
  width: 270px !important;
}

.listElement div.element {
  border: 1px solid #aeaeae;
  width: 79%;
  margin: 6px 0;
  padding: 6px 0;
  border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  display: inline-block;
  cursor: move;
  margin-left: 11px;
}

.addElement {
  top: 10px;
  margin-top: 18px;
  font-size: 25px;
  color: #4cb7c6 !important;
  cursor: pointer;
  text-align: left !important;
  padding-left: 20px;
}

.addElement2 {
  top: 10px;
  margin-top: 18px;
  font-size: 25px;
  color: red !important;
  cursor: pointer;
  text-align: left !important;
  padding-left: 20px;
}

.noElements {
  margin-top: 20px;
}

.deleteElement {
  z-index: 99999;
  font-size: 22px;
  cursor: pointer;
  margin: 12px;
  position: relative;
  top: -17px;
  width: 4%;
  left: -46px;
}

.deleteElement-pointer {
  cursor: pointer;
}

.deleteElement-not-allowed {
  cursor: not-allowed !important;
}

.titleFrmElements {
  font-size: 30px;
  float: left;
  margin-top: 50px;
  padding-left: 34px;
}

.divSection .FrmAvatarSectors {
  position: relative;
  left: -90px !important;
}

.divSection .lblErrorElement {
  color: red !important;
  font-size: 12px !important;
  position: relative;
  top: -7px;
  width: 100% !important;
  display: block;
  padding-left: 180px;
  text-align: left;
}

#frmStages .lblErrorElement {
  position: relative;
  left: -36px;
  top: -1px !important;
}

.divSection .lblInfoElement {
  font-weight: bold;
  float: left;
  font-size: 12px !important;
  position: relative;
  left: 2px;
  text-align: left;
}

.moreOptions {
  position: absolute;
  top: 3px;
  right: -3px;
  top: 12px;
  color: #121212 !important;
  font-size: 17px !important;
}

.notVisible {
  visibility: hidden;
}

.actionsMenu .menuitem {
  margin-right: 0 !important;
  position: relative;
  top: -1px;
  font-size: 18px;
  padding: 7px;
}

.actionsMenu .menuitem {
  font-size: 12px;
}

.menutitle {
  width: 100%;
  text-align: center;
  color: #121212;
  font-weight: bold !important;
}

.ant-dropdown-menu-item {
  padding-top: 1px !important;
  font-size: 13px;
  font-weight: 400;
  font-family:
    Roboto,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
}

.filterToolbar .ant-select-selection__rendered {
  color: #b7b7b7;
  font-size: 12px;
  top: -2px;
}

.divLabel {
  font-size: 22px;
  padding: 12px;
}

.ant-layout-footer {
  background-color: white !important;
  padding: 24px 0 !important;
}

.numberFix {
  top: 10px !important;
}

#frmStages .listElement div.element {
  left: -4px !important;
  position: relative;
}

#frmStages .deleteElement {
  position: relative;
  left: -32px;
}

#frmStages .addElement {
  position: relative;
}

/* modal delete/archive project */
.ant-modal-footer {
  border-top: none !important;
}

.footerDelete {
  width: 100%;
  text-align: center;
}

.wrapFormDelete {
  width: 63%;
  text-align: center;
  color: #858585;
  margin: auto;
  margin-top: 20px;
}

.titleFormDelete {
  font-size: 25px;
}

.infoFormDelete {
  font-size: 50px;
  color: red !important;
  margin-top: 18px;
}

.wrapFormDelete p {
  margin: 20px;
  font-size: 20px;
  line-height: 24px;
}

.footerDelete button {
  border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  height: 45px !important;
  width: 170px !important;
  margin: 28px !important;
}

.btnArchiveFormDelete {
  background: #00a8b9 !important;
  border: 1px solid #00a8b9 !important;
}

.btnDeleteFormDelete {
  background: #eb0814 !important;
  border: 1px solid #eb0814 !important;
}

.dialogArchive .selectReason {
  width: 350px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.dialogArchive .ant-select-selection--single {
  top: 0px !important;
  width: 100% !important;
  height: 51px !important;
  border: 1px solid white !important;
  border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  background-color: white !important;
  border-bottom: 1px solid #77c8d4 !important;
  font-size: 18px !important;
  padding-left: 2px !important;
  color: #121212 !important;
}

.dialogArchive .ant-select-selection {
  border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
}

.dialogArchive .ant-select-arrow {
  color: #121212 !important;
  top: 25px;
}
.dialogArchive .ant-select-selection__rendered {
  top: 10px;
  margin-left: -2px !important;
}
.dialogArchive .ant-select-selection-selected-value {
  color: rgba(0, 0, 0, 0.65) !important;
}

.dialogArchive .ant-select-focused .ant-select-selection,
.dialogArchive .ant-select-selection:focus,
.dialogArchive .ant-select-selection:active,
.dialogArchive .ant-select-open .ant-select-selection,
.dialogArchive .ant-input:focus {
  border-color: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.dialogArchive .lblErrorElement {
  color: red !important;
  font-size: 12px !important;
  position: relative;
  top: -28px;
  width: 100% !important;
  display: block;
  padding-left: 180px;
  text-align: left;
  right: 52px;
}

.ta-lblErrorFixNumber {
  position: relative !important;
  top: 2px !important;
}

.divSection .fixLblErrorSplitCol {
  margin-left: 108px !important;
}

.animatedWide {
  display: flex !important;
  align-items: center !important;
  height: 100% !important;
}

.divWide {
  width: 100%;
}

.ant-layout-header {
  height: 70px !important;
  background: none !important;
  margin: 10px;
  z-index: 999;
}

.table-users-project .ant-table-pagination.ant-pagination,
.user-settings-company .ant-table-pagination.ant-pagination {
  margin: 5px 0 55px 0;
}

.table-users-project .ant-select-selection-selected-value,
.user-settings-company .ant-select-selection-selected-value {
  overflow: initial;
}

#spanActive {
  color: #1d9de6 !important;
  font-weight: bold !important;
}

.ant-notification,
.ant-notification-topRight {
  z-index: 99999 !important;
}

@media all and (max-width: 600px) {
  .ant-layout-header {
    display: none;
  }
}

#layoutWide .ant-layout {
  overflow: hidden;
}

.ant-upload {
  height: 170px !important;
}

.imgLogoProject {
  border-radius: 10px;
  width: 100%;
}

/** new design */
.statisticCompany {
  display: flex;
  min-width: 440px;
}
.statisticCompany div {
  display: inline;
  margin: 0 16px 0 0;
  font-size: 16px;
}

.companyInfo {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  width: 50%;
  display: inline-block;
  position: relative;
  top: 16px;
}

.titleCompany {
  color: #121212;
  font-weight: bold;
  font-size: 24px;
}

.configAccount {
  text-align: right;
}

.logoCompany {
  text-align: center;
  margin: 0 21px 21px 34px;
  display: inline;
}

.statisticIcon {
  color: var(--primary) !important;
  margin-right: 8px;
}

.statisticCompany span {
  color: var(--dark-mint) !important;
}

.statisticCompany .sumCurrency {
  color: rgba(0, 0, 0, 0.65) !important;
}

.iconCompany {
  position: relative;
  top: 1px;
  margin-right: 8px;
}

.budget {
  position: relative;
  top: -7px;
  left: -8px;
}

.budget svg {
  position: relative;
  top: 6px;
}

.BtnTypeViewActive {
  background: #f18640 !important;
  border: 1px solid #f18640 !important;
  margin-left: 6px !important;
}

.BtnTypeView {
  background: white !important;
  border: 1px solid white !important;
  margin-left: 6px !important;
  color: #00a8b9 !important;
}

.ant-badge-dot {
  width: 10px !important;
  height: 10px !important;
}

.ant-badge {
  position: relative;
  top: 2px;
  margin-right: 10px !important;
}

.account {
  position: relative;
  right: 12px;
  float: right;
}

/** date picker */
.toolbar-container-secondary .ant-calendar-picker {
  width: 150px !important;
  top: 2px !important;
  float: left;
}

.toolbar-container-secondary .ant-calendar-picker ::placeholder {
  color: rgba(0, 0, 0, 0.65) !important;
  opacity: 1; /* Firefox */
}

.toolbar-container-secondary .ant-calendar-picker input {
  font-size: 16px !important;
}

.toolbar-container-secondary .ant-calendar-picker input {
  padding: 5px 24px !important;
}

.toolbar-container-secondary .ant-calendar-picker-input {
  min-height: 25px;
  height: 27px;
  border: 1px solid transparent !important;
  border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  padding: 0px 0 0px 20px !important;
  width: 92% !important;
  min-width: 100px !important;
  cursor: pointer;
}
.toolbar-container-secondary .ant-calendar-picker-input:hover {
  border-bottom: 1px solid #00a8b9 !important;
}

.toolbar-container-secondary .ant-calendar-picker-icon,
.toolbar-container-secondary .ant-calendar-picker-clear,
.toolbar-container-secondary .anticon-close-circle {
  left: 1px;
  color: #00a8b9 !important;
  top: 12px;
}

.toolbar-container-secondary .filters {
  margin-left: 35px;
  margin-top: 15px;
}

/* filter Select */
.filterSelect .ant-select-arrow-icon {
  left: 1px;
  color: #00a8b9 !important;
}

.filterSelect .ant-select-selection--single {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 16px !important;
  border: none;
  border-radius: 0px !important;
  height: 32px !important;
  width: 100px !important;
}

.filterSelect .ant-select-selection--single:hover {
  border-bottom: 1px solid #00a8b9 !important;
}

.filterSelect .ant-select-arrow,
.filterSelect .ant-select-selection__clear {
  color: #00a8b9 !important;
  right: 88px !important;
  top: 16px !important;
}

.filterSelect .ant-select-selection__clear {
  font-size: 14px;
}

.filterSelect .ant-select-selection__rendered {
  margin-left: 20px !important;
  margin-right: 3px !important;
  top: 1px;
}

.filterSelect .ant-select-selection__placeholder {
  color: #bfbfbf !important;
}

.filters .btnPrimary {
  background: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  color: var(--black) !important;
  height: 30px;
  position: relative;
  top: 1px;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

/** view select */

.fixedPanelFilters .viewSelect .ant-select-arrow-icon {
  left: 1px;
  color: var(--primary) !important;
}

.fixedPanelFilters .viewSelect .ant-select-selection--single {
  background-color: var(--white) !important;
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 14px !important;
  border: none;
  border-bottom: 1px solid var(--primary) !important;
  border-radius: 0px !important;
  height: 32px !important;
  width: 150px !important;
}

.fixedPanelFilters .viewSelect .ant-select-arrow,
.fixedPanelFilters .viewSelect .ant-select-selection__clear {
  color: var(--primary) !important;
}

.fixedPanelFilters .viewSelect .ant-select-selection__clear {
  font-size: 14px;
}

.fixedPanelFilters .viewSelect .ant-select-selection__rendered {
  top: 1px;
}

.fixedPanelFilters .viewSelect .ant-select-selection__placeholder {
  color: #bfbfbf !important;
}

.fixedPanelFilters .viewSelect .anticon:not(.ant-select-arrow) {
  color: #f18640;
  margin-right: 5px;
}

.fixedPanelFilters .viewSelect .ant-select-selection__rendered {
  margin: 0;
}

.fixedPanelFilters .viewSelect .ant-select-selection-selected-value {
  padding-left: 2px;
}

.view-dropdown-select {
  width: 150px !important;
}

.view-dropdown-select .ant-select-dropdown-menu li {
  padding-left: 3px !important;
}

.view-dropdown-select .ant-select-dropdown-menu-item svg {
  color: #f18640 !important;
  margin-right: 5px;
}

.avatar-btn {
  color: #121212 !important;
}

.avatar-btn img {
  position: relative !important;
  right: 1px !important;
  top: -1px !important;
  width: 104% !important;
  height: 104% !important;
  background-color: #121212;
}

.imgProject {
  width: 100%;
}

.cardProject {
  cursor: pointer;
  margin-bottom: 20px;
  min-height: 100px;
  border: 1px solid rgba(223, 223, 223, 1);
  overflow: hidden;
  padding-bottom: 13px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  box-shadow: 0px 3px 6px 1px rgba(223, 223, 223, 1);
}

.titleProject {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  padding: 12px 10px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #121212 !important;
}
.subTitleProject {
  font-size: 14px;
  text-align: left;
  padding: 0 10px;
  top: -5px;
  position: relative;
  height: 22px;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.indicatorsProject {
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
  /* width: 98%; */
  margin-top: 30px;
  font-size: 14px;
  padding-bottom: 15px;
  padding-left: 6px;
  padding-right: 6px;
}

.indicatorsProject div {
  /* width: 33%; */
  width: auto;
  display: inline-block;
  font-size: 14px;
}

.indicatorsProject div:first-child {
  text-align: left;
}

.indicatorsProject div:last-child {
  text-align: right;
  color: #00ca2a;
}

.indicatorsProject .ind-prg {
  width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.indicatorsProject .ind-commit {
  width: 37%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.indicatorsProject .ind-consts {
  width: 37%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.divider {
  border-top: 1px solid #d8d8d8;
  margin-top: 2px;
  height: 1px;
  padding: 0 10px;
  width: 92%;
}

.propsProject {
  padding: 10px 9px 0px;
  display: flex;
  justify-content: space-between;
}

.propsProject div {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: flex;
  gap: 5px;
}

.propsProject div span {
  display: flex;
  gap: 5px;
  align-items: center;
}

.propsProject div:first-child {
  justify-content: flex-start;
}

.propsProject div:last-child {
  justify-content: flex-end;
}

@media all and (max-width: 1450px) {
  .propsProject div:first-child {
    width: 100%;
    text-align: center;
  }
  .propsProject div:last-child {
    width: 100%;
    text-align: center;
  }
}

.propsProject .budget svg {
  margin: 0;
  left: -8px;
  top: 7px;
}

.propsProject .iconCompany {
  position: relative;
  top: 7px;
  margin-right: -10px;
  right: 7px;
}

.iconArea {
  position: relative;
  top: 2px;
}

.iconDollar {
  position: relative;
  top: 4px;
  left: -5px;
  margin-right: -6px;
}

.finished {
  filter: grayscale(100%);
}

.finished .cardProject {
  background: darkgray;
}

.listUsers {
  text-align: right;
  position: absolute;
  right: 8px;
  top: 4%;
}

.listUsers .ant-avatar-image {
  position: relative;
  float: right;
  left: 1px;
}

.dateProject {
  color: var(--white);
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 18px;
  margin-left: 10px;
  border-radius: 5px;
  padding: 0 5px;
}

.fixedToolbar {
  position: initial;
  width: 100%;
  z-index: 999;
}

.fixedPanel {
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 72px;
}

.fixedPanelFilters {
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 155px !important;
}

.toolbar-container-secondary .buttonsNav {
  height: 50px;
  padding-top: 22px;
  float: right;
  position: relative;
  margin-right: 37px;
}

.toolbar-container-secondary .buttonsNav .ant-radio-button-wrapper {
  background-color: white;
  border: none !important;
  color: #a1a1a1 !important;
  text-align: center !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  margin-right: 10px;
}

.toolbar-container-secondary .buttonsNav .ant-radio-button-wrapper-checked {
  text-align: center !important;
  background-color: #f18640 !important;
  border: 1px solid #f18640 !important;
  border: 1px solid !important;
  border-radius: 8px !important;
  -webkit-border-radius: 9px !important;
  -moz-border-radius: 9px !important;
  padding: 0 !important;
  color: white !important;
}

.toolbar-container-secondary
  .buttonsNav
  .ant-radio-button-wrapper-checked
  span {
  padding: 0 14px !important;
}

.toolbar-container-secondary .buttonsNav .selectFilter {
  float: right;
  min-width: 150px;
  margin-left: 10px;
}

.toolbar-container-secondary .buttonsNav .ant-select-selection {
  background-color: #f18640 !important;
  border: none !important;
}
.toolbar-container-secondary .buttonsNav .ant-select-arrow {
  padding-left: 12px;
  border-left: 1px solid white;
}

.toolbar-container-secondary
  .buttonsNav
  .ant-select-focused
  .ant-select-selection,
.toolbar-container-secondary .buttonsNav .ant-select-selection:focus,
.toolbar-container-secondary .buttonsNav .ant-select-selection:active,
.toolbar-container-secondary .buttonsNav .ant-select-open .ant-select-selection,
.toolbar-container-secondary .buttonsNav .ant-input:focus {
  border-color: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.toolbar-container-secondary .buttonsNav .ant-input {
  height: 27px !important;
}

.link-account {
  cursor: pointer;
}

.titleItem {
  position: relative !important;
  right: 3px !important;
}

.titleItem img {
  position: relative !important;
  color: #00a3b8 !important;
  font-size: 26px !important;
  top: -1px !important;
  margin-right: 14px;
}

.logoToolbar {
  width: 55px;
  margin-top: 0;
  margin-bottom: 42px;
  float: left;
}

.selectors {
  display: inline;
  margin-left: 10px;
}

.moreOptions {
  opacity: 0;
}

.cardProject:hover .moreOptions {
  opacity: 1 !important;
  transition: 0.4s ease;
}

.imgPry {
  display: flex;
  align-content: flex-end;
  max-height: 152px;
}

.frmWide .fixOnlyNumber {
  display: inline !important;
  padding: 0 !important;
  height: 49px !important;
  position: relative !important;
  padding-bottom: 0px !important;
  margin: 0 !important;
}

#numberformatBudget {
  top: 3px !important;
}

#numberformatProjectSize {
  width: 100%;
}

/** view list select projects */
.itemList {
  display: flex;
  align-items: center;
  padding-bottom: 0 !important;
}

.itemList div {
  flex: 1 1 auto;
}

.itemList .logoproject {
  width: 0px;
  margin-right: 10px;
}

.itemList .logoproject img {
  width: 110%;
  float: left;
}

@media all and (max-width: 800px) {
  .itemList .logoproject {
    display: none;
  }
}

.itemList .responsables {
  text-align: left;
  position: relative;
  top: -12px;
}

.itemList .responsables .ant-avatar-image {
  position: absolute;
  float: left;
  left: 1px;
}

.itemList .red-text {
  font-weight: bold;
  color: red;
  font-size: 18px;
}

.itemList .red-text.ant-progress .ant-progress-text {
  color: red;
}

.itemList .blue-text {
  font-weight: bold;
  color: #2c3421;
  font-size: 18px;
}

.itemList .titleList {
  font-size: 14px;
  color: #d1d1d1;
  font-weight: bold;
}

.infoproject {
  margin-left: 20px;
  text-align: left;
  font-size: 14px;
  max-width: 460px;
}

.itemList .infoproject div:first-child {
  font-weight: bold;
  font-weight: normal;
}

.overlay-menu {
  background: white;
  padding: 10px !important;
  box-shadow: 2px 3px 6px 1px rgba(229, 229, 229, 1);
}

.overlay-menu .actionsMenu div {
  cursor: pointer;
  font-weight: normal;
}

.overlay-menu .actionsMenu div:hover {
  font-weight: bold;
}

.cardProject:hover {
  border: 1px solid #00a8b8;
}

.imgProjectSpin {
  display: flex !important;
  align-items: center;
  width: 100%;
  padding-left: 48% !important;
  min-height: 90px;
}

.hidden {
  display: none !important;
}

.logoproject .imgProjectSpin {
  min-height: 200px !important;
}

.turquoise {
  color: #00aaba;
}

.itemListGen {
  cursor: pointer;
  margin-bottom: 20px;
  min-height: 100px;
  border: 1px solid #2c3421;
  overflow: hidden;
  outline: 0;
  padding-bottom: 10px;
  outline: 0 !important;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

.itemListGen:hover {
  outline: 0 !important;
  border: 1px solid var(--dark-mint);
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

.app,
.content {
  height: 100%;
  margin: 0;
}

.frm-forgot .no-width,
.frm-forgot .ant-btn {
  width: auto !important;
}

#sector.ant-popover-inner,
#responsible.ant-popover-inner {
  margin-left: 1px;
  margin-top: -6px;
}

#sector.ant-popover-inner .sector_check_filter,
#responsible.ant-popover-inner .sector_check_filter {
  padding: 0px;
}
.container-card {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.without-sidenav-reverse {
  width: 100%;
  min-height: 100vh;
  background: var(--dark-green);
  display: flex;
  justify-content: center;
  align-items: center;
}

.without-sidenav-reverse::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url('../assets/img/bg-new-login.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  mix-blend-mode: multiply;
  opacity: 1;
  inset: 0;
}

.ant-pagination-item,
.ant-pagination-item:hover,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-disabled .ant-pagination-item-link {
  background-color: var(--cool-gray);
  border-color: var(--cool-gray);
  color: var(--black);
}

.ant-pagination-item.ant-pagination-item-active {
  background-color: var(--primary);
  border-color: var(--primary);
}

.ant-pagination-item.ant-pagination-item-active a,
.ant-pagination-item:hover a {
  color: var(--black);
}

.ant-pagination-next .ant-pagination-item-link:hover,
.ant-pagination-disabled .ant-pagination-item-link:hover {
  background-color: var(--cool-gray);
  border-color: var(--cool-gray);
  color: var(--black);
}

.ant-layout > div:nth-child(3),
.ant-layout-content > div:nth-child(1) {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
