.constraint-list__container {
  width: 100%;
  height: 100%;
  padding-right: 6px;
}

.constraint-list__container .container__type {
  width: 100%;
  height: 34px;
  margin-bottom: 6px;
  border-radius: 5px;
  background-color: var(--dark-green);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}

.constraint-list__container .container__type.type--selected {
  background-color: var(--primary);
}

.constraint-list__container .container__type:last-child {
  margin-bottom: 0;
}

.constraint-list__container .container__type .type__name {
  display: flex;
  align-items: center;
}

.constraint-list__container .container__type .type__name .name__select {
  margin-right: 10px;
}

.constraint-list__container .container__type .type__name p {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: var(--white);
}

.constraint-list__container .container__type.type--selected .type__name p {
  color: var(--black);
}

.constraint-list__container .container__type .type__icon {
  width: 16px;
  height: 16px;
  display: flex;
}

.constraint-list__container .container__type .type__icon .icon i {
  display: flex;
}

.constraint-list__container .container__type .type__icon .icon i img {
  width: 100%;
}
