.btn-etapa {
  display: inline-block;
  text-align: center;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  line-height: 1.5;
  display: inline-block;
  border: 0px;
  cursor: pointer;
}

.center-btn-generals {
  display: flex;
  justify-content: center;
  justify-items: center;
}
.flex-end-save-template {
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
}
.btn-project-stage {
  display: inline-block;
  text-align: center;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  line-height: 1.5;
  display: inline-block;
  border: 2px solid var(--primary);
  cursor: pointer;
  background-color: var(--primary);
  color: var(--black);
  border-radius: 5px;
}

.btn-project-template {
  display: inline-block;
  text-align: center;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  line-height: 1.5;
  color: #bee1f8;
  display: inline-block;
  border: 2px solid #bee1f8;
  cursor: pointer;
  background-color: var(--white);
  margin-right: 10px;
  border-radius: 5px;
}

.btn-etapa:focus {
  outline: none;
}

.underline-btn-etapa {
  color: #1890ff;
  border: 1px solid #1890ff;
}

/* ETAPA */
.grid-etapa {
  display: grid;
  grid-template-columns: 90% 10%;
}

.description-form {
  font-size: 12px;
}

.mt-description {
  margin-top: 32px;
}

.mt-description-manager {
  margin-top: 51px;
}

.btn-productividad {
  display: inline-block;
  text-align: center;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 10px;
  line-height: 1.5;
  display: inline-block;
  border: 0px;
  cursor: pointer;
  width: 90px;
}

.bg-no-productividad {
  background-color: #586666;
  color: white;
}
.bg-productividad {
  background-color: #1890ff;
  color: white;
}

/* grid-cards-etapas */
.shawdow-etapas {
  -webkit-box-shadow: 0px 1px 5px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 5px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 5px -2px rgba(0, 0, 0, 0.75);
  border-radius: 10px !important;
}

.grid-cards-etapas {
  display: grid;
  grid-template-columns: 50% 50%;
}

.grid-etapas-left {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 10px;
  margin-left: 4px;
}

.grid-etapas {
  margin-bottom: 40px;
}

.grid-etapa .btn-productivity {
  margin-top: 15px;
  display: flex;
  gap: 5px;
}

.grid-etapas div .ant-form-item {
  width: 95%;
}

@media (min-width: 592px) and (max-width: 1359px) {
  .grid-etapas {
    display: grid;
    grid-template-columns: 18% 22% 60%;
    grid-gap: 3px;
  }
  .btn-etapa {
    display: inline-block;
    text-align: center;
    user-select: none;
    padding: 0.33rem 0.3rem;
    font-size: 12px;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
  }
  .title-etapas {
    font-size: 12px;
  }
  .avatars-etapa {
    border-radius: 50px;
    height: 13px;
    width: 13px;
  }
}

@media (min-width: 1360px) {
  .grid-etapas {
    display: grid;
    grid-template-columns: 18% 22% 60%;
    grid-gap: 3px;
  }
  .btn-etapa {
    display: inline-block;
    text-align: center;
    user-select: none;
    padding: 0.33rem 0.3rem;
    font-size: 12px;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
  }
  .title-etapas {
    font-size: 12px;
  }
  .avatars-etapa {
    border-radius: 50px;
    height: 13px;
    width: 13px;
  }
}

@media (min-width: 1609px) and (max-width: 1726px) {
  .grid-etapas {
    display: grid;
    grid-template-columns: 18% 22% 60%;
    grid-gap: 3px;
  }
  .btn-etapa {
    display: inline-block;
    text-align: center;
    user-select: none;
    padding: 0.33rem 0.3rem;
    font-size: 12px;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
  }
  .title-etapas {
    font-size: 12px;
  }
  .avatars-etapa {
    border-radius: 50px;
    height: 13px;
    width: 13px;
  }
}

@media (min-width: 1727px) and (max-width: 1790px) {
  .grid-etapas {
    display: grid;
    grid-template-columns: 20% 22% 58%;
    grid-gap: 3px;
  }
  .btn-etapa {
    display: inline-block;
    text-align: center;
    user-select: none;
    padding: 0.33rem 0.3rem;
    font-size: 9.8px;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
  }
  .title-etapas {
    font-size: 12px;
  }
  .avatars-etapa {
    border-radius: 50px;
    height: 13px;
    width: 13px;
  }
}

@media (min-width: 1791px) and (max-width: 1806px) {
  .grid-etapas {
    display: grid;
    grid-template-columns: 20% 22% 57.5%;
    grid-gap: 3px;
  }
  .btn-etapa {
    display: inline-block;
    text-align: center;
    user-select: none;
    padding: 0.33rem 0.3rem;
    font-size: 9.8px;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
  }
  .title-etapas {
    font-size: 12px;
  }
  .avatars-etapa {
    border-radius: 50px;
    height: 13px;
    width: 13px;
  }
}

@media (min-width: 1807px) and (max-width: 1920px) {
  .grid-etapas {
    display: grid;
    grid-template-columns: 20% 22% 57.5%;
    grid-gap: 3px;
  }
  .btn-etapa {
    display: inline-block;
    text-align: center;
    user-select: none;
    padding: 0.33rem 0.3rem;
    font-size: 12px;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
  }
  .title-etapas {
    font-size: 12px;
  }
  .avatars-etapa {
    border-radius: 50px;
    height: 13px;
    width: 13px;
  }
}

@media (min-width: 1921px) and (max-width: 3000px) {
  .grid-etapas {
    display: grid;
    grid-template-columns: 18% 22% 60%;
    grid-gap: 3px;
  }
  .btn-etapa {
    display: inline-block;
    text-align: center;
    user-select: none;
    padding: 0.33rem 0.3rem;
    font-size: 14px;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
  }
  .title-etapas {
    font-size: 13px;
  }
  .avatars-etapa {
    border-radius: 50px;
    height: 13px;
    width: 13px;
  }
}
