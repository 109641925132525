@import '../../stylesheets/base';

.activity-card-drawer {
  @extend %inverted-scrollbar--bluish-gray;
  height: 312px;

  &[class*='--loading'] > section {
    opacity: 0.3;
  }

  > section {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px 20px;
  }

  [class*='__header__'] {
    border-bottom-width: 0;
    margin: 0 -15px;
    padding-bottom: 0;
  }

  [class*='__close-button'] img {
    width: 16px;
    height: 16px;
  }

  &__content {
    flex: 1;
    display: flex;
    align-items: stretch;

    > :nth-child(2) {
      flex: 2;
    }

    > :nth-child(3) {
      flex: 1;
    }
  }

  > :first-child {
    border-bottom-width: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
