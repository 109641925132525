.container__select-tasks .select-tasks {
  width: 100%;
  margin-top: 10px;
}

.container__select-tasks .select-tasks.disabled {
  opacity: var(--disabled-opacity);
}

.container__select-tasks .select-tasks.disabled .ant-select-selection {
  cursor: not-allowed !important;
}

.container__select-tasks .select-tasks .select-tasks__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container__select-tasks .select-tasks .select-tasks__container span {
  color: var(--white);
}

.container__select-tasks .select-tasks .ant-select-selection {
  height: 68px;
  border-radius: var(--border-radius-input-modal);
  background-color: var(--dark-green-sec);
  border: 0;
  box-shadow: none;
  overflow-y: scroll;
  padding: 0 10px 10px 10px;
  cursor: pointer;
}

.container__select-tasks
  .select-tasks
  .ant-select-selection::-webkit-scrollbar {
  width: 4px;
  height: 36px;
}

.container__select-tasks
  .select-tasks
  .ant-select-selection::-webkit-scrollbar-thumb {
  background-color: var(--gray-1);
  border-radius: 3px;
}

.container__select-tasks
  .select-tasks
  .ant-select-selection::-webkit-scrollbar-track {
  background-color: var(--dark-green-sec);
  border-radius: 3px;
}

.container__select-tasks .select-tasks .ant-select-selection:active {
  box-shadow: none;
}

.container__select-tasks
  .select-tasks
  .ant-select-selection
  .ant-select-selection__rendered
  .ant-select-selection__choice {
  margin: 10px 10px 0 0;
  background-color: var(--primary);
  border-radius: 3px;
  color: var(--black);
  font-size: 12px;
  font-weight: 400;
  border: 0;
}

.container__select-tasks
  .select-tasks
  .ant-select-selection
  .ant-select-selection__rendered
  .ant-select-selection__choice
  .ant-select-selection__choice__remove
  i
  svg {
  fill: var(--white);
}

.container__select-tasks
  .select-tasks
  .ant-select-selection
  .ant-select-search__field__placeholder {
  top: 20px;
  height: calc(100% - 20px);
}

.select-tasks__container .task-list-icon {
  img {
    width: 24px !important;
    margin-bottom: 5px;
  }
}

.select-acts {
  background-color: var(--dark-green-sec);
}

.select-acts #rc-tree-select-list_1 .ant-select-tree {
  max-height: 300px;
  overflow-y: scroll;
}

.select-acts #rc-tree-select-list_1 .ant-select-tree::-webkit-scrollbar {
  width: 4px;
  height: 36px;
}

.select-acts #rc-tree-select-list_1 .ant-select-tree::-webkit-scrollbar-thumb {
  background-color: var(--gray-1);
  border-radius: 3px;
}

.select-acts #rc-tree-select-list_1 .ant-select-tree::-webkit-scrollbar-track {
  background-color: var(--dark-green-sec);
  border-radius: 3px;
}

.select-acts
  :root
  .ant-select-tree
  li
  span.ant-select-tree-switcher.ant-select-tree-switcher_close
  .ant-select-switcher-icon {
  font-size: 14px;
}

.select-acts .ant-select-tree svg {
  fill: var(--white);
}

.select-acts .ant-select-tree li .ant-select-tree-node-content-wrapper {
  color: var(--white);
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
}

.select-acts
  li.ant-select-tree-treenode-disabled
  > .ant-select-tree-node-content-wrapper
  span {
  color: var(--white);
  opacity: var(--disabled-opacity);
}

.select-acts .ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: var(--dark-green-sec);
}

.select-acts .ant-select-tree-checkbox-inner {
  background-color: transparent;
  border: 2px solid var(--white);
  border-radius: 3px;
}

.select-acts
  .ant-select-tree-checkbox-disabled
  .ant-select-tree-checkbox-inner {
  border: 2px solid var(--white) !important;
  opacity: var(--disabled-opacity);
}

.select-acts .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner {
  border-color: var(--white);
}

.select-acts .ant-select-tree-checkbox-checked::after {
  border: 0;
}

.select-acts
  .ant-select-tree-checkbox-checked
  .ant-select-tree-checkbox-inner::after {
  border-right: 2px solid var(--primary);
  border-bottom: 2px solid var(--primary);
}

.select-acts
  .ant-select-tree-checkbox.ant-select-tree-checkbox-checked
  .ant-select-tree-checkbox-inner {
  background-color: var(--white);
  border-color: var(--white);
}

.select-acts
  .ant-select-tree-checkbox-indeterminate
  .ant-select-tree-checkbox-inner::after {
  background-color: var(--primary);
}

.select-acts
  .ant-select-tree-checkbox-indeterminate
  .ant-select-tree-checkbox-inner {
  background-color: var(--white);
  border-color: var(--white);
}

.select-tasks.disabled.ant-select.ant-select-enabled {
  width: 100%;
}
