.frm-finish .title-frm {
  font-size: 15px;
  color: #2c3421;
  font-weight: bold;
  line-height: 18px;
  text-align: left;
  margin-top: 10px;
  border-bottom: 0.3px solid;
  padding-bottom: 15px;
}

.frm-finish .hand-close {
  float: right;
  color: #c4c4c4;
  margin-right: 3px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.frm-finish .tab-left {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  padding: 20px 0;
  background: #121212;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  flex-direction: column;
}

.frm-finish .ant-modal-body {
  padding: 0 !important;
}

.frm-finish .list-left {
  width: 100%;
  text-align: left;
  padding: 15px 20px;
  display: flex;
  align-items: center;
}

.frm-finish .reviewed {
  color: #34af00;
}

.frm-finish .current {
  color: var(--primary);
}

.frm-finish .option-text {
  display: inline;
}

.frm-finish .option-text {
  display: inline;
}

.frm-finish .option-subtitle {
  font-size: 10px;
}

.frm-finish .ant-modal-content {
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
}

.frm-finish .img-upload {
  width: 17px;
  margin-right: 6px;
}

.frm-finish .tab-right {
  padding: 10px;
}

.frm-finish .tab-right .title,
.frm-finish .title {
  display: inline;
}

.frm-finish .title {
  font-weight: bold;
  color: #2c3421;
}

.frm-finish .process-title {
  border-bottom: 1px solid #2c3421;
  padding-bottom: 6px;
  width: 95%;
  padding: 10px 0;
  text-align: left;
}

.frm-finish .title-info {
  color: #2c3421;
}

.frm-finish .title-info {
  padding: 12px 0;
}

.frm-finish .list-items {
  height: 100%;
  max-height: 310px;
  min-height: 310px;
  overflow: auto;
}

.frm-finish .list-items .item-info {
  display: flex;
  justify-content: space-between;
  border: 2px solid #2c3421;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  padding: 5px;
  font-size: 12px;
  margin: 9px 14px 0px 0px;
  cursor: pointer;
}
.frm-finish .list-items .item-info div {
  display: inline;
  padding: 5px;
}

.frm-finish .buttons {
  height: 60px;
  margin-bottom: 10px;
}

.frm-finish .btn-continue,
.frm-finish .btn-continue:hover,
.frm-finish .btn-continue:active,
.frm-finish .btn-continue:focus {
  background: var(--primary);
  color: var(--black);
  border: 1px solid var(--primary);
  margin: 0 4px;
  font-size: 13px;
  max-height: 25px;
}
.frm-finish .btn-previous,
.frm-finish .btn-previous:hover,
.frm-finish .btn-previous:active,
.frm-finish .btn-previous:focus {
  background: var(--black);
  color: var(--primary);
  border: 1px solid var(--primary);
  margin: 0 4px;
  font-size: 13px;
  max-height: 25px;
}

.frm-finish .buttons {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  flex-direction: row;
}

.frm-finish .back-white {
  background: white;
  width: 25px;
  border-radius: 164px;
  height: 20px;
  padding: 3px;
  margin-right: 13px;
}

.frm-finish .option-icon {
  font-size: 28px;
  margin-right: 10px;
}

.frm-finish .back-white .option-icon {
  position: relative;
  top: -7px;
  left: -3px;
}

.frm-finish .item-number {
  min-width: 55px;
}

.frm-finish .item-name {
  height: 25px;
  text-overflow: ellipsis;
  width: 136px;
  word-break: break-all;
  overflow: hidden;
}

.frm-finish .skip-link {
  color: #2c3421 !important;
  font-size: 12px;
  padding: 4px;
  cursor: pointer;
}

.frm-finish .hidden {
  display: none;
}

.frm-finish .title-info {
  color: #2c3421;
}

.frm-finish .selected {
  border: 2px solid var(--primary) !important;
}

/** //////////////////////////////////////////////////////////////////////////////// */
.frm-finish .row-normal {
  text-align: center;
  color: #121212;
  width: 90%;
}

.frm-finish .col-normal {
  height: 100%;
}

.frm-finish .text-title {
  display: inline-block;
  margin: 20px;
  color: #2c3421;
}
.frm-finish .img-upload {
  width: 23px;
  margin: auto 10px;
}

.frm-finish .div-vertical {
  height: 100%;
  align-self: auto;
  display: flex;
  line-height: 30px;
  margin: 4px;
}

.frm-finish .btn-import {
  height: auto;
  margin: 0 10px;
  border: 1px solid #2c3421;
}

.frm-finish .body-modal {
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.frm-finish .hidden {
  display: none;
}

.frm-finish .text-remaining {
  font-size: 18px;
  margin-bottom: 16px;
}

.frm-finish .text-remaining span {
  color: var(--primary);
}

.frm-finish .text-filename {
  text-align: left;
  color: #2c3421;
}

.frm-finish .text-filename img {
  margin: 0 10px 0px 0px;
}

.frm-finish .btn-cancel,
.frm-finish .btn-cancel:hover {
  color: #e50101;
  border: 1px solid #e50101;
  margin-bottom: 12px;
}

.frm-finish .div-progress {
  margin-bottom: 30px;
}

.frm-finish .text-success {
  color: #2c3421;
  font-size: 20px;
  padding: 14px 30%;
}

.frm-finish .circle-ok {
  font-size: 22px;
  color: green;
  margin-bottom: 20px;
}

.frm-finish .btn-continue,
.frm-finish .btn-continue:hover,
.frm-finish .btn-continue:active,
.frm-finish .btn-continue:focus {
  background: var(--primary);
  color: var(--black);
  border: 1px solid var(--primary);
}

.frm-finish .div-circle {
  margin-bottom: 16px;
}

.frm-finish .ant-upload-picture-card-wrapper {
  width: auto;
  margin: 0 10px;
}
.frm-finish .ant-upload {
  height: auto !important;
}
.frm-finish .text-success {
  color: #2c3421;
}
