@import '../../../../assets/styles/variables.scss';

.project-cell {
  padding-left: 20px;
  color: $black;
  display: flex;
  .main-schedule {
    margin-left: 10px;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    color: $dark-gray;
    &:hover {
      text-decoration: underline;
    }
  }

  .project-name {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
  }
}
