@media (min-width: 592px) and (max-width: 1359px) {
  .grid-general {
    display: grid;
    grid-template-columns: 18% 22% 20% 20% 20%;
    gap: 3px;
    margin-top: 10px;
  }
  .input-picker-settings {
    border: 1px solid #70bdf0;
    border-radius: 5px;
  }
  .camera-icon-general {
    position: relative;
    left: 80%;
    bottom: 15px;
    background-color: #2c3421;
    border-radius: 64px;
  }
  .image-general {
    height: 150px;
    width: 90%;
    margin-right: 20px;
    border-radius: 5px;
  }

  /* BUTTON ETAPAS */
  .btn-double {
    display: inline-block;
    text-align: center;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 8.7px;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
  }

  .bg-btn {
    color: var(--white);
    background-color: var(--primary);
    transition: 0.3s;
    border: 1px solid var(--primary);
  }

  .bg-btn-white {
    background-color: var(--white);
    transition: 0.5s;
  }
  /* HOVER PRESUPUESTO Y TAMAÑO */
  .bg-btn-hover:hover {
    background-color: var(--primary);
    color: var(--black);
    transition: 0.5s;
  }

  /* BUTTON AGREGAR ETAPA */

  .btn-add-etapa {
    display: inline-block;
    font-weight: 400;
    color: var(--black);
    text-align: center;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.8rem;
    line-height: 1.5;
    border: 1px solid var(--primary);
    cursor: pointer;
    background: var(--primary);
    border-radius: 3px;
    transition: 0.3s;
  }
  .btn-add-etapa:hover {
    transition: 0.3s;
    background: var(--primary);
  }

  /* ETAPAS */
  .grid-double {
    display: grid;
    grid-template-columns: 25% 60%;
  }
  .input-pcr {
    width: 85% !important;
    border: 1px solid #70bdf0 !important;
  }
}
.img-upload-logo {
  position: relative;
  cursor: pointer;
  top: -50% !important;
  left: 70px !important;
}
.project-and-owner-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 5px;
}
.logo-title-imageContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 186px;
}
.cont-projectImag-edit {
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-self: center !important;
  height: 140px;
}
.cont-projectOwnerImg-edit {
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-self: center !important;
  height: 60px;
  margin-top: 10px;
}
.project-logos-titles {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #121212;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
#file-input-edit-image {
  display: none;
}

#file-input-edit-image2 {
  display: none;
}

.projectImg-resize {
  width: 186px;
  height: 140px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.proyectOwnerimg-resize {
  width: 186px;
  height: 60px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #f5f5f5;
  border-radius: 5px;
}

@media (min-width: 1360px) and (max-width: 1721px) {
  .grid-general {
    display: grid;
    grid-template-columns: 18% 22% 20% 20% 20%;
    gap: 3px;
    margin-top: 10px;
  }
  .input-picker-settings {
    /* border: 1px solid #70BDF0; */
    border-radius: 5px;
  }
  .camera-icon-general {
    position: relative;
    left: 80%;
    bottom: 15px;
    background-color: #2c3421;
    border-radius: 64px;
  }
  .image-general {
    height: 150px;
    width: 90%;
    margin-right: 20px;
    border-radius: 5px;
  }

  /* BUTTON ETAPAS */
  .btn-double {
    display: inline-block;
    text-align: center;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 8.7px;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
  }

  .bg-btn {
    color: var(--black);
    background-color: var(--primary);
    transition: 0.3s;
    border: 1px solid var(--primary);
  }

  .bg-btn:hover {
    transition: 0.3s;
    background-color: var(--primary);
  }
  .bg-btn-white {
    background-color: var(--white);
    transition: 0.5s;
  }
  /* HOVER PRESUPUESTO Y TAMAÑO */
  .bg-btn-hover:hover {
    background-color: var(--primary);
    color: var(--black);
    transition: 0.5s;
  }

  .underline-btn {
    color: var(--primary);
    border: 1px solid var(--primary);
  }

  /* BUTTON AGREGAR ETAPA */

  .btn-add-etapa {
    display: inline-block;
    font-weight: 400;
    color: var(--black);
    text-align: center;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.8rem;
    line-height: 1.5;
    border: 1px solid v;
    cursor: pointer;
    background: var(--primary);
    border-radius: 3px;
    transition: 0.3s;
  }

  /* ETAPAS */
  .grid-double {
    display: grid;
    grid-template-columns: 25% 60%;
  }
  .input-pcr {
    width: 85% !important;
    border: 1px solid #70bdf0 !important;
  }
}

@media (min-width: 1723px) and (max-width: 1790px) {
  .grid-general {
    display: grid;
    grid-template-columns: 20% 22.2% 20.5% 20.5% 20%;
    margin-top: 10px;
  }

  .camera-icon-general {
    position: relative;
    left: 85%;
    bottom: 15px;
    background-color: #2c3421;
    border-radius: 64px;
  }
  .image-general {
    height: 170px;
    width: 90%;
    margin-right: 20px;
    border-radius: 5px;
  }

  /* BUTTON ETAPAS */
  .btn-double {
    display: inline-block;
    text-align: center;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 8.7px;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
  }

  .bg-btn {
    color: var(--black);
    background-color: var(--primary);
    transition: 0.3s;
    border: 1px solid var(--primary);
  }

  .bg-btn:hover {
    transition: 0.3s;
    background-color: var(--primary);
  }
  .bg-btn-white {
    background-color: var(--white);
    transition: 0.5s;
  }
  /* HOVER PRESUPUESTO Y TAMAÑO */
  .bg-btn-hover:hover {
    background-color: var(--primary);
    color: var(--black);
    transition: 0.5s;
  }

  .underline-btn {
    color: #1890ff;
    border: 1px solid #1890ff;
  }

  /* BUTTON AGREGAR ETAPA */

  .btn-add-etapa {
    display: inline-block;
    font-weight: 400;
    color: var(--black);
    text-align: center;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.8rem;
    line-height: 1.5;
    border: 1px solid var(--primary);
    cursor: pointer;
    background: var(--primary);
    border-radius: 3px;
    transition: 0.3s;
  }

  /* ETAPAS */
  .grid-double {
    display: grid;
    grid-template-columns: 25% 60%;
  }
  .input-pcr {
    width: 85% !important;
    border: 1px solid #70bdf0 !important;
  }
}

@media (min-width: 1791px) and (max-width: 1800px) {
  .grid-general {
    display: grid;
    grid-template-columns: 20% 22.2% 20.8% 20.8% 20%;
    margin-top: 10px;
  }

  .camera-icon-general {
    position: relative;
    left: 85%;
    bottom: 15px;
    background-color: #2c3421;
    border-radius: 64px;
  }
  .image-general {
    height: 170px;
    width: 90%;
    margin-right: 20px;
    border-radius: 5px;
  }

  /* BUTTON ETAPAS */
  .btn-double {
    display: inline-block;
    text-align: center;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 8.7px;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
  }

  .bg-btn {
    color: var(--black);
    background-color: var(--primary);
    transition: 0.3s;
    border: 1px solid var(--primary);
  }

  .bg-btn:hover {
    transition: 0.3s;
    background-color: var(--primary);
  }
  .bg-btn-white {
    background-color: var(--white);
    transition: 0.5s;
  }
  /* HOVER PRESUPUESTO Y TAMAÑO */
  .bg-btn-hover:hover {
    background-color: var(--primary);
    color: var(--black);
    transition: 0.5s;
  }

  .underline-btn {
    color: #1890ff;
    border: 1px solid #1890ff;
  }

  /* BUTTON AGREGAR ETAPA */

  .btn-add-etapa {
    display: inline-block;
    font-weight: 400;
    color: var(--black);
    text-align: center;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.8rem;
    line-height: 1.5;
    border: 1px solid var(--primary);
    cursor: pointer;
    background: var(--primary);
    border-radius: 3px;
    transition: 0.3s;
  }

  /* ETAPAS */
  .grid-double {
    display: grid;
    grid-template-columns: 25% 60%;
  }
  .input-pcr {
    width: 85% !important;
    border: 1px solid #70bdf0 !important;
  }
}

@media (min-width: 1801px) and (max-width: 1818px) {
  .grid-general {
    display: grid;
    grid-template-columns: 20% 22.2% 20.8% 20.8% 20%;
    margin-top: 10px;
  }

  .camera-icon-general {
    position: relative;
    left: 85%;
    bottom: 15px;
    background-color: #2c3421;
    border-radius: 64px;
  }
  .image-general {
    height: 170px;
    width: 90%;
    margin-right: 20px;
    border-radius: 5px;
  }

  /* BUTTON ETAPAS */
  .btn-double {
    display: inline-block;
    text-align: center;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 8.7px;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
  }

  .bg-btn {
    color: var(--black);
    background-color: var(--primary);
    transition: 0.3s;
    border: 1px solid var(--primary);
  }

  .bg-btn:hover {
    transition: 0.3s;
    background-color: var(--primary);
  }
  .bg-btn-white {
    background-color: var(--white);
    transition: 0.5s;
  }
  /* HOVER PRESUPUESTO Y TAMAÑO */
  .bg-btn-hover:hover {
    background-color: var(--primary);
    color: var(--black);
    transition: 0.5s;
  }

  .underline-btn {
    color: #1890ff;
    border: 1px solid #1890ff;
  }

  /* BUTTON AGREGAR ETAPA */

  .btn-add-etapa {
    display: inline-block;
    font-weight: 400;
    color: var(--black);
    text-align: center;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.8rem;
    line-height: 1.5;
    border: 1px solid var(--primary);
    cursor: pointer;
    background: var(--primary);
    border-radius: 3px;
    transition: 0.3s;
  }

  /* ETAPAS */
  .grid-double {
    display: grid;
    grid-template-columns: 25% 70%;
  }
  .input-pcr {
    width: 95% !important;
    border: 1px solid #70bdf0 !important;
  }
}

@media (min-width: 1819px) and (max-width: 2000px) {
  .grid-general {
    display: grid;
    grid-template-columns: 20% 22.2% 20.8% 20.8% 20%;
    margin-top: 10px;
  }

  .input-picker-settings {
    width: 85% !important;
    border: 1px solid #70bdf0;
  }

  .camera-icon-general {
    position: relative;
    left: 85%;
    bottom: 15px;
    background-color: #2c3421;
    border-radius: 64px;
  }
  .image-general {
    height: 170px;
    width: 90%;
    margin-right: 20px;
    border-radius: 5px;
  }

  /* BUTTON ETAPAS */
  .btn-double {
    display: inline-block;
    text-align: center;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 8.7px;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
  }

  .bg-btn {
    color: var(--black);
    background-color: var(--primary);
    transition: 0.3s;
    border: 1px solid var(--primary);
  }

  .bg-btn:hover {
    transition: 0.3s;
    background-color: var(--primary);
  }
  .bg-btn-white {
    background-color: var(--white);
    transition: 0.5s;
  }
  /* HOVER PRESUPUESTO Y TAMAÑO */
  .bg-btn-hover:hover {
    background-color: var(--primary);
    color: var(--black);
    transition: 0.5s;
  }

  .underline-btn {
    color: #1890ff;
    border: 1px solid #1890ff;
  }

  /* BUTTON AGREGAR ETAPA */

  .btn-add-etapa {
    display: inline-block;
    font-weight: 400;
    color: var(--black);
    text-align: center;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.8rem;
    line-height: 1.5;
    border: 1px solid var(--primary);
    cursor: pointer;
    background: var(--primary);
    border-radius: 3px;
    transition: 0.3s;
  }

  /* ETAPAS */
  .grid-double {
    display: grid;
    grid-template-columns: 25% 70%;
  }
  .input-pcr {
    width: 95% !important;
    border: 1px solid #70bdf0 !important;
  }
}

@media (min-width: 2001px) and (max-width: 2137px) {
  .grid-general {
    display: grid;
    grid-template-columns: 18% 22% 20% 20% 20%;
    margin-top: 10px;
  }

  .camera-icon-general {
    position: relative;
    left: 85%;
    bottom: 15px;
    background-color: #2c3421;
    border-radius: 64px;
  }
  .image-general {
    height: 170px;
    width: 90%;
    margin-right: 20px;
    border-radius: 5px;
  }

  /* BUTTON ETAPAS */
  .btn-double {
    display: inline-block;
    text-align: center;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 8.7px;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
  }

  .bg-btn {
    color: var(--black);
    background-color: var(--primary);
    transition: 0.3s;
    border: 1px solid var(--primary);
  }

  .bg-btn:hover {
    transition: 0.3s;
    background-color: var(--primary);
  }
  .bg-btn-white {
    background-color: var(--white);
    transition: 0.5s;
  }
  /* HOVER PRESUPUESTO Y TAMAÑO */
  .bg-btn-hover:hover {
    background-color: var(--primary);
    color: var(--black);
    transition: 0.5s;
  }

  .underline-btn {
    color: #1890ff;
    border: 1px solid #1890ff;
  }

  /* BUTTON AGREGAR ETAPA */

  .btn-add-etapa {
    display: inline-block;
    font-weight: 400;
    color: var(--black);
    text-align: center;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.8rem;
    line-height: 1.5;
    border: 1px solid var(--primary);
    cursor: pointer;
    background: var(--primary);
    border-radius: 3px;
    transition: 0.3s;
  }

  /* ETAPAS */
  .grid-double {
    display: grid;
    grid-template-columns: 25% 70%;
  }
  .input-pcr {
    width: 95% !important;
    border: 1px solid #70bdf0 !important;
  }
}

@media (min-width: 2137px) and (max-width: 5000px) {
  .grid-general {
    display: grid;
    grid-template-columns: 18% 22% 20% 20% 20%;
    margin-top: 10px;
  }
  .input-picker-settings {
    width: 70% !important;
    border: 1px solid #70bdf0;
  }

  .camera-icon-general {
    position: relative;
    left: 85%;
    bottom: 15px;
    background-color: #2c3421;
    border-radius: 64px;
  }
  .image-general {
    height: 190px;
    width: 90%;
    margin-right: 20px;
    border-radius: 5px;
  }

  /* BUTTON ETAPAS */
  .btn-double {
    display: inline-block;
    text-align: center;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 8.7px;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
  }

  .bg-btn {
    color: var(--black);
    background-color: var(--primary);
    transition: 0.3s;
    border: 1px solid var(--primary);
  }

  .bg-btn:hover {
    transition: 0.3s;
    background-color: var(--primary);
  }
  .bg-btn-white {
    background-color: var(--white);
    transition: 0.5s;
  }
  /* HOVER PRESUPUESTO Y TAMAÑO */
  .bg-btn-hover:hover {
    background-color: var(--primary);
    color: var(--black);
    transition: 0.5s;
  }

  .underline-btn {
    color: #1890ff;
    border: 1px solid #1890ff;
  }

  /* BUTTON AGREGAR ETAPA */

  .btn-add-etapa {
    display: inline-block;
    font-weight: 400;
    color: var(--black);
    text-align: center;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.8rem;
    line-height: 1.5;
    border: 1px solid var(--primary);
    cursor: pointer;
    background: var(--primary);
    border-radius: 3px;
    transition: 0.3s;
  }

  /* ETAPAS */
  .grid-double {
    display: grid;
    grid-template-columns: 25% 70%;
  }
  .input-pcr {
    width: 95% !important;
    border: 1px solid #70bdf0 !important;
  }
}
.title-config-project-general {
  margin-top: 20px;
  color: #2c3421;
}
.grid-etapas .title-config-projects,
.grid-general .title-config-projects {
  color: #2c3421;
}

.grid-settings .input-text {
  border: 1px solid var(--black);
  width: 95%;
  height: 34px;
  color: var(--black);
  margin-bottom: 20px;
  font-size: 12px;
}

.grid-general .ant-calendar-picker-input {
  height: 35px;
  border: 1px solid var(--black);
}

.grid-general .number-input-double {
  width: 56%;
  margin-left: 7px;
}

.grid-general .select-budget .ant-select-selection-selected-value,
.grid-general .select-size .ant-select-selection-selected-value {
  text-align: center;
}

.grid-general .fix-title {
  margin-bottom: 20px;
}

.select-budget .ant-select-selection--single .ant-select-selection__rendered,
.select-size .ant-select-selection--single .ant-select-selection__rendered,
.select-budget .ant-select-selection__rendered,
.select-size .ant-select-selection__rendered {
  margin: 0;
}

.select-budget .ant-select-selection-selected-value,
.select-size .ant-select-selection-selected-value {
  width: 100%;
}

.options-currency {
  width: 260px !important;
}

.grid-general .date-input-project {
  width: 75%;
}

.grid-general .numeric-project {
  width: 56%;
  margin-left: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 8px;
}

.grid-general .percentage-project {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 8px;
}

.grid-etapas .input-error {
  border: 1px solid var(--red) !important;
}
