.App {
  font-family: sans-serif;
  text-align: center;
}

.paginator-number {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 10px !important;
  padding: 9.5px !important;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  color: #595959;
}

.paginator-number:hover {
  color: #2196f3;
  border-color: #2196f3 !important;
}

.per-page-paginator-select {
  font-size: 10px !important;
  margin-bottom: 2px !important;
}

.per-page-paginator-select .ant-select-selection {
  border-radius: 2px !important;
}

.per-page-paginator-select .ant-select-arrow {
  color: #d9d9d9 !important;
}

.sticky {
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 2;
}
