.gridContainer {
  height: 100% !important;
  margin: 25px;
  display: grid !important;
  grid-template: auto 1fr / 25% 68% 7%;
  &__total {
    text-align: center;
    font-weight: 500;
    background-color: #efefef;
    width: 100px;
  }
}
.matrix__filter-buttons {
  display: inline-block !important;
}
.gridHeader {
  overflow: hidden;
}
