.order-lookahead {
  top: 5px !important;
  left: 9px !important;
}

.order-lookahead .ant-radio-button-wrapper-checked {
  background: #2c3421 !important;
  border: 1px solid #2c3421 !important;
}

.dropdown-groupping {
  color: #121212;
  position: relative;
  top: 5px;
  left: 8px;
}

.dropdown-groupping:hover {
  color: #121212;
}

.arrow-img-groupping {
  position: relative;
  top: 5px;
  width: 6px;
  left: 21px;
}

.button-switch-groupping {
  position: relative;
  top: -2px;
  left: 16px;
}
