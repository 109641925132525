.overlay-type-dropdown-popover .ant-popover-content .ant-popover-arrow {
  border-right-color: var(--dark-green-sec);
  border-bottom-color: var(--dark-green-sec);
}

.overlay-type-dropdown-popover .ant-popover-content .ant-popover-inner {
  background-color: var(--dark-green-sec);
  border-radius: 5px;
}

.overlay-type-dropdown-popover
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content {
  padding: 10px 0;
}

.overlay-type-dropdown-popover
  .ant-popover-inner-content
  .dropdown-popover-types
  .types__menu {
  width: 126px;
  display: flex;
  flex-direction: column;
}

.overlay-type-dropdown-popover
  .ant-popover-inner-content
  .dropdown-popover-types
  .types__menu
  .menu__option {
  height: 30px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  color: var(--white);
  cursor: pointer;
}

.overlay-type-dropdown-popover
  .ant-popover-inner-content
  .dropdown-popover-types
  .types__menu
  .menu__option:hover {
  background-color: var(--black);
  color: var(--white);
}

.overlay-type-dropdown-popover
  .ant-popover-inner-content
  .dropdown-popover-types
  .types__menu
  .menu__option.active {
  background-color: var(--primary);
}

.overlay-type-dropdown-popover
  .ant-popover-inner-content
  .dropdown-popover-types
  .types__menu
  .menu__option.active
  span {
  color: var(--black);
}

.popover-types-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.popover-types-content span {
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  text-transform: uppercase;
  color: var(--white);
}

.popover-types-content .content__arrow {
  position: absolute;
  right: 0;
  opacity: 0;
  transition-duration: 0.3s;
  visibility: hidden;
}

.popover-types-content .content__arrow.open,
.popover-types-content.ant-popover-open .content__arrow {
  visibility: visible;
  opacity: 1;
}

.popover-types-content .content__arrow i img {
  width: 10px;
}
