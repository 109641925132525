@import '../../../assets/styles/variables.scss';

.tooltip-design-system-v2 {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      box-shadow: none;
      background-color: $dark-green-sec;
      border-radius: 5px;
      padding: 10px;
      font: $regular-s-font;
      color: $white;
    }

    .ant-tooltip-arrow {
      &::before {
        box-shadow: none;
        background-color: $dark-green-sec;
      }
    }
  }
}
