.columnsHeaders {
  border-right: 0.5px solid #e3e3e3;

  display: flex;
  justify-content: center;
  align-items: center;
}

.columnsHeaders.selected {
  background-color: #c8e8fb;
}
