@import '../../../assets/styles/variables.scss';

.container__selector {
  border-radius: 5px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &.size--default {
    height: 30px;
  }

  &.size--small {
    height: 24px;
  }

  &.theme--default {
    color: $white;
    background-color: $dark-green;
  }

  &.theme--dark {
    color: $white;
    background-color: $black;
  }

  &.pointer {
    cursor: pointer;
  }

  &.disabled {
    opacity: $disabled-opacity;
    cursor: no-drop;
  }

  &.error {
    //border: 1px solid $light-red;

    label {
      //color: $light-red;
    }
  }

  label {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;

    &.pointer {
      cursor: pointer;
    }

    &.disabled {
      cursor: no-drop;
    }
  }

  .selector__arrow {
    margin-left: 10px;
    transition-duration: 0.3s;

    &.rotate {
      transform: rotate(-180deg);
    }

    i {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
