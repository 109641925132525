@import '../UiKit/UiKit.scss';

.notification-system {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(88, 88, 88, 0.2);

  &.type--warning {
    background-color: $warning;
  }

  &.type--error {
    background-color: $ipsum-l-orange;
  }

  .notification-system__content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .content__icon {
      display: flex;
      margin-right: 15px;

      i {
        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    span {
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
      color: $dark-gray;
    }
  }
}
