@import '../../assets/styles/variables.scss';

.activity-tree-pane__container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 50px 32px auto 36px;
  grid-template-columns: auto;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  border-top: 1px solid #cecece;
  border-right: 1px solid #cecece;

  .container__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid $black;

    .header__left {
      display: flex;
      gap: 10px;

      span {
        font: $h4-font;
        color: $black;
      }
    }
  }

  .container__button {
    display: flex;

    button {
      width: 100%;
      height: 36px;
      border-radius: 5px;
      background-color: $black;
      color: $white;
      font: $regular-s-font;
      border: 0;

      &:disabled {
        opacity: $disabled-opacity;
        cursor: not-allowed;
      }
    }
  }
}
