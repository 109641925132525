.projects_section_title {
  font-weight: bold;
  display: inline;
  padding-left: 22px;
}
.linkCrud {
  color: rgb(15, 75, 238) !important;
  cursor: pointer;
}
.labelCrud {
  color: black !important;
}
.center {
  text-align: center !important;
}
.underline {
  text-decoration-line: underline;
  text-decoration-style: solid;
}

.fixedToolbar {
  position: initial;
}

.loader-spinner-projects {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.animationProjects {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapLoading {
  display: flex;
  align-items: center;
  justify-content: center;
}
