.progress {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  width: 100%;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;

  &
    :global
    .ant-input-number.custom-input-number-planification.ant-input-number-focused.progress-bar-custom-input {
    width: 100% !important;
  }

  & :global .custom-input-number-planification .ant-input-number-input {
    padding: 0 !important;
  }
}

.progress_label {
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
}
