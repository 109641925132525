@import '../../../assets/styles/variables.scss';

.popover-design-system-v2 {
  .ant-popover-content {
    .ant-popover-arrow {
      box-shadow: none;
    }

    .ant-popover-inner {
      box-shadow: none;
      border-radius: 5px;

      .ant-popover-inner-content {
        padding: 0;
      }
    }
  }

  &.theme--light {
    &.ant-popover-placement-top,
    &.ant-popover-placement-topLeft,
    &.ant-popover-placement-topRight {
      .ant-popover-content {
        .ant-popover-arrow {
          border-right-color: $gray-2;
          border-bottom-color: $gray-2;
        }
      }
    }

    &.ant-popover-placement-left,
    &.ant-popover-placement-leftTop,
    &.ant-popover-placement-leftBottom {
      .ant-popover-content {
        .ant-popover-arrow {
          border-top-color: $gray-2;
          border-right-color: $gray-2;
        }
      }
    }

    &.ant-popover-placement-bottom,
    &.ant-popover-placement-bottomLeft,
    &.ant-popover-placement-bottomRight {
      .ant-popover-content {
        .ant-popover-arrow {
          border-top-color: $gray-2;
          border-left-color: $gray-2;
        }
      }
    }

    &.ant-popover-placement-right,
    &.ant-popover-placement-rightTop,
    &.ant-popover-placement-rightBottom {
      .ant-popover-content {
        .ant-popover-arrow {
          border-bottom-color: $gray-2;
          border-left-color: $gray-2;
        }
      }
    }

    .ant-popover-content {
      .ant-popover-inner {
        background-color: $white;
        border: 1px solid $gray-2;
      }
    }
  }

  &.theme--dark {
    &.ant-popover-placement-top,
    &.ant-popover-placement-topLeft,
    &.ant-popover-placement-topRight {
      .ant-popover-content {
        .ant-popover-arrow {
          border-right-color: $bed-rock;
          border-bottom-color: $bed-rock;
        }
      }
    }

    &.ant-popover-placement-left,
    &.ant-popover-placement-leftTop,
    &.ant-popover-placement-leftBottom {
      .ant-popover-content {
        .ant-popover-arrow {
          border-top-color: $bed-rock;
          border-right-color: $bed-rock;
        }
      }
    }

    &.ant-popover-placement-bottom,
    &.ant-popover-placement-bottomLeft,
    &.ant-popover-placement-bottomRight {
      .ant-popover-content {
        .ant-popover-arrow {
          border-top-color: $bed-rock;
          border-left-color: $bed-rock;
        }
      }
    }

    &.ant-popover-placement-right,
    &.ant-popover-placement-rightTop,
    &.ant-popover-placement-rightBottom {
      .ant-popover-content {
        .ant-popover-arrow {
          border-bottom-color: $bed-rock;
          border-left-color: $bed-rock;
        }
      }
    }

    .ant-popover-content {
      .ant-popover-inner {
        background-color: $dark-green;
        border: 1px solid $bed-rock;
      }
    }
  }
}
