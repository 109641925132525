.text-center {
  text-align: center;
}

.avatar {
  height: 80px;
  width: 80px;
  border-radius: 50px;
}

.camera-icon {
  position: relative;
  left: 130px;
  top: 85px;
  background-color: var(--primary);
  border-radius: 64px;
}
.card-shadow {
  -webkit-box-shadow: 1px 3px 5px -2px rgba(193, 193, 193, 1);
  -moz-box-shadow: 1px 3px 5px -2px rgba(193, 193, 193, 1);
  box-shadow: 1px 3px 5px -2px rgba(193, 193, 193, 1);
}
.txt-profile-size {
  font-size: 12px;
  padding: 12px !important;
  border-bottom: 1px solid #d1d1d1;
  cursor: pointer;
}
.bg-active-txt {
  background-color: var(--primary);
  color: var(--black);
}
.txt-profile-size:hover {
  background-color: var(--cool-gray);
  color: var(--black);
}

.align-txt-profile {
  position: relative;
  left: 15px;
  color: gray;
}
.align-txt-profile:hover {
  color: black;
}

.ant-card-body .ant-upload {
  height: 120px !important;
}

.setting-project-version {
  font-size: 12px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  line-height: 14px;
  letter-spacing: 0em;
  color: var(--black);
  text-align: center;
  margin-top: 15px;
  position: relative;
  z-index: 1;
  width: 230px;
}
