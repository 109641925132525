@import '../../../../stylesheets/base';
@import '../../../../assets/styles/variables.scss';

.three-by-two-tab {
  font-size: $font-xs;
  padding: 0 20px;
  color: $white;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  label:not([class^='ant-']) {
    border-bottom: 1px solid $bluish-gray-50;
    padding: 20px 0;

    &:nth-child(1),
    &:nth-child(4) {
      padding-right: 30px;
    }

    &:nth-child(2),
    &:nth-child(5) {
      padding: 20px 15px;
    }

    &:nth-child(3),
    &:nth-child(6) {
      padding-left: 30px;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      padding-top: 5px;
    }
  }

  input:not([class^='ant-']) {
    width: 100%;
    text-align: right;
    margin-top: 10px;
  }

  input:not([class*='text-input']):not([class^='ant-']) {
    border-radius: $default-border-radius;
    background-color: $dark-green;
    padding: 3px 6px;
    height: auto;
    line-height: 18px;
    border-width: 0;
    display: block;

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $dark-green;
      outline-width: 0;
    }

    &:disabled {
      color: $dark-green;
      opacity: $disabled-opacity;
    }
  }
}

.centered-cell {
  text-align: center;
}

.right-aligned-cell {
  text-align: right;
}
