.dateRangeOptions {
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
}

.dateRangeOptions.dateRangeSelection::before {
  position: absolute;
  left: -9px;
  display: flex;
  content: '';
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background-color: #1890ff;
}

.lookahead-filter-header {
  padding: 10px 28px 11px 25px;
}
