.layout {
  background-color: #fff;
  margin: 10px;
  /* margin-bottom: 300px!important; */
}

.car {
  background: #fff;
  border-radius: 5px;
  border: 1px solid rgba(223, 223, 223, 1);
  border-radius: 10px;
  /* -moz-border-radius: 10px; */
  -webkit-border-radius: 5px;
  box-shadow: 0px 0px 6px 1px rgba(223, 223, 223, 1);
}

.car.fill-height {
  height: calc(100vh - 240px) !important;
}

.cont-img-analitycs {
  width: 128px;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin: 0 15px;
}

.img_project_general {
  widows: 100%;
  max-width: 128px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  float: left;
}

.width-css {
  width: 100%;
}

.height-css {
  height: 100%;
}

.text_title_container {
  /* float: left; */
  margin-left: 30px;
  margin-top: 15px;
}

.text_title_container_h1 {
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  /* identical to box height */
  color: #121212;
}

.text_title_container_place {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  /* identical to box height */

  color: #121212;
}

.icon-img {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.data_proyect {
  margin-top: 10px;
}

.data_proyect_container_first {
  float: left;
}
.data_proyect_container {
  margin-left: 20px;
  float: left;
  span {
    font-size: 13px;
    font-weight: 400;
    font-family:
      Roboto,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Oxygen,
      Ubuntu,
      Cantarell,
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif !important;
  }
}

.img_project_general_galary {
  width: 100%;
  height: 100%;
  flex: 1;
  resize: 'contain';
  border-radius: 5px;
}

.img_project_general_galary_contenct {
  width: 100%;
  height: 100%;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 0px !important;
  height: 0px !important;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
  border-right: 1px solid rgba(0, 0, 0, 0) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
}

body {
  margin: 0;
  padding: 0;
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-grid-item-for-over {
  z-index: 1000 !important;
}

.filter_contect {
  margin-left: 25px;
  margin-top: 31px;
  width: 100%;
  height: 60px;
}

.filter_buton {
  position: absolute;
  cursor: pointer;
  width: 60px;
  height: 10px;
  margin-left: 1px !important;
  margin-top: 0px;

  span {
    font-size: 13px;
    font-weight: 400;
    font-family:
      Roboto,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Oxygen,
      Ubuntu,
      Cantarell,
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif !important;
  }
}

.filter_menu {
  padding: 5px !important;
}

.filter_menu_item {
  padding-left: 10px !important;
  padding-top: 3px !important;
  padding-bottom: 0px !important;
  padding-right: 10px !important;
}

.filter_menu_text {
  color: #2c3421;
}

.sector_check_filter {
  padding: 20px;
}

.filter_analitycs {
  display: grid;
  row-gap: 20px;
}

.filter_0tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-width: auto;
  outline: #fff;
}

.filter_tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-width: auto;
  outline: 0.1px dashed #2c3421;
}

.filter_date_analitycs {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.apply-filter-btn {
  cursor: pointer;
  width: 100%;
  text-align: center;
  height: 24px;
  background: var(--primary);
  border-radius: 5px;
  padding-top: 2px;
  color: var(--black);
}

.ant-checkbox-group-item {
  display: block !important;
  margin-right: 0 !important;
}

.panel-button-container {
  float: right;
}

.panel-button-container > .boton {
  width: 180px;
  height: 30px;
  border-radius: 5px;
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--black);
  margin: 0 10px;
  span {
    font-size: 13px;
    font-weight: 400;
    font-family:
      Roboto,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Oxygen,
      Ubuntu,
      Cantarell,
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif !important;
  }
}

.panel-button-container > .boton:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--black);
}

.panel-filters-container > .boton_filter {
  min-width: 100px;
  min-height: auto;
  border-radius: 5px;
  background-color: var(--primary);
  border-color: var(--primary);
  margin: 0 0px;
}

.panel-filters-container > .scroll-content {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 400px;
  padding: 2px 0px !important;
}

.panel-button-container > .general-style {
  background-color: #2c3421;
}

.wrapper-component {
  padding-bottom: 60px !important;
}

.loader-spinner-users {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
  width: 100% !important;
  font-size: 10px;
}

.loader-spinner-analitycs {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
  width: 100% !important;
  font-size: 10px;
}

.loading-div {
  margin: 0;
  margin-bottom: 6px;
  position: relative;
  top: -12px;
}
.loading-div .ant-spin-dot {
  font-size: 14px !important;
}

.widget-analitycs {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader-spinner-analytics {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100% !important;
}

.loader-spinner-analytics .spinner-load {
  position: relative;
  top: -40px;
}
