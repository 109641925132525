html {
  font-family: sans-serif;
  font-size: 12px;
}

.GridStaticColMatrix {
  /**
    border-right: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
    border: 0.5px solid #1212123b;
      */
  border-radius: 5px 0px 0px 5px;
  display: inline-block;
}

.GridStaticHeaderMatrix {
  border-left: 0px solid white;
  border-right: 0px solid white;
}

.GridStaticHeaderLocations.matrix-width,
.GridStaticHeaderMatrix.matrix-width {
  width: 100% !important;
}

.GridStaticHeaderLocations.structure {
  border-radius: 5px 5px 0 0;
}

.GridStaticHeaderLocations.location {
  border-left: 0.5px solid #e3e3e3;
  border-right: 0.5px solid #e3e3e3;
}

.GridMatrix {
  scroll-behavior: smooth !important;
  border-right: 0px solid #c4c4c4;
  border-bottom: 0px solid #c4c4c4;
  border-left: 0px solid #c4c4c4;
  display: inline-block;
}

.col-fixed-row-matrix {
  width: 100%;
  height: 100%;
  text-align: center;
  color: #121212;
  border-bottom: 0.5px solid white;
  box-sizing: border-box;
  padding: 3%;
  background-color: #e3e3e3;
}

.location-header-fix-matrix {
  background-color: #e3e3e3;
  width: 100%;
  height: 100%;
  color: #535353;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  border-right: 0.5px solid #828282;
}

.location-header-fix-matrix.locations {
  border-top: 0.5px solid #fff;
}

.location-header-fix-matrix.activities {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  background-color: #858585;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  border-right: 0.1px solid #e3e3e3;
  border-bottom: 0.1px solid #e3e3e3;
}

.location-header-fix-matrix.matrix-structure.first-round {
  border-top-left-radius: 5px;
}

.location-header-fix-matrix.matrix-structure.last-round {
  border-top-right-radius: 5px;
  border: none;
}

.grid-element-with-units-matrix {
  width: 100%;
  height: 100%;
  color: black;
  font-weight: normal;
  text-align: center;
  padding-top: 0.35%;

  border-bottom: 0.25px solid #e3e3e3;
  border-right: 0.25px solid #e3e3e3;
}

.grid-element-without-units-matrix {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 1%;
  border-right: 0.5px solid #e3e3e3;
  border-bottom: 0.5px solid #e3e3e3;
}

.fixed-unit-header-with-activities-matrix {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3e3e3;
  color: #121212;
  width: 100%;
  height: 100%;
  padding: 5px;
  border-right: 0.5px solid white;
  border-top: 0.5px solid white;
}

.fixed-unit-header-without-activities-matrix {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  color: #121212;
  background-color: #e3e3e3;
  width: 100%;
  height: 100%;
  padding: 5px;
  text-align: center;
  border-right: 0.5px solid white;
  border-top: 0.5px solid white;
}

.fixed-col-activity-with-units-matrix {
  background-color: #e3e3e3;
  color: #121212;
  width: 100%;
  height: 100%;
}

.fixed-col-activity-without-units {
  width: 100%;
  height: 100%;
}

.border-rigth-gray-matrix {
  border-right: 1.5px solid #828282;
}

.activities-checkbox-container-virtualized {
  border-radius: 5px;
  padding-top: 5px !important;
  box-shadow: 0 3px 1px 1px rgba(0, 0, 0, 0.2);
  height: 100% !important;
}

.activities-checkbox-container-virtualized
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.hide-style-pointer {
  cursor: pointer;
  position: relative;
  top: -2px;
  left: 4px;
}

.grid-container-style {
  border-radius: 5px;
  margin-top: 14px;
  box-shadow: 0 3px 1px 1px rgba(0, 0, 0, 0.2);
  height: calc(100vh - 281px) !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #25353a;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}

.matrix-activities-filter div {
  display: inline-block !important;
}

.matrix-activities-filter .css-1gpjby2 {
  width: 0px !important;
  visibility: hidden !important;
}

.filter-adjust-matrix {
  cursor: pointer;
  margin-right: 5px;
  position: relative;
  top: 8px;
  left: 10px;
}

.lookahead-view .filter-lookahead-multi-check .css-151xaom-placeholder {
  transform: translateY(20%);
}

.css-ik6y5r div {
  display: block !important;
}

.matrix-config .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.bordered-matrix-conf {
  border: 0.5px solid rgba(209, 209, 209, 0.5);
  text-align: center;
}

.matrix-structure {
  padding-top: 0px !important;
}

.no-border {
  border-right: none;
}

.radioModalTakt > span > .ant-radio-inner {
  border-color: #1890ff;
}
