@import '../../../assets/styles/variables.scss';

.popover-uniselect-design-system {
  padding: 10px 0;

  .popover-uniselect-design-system__search {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 20px;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    input {
      width: 100%;
      background-color: transparent;
      font: $regular-s-font;
      border: 0;
      height: 16px;
    }

    .search__clean {
      flex: 16px 1 0;
      width: 16px;
      cursor: pointer;

      i {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    .search__icon {
      flex: 16px 1 0;
      width: 16px;

      i {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .popover-uniselect-design-system__items {
    width: 100%;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $black;
    }

    .items__empty {
      width: 100%;
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .empty__icon {
        width: 24px;

        i {
          img {
            width: 24px;
            height: 24px;
          }
        }
      }

      span {
        font: $regular-s-font;
      }
    }

    .items__list {
      display: flex;
      flex-direction: column;

      .list__item {
        width: 100%;
        height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 20px;
        transition-duration: 0.3s;

        &.group {
          height: 35px;

          .item__text {
            .text {
              margin-top: 10px;

              span {
                font: $medium-s-font;
              }
            }

            .text__options {
              display: flex;
              align-items: center;
              gap: 10px;

              i {
                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }

        &.disabled {
          opacity: $disabled-opacity;
          cursor: not-allowed;
        }

        .item__text {
          display: flex;
          justify-content: space-between;

          .text {
            display: flex;
            align-items: center;
            gap: 10px;

            span {
              font: $regular-s-font;
            }
          }

          .text__options {
            display: flex;
            align-items: center;
            gap: 10px;

            i {
              img {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
  }

  .popover-uniselect-design-system__cta {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 0 10px;
    border-top-width: 1px;
    border-top-style: solid;
    transition-duration: 0.3s;

    .cta__icon {
      width: 16px;

      i {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    span {
      font: $regular-s-font;
    }
  }

  &.theme--light {
    .popover-uniselect-design-system__search {
      border-bottom-color: $gray-2;

      input {
        color: $black;

        &::placeholder {
          color: $black;
          opacity: $disabled-opacity;
        }

        &::-ms-input-placeholder {
          color: $black;
        }
      }
    }

    .popover-uniselect-design-system__items {
      &::-webkit-scrollbar-thumb {
        background: $gray-2;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $dark-mint;
      }

      .items__empty {
        span {
          color: $dark-gray;
        }
      }

      .items__list {
        .list__item {
          &.group {
            .item__text {
              .text {
                span {
                  color: $dark-gray;
                }
              }
            }
          }

          .item__text {
            .text {
              span {
                color: $black;
              }
            }
          }

          &:is(:not(.group)) {
            cursor: pointer;
          }

          &:hover:is(:not(.group)) {
            background-color: $cool-gray;
          }

          &.active:is(:not(.group)) {
            background-color: $primary;
          }
        }
      }
    }

    .popover-uniselect-design-system__cta {
      border-top-color: $gray-2;

      &:hover {
        background-color: $cool-gray;
      }

      span {
        color: $dark-mint;
      }
    }
  }

  &.theme--dark {
    .popover-uniselect-design-system__search {
      border-bottom-color: $bed-rock;

      input {
        color: $white;

        &::placeholder {
          color: $white;
          opacity: $disabled-opacity;
        }

        &::-ms-input-placeholder {
          color: $white;
        }
      }
    }

    .popover-uniselect-design-system__items {
      &::-webkit-scrollbar-thumb {
        background: $bed-rock;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $primary;
      }

      .items__empty {
        span {
          color: $clear-bed-rock;
        }
      }

      .items__list {
        .list__item {
          &.group {
            .item__text {
              .text {
                span {
                  color: $clear-bed-rock;
                }
              }
            }
          }

          .item__text {
            .text {
              span {
                color: $white;
              }
            }
          }

          &:is(:not(.group)) {
            cursor: pointer;
          }

          &:hover:is(:not(.group)) {
            background-color: $black;
          }

          &.active:is(:not(.group)) {
            background-color: $primary;

            span {
              color: $black;
            }
          }
        }
      }
    }

    .popover-uniselect-design-system__cta {
      border-top-color: $bed-rock;

      &:hover {
        background-color: $black;
      }

      span {
        color: $primary;
      }
    }
  }
}

.popover-uniselect-design-system__subitems {
  padding: 10px 0;

  .subitems__content {
    width: 100%;
    height: 30px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    cursor: pointer;
    transition-duration: 0.3s;

    &.divider {
      height: 10px;
    }

    &.disabled {
      opacity: $disabled-opacity;
      cursor: not-allowed;
    }

    .content__info {
      display: flex;
      align-items: center;
      gap: 10px;

      .info__icon {
        i {
          img {
            width: 16px;
            height: 16px;
          }
        }
      }

      span {
        font: $regular-s-font;
      }
    }

    .content__icon {
      i {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    .content__divider {
      width: 100%;
      height: 1px;
    }
  }

  &.theme--light {
    .subitems__content {
      &:hover:is(:not(.divider)) {
        background-color: $cool-gray;
      }

      &.active {
        background-color: $primary;
      }

      .content__info {
        span {
          color: $black;
        }
      }

      .content__divider {
        background-color: $gray-2;
      }
    }
  }

  &.theme--dark {
    .subitems__content {
      &:hover:is(:not(.divider)) {
        background-color: $black;
      }

      &.active {
        background-color: $primary;

        span {
          color: $black;
        }
      }

      .content__info {
        span {
          color: $white;
        }
      }

      .content__divider {
        background-color: $bed-rock;
      }
    }
  }
}
