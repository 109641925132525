@import '../../../stylesheets/base';
@import '../../../assets/styles/variables.scss';

.tabs {
  background-color: $black;
  border-radius: $default-border-radius;
  margin: 0 15px;

  :global .ant-tabs-bar {
    border-bottom-width: 0;
    margin-bottom: 15px;
  }

  :global .ant-tabs-nav > div {
    height: 30px;

    /* Increasing specificity to override Ant's default style */
    > .ant-tabs-tab {
      line-height: 30px !important;
      height: 30px !important;
      font-size: 12px !important;
    }

    > .ant-tabs-tab-active {
      line-height: 26px !important;
      height: 30px !important;
    }
  }

  :global .ant-tabs-nav-container {
    height: 33px !important;
  }
}
