.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-style {
  font-size: 23px !important;
  font-weight: 600 !important;
  color: #333334;
  margin-top: 8%;
}

.sub-title {
  font-size: 19px !important;
  font-weight: 0px !important;
  color: '#333334';
}

.common-btn-style {
  margin-right: 15px !important;
  margin-top: 15px !important;
  background-color: rgb(237, 133, 54) !important;
  border-color: rgb(237, 133, 54) !important;
  font-weight: 700 !important;
  font-size: 13px !important;
  padding: 0px 1% 0px 1% !important;
  width: 15%;
}

.btnblock {
  height: 44px !important;
  width: 22% !important;
  border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  margin-left: 15px !important;
}
