.crear-admin-form {
  margin: 20px;
}

.form-admin-create-title {
  font-size: 16px;
}

.submit_button {
  background-color: #2c3421 !important;
  color: white !important;
  margin-top: 20px;
}

.admin-create-company .img-upload-logo {
  position: relative;
  cursor: pointer;
  top: 115px;
  left: 0;
}

#file-input-edit-image {
  display: none;
}

.cont-imag-edit {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column;
}

.img-company-edit {
  border-radius: 10px;
}

.img-to-upload-admin {
  cursor: pointer;
}

.fix-admin-css-crop {
  height: 150px;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
