@import '../../assets/styles/variables.scss';

.SelectCompanyView {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__description {
    text-align: center;
    font: $regular-m-font;
    color: $black;
  }

  &__logout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    a {
      font: $regular-m-font;
      line-height: 18px;
      color: $dark-mint;
    }
  }
}
