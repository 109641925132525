@import '../../../../stylesheets/base';

.resources-tab {
  overflow-y: auto;
  margin-bottom: 20px;
  &__table {
    @extend %zebra-striped-table;
    width: calc(100% - 40px);
    margin: 0 20px;
  }

  &__name-column {
    min-width: 250px;
  }

  &__quantity-input {
    display: inline-block;

    input {
      width: 80px;
    }
  }
}

.tableFixHead {
  display: block;
  width: 100%;
  overflow: auto;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  min-width: 420px;
}

.tableFixHead tbody {
  display: block;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden !important;
  white-space: pre-wrap;
  max-height: 150px;
  border-radius: 0 0 5px 5px !important;
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 5px !important;
  }
}

.tableFixHead th,
.tableFixHead td {
  width: 17%;
  &:first-child {
    width: 49%;
  }
  &:last-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.tableFixHead th {
  &:last-child {
    width: calc(17% + 4px) !important;
  }
}

.tableFixHead tr {
  display: flex;
}

.tableFixHead thead {
  border-radius: 5px 5px 0 0 !important;
  display: block;
}
