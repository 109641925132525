@import '../../../assets/styles/variables.scss';

.companyOption {
  &__layout {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: calc((5 * 40px) + 40px);
    overflow-y: auto;

    &-scroll {
      padding-right: 5px;

      &::-webkit-scrollbar {
        width: 4px;
      }
    }
  }

  &__item {
    background-color: $cool-gray;
    padding: 5px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    height: 40px;
    cursor: pointer;

    h2 {
      margin: 0;
      width: 85%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $black;
      font: $medium-m-font;
    }

    &--dark {
      background-color: $dark-green;
      h2 {
        color: $white;
      }
    }
  }
}
