.assignBtn {
  background-color: #69bf45;
  color: #ffffff;
  cursor: pointer;

  font-size: 13px;
  text-align: center;
  font-weight: 400;

  border-radius: 5px;
  outline: none;
  border: none;
  padding: 3px 8px;
  width: 121px !important;
}

.assignBtn.unassign {
  background-color: #e50101;
}

.assignBtn.disabled {
  background-color: #fff;
  color: #c4c4c4;
  border: 1px solid #c4c4c4;
  cursor: no-drop;
}

/**
 * =========
 *  Toast
 * =========
 * */
.toastContianer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;
  align-content: center;

  height: 80px;
}

.toastImg {
  width: 20px;
}

/**
 * =========
 *  Settigns Hours
 * =========
 * */
.settingsHours {
  display: inline-block;
  font-size: 19px;
  cursor: pointer;
}

.tooltipImg {
  cursor: pointer;
  font-size: 16px;
}

.formHours {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.formHours label {
  color: #1890ff;
}

.formHours input {
  margin: 10px 0;
}
