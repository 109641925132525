.constraint-dates__container {
  width: 270px;
  height: 32px;
  background-color: var(--dark-green-sec);
  border-radius: var(--border-radius-input-modal);
  display: flex;
  justify-content: center;
  align-items: center;
}

.constraint-dates__container.disabled {
  opacity: var(--disabled-opacity);
}

.constraint-dates__container .container__popover {
  width: 100%;
  cursor: pointer;
}

.constraint-dates__container.disabled .container__popover {
  cursor: not-allowed;
}

.constraint-dates__container .container__popover .popover__content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.constraint-dates__container .container__popover i {
  margin-right: 10px;
}

.constraint-dates__container.disabled .container__popover i {
  cursor: not-allowed;
}

.constraint-dates__container .container__popover i img {
  width: 16px;
}

.constraint-dates__container .container__label span {
  font-size: 10px;
  line-height: 11px;
  font-weight: 300;
  color: var(--white);
}
