.gantt-toolbar {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  user-select: none;

  &__levels-submenu {
    @for $i from 0 to 20 {
      li:nth-child(#{$i + 1}) span {
        padding-left: 4px * $i;
      }
    }
  }
}
