@import '../../assets/styles/variables.scss';

.login__notification {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}

.login__form {
  width: 100%;

  form {
    display: flex;
    flex-direction: column;

    .form__input {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      label {
        font: $regular-m-font;
        line-height: 18px;
        color: $black;
        margin-bottom: 10px;
      }

      input {
        width: 100%;
        height: 40px;
        border-radius: 5px;
        background-color: $cool-gray;
        box-sizing: border-box;
        color: $black;
        border: 1px solid $cool-gray;
        transition-duration: $transition-duration-default;

        &:focus {
          border: 1px solid $dark-mint;
        }

        &.error {
          border: 1px solid $red;
          background-color: $lighter-red;
        }
      }

      small {
        margin-top: 4px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
        color: $red;
      }

      button {
        width: 100%;
        height: 40px;
        color: $black;
        background-color: $primary;
        font: $regular-m-font;
        line-height: 18px;
        border: 0;
        border-radius: 5px;
        transition-duration: $transition-duration-default;

        &:hover {
          background-color: $dark-mint;
        }

        &:disabled {
          background-color: $gray-2;
          color: $gray-1;
        }
      }
    }

    .form__forgot {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-bottom: 20px;

      a {
        font: $regular-m-font;
        line-height: 18px;
        color: $dark-mint;
      }
    }

    .form__signup {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-bottom: 20px;

      span {
        line-height: 18px;
        font-weight: 300;
        color: $black;

        a {
          font: $regular-m-font;
          color: $dark-mint;
          margin-left: 5px;
        }
      }
    }

    .form__line {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: relative;

      .line__separation {
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $gray-2;
      }

      span {
        font: $regular-m-font;
        line-height: 18px;
        color: $black;
        padding: 0 20px;
        background-color: $white;
        z-index: 1;
      }
    }

    .form__procore {
      display: flex;
      margin-top: 20px;
    }

    .form__beta {
      display: flex;
      margin-top: 20px;
    }
  }
}

.loggin-project-version {
  font-size: 16px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  line-height: 19px;
  letter-spacing: left;
  color: var(--white);
  text-align: center;
  margin-top: 15px;
  position: relative;
  z-index: 1;
}
