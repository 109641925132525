@import '../../../assets/styles/variables.scss';

.table-design-system {
  .ant-table-content {
    .ant-table-header {
      background-color: transparent;
    }

    .ant-table-scroll {
      .ant-table-body {
        background-color: transparent;

        &::-webkit-scrollbar {
          width: 100%;
          height: 5px;
        }
      }
    }

    .ant-table-fixed-right {
      .ant-table-body-outer {
        .ant-table-body-inner {
          &::-webkit-scrollbar {
            width: 5px;
          }
        }
      }
    }

    .ant-table-body,
    .ant-table-scroll,
    .ant-table-fixed-left,
    .ant-table-fixed-right {
      .ant-table-thead {
        height: 40px;

        tr {
          &:first-child {
            th {
              &:first-child {
                border-top-left-radius: 5px;
              }
            }
          }

          &:last-child {
            th {
              &:last-child {
                border-top-right-radius: 5px;
              }
            }
          }

          th {
            padding-left: 10px !important;
            padding-right: 10px !important;
            font: $medium-s-font !important;

            .anticon-filter {
              &:hover {
                background-color: rgba(0, 0, 0, 0.04);
              }

              &.ant-table-filter-open {
                background-color: rgba(0, 0, 0, 0.04);
              }
            }
          }
        }
      }

      .ant-table-tbody {
        tr {
          td {
            padding: 0 !important;
            font: $regular-s-font !important;
            border-bottom: 0 !important;
          }
        }
      }

      .ant-checkbox {
        &.ant-checkbox-checked {
          &::after {
            border: 0;
          }
        }

        .ant-checkbox-inner {
          background-color: transparent;
          border-radius: 4px;
          border-width: 2px;
        }

        &.ant-checkbox-disabled {
          opacity: 0.1;
        }
      }

      .ant-radio-wrapper {
        .ant-radio {
          .ant-radio-inner {
            border-width: 2px;
            background-color: transparent;
          }

          &.ant-radio-checked {
            .ant-radio-inner {
              &::after {
                top: 2px;
                left: 2px;
              }
            }
          }
        }
      }
    }

    .ant-table-fixed-left {
      .ant-table-thead {
        tr {
          th {
            &:last-child {
              border-top-right-radius: 0 !important;
            }
          }
        }
      }
    }

    .ant-table-fixed-right {
      .ant-table-thead {
        tr {
          th {
            &:first-child {
              border-top-left-radius: 0 !important;
            }
          }
        }
      }
    }

    .ant-table-placeholder {
      width: 100%;
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 0;
      border: 0;

      div {
        i {
          img {
            width: 48px;
            height: 48px;
          }
        }
      }

      span {
        font: $regular-s-font;
      }
    }
  }

  .ant-pagination {
    li {
      min-width: 28px;
      width: 28px;
      height: 28px;
      margin-right: 5px;
      border-radius: 0;
      border: 0;
      background-color: transparent;

      &.ant-pagination-disabled {
        a {
          opacity: $disabled-opacity;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        font: $regular-s-font;
        border-radius: 0;

        .ant-pagination-item-container {
          width: 28px;
        }
      }

      &.ant-pagination-prev {
        border-radius: 5px 0 0 5px;

        a {
          border-radius: 5px 0 0 5px;
        }
      }

      &.ant-pagination-next {
        border-radius: 0 5px 5px 0;

        a {
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }

  &.size--default {
    .ant-table-content {
      .ant-table-tbody {
        tr {
          height: 40px;
        }
      }
    }
  }

  &.size--small {
    .ant-table-content {
      .ant-table-tbody {
        tr {
          height: 30px;
        }
      }
    }
  }

  &.theme--light {
    .ant-spin-nested-loading {
      .ant-spin {
        .ant-spin-dot {
          .ant-spin-dot-item {
            background-color: $dark-gray;
          }
        }
      }
    }

    .ant-table-content {
      .ant-table-scroll {
        .ant-table-body {
          overflow-x: auto !important;

          &::-webkit-scrollbar-thumb {
            background-color: $gray-2;
            border-radius: 3px;
          }

          &::-webkit-scrollbar-track {
            background-color: $black;
            border-radius: 3px;
          }
        }
      }

      .ant-table-fixed-right {
        .ant-table-body-outer {
          .ant-table-body-inner {
            &::-webkit-scrollbar-thumb {
              background-color: $gray-2;
              border-radius: 3px;
            }

            &::-webkit-scrollbar-track {
              background-color: $black;
              border-radius: 3px;
            }
          }
        }
      }

      .ant-table-body,
      .ant-table-scroll,
      .ant-table-fixed-left,
      .ant-table-fixed-right {
        .ant-table-thead {
          tr {
            th {
              background-color: $gray-2;
              color: $black;

              .ant-checkbox {
                &:hover {
                  .ant-checkbox-inner {
                    border-color: $gray-1 !important;
                  }
                }

                &.ant-checkbox-checked {
                  .ant-checkbox-inner {
                    background-color: $dark-mint !important;
                  }

                  &:hover {
                    .ant-checkbox-inner {
                      border-color: $dark-mint !important;
                    }
                  }
                }

                &.ant-checkbox-indeterminate {
                  .ant-checkbox-inner {
                    &::after {
                      background-color: $dark-mint;
                    }
                  }
                }

                .ant-checkbox-inner {
                  border-color: $gray-1;
                }
              }

              .ant-table-column-sorter {
                i {
                  &.on {
                    color: $dark-mint;
                  }
                }
              }
            }
          }
        }

        .ant-table-tbody {
          tr {
            td {
              color: $black;

              .ant-checkbox {
                &:hover {
                  .ant-checkbox-inner {
                    border-color: $gray-1 !important;
                  }
                }

                &.ant-checkbox-checked {
                  .ant-checkbox-inner {
                    background-color: $dark-mint !important;
                  }

                  &:hover {
                    .ant-checkbox-inner {
                      border-color: $dark-mint !important;
                    }
                  }
                }

                .ant-checkbox-inner {
                  border-color: $gray-1;
                }
              }

              .ant-radio-wrapper {
                .ant-radio {
                  .ant-radio-inner {
                    border-color: $gray-2;
                  }

                  &:hover {
                    .ant-radio-inner {
                      border-color: $gray-2;
                    }
                  }

                  &.ant-radio-checked {
                    .ant-radio-inner {
                      border-color: $dark-mint;

                      &::after {
                        background-color: $dark-mint;
                      }
                    }

                    &:hover {
                      .ant-radio-inner {
                        border-color: $dark-mint;
                      }
                    }
                  }
                }
              }
            }

            &:nth-child(even) {
              background-color: $l-gray-2;
            }

            &:nth-child(odd) {
              background-color: $white;
            }

            &:hover,
            &.ant-table-row-selected,
            &.ant-table-row-hover {
              td {
                background-color: $lighter-green;
              }
            }
          }
        }
      }
    }

    .ant-table-placeholder {
      background-color: $white;

      span {
        color: $black;
      }
    }

    .ant-pagination {
      li {
        a {
          background-color: $cool-gray;
          border-color: $cool-gray;
          color: $black;
        }

        &.ant-pagination-item-active {
          a {
            background-color: $primary;
          }
        }

        &.ant-pagination-jump-prev-custom-icon,
        &.ant-pagination-jump-next-custom-icon {
          a {
            i {
              color: $black;
            }
          }
        }
      }
    }
  }

  &.theme--dark {
    .ant-spin-nested-loading {
      .ant-spin {
        .ant-spin-dot {
          .ant-spin-dot-item {
            background-color: $primary;
          }
        }
      }
    }

    .ant-table-content {
      .ant-table-scroll {
        .ant-table-body {
          &::-webkit-scrollbar-thumb {
            background-color: $bed-rock;
            border-radius: 3px;
          }

          &::-webkit-scrollbar-track {
            background-color: $black;
            border-radius: 3px;
          }
        }
      }

      .ant-table-fixed-right {
        .ant-table-body-outer {
          .ant-table-body-inner {
            &::-webkit-scrollbar-thumb {
              background-color: $bed-rock;
              border-radius: 3px;
            }

            &::-webkit-scrollbar-track {
              background-color: $black;
              border-radius: 3px;
            }
          }
        }
      }

      .ant-table-body,
      .ant-table-scroll,
      .ant-table-fixed-left,
      .ant-table-fixed-right {
        .ant-table-thead {
          tr {
            th {
              background-color: $gray-2;
              color: $black;

              .ant-checkbox {
                &:hover {
                  .ant-checkbox-inner {
                    border-color: $gray-1 !important;
                  }
                }

                &.ant-checkbox-checked {
                  .ant-checkbox-inner {
                    background-color: $dark-mint !important;
                    border-color: $dark-mint !important;
                  }

                  &:hover {
                    .ant-checkbox-inner {
                      border-color: $dark-mint !important;
                    }
                  }
                }

                &.ant-checkbox-indeterminate {
                  .ant-checkbox-inner {
                    &::after {
                      background-color: $dark-mint;
                    }
                  }
                }

                .ant-checkbox-inner {
                  border-color: $gray-1;
                }
              }

              .ant-table-column-sorter {
                i {
                  &.on {
                    color: $dark-mint;
                  }
                }
              }
            }
          }
        }

        .ant-table-tbody {
          tr {
            td {
              color: $white;

              .ant-checkbox {
                &:hover {
                  .ant-checkbox-inner {
                    border-color: $bed-rock !important;
                  }
                }

                &.ant-checkbox-checked {
                  .ant-checkbox-inner {
                    background-color: $primary !important;
                    border-color: $primary !important;

                    &::after {
                      border-color: $dark-blue-green;
                    }
                  }

                  &:hover {
                    .ant-checkbox-inner {
                      border-color: $primary !important;
                    }
                  }
                }

                .ant-checkbox-inner {
                  border-color: $bed-rock;
                }
              }

              .ant-radio-wrapper {
                .ant-radio {
                  .ant-radio-inner {
                    border-color: $bed-rock;
                  }

                  &:hover {
                    .ant-radio-inner {
                      border-color: $bed-rock;
                    }
                  }

                  &.ant-radio-checked {
                    .ant-radio-inner {
                      border-color: $primary;

                      &::after {
                        background-color: $primary;
                      }
                    }

                    &:hover {
                      .ant-radio-inner {
                        border-color: $primary;
                      }
                    }
                  }
                }
              }
            }

            &:nth-child(even) {
              background-color: $dark-green;
            }

            &:nth-child(odd) {
              background-color: $bed-rock-t2;
            }

            &:hover,
            &.ant-table-row-selected,
            &.ant-table-row-hover {
              td {
                background-color: $dark-blue-green;
              }
            }
          }
        }
      }
    }

    .ant-table-placeholder {
      background-color: $dark-green;

      span {
        color: $white;
      }
    }

    .ant-pagination {
      li {
        a {
          background-color: $dark-green;
          border-color: $dark-green;
          color: $white;
        }

        &.ant-pagination-item-active {
          a {
            background-color: $primary;
            color: $black;
          }
        }

        &.ant-pagination-jump-prev-custom-icon,
        &.ant-pagination-jump-next-custom-icon {
          a {
            i {
              color: $white;
            }

            .ant-pagination-item-ellipsis {
              color: $white;
            }
          }
        }
      }
    }
  }
}
