.dependencies-alert-modal .ant-modal-content {
  background-color: var(--black);
  border-radius: 10px;
}

.dependencies-alert-modal .ant-modal-content .ant-modal-body {
  padding-top: 45px;
}

.dependencies-alert-modal
  .ant-modal-content
  .ant-modal-close
  .ant-modal-close-x
  i
  img {
  width: 14px;
  height: 14px;
}

.dependencies-alert-modal
  .ant-modal-content
  .ant-modal-body
  .dependencies-alert-modal__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dependencies-alert-modal
  .ant-modal-content
  .ant-modal-body
  .dependencies-alert-modal__content
  h6 {
  margin: 20px 0;
  text-align: center;
  color: var(--white);
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
}

.dependencies-alert-modal
  .ant-modal-content
  .ant-modal-body
  .dependencies-alert-modal__content
  button {
  width: 38px;
  height: 33px;
  background-color: var(--primary);
  border: 0;
  color: var(--black);
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  border-radius: 5px;
  text-transform: uppercase;
}
