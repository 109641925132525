.wrappedCalendars {
  height: 100%;
  display: grid;
}

.viewCalendars {
  height: 100% !important;
}

.calendarsHeader {
  padding: 40px;
  margin-bottom: 30px !important;
}

.calendarsHeader .title {
  font-size: 16px;
  color: #2c3421;
}

.calendarsHeader .subtitle {
  font-size: 16px;
}

.calendarsList {
  text-align: center;
  padding: 10px;
  margin-top: 15px;
}

.calendarsList .active {
  outline: 0 !important;
  border: 1px solid var(--dark-mint);
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

.viewCalendars .btnPrimary {
  background: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  color: var(--black);
  height: 32px;
  position: static !important;
  top: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  .calendarIcon {
    padding-right: 5px;
  }
}

.viewCalendars .actions {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.actions > * {
  margin-left: 5px;
  border-radius: 5px;
}

.viewCalendars .empty {
  width: 100%;
  font-size: 20px;
  color: #2c3421;
  text-align: center;
  padding: 40px !important;
}

.wrapp {
  height: 100%;
}

.wrappEdit {
  margin-bottom: 60px;
  box-shadow: 0px 3px 6px 1px rgba(223, 223, 223, 1);
  min-height: 100%;
}

/** edit */
.calendarsEdit {
  padding: 10px;
}

.calendarsEdit .title {
  font-size: 16px;
  color: #2c3421;
  width: 300px;
  display: flex;
}

.calendarsEdit .subtitle {
  font-size: 12px;
}

.spinCalendars {
  height: 80vh !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
