@import '../../../stylesheets/base';
.toolbar-submenu,
.toolbar-submenu-header {
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  white-space: nowrap;
  border-radius: $default-border-radius;
  background-color: $white;
  box-shadow: rgba($gray-100, 0.3) 0 2px 8px 0;
  user-select: none;
}

.toolbar-submenu-header {
  width: 100%;
}

.toolbar-submenu__item,
.toolbar-submenu-header__item {
  width: 100%;
  border-width: 0;
  color: #121212;
  text-align: left;
  line-height: 32px;
  padding: 0 20px;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  > span {
    align-items: center;
    span:not(:has(span)) {
      font: $regular-s-font;
    }
  }
  i {
    display: inline-block;
    margin-right: 10px;
    margin-left: 0px;
    text-align: center;
    // Prevents the submenu position to be miscalculated when it gets expanded
    // for the first time (since icons are only loaded when the submenu is rendered)
    min-width: 16px;
  }
  .toolbar-submenu,
  .toolbar-submenu-header {
    position: absolute;
    display: none;
    top: 0;
  }
  &:hover {
    background-color: $gray-20;
    .toolbar-submenu {
      display: block;
    }
  }
  &[data-subitems='responsable'],
  &[data-subitems='task_name'],
  &[data-subitems='material'],
  &[data-subitems='work'] {
    cursor: default;
    &:hover {
      background-color: transparent;
    }
  }
}

.toolbar-massive-submenu__item {
  @extend .toolbar-submenu__item;
  color: $white;
  &:hover {
    background-color: $primary;
    .toolbar-submenu {
      display: block;
    }
  }
}

.toolbar-submenu__item span,
.toolbar-submenu__item div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  height: 30px;
}

.toolbar-submenu__item--checked {
  background-color: #2c3421;
  color: #ffffff;

  &:hover {
    background-color: $gray-20;
    .toolbar-submenu-header__item-text {
      color: $gray-80;
    }
  }
}

.toolbar-submenu__item-check-circle {
  img {
    width: 14px !important;
  }
}

.toolbar-submenu__item__ab {
  padding: 0 20px;
  & .toolbar-submenu__item__icon {
    margin-right: 7.88px;
    margin-left: 16.8px;
  }
}

.toolbar-submenu-header__item {
  padding: 0 20px;
  > span {
    position: relative;
  }
  &.toolbar-submenu-header__item-title {
    /* Bar colors */
    width: inherit;
    height: 14px;
    font: $medium-s-font;
    line-height: 14px;
    /* identical to box height */
    /* Achromatic Colors/Dark Gray */
    color: #121212;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  &.toolbar-submenu-header__item-line-line {
    /* Vector 189 */
    width: 94px;
    height: 0px;
    /* Achromatic Colors/Lighter Gray */
    border: 1px solid #efefef;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  &:hover {
    background-color: transparent;
    .toolbar-submenu {
      display: block;
    }
  }
}

.toolbar-submenu__item-switch {
  position: absolute;
  right: 20px;
  :global .ant-swich {
    background-color: #d1d1d1 !important;
  }
  :global .ant-switch-checked {
    background-color: #34af00 !important;
  }
}

.toolbar-submenu--right-direction .toolbar-submenu {
  left: 100%;
}

.toolbar-submenu--left-direction .toolbar-submenu {
  right: 100%;
}

.toolbar-submenu-header__item-text {
  font: $regular-s-font;
  color: #121212;
  &.checked {
    color: #ffffff;
  }
}

.toolbar-submenu-header__item__separator {
  padding: 0;
  margin: 5px auto 5px auto;
  width: 148px;
  height: 0px;
  border-top: 1px solid #c4c4c4;
}
