@import '../../stylesheets/base.scss';
@import '../../assets/styles/variables.scss';

.virtual-dynamic-table {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    display: grid;
    grid-template-rows: auto;
    padding: 0 10px 0 6px;
    background-color: #efefef;
    color: #25353a;
    border-radius: 5px 5px 0 0;
    font-size: 12px;
    font-weight: 400;

    .header__content {
      display: flex;
      align-items: center;
      border-right: 0.5px solid #c4c4c4;

      &:last-child {
        border-right: 0;
      }
    }
  }

  &__body {
    background-color: $bed-rock-t2;
    padding: 6px;
    border-radius: 0 0 5px 5px;

    .body__list {
      &::-webkit-scrollbar {
        width: 4px;
        height: 36px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $scroll-color;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-track {
        background-color: $black;
        border-radius: 3px;
      }

      .list__content {
        display: grid;
        grid-template-rows: auto;

        .content__row {
          display: flex;
          align-items: center;
          color: $white;
          font-size: 12px;
          line-height: 14px;
          font-weight: 300;
          padding: 0 5px;
          border-bottom: 1px solid $bed-rock-border;

          &:first-child {
            padding-left: 0;
          }

          &:nth-child(odd) {
            background-color: $bed-rock-t2;

            input {
              background-color: $bed-rock-t2;

              &:focus,
              &:hover {
                background-color: $dark-green;
              }
            }
          }

          &:nth-child(even) {
            background-color: $dark-green;

            input {
              background-color: $dark-green;

              &:focus,
              &:hover {
                background-color: $bed-rock-t2;
              }
            }
          }

          input {
            width: 41px;
            height: 26px;
            border: 0;
            color: $white;
            font-size: 12px;
            line-height: 14px;
            font-weight: 300;
            padding: 0 2px;
            cursor: pointer;
            text-align: center;
          }
        }
      }
    }
  }
}
