@import '../../../../../../../stylesheets/base';

.dependencies-filter-container {
  max-height: 14px;
  display: flex;
  align-items: center;

  span {
    color: $brand-orange-40;
    font-weight: bold;
    margin-right: 10px;
    font-size: 12px;
  }

  .dependencies-filter-switch {
    width: 30px;
    height: 18px;
    min-width: 30px;
    background-color: #d1d1d1;

    &.ant-switch-checked {
      background-color: #34af00 !important;
    }

    &::after {
      width: 14px;
      height: 14px;
    }
  }
}
