@import '../../../assets/styles/variables.scss';

.week-report-container,
.lookahead-report-container {
  height: inherit;
}

.report_container {
  align-items: center;
  display: flex;
  height: inherit;
  justify-content: center;
  width: inherit;

  div {
    background-color: $cool-gray !important;
    color: $black;
    font-size: 13px !important;
    font-weight: 400;
    height: 229px;
    line-height: 15.23px !important;
    padding: 40px 0px 30px 0px;
    width: 320px;
    border-radius: 5px;
  }
}

.report_header {
  display: grid;
  gap: 10px;
  place-items: center;
}

.report_content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;

  button {
    height: 30px !important;
    width: 98px !important;
  }

  .button_download {
    align-items: center;
    background-color: $primary;
    border: 1px solid transparent;
    border-radius: 5px;
    color: $black;
    display: flex;
    height: 30px !important;
    justify-content: center;
    width: 98px !important;
    padding: 8px 20px 8px 20px;
    text-decoration: none !important;

    &:hover {
      background-color: $dark-mint;
    }

    &:disabled {
      cursor: not-allowed;
    }

    .download__spinner {
      background-color: transparent !important;

      svg {
        fill: $black;
      }
    }
  }

  .button_back {
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    color: #53c255 !important;
  }
}

.pdfViewContainer {
  height: inherit;
  width: 100%;

  iframe {
    height: inherit;
    width: inherit;
  }
}
