.cats-settings .btn-tipo,
.cats-settings .btn-tipo:hover,
.cats-settings .btn-tipo:link,
.cats-settings .btn-tipo:visited {
  min-width: 80px;
  width: auto;
  font-size: 12px !important;
  background-color: var(--primary) !important;
  color: var(--black) !important;
  margin-top: 20px;
  border: 1px solid var(--primary) !important;
  border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
}

.cats-settings .wrapp-categories {
  margin-top: 20px;
}

.cats-settings .btns-cats {
  width: 100%;
  text-align: right;
}

.cats-settings .btns-cats-tags {
  text-align: right;
}

.cats-settings .fix-tags {
  margin-top: 11px !important;
}

.cats-settings .fix-cncs {
  margin-top: -33px !important;
}
