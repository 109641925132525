.custom-dropdown-selector .select-search__row {
  height: inherit !important;
}

.custom-dropdown-selector .custom-create-tag-to-select-checkbox {
  font-size: 12px !important;
  width: 59px;
  position: relative;
  left: 20px;
}

.custom-dropdown-selector .select-search {
  max-height: 294px !important;
}

.custom-dropdown-selector .footer-custom-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
  padding: 8px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.custom-dropdown-selector .footer-custom-dropdown button {
  border: 0;
  font-size: 13px;
  color: var(--black);
  font-weight: 500;
  line-height: 15.23px;
  background-color: var(--primary);
  width: 123px;
  height: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.have-footer .ant-popover-inner-content {
  background-color: var(--black) !important;
}
