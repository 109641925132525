@import '../../../../assets/styles/variables.scss';

.text_container_title {
  font: $medium-s-font !important;
}

.text_container_description {
  font: $regular-s-font !important;
}

.text_title_container_place {
  font: $regular-s-font !important;
}

.filter_buton {
  font: $regular-s-font !important;
}
