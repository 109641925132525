@import '../../../assets/styles/variables.scss';

.signupconfirmation__expired-screen {
  width: 440px;
  position: relative;
  z-index: 1;
  background-color: $white;
  padding: 50px 50px 32px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .signupconfirmation__expired-screen__logo {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .signupconfirmation__expired-screen__body {
    display: flex;
    flex-direction: column;

    .body__title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 30px 0;

      h6 {
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
        color: $black;
        margin: 0;
        text-align: left;
      }

      img {
        width: 48px;
        margin-top: 10px;
      }
    }

    .body__button {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;

      button {
        width: 340px;
        height: 40px;
        color: $black;
        background-color: $primary;
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
        border: 0;
        border-radius: 5px;
        transition-duration: $transition-duration-default;
        outline: none;

        &:hover {
          background-color: $dark-mint;
        }

        &:disabled {
          background-color: $gray-2;
          color: $gray-1;
        }
      }
    }
  }
}
