.summaryGrid {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.cell {
  background-color: #efefef;
  color: #121212;
  font-family: 'Roboto', arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
