.filterText {
  display: inline-block;
  margin: 15px 0 0 0;

  color: #2c3421;
  font-size: 12px;
  font-weight: 400;

  cursor: pointer;
}

.filter-icon {
  margin-right: 5px;

  &.filter-icon--reverse {
    display: inline-block;
    transform: rotate(180deg);
  }
}

.filter-type {
  display: grid;
  grid-template: repeat(2, 32px) / [first]127px [second]36px [third]147px[last];
  grid-gap: 10px;

  margin: 15px 0 0 0;

  & .filter-multi-check {
    border-radius: 5px;
    background-color: #f5f5f5;

    &:last-child > div > div {
      right: 0px;
    }

    & .filter-list-item {
      box-shadow: 0px 0px 10px rgb(0 0 0 / 20%) !important;
      width: 200px !important;
      border-radius: 10px !important;
    }

    & > div {
      height: 100%;
      border-radius: 5px;

      & > button {
        width: 100% !important;
        margin: 0 !important;
        padding: 9px !important;
        top: 0 !important;
        height: inherit;

        align-items: center;

        & > div {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        & :global .css-1gpjby2 {
          display: none;
        }
      }

      & :global .css-48ayfv {
        & i {
          transform: rotate(180deg);
        }
      }
    }

    & .text {
      font-size: 12px;
      font-weight: 300;
      color: #121212;
    }

    &:nth-child(1) {
      grid-column: first / last;
    }

    &:nth-child(2) {
      grid-column: first / third;
    }

    &:last-child {
      grid-column: third / last;
    }
  }
}

.filter-multi-check {
  & :global .css-1qprcsu-option {
    background-color: #b2d4ff00 !important;
    color: #121212 !important;
    font-weight: normal !important;
    min-width: 0px !important;
    background-image: url('../../../assets/img/checked.png');
    background-repeat: no-repeat;
    background-size: 10px;
    background-position-x: 13px;
    background-position-y: 13px;
  }

  & :global .css-1vr111p-option {
    background-color: #b2d4ff00 !important;
    color: #121212 !important;
    font-weight: normal !important;
    min-width: 0px !important;
    background-image: url('../../../assets/img/unchecked.png');
    background-repeat: no-repeat;
    background-size: 10px;
    background-position-x: 13px;
    background-position-y: 13px;
  }

  & :global .css-1jllj6i-control,
  & :global .css-6y1x9t-control {
    border-bottom-color: #2c3421 !important;
    box-shadow: none;
    border-radius: 5px !important;
    border-style: solid !important;
    border-width: 0px !important;
    border-bottom-width: 1px !important;
    min-width: 0px !important;
    min-height: 0px !important;
    width: 100%;
    margin: 0;
    margin-bottom: 0px !important;
  }

  & :global .css-1wy0on6 svg {
    color: #2c3421 !important;
    width: 16px !important;
  }

  & :global .css-1qprcsu-option input[type='checkbox'] {
    opacity: 0;
  }

  & :global .css-1vr111p-option input[type='checkbox'] {
    opacity: 0;
  }

  & :global .css-ik6y5r {
    box-shadow: inset 0 0px 0 rgba(0, 0, 0, 0.1) !important;
  }

  & :global .css-11unzgr {
    max-height: inherit !important;
  }

  & :global .css-v1axir {
    max-height: 600px !important;
  }
}
