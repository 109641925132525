/* Colors */

// Brand colors
$brand-blue-80: #155d77;
$brand-blue-40: #2c3421;
$brand-orange-80: #ba5203;
$brand-orange-60: #f5864a;
$brand-orange-40: #7dff8a;
$brand-orange-10: #ffdabd;

// Shades of gray
$gray-100: #333333;
$gray-80: #121212;
$gray-30: #d1d1d1;
$gray-20: #efefef;

// Shades of bluish gray
$bluish-gray-100: #151e21;
$bluish-gray-90: #25353a;
$bluish-gray-80: #314045;
$bluish-gray-70: #3a4f56;
$bluish-gray-60: #42545b;
$bluish-gray-50: #475b61;
$bluish-gray-40: #688a95;
$bluish-gray-30: #b3b3b3;
$bluish-gray-tr: transparent;

// Others
$black: #000000;
$white: #ffffff;
$red: #fa7676;
$green: #34af01;
$yellow: #fdd551;
$green-icon: #53c255;
$overlay-color: rgba($gray-100, 0.4);
$scroll-color: #c4c4c4;

/* Typography */
$default-font-stack: Roboto, arial, sans-serif;

// Font sizes
$font-3xs: 8px;
$font-2xs: 10px;
$font-xs: 12px;
$font-sm: 14px;
$font-base: 16px;
$font-lg: 18px;
$font-xl: 20px;

/* General */
$small-border-radius: 2px;
$default-border-radius: 4px;
$large-border-radius: 10px;
$short-transition-duration: 70ms;
$default-transition-duration: 150ms;
$long-transition-duration: 250ms;

/* UI elements */
$app-toolbar-height: 72px;
$small-user-avatar-width: 15px;

// Makes these vars available to Webpack
:export {
  brandBlue80: $brand-blue-80;
  brandBlue40: $brand-blue-40;
  brandOrange80: $brand-orange-80;
  brandOrange60: $brand-orange-60;
  brandOrange40: $brand-orange-40;
  brandOrange10: $brand-orange-10;
  gray100: $gray-100;
  gray80: $gray-80;
  gray30: $gray-30;
  gray20: $gray-20;
  bluishGray100: $bluish-gray-100;
  bluishGray90: $bluish-gray-90;
  bluishGray80: $bluish-gray-80;
  bluishGray70: $bluish-gray-70;
  bluishGray60: $bluish-gray-60;
  bluishGray50: $bluish-gray-50;
  bluishGray40: $bluish-gray-40;
  bluishGray30: $bluish-gray-30;
  white: $white;
  red: $red;
  green: $green;
  greenIcon: $green-icon;
}
