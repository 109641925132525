.custom-check-box {
  cursor: pointer;
  padding-right: 6px;
  position: relative;
  top: -1px;
}

.custom-check-box.disabled {
  opacity: 0.6;
}
