.gantt-header-filters {
  height: 57px !important;
  padding: 20px;
  z-index: 0;
}

/* Increasing specificity to override Ant's default style */
.ant-row.gantt-header-filters {
  display: flex;
  align-items: center;
}

.gantt-header-filters > :last-child {
  margin-left: auto;
}

.css-1gpjby2 {
  visibility: hidden !important;
}

.css-48ayfv {
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.08) !important;
  top: -6px !important;
  position: relative !important;
  background-color: #ff000000 !important;
}

.css-1r4vtzz {
  /* margin: 8px 0px 5px 8px !important; */
  padding: 0px !important;
  width: 100% !important;
  /* top: -6px !important; */
  position: relative !important;
  background-color: #ff000000 !important;
  align-items: center !important;
}

.vertical {
  border-left: 1px solid red;
  height: 8px;
  position: relative;
  top: -1px;
}

.gantt-header-search-bar {
  margin-left: 10px !important;
}

.css-1vr111p-option {
  text-align: left !important;
}

.css-1qprcsu-option {
  text-align: left !important;
  background-color: none !important;
}

.gantt-filter-header-img {
  width: 12px;
  height: 12px;
  margin-bottom: 3px;
  margin-right: 5px;
}

.svg-icon-grid-view,
.svg-icon-date,
.svg-icon-filter,
.svg-icon-order,
.svg-icon-group,
.svg-icon-columns {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  line-height: 30px;
}

.svg-icon-redo,
.svg-icon-undo,
.svg-icon-critical-path,
.svg-icon-gantt-levels,
.svg-icon-import-gantt,
.svg-icon-hide,
.svg-icon-export-file,
.svg-icon-save_baseline,
.svg-icon-save {
  margin-right: 10px;
  margin-left: 10px;
}

.config-header-icon {
  width: 14px !important;
  position: relative;
  top: -1px;
}

.config-header-title {
  color: #121212;
  padding-bottom: 5px;
}

.config-header-line {
  opacity: 0.5;
  border-top: 0.5px solid #d1d1d1;
  padding-bottom: 10px;
}

.config-header-op {
  padding: 7px;
  cursor: pointer;
}

.disabled {
  cursor: not-allowed !important;
}

.config-header-op:hover {
  background: #efefef;
  border-radius: 3px;
}

.quit-config-header {
  float: right;
  cursor: pointer;
  position: relative;
  top: -1px;
}

.config-header-container {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 17px;
}

.title_gantt_lite {
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #2c3421 !important;
  border-radius: 0px !important;
}

.gantt-filter-left-filter {
  position: absolute;
  top: 47px;
  left: 40px;
  z-index: 100;
}

.gantt-filter-left-order {
  position: absolute;
  top: 47px;
  left: 109px;
  z-index: 100;
}

.gantt-filter-left-dates {
  position: absolute;
  top: 47px;
  left: 74px;
  z-index: 99999;
}

.gantt-filter-left-columns {
  position: absolute;
  top: 0;
  left: 142px;
  z-index: 99999;
}

.gantt-massive-actions {
  padding: 0px;
  justify-content: center;
  background-color: var(--black);
  height: 30px !important;
}

.gantt-massive-actions > :last-child {
  margin-left: inherit !important;
}

.body-center .number-msg {
  font-size: 16px;
  color: var(--primary);
  margin-left: 3px;
  margin-right: 3px;
}
