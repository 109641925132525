@import '../assets/styles/variables.scss';

// This partial inverts the scrollbar colors of an element and its children
%inverted-scrollbar {
  &::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track {
    background: $gray-100;
  }

  &::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb {
    background: $gray-20;
  }

  &::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb:hover {
    background: $brand-orange-40;
  }
}

%inverted-scrollbar--bluish-gray {
  @extend %inverted-scrollbar;

  &::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track {
    background: $bluish-gray-100;
  }
}

// This partial achieves a generic color inversion effect by using the invert filter.
// Initially, it's being used to easily invert icon colors
%inverted-color {
  // Note: !important was needed here, as it's overriding an inline style
  filter: invert(100%) !important;
}

%zebra-striped-table {
  thead {
    background-color: $white;

    th {
      position: relative;
      padding: 6px 20px;
      color: $bluish-gray-80;
      font-size: $font-xs;
      font-weight: 400;

      &:not(:last-child)::after {
        content: '';
        border-right: 1px solid $gray-20;
        height: 10px;
        width: 0;
        display: block;
        position: absolute;
        top: 10px;
        right: 0;
      }

      &:first-child {
        border-top-left-radius: $default-border-radius;
      }

      &:last-child {
        border-top-right-radius: $default-border-radius;
      }
    }
  }

  tbody {
    padding: 0;
    overflow: auto;
    max-height: 400px;

    td {
      color: $white;
      padding: 0 20px;
      height: 30px;
      line-height: 30px;
      white-space: nowrap;
      font-size: $font-xs;
      font-weight: 300;

      &:nth-child(odd) {
        background-color: $bed-rock-t2;
      }

      &:nth-child(even) {
        background-color: $dark-green;
      }
    }

    tr:last-child {
      td:first-child {
        border-bottom-left-radius: $default-border-radius;
      }

      td:last-child {
        border-bottom-right-radius: $default-border-radius;
      }
    }

    tr:not(:last-child) td:nth-child(even) {
      border-bottom: 1px solid $bluish-gray-60;
    }
  }
}
