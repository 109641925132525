.gantt-main-container-btn-style {
  margin-right: 0px !important;
  margin-left: 15px !important;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  padding: 0px 1% 0px 1% !important;
  min-width: 80px;
  height: 24px !important;
  font-family: 'Roboto-light', arial;
  color: var(--black);
}

.gantt-main-container-btn-style:hover {
  color: var(--black);
}

.gantt-main-container-disabled-btn-style {
  background-color: #ffffff00 !important;
  border-color: #ffffff00 !important;
  color: #121212 !important;
  font-weight: 300 !important;
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045) !important;
  font-size: 14px;
}

.gantt-container-header {
  display: flex;
  align-items: center;
  background-color: var(--cool-gray);
  height: 57px;
  padding-right: 50px;
}

.gantt-container-header > :last-child {
  margin-left: auto;
}

.gantt-container-img {
  width: 12px;
  height: 12px;
  margin-bottom: 3px;
  margin-right: 5px;
}
