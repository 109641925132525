.delete-resources {
  color: red !important;
  margin-left: 5px !important;
  margin-top: 3px !important;
}

.resources-field {
  border: 0;
  margin-top: 0;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.machinery-icon {
  position: relative;
}

.material-icon {
  position: relative;
  top: 3px;
}

.no-resources {
  display: flex;
  flex: 1;
  justify-content: center;
}
