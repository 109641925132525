@import '../../../stylesheets/base';
@import '../../../assets/styles/variables.scss';

.footer-right {
  :global .ant-modal-content {
    .ant-modal-footer {
      justify-content: end !important;
    }
  }
}

.without-close {
  :global .ant-modal-content {
    .ant-modal-close-x {
      display: none;
    }
  }
}

.facelifted-modal {
  :global .ant-modal-content {
    background-color: $black;
    border-radius: $large-border-radius;

    .ant-modal-header {
      margin: 0;
      background-color: $black;
      border-top-left-radius: $large-border-radius;
      border-top-right-radius: $large-border-radius;
      border-bottom-width: 0 !important;
      padding: 10px 20px;
    }

    .ant-modal-title {
      color: $white !important;
      font: $medium-m-font;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      i {
        margin-right: 8px;
      }
    }

    .ant-modal-close-x {
      height: auto;
      width: auto;
      line-height: initial;
      margin: 12px 18px;
      transform: scale(1.2, 1.1);

      path {
        fill: $white;
      }
    }
  }

  :global .ant-modal-body {
    padding-bottom: 0 !important;
    background-color: $black;

    * {
      color: $white;
      font: $regular-s-font;
    }

    h3 {
      font: $regular-s-font !important;
    }
  }

  :global .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 24px;
    background-color: $black;

    button {
      cursor: pointer;
      border-width: 0;
      border-radius: $default-border-radius;
      font-weight: 500;
      font: $regular-s-font;
      padding: 5px 15px;
      transition: background-color $short-transition-duration ease-in;
    }

    button:not([data-type='main']) {
      background-color: $white;

      &:hover {
        background-color: $gray-20;
      }
    }

    [data-type='main'] {
      background-color: $primary;
      border-color: $primary;
      color: $black;

      &:hover {
        background-color: $dark-mint;
      }
    }
  }

  :global .ant-radio-group {
    p {
      padding-left: 24px;
      font-weight: 300;
      color: $gray-20;
    }

    .ant-radio-inner {
      border-color: $gray-1;
      background-color: transparent;
      transform: scale(0.9);
    }
  }

  :global .ant-radio-checked .ant-radio-inner {
    border-color: $brand-orange-40 !important;

    &::after {
      background-color: $brand-orange-40;
    }

    &:hover {
      border-color: $brand-orange-80;
    }
  }

  :global .ant-radio-wrapper-disabled {
    opacity: 0.5;
  }

  input,
  textarea {
    border-radius: $default-border-radius;
    display: block;
    background-color: $dark-green;
    padding: 3px 6px;
    height: auto;
    line-height: 18px;
    border-width: 0;
    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $dark-green;
      outline-width: 0;
    }
  }

  label {
    color: $gray-20;
  }
}

.gantt-alert-modal {
  :global .ant-modal-content {
    background-color: $black;
    border-radius: $large-border-radius;

    .ant-modal-header {
      margin: 0;
      background-color: $black;
      border-top-left-radius: $large-border-radius;
      border-top-right-radius: $large-border-radius;
      border-bottom-width: 0 !important;
      padding: 10px 20px;
    }

    .ant-modal-title {
      color: $white !important;
      font-weight: 700;
      i {
        margin-right: 8px;
      }
    }

    .ant-modal-close-x {
      height: 26px !important;
      width: 26px !important;
      line-height: initial;
      margin: 12px 18px;
      transform: scale(0.875, 0.875);

      path {
        fill: $white;
      }
      svg {
        height: 24px;
        width: 24px;
      }
    }

    .icon-center {
      text-align: center;
      margin-bottom: 13px;
      .warning-icon img {
        width: 48px !important;
      }
    }

    .warbning-icon path {
      color: $red !important;
    }

    .warbning-icon svg {
      font-size: 42px !important;
      color: $red !important;
    }

    .body-center {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      margin: 0 20px;
    }

    .message-ref {
      font-size: 16px;
      text-decoration: underline;
    }

    .ant-modal-footer {
      display: flex;
      justify-content: center;
      padding: 24px;
      position: relative;
      top: -6px;
    }

    .ant-modal-footer .ant-btn {
      font: $regular-s-font;
    }

    .ant-btn {
      color: $black;
    }

    .ant-btn:hover {
      color: $black;
    }

    .ant-btn-primary {
      background-color: $primary !important;
      color: $black;
    }

    .ant-btn-primary:hover {
      color: $black;
      background-color: $dark-mint;
    }

    .ant-modal-body {
      padding: 0;
    }
  }

  :global .ant-modal-body {
    padding-bottom: 0 !important;

    * {
      color: $white;
      font-size: $font-xs;
    }
  }

  :global .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 24px;

    button {
      cursor: pointer;
      border-width: 0;
      border-radius: $default-border-radius;
      font-weight: 500;
      padding: 5px 15px;
      transition: background-color $short-transition-duration ease-in;
    }

    button:not([data-type='main']) {
      background-color: $white;

      &:hover {
        background-color: $gray-20;
      }
    }

    [data-type='main'] {
      background-color: $primary;
      border-color: $primary;
      color: $black;

      &:hover {
        background-color: $dark-mint;
      }
    }
  }

  :global .ant-radio-group {
    p {
      padding-left: 24px;
      font-weight: 300;
      color: $gray-20;
    }

    .ant-radio-inner {
      border-color: $gray-80;
      background-color: transparent;
      transform: scale(0.9);
    }
  }

  :global .ant-radio-checked .ant-radio-inner {
    border-color: $primary !important;

    &::after {
      background-color: $dark-mint;
    }

    &:hover {
      border-color: $dark-mint;
    }
  }

  :global .ant-radio-wrapper-disabled {
    opacity: 0.5;
  }

  input,
  textarea {
    border-radius: $default-border-radius;
    display: block;
    background-color: $dark-green;
    padding: 3px 6px;
    height: auto;
    line-height: 18px;
    border-width: 0;

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $dark-green;
      outline-width: 0;
    }
  }

  label {
    color: $white;
  }
}

.saved-baselines-modal__grid-header {
  background-color: $white;
  border-top-left-radius: $default-border-radius;
  border-top-right-radius: $default-border-radius;

  :global .ant-col {
    padding: 6px 20px;
    color: $bluish-gray-80;
    font: $regular-s-font;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:not(:last-child)::after {
      content: '';
      border-right: 1px solid $gray-20;
      height: 10px;
      width: 0;
      display: block;
      position: absolute;
      top: 10px;
      right: 0;
    }
    &:nth-last-child(2) {
      justify-content: center;
    }

    &:nth-last-child(3) {
      justify-content: center;
    }
  }
}

.saved-baselines-modal__grid-content {
  padding: 0;
  overflow: auto;
  max-height: 400px;

  div {
    color: $white;
  }

  :global .ant-col {
    padding: 0 20px;
    height: 38px;
    line-height: 38px;
    white-space: nowrap;
    border-bottom: 1px solid $bed-rock-border;

    &:nth-child(odd) {
      background-color: $dark-green;
    }

    &:nth-child(even) {
      background-color: $bed-rock-t2;
    }
  }

  :global .ant-row:last-child {
    .ant-col:first-child {
      border-bottom-left-radius: $default-border-radius;
    }

    .ant-col:last-child {
      border-bottom-right-radius: $default-border-radius;
    }
  }
}

.saved-baselines-modal__text-cell span {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font: $regular-s-font;
}

.saved-baselines-modal__avatar-cell img {
  border-radius: 50%;
  width: 15px;
}

.saved-baselines-modal__actions-cell {
  align-items: center;

  &:global(.ant-col) {
    display: flex;
  }

  > div {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  > * {
    display: inline-block;
    font-size: 0;
    line-height: 30px;
    padding: 0 8px;
    margin: 0 7px;
  }
}

.new-baseline-modal :global .ant-modal-body {
  display: flex;

  :global(.ant-radio-wrapper) {
    white-space: normal;
  }

  h3 {
    font-weight: 300;
    margin-bottom: 20px;

    strong {
      font-weight: 600;
    }
  }

  label {
    display: block;
  }

  input,
  textarea {
    width: 100%;
    margin: 5px 0 15px;
  }

  textarea {
    min-height: 100px;
  }

  p {
    margin-bottom: 0;
  }
}

.new-baseline-modal :global .ant-modal-footer {
  padding-top: 0;
}

.new-baseline-modal__section {
  flex: 1 1 0;
}

.work-hours-modal {
  &__content {
    label {
      display: flex;
      align-items: center;

      input {
        margin-left: auto;
        padding-left: 5px;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__link {
    font-weight: 400;
    size: 12px;
    line-height: 14.06px;
    font-family: Roboto, sans-serif;
    text-decoration-line: underline;
    color: $brand-orange-40 !important;
  }
}

.confirm-modal {
  :global .ant-modal-content {
    height: auto !important;
    background-color: $black;
    padding-top: 40px !important;
    width: 490px !important;

    .ant-modal-body {
      * {
        font-size: $font-base !important;
      }
    }

    .ant-modal-close-x {
      svg {
        height: 14px;
        width: 14px;
      }
    }
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
