.frm-importer .title-frm {
  font-size: 15px;
  color: #2c3421;
  font-weight: bold;
  line-height: 18px;
  text-align: left;
  margin-top: 10px;
  border-bottom: 0.3px solid;
  padding-bottom: 15px;
}

.frm-importer .hand-close {
  float: right;
  color: #c4c4c4;
  margin-right: 3px;
  margin-top: -7%;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.frm-importer .row-normal {
  text-align: center;
  color: #121212;
  width: 90%;
}

.frm-importer .col-normal {
  height: 100%;
}

.frm-importer .text-title {
  display: inline-block;
  margin: 20px;
  color: #2c3421;
}
.frm-importer .img-upload {
  width: 23px;
  margin: auto 10px;
}

.frm-importer .div-vertical {
  height: 100%;
  align-self: auto;
  display: flex;
  line-height: 30px;
  margin: 4px;
}

.frm-importer .btn-import {
  height: auto;
  margin: 0 10px;
  border: 1px solid #2c3421;
}

.frm-importer .body-modal {
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.frm-importer .hidden {
  display: none;
}

.frm-importer .text-remaining {
  font-size: 18px;
  margin-bottom: 16px;
}

.frm-importer .text-filename {
  text-align: left;
  color: #2c3421;
}

.frm-importer .text-filename img {
  margin: 0 10px 0px 0px;
}

.frm-importer .btn-cancel,
.frm-importer .btn-cancel:hover {
  color: #e50101;
  border: 1px solid #e50101;
}

.frm-importer .div-progress {
  margin-bottom: 10px;
}

.frm-importer .text-success {
  color: #2c3421;
  font-size: 20px;
  padding: 14px 30%;
}

.frm-importer .circle-ok {
  font-size: 22px;
  color: green;
}

.frm-importer .btn-continue,
.frm-importer .btn-continue:hover,
.frm-importer .btn-continue:active,
.frm-importer .btn-continue:focus {
  background: var(--primary);
  color: var(--black);
  border: 1px solid var(--primary);
}

.frm-importer .div-circle {
  margin-bottom: 16px;
}

.frm-importer .ant-upload-picture-card-wrapper {
  width: auto;
  margin: 0 10px;
}
.frm-importer .ant-upload {
  height: auto !important;
}
