.cells {
  width: 100%;
  font-size: 10px;
}

.hourstotal {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;

  border: 0.5px solid gray;
  color: #ffffff;
  height: 100%;
  width: 100%;

  background-color: #151e21;
  text-align: center;
  font-size: 12px;
  font-weight: normal;
}
