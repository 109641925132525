@import '../../../stylesheets/base';
@import '../../../assets/styles/variables.scss';

.requests-accordion {
  &__item {
    color: $white;
    background-color: $black;
    border-radius: $default-border-radius;
    margin-bottom: 15px;
    border: 0.5px solid $dark-green;
  }

  &__header {
    background-color: $dark-green;
    border-radius: $default-border-radius;
    padding: 15px;
  }

  &__content {
    padding: 15px;
    button {
      border-width: 0;
      cursor: pointer;
    }
  }

  &__header-top-row {
    display: flex;
    font-size: $font-base;
    margin-bottom: 5px;
  }

  &__id {
    border-right: 1px solid $bluish-gray-40;
    padding-right: 5px;
    margin-right: 5px;
  }

  &__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 10px;
  }

  &__status-icon {
    margin-left: auto;

    img {
      width: 20px;
      height: 20px;
    }
  }

  &__header-bottom-row {
    display: flex;
    font-size: $font-xs;
    align-items: center;
  }

  &__author-thumb {
    width: $small-user-avatar-width;
    height: $small-user-avatar-width;
    border-radius: 50%;
    margin-right: 10px;
  }

  &__author-no-img {
    width: $small-user-avatar-width;
    height: $small-user-avatar-width;
    border-radius: 50%;
    margin-right: 10px;

    background-color: #ffffff;
    color: #121212cf;

    font-size: 8px;
    font-weight: 500;

    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &__elapsed-time {
    color: $light-red;
    margin-left: 5px;
  }

  &__chevron-icon {
    margin-left: auto;
    text-align: center;
    width: 20px;

    img {
      width: 20px;
    }

    &--inverted {
      transform: rotate(180deg);
    }
  }

  &__content-top-row {
    display: flex;
    justify-content: space-between;
  }

  &__modifications {
    display: flex;
    flex-direction: column;

    > * {
      flex: 1 1 0;
    }
  }

  &__modification-name {
    margin-bottom: 2px;
  }

  &__original-value {
    font-size: $font-2xs;
    opacity: 0.8;
  }

  &__new-value {
    color: $yellow;
  }

  &__description {
    position: relative;
    margin: 10px 0;
  }

  &__description-textarea {
    background-color: $dark-green;
    width: 100%;
    height: 100px;
    border-radius: $default-border-radius;
    border-width: 0;
    outline-width: 0;
    resize: none;
    padding: 15px;
  }

  &__buttons {
    display: flex;

    button {
      font-size: $font-sm;
      padding: 5px 15px;
      border-radius: $default-border-radius;
      transition:
        background-color $short-transition-duration ease-in,
        box-shadow $short-transition-duration ease-in;

      i {
        margin-right: 5px;
      }

      &:disabled {
        cursor: not-allowed;
      }

      &:active {
        color: $black;
      }

      &:not(:disabled) {
        &:not(:active):hover {
          color: $black;
        }
      }
    }
  }

  &__approve-button:disabled,
  &__preview-button,
  &__reject-button {
    color: $black;
  }

  &__preview-button--activated {
    color: $black;
    background-color: $dark-green;
  }

  &__reject-button {
    margin: 0 15px 0 auto;
  }

  &__approve-button:not(:disabled) {
    background-color: $primary;
    color: $black;
  }
}
