@import '../../../../../../../stylesheets/variables.scss';

.anticon-right-circle {
  margin-right: 6px;
  font-size: 15px;
  stroke-width: 8px;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}
