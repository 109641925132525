@import '../../../stylesheets/base';
@import '../../../assets/styles/variables.scss';

.filter-buttons {
  width: 100%;

  li {
    display: flex;
    width: 100%;
  }

  button {
    flex: 1 1 0;
    padding: 0;
    color: $black;
    background-color: $cool-gray;
    font-size: $font-xs;
    outline-width: 0;

    &:hover:not(:disabled) {
      background-color: $l-gray-4;
    }

    &[class*='--cta'] {
      color: $black;
      background-color: $primary;

      &:hover:not(:disabled) {
        background-color: $dark-mint;
      }
    }
  }

  img {
    width: 18px;
  }
}
