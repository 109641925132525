%options_general {
  font-family: 'Roboto', arial, sans-serif;
  text-align: center;
  font-weight: 300;
  line-height: 12px;
  cursor: pointer;
}
.modalcontent {
  max-height: 500px;
  overflow: scroll;
  margin-top: 10px;
}
.options {
  &__clear {
    @extend %options_general;
    color: #121212;
    &:hover {
      color: #383838;
    }
  }
  &__all {
    @extend %options_general;
    color: #2c3421;
    margin-left: 10px;
    &:hover {
      color: #229cee;
    }
  }
}
