@import '../../assets/styles/variables.scss';

.forgot__title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;

  h3 {
    font: $medium-l-font;
    line-height: 23px;
    color: $black;
  }
}

.forgot__notification {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}

.forgot__form {
  display: flex;
  flex-direction: column;

  .form__input {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    label {
      font: $regular-m-font;
      line-height: 18px;
      color: $black;
      margin-bottom: 10px;
    }

    input {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      background-color: $cool-gray;
      box-sizing: border-box;
      color: $black;
      border: 1px solid $cool-gray;
      transition-duration: $transition-duration-default;

      &:focus {
        border: 1px solid $dark-mint;
      }

      &.error {
        border: 1px solid $red;
        background-color: $lighter-red;
      }
    }

    small {
      margin-top: 4px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
      color: $red;
    }

    button {
      width: 100%;
      height: 40px;
      color: $black;
      background-color: $primary;
      font: $regular-m-font;
      line-height: 18px;
      border: 0;
      border-radius: 5px;
      transition-duration: $transition-duration-default;

      &:hover {
        background-color: $dark-mint;
      }

      &:disabled {
        background-color: $gray-2;
        color: $gray-1;
      }
    }
  }

  .form__login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    a {
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      color: $dark-mint;
    }
  }
}

.forgot__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;

  span {
    font: $regular-m-font;
    line-height: 18px;
    color: $black;
    margin-top: 20px;

    b {
      font-weight: 700;
    }
  }
}

.forgot__login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  a {
    font: $regular-m-font;
    line-height: 18px;
    color: $dark-mint;
  }
}
