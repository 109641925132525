@import '../../assets/styles/variables.scss';

.CompanyPopover__content {
  width: 270px;
  min-height: 80px;
  border-radius: 5px;
  padding: 10px 0px;

  .CompanyPopover {
    &__currentCompany {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 10px 20px;

      font: $medium-xs-font;
      color: $cool-gray;

      &__line {
        width: 100%;
        height: 1px;
        margin: 0px 0;
        background-color: $separation-line;
        opacity: $disabled-opacity;
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 20px;
      color: white;

      &__company {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        overflow: hidden;
        width: 100%;

        img {
          height: 21.5px;
          width: 21.5px;
          border-radius: 50%;
          object-fit: contain;
          background: $white;
        }
        span {
          color: $gray-2;
          font: $medium-s-font;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 80%;
        }
      }

      &__buttonChange {
        cursor: pointer;
        color: $primary;
        font: $regular-s-font;
      }
    }
  }
}
