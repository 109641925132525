.crop_image_modal .img-to-crop {
  height: 100%;
  /* width: 100%; */
  max-height: 600px !important;
  margin: auto !important;
}

.crop_image_modal .column-wrap-image-prev {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  /* width: 100%; */
  height: 100%;
  background-color: gray;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.crop_image_modal .grid-crop .column-title {
  color: #ffffff;
}

.crop_image_modal .grid-crop .ant-select-selection__rendered {
  background-color: #3a4f56;
  color: #d1d1d1;
  font-weight: 300;
  border-radius: 5px;
  height: 32px;
  margin: 0;
  padding: 0 24px 0 11px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.crop_image_modal
  .grid-crop
  .ant-select-selection--single.ant-select-selection {
  background-color: #3a4f56 !important;
}

.crop_image_modal .close-add-user {
  width: 100%;
  text-align: center;
  padding: 7px;
  font-size: 16px;
  padding: 8px;
  color: #f59d04;
}

.crop_image_modal .close-add-user span {
  cursor: pointer;
}

.crop_image_modal .ant-modal-header {
  background-color: #151e21;
  border-bottom: none;
}

.crop_image_modal .ant-modal-title {
  color: #fff;
}

.crop_image_modal .ant-modal-close-x {
  color: var(--primary);
}

.crop_image_modal .ant-modal-body,
.crop_image_modal .ant-modal-footer {
  background-color: #25353a;
}

.canvas-html {
  display: none;
}

.crop_image_modal .footer-edit-image {
  padding: 0 4px 0 5px;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-row-gap: 10px;
}

.crop_image_modal .first-button {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.crop_image_modal .second-button {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: 10px 5px 10px 2px !important;
}
