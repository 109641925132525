@import '../../../../assets/styles/variables.scss';

.txt-profile-size {
  font: $regular-s-font;
}

.txt-profile-size-user {
  font: $medium-m-font;
}

.txt-profile-size-role {
  font: $regular-s-font;
}

.setting-project-version {
  font: $light-s-font;
}
