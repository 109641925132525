@import '../../../assets/styles/variables.scss';

.set-current__content {
  display: flex;
  flex-direction: column;

  .content__form {
    display: flex;
    flex-direction: column;

    .form__icon {
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        img {
          width: 48px;
        }
      }
    }

    .form__title {
      margin: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      h5 {
        margin: 0;
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
        color: $white;
      }
    }
  }

  .content__buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;

    button {
      height: 32px;
      border: 0;
      border-radius: 5px;
      padding: 0 10px;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      transition: 0.3s;

      &.buttons__cancel {
        background-color: $cool-gray;
        color: $black;
      }

      &.buttons__confirm {
        background-color: $primary;
        color: $black;
      }
    }
  }
}
