.full-width-style {
  width: 100% !important;
  padding: 10px;
}

.delete-filter-style {
  cursor: pointer;
  color: #ee893c !important;
}

.single-filter-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.custom-search-bar-prop {
  width: 94% !important;
}

.custom-search-bar-prop.ant-input-number {
  width: 100% !important;
}

.counter-selected-filters {
  position: absolute;
  top: -60%;
  left: 88%;
  color: var(--black);
  background: var(--primary);
  border-radius: 33px;
  width: 18px;
  height: 18px;
}

.counter-selected-filters-gantt {
  position: absolute;
  top: -66%;
  left: 92%;
  color: var(--black);
  background: var(--primary);
  border-radius: 33px;
  width: 18px;
  height: 18px;
}
