/*.constraint-label-calendar {
  display: inline-block;
  min-width: 80px;
  color: #121212;
  text-transform: capitalize;
  font-size: 13px;
  margin-right: 40px;
  margin-top: 15%;
}

.constraint-calendar-navigation-links {
  font-size: 10px;
  color: #F18640 !important;
}

.constraint-navigation-links {
  font-size: 10px;
  color: #F18640 !important;
}

.first-month,
.last-month {
  display: inline-block;
  cursor: pointer;
}

.first-month {
  margin-right: 50px;
}

.last-month {
  margin-left: 10px;
}

.first-month .anticon:first-child {
  position: relative !important;
  left: 10px !important;
}

.last-month .anticon:last-child {
  position: relative !important;
  right: 10px !important;
}

.wrapp-calendar .ant-fullcalendar-selected-day .item-calendar {
  color: white;
  background: #C4C4C4 !important;
}

.ant-fullcalendar-column-header-inner {
  font-size: 10px;
  display: block;
}*/

.header-calendar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-calendar .calendar__left-arrow,
.header-calendar .calendar__right-arrow {
  cursor: pointer;
}

.header-calendar .calendar__date span {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: var(--white);
}
