@import '../../assets/styles/variables.scss';

.toolbar-company {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  z-index: 999;
  padding: 7.5px 28px;
  background-color: $white;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);

  .company__container {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .company__name {
      display: flex;
      align-items: center;
      gap: 20px;

      h2 {
        font: $medium-xl-font;
        line-height: 28px;
        color: $black;
        margin: 0;
      }

      span {
        background-color: $cool-gray;
        font: $regular-s-font;
        padding: 5px 10px;
        border-radius: 5px;
        cursor: pointer;
      }
    }

    .company__info {
      display: flex;
      gap: 20px;

      .info__detail {
        display: flex;
        align-items: center;
        gap: 5px;

        i {
          img {
            width: 16px;
          }
        }

        span {
          font: $regular-s-font;
          line-height: 18px;
          font-weight: 400;
          color: $black;
        }
      }
    }
  }
}
