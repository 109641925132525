@import '../../../../assets/styles/variables.scss';

.company-cell {
  display: flex;
  justify-content: center;
  padding-left: 0;
  span {
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
    text-align: center;
  }
}
