@import '../../../../../assets/styles/variables.scss';

.toolbar-right {
  display: flex;
  gap: 5px;

  .toolbar-right__button {
    width: 32px;
    height: 32px;
    background-color: $gray-2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;

    .button__icon {
      i {
        img {
          width: 16px;
        }
      }
    }
  }

  .toolbar-right__dropdown {
    width: 121px;
  }
}
