.header-gantt-style {
  margin-top: 15px;
  margin-bottom: 6px;
}

.loader-gantt-style {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100vh !important;
}

.gantt_grid_scale {
  z-index: 100;
  box-shadow: 0px 4px 17px 1px rgba(82, 82, 82, 0.32);
  position: relative;
  border-bottom: 0px solid #cecece !important;
}

.gantt_grid_column_resize_wrap .gantt_grid_column_resize {
  background-color: #d1d1d1;
  height: 70%;
  top: 3px;
  width: 1px;
  margin: 0 auto;
  position: relative;
}

.gantt_grid_scale,
.gantt_task_scale {
  box-shadow: -5px 4px 17px 1px rgba(82, 82, 82, 0.32);
  position: relative;
  z-index: 0;
}

.gantt_task .gantt_task_scale .gantt_scale_cell {
  border-right: 0px solid #ffffff !important;
  color: #121212 !important;
  border: 1px solid #cececf;
  border-top: none;
}

.gantt_task_bg .gantt_task_row {
  border-bottom: 0px solid white !important;
}

.gantt_grid_scale .gantt_grid_head_cell {
  color: #121212 !important;
  font-size: 12px;
  font-weight: 600;
}

.gantt_grid_scale,
.gantt_task_scale {
  border-bottom: 0px solid #cecece !important;
}

.gantt_scale_line {
  border-top-width: 0px !important;
}

.gantt_layout_cell_border_right {
  border-right: 0px solid #cecece !important;
}

.gantt_layout_cell_border_right.gantt_resizer {
  width: 1.8px !important;
  background: #00000029 !important;
  margin-top: 35px !important;
}

.gantt_btn_set:focus,
.gantt_cell:focus,
.gantt_grid_head_cell:focus,
.gantt_popup_button:focus,
.gantt_qi_big_icon:focus,
.gantt_row:focus {
  box-shadow: inset 0 0 1px 1px #20202047 !important;
}

.gantt_side_content {
  top: 10px !important;
}

.task-status {
  color: #fff;
}

.task-status.red {
  color: #f00;
}
.task-status.fa-check {
  color: #008000;
}

.task-status.blue {
  color: #00f;
}

.task-status.yellow {
  color: yellow;
}

.css-1r4vtzz {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0) !important;
  color: #1890ff !important;
}

/** Styles for custom dropdown inside chart**/

.over-top-dropdown {
  position: absolute;
  text-align: center;
  top: -27px;
  left: 0px;
  width: 43px;
  height: 48px;
  background: #3a3a3a;
  color: white;
  border-radius: 10px;
  z-index: 2;
}

.dates-activity-modification-gantt {
  font-size: 11px;
  margin-left: 10px;
}

.gantt_tree_content {
  width: 100% !important;
}
.gantt-dropdown {
  position: relative;
  float: right;
  cursor: pointer;
}

.cut-text-and-dot {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#gantt_dropdown {
  position: absolute;
  display: none;
  background: #3a3a3a;
  color: white;
  border-radius: 10px;
  padding: 10px;
}

.fade-in {
  animation: animationInEffect ease 0.3s;
  -webkit-animation: animationInEffect ease 0.3s;
  -moz-animation: animationInEffect ease 0.3s;
  -o-animation: animationInEffect ease 0.3s;
  -ms-animation: animationInEffect ease 0.3s;
}

@keyframes animationInEffect {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes animationInEffect {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes animationInEffect {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes animationInEffect {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes animationInEffect {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.float-rigth {
  float: right;
  position: relative;
  top: 7px;
  left: -15px;
}

.custom-date-baseline {
  padding: 5px 3% 5px 7px !important;
}

.gantt-header-search-bar .ant-calendar-picker-icon {
  right: 4px !important;
}

.title_ligth_modal {
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  position: relative;
  left: 20px;
  color: #2c3421;
}

.content_ligth_modal {
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  top: 17px;
  position: relative;

  color: #121212;
}

.button_active_ligth_version {
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  align-items: center;
  margin-top: 50px;
  width: 210px;
  height: 41px !important;
  color: var(--black) !important;
  background: var(--primary) !important;
  margin-left: auto;
  margin-right: auto;
  display: block !important;
}

.button_active_version {
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  align-items: center;
  margin-top: 50px;
  width: 210px;
  height: 41px !important;
  color: white !important;
  background: #2c3421 !important;
  margin-left: auto;
  margin-right: auto;
  display: block !important;
}

.gantt-loader-fancy {
  top: -58px !important;
  left: 0px !important;
}

.addapt-to-gantt {
  flex-direction: column;
}

/** activity delete modal */
.delete-text-gantt {
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  line-height: 18.75px !important;
  color: white !important;
  margin-bottom: 20px;
}

.delete-text-gantt div,
.delete-text-gantt span {
  font-weight: 500 !important;
  font-size: 16px !important;
  color: white !important;
  width: 100%;
}

.delete-modal-gantt .ant-modal-body {
  text-align: center !important;
}

.delete-modal-gantt .ant-modal-footer {
  justify-content: flex-end !important;
  padding: 8px 24px !important;
  top: -10px !important;
}

.wrapper {
  list-style-type: none;
  padding: 0;
  border-radius: 3px;
}
.form-row {
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
  padding-right: 25px;
}

.form-row > label {
  padding: 2px 2px 2px 20px;
  flex: 1;
  text-align: left;
}

.form-row > input,
.form-row > textarea {
  flex: 3;
}

.form-row > input,
.form-row > button {
  padding: 0.5em;
}

.frmdeletemodal {
  position: relative;
  top: -4px;
}

.delete-modal-gantt .ant-modal-close-x svg {
  height: 17px !important;
  width: 17px !important;
}

.delete-modal-gantt textarea {
  padding: 10px !important;
  height: 64px !important;
}

.spin-save {
  position: fixed !important;
  z-index: 1015542323 !important;
  margin-left: auto;
  margin-right: auto;
  width: 150px !important;
  left: 45% !important;
  height: 59px !important;
  /* margin-top: -50px !important; */
  top: 10px !important;
  background: white;
  border-radius: 5px;
  padding: 5px;
  color: black;
}
