@import '../../assets/styles/variables.scss';

.modal--export--pdf {
  width: 100%;
  display: flex;

  .modal--export--pdf__tabs {
    width: 100%;
    height: 30px;
    background-color: $dark-green-sec;
    display: flex;
    position: absolute;
    left: 0;
    padding: 0 20px;

    .tabs__tab {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      line-height: 14px;
      color: $gray;
      cursor: pointer;
      transition-duration: 0.3s;
      font: $regular-s-font;

      &.active {
        background-color: $black;
        border-top: 3px solid $primary;

        &:hover {
          color: $gray;
        }
      }

      &:hover {
        color: $bed-rock;
      }
    }
  }

  .modal--export--pdf__content {
    width: 100%;
    padding-top: 30px;

    .content__footer {
      display: flex;
      justify-content: flex-end;
      gap: 10px;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        .tab--page {
          width: 100%;
          height: 160px;
          display: flex;
          flex-direction: column;
          margin: 20px 0;
          gap: 10px;

          .tab--page__title {
            display: flex;

            h5 {
              line-height: 14px;
              font: $medium-s-font;
              color: $white;
              margin: 0;
            }
          }

          .tab--page__format {
            display: flex;
            justify-content: space-between;
            align-items: center;

            label {
              font: $regular-s-font;
              line-height: 14px;
              color: $white;
            }

            .format__dropdown {
              width: 160px;
            }
          }

          .tab--page__scaling {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 15px 20px;
            border-radius: 5px;
            border: 1px solid $bed-rock;

            label {
              line-height: 14px;
              font: $medium-s-font;
              color: $white;
            }
          }

          .tab--page__trim {
            display: flex;
          }
        }
        height: 32px;
        border: 0;
        padding: 0 20px;
        border-radius: 5px;
        line-height: 14px;
        font: $regular-s-font;
        color: $black;
        transition-duration: 0.3s;

        &.footer__cancel {
          background-color: $cool-gray;

          &:hover {
            background-color: $gray;
          }
        }

        &.footer__download {
          background-color: $primary;

          &:hover {
            background-color: $dark-mint;
          }

          &:disabled {
            cursor: not-allowed;
          }

          .download__spinner {
            display: flex;

            i {
              svg {
                fill: $black;
              }
            }
          }
        }
      }
    }
  }
}
