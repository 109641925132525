.assign-takt-btn {
  position: relative;
  float: right;
  font-size: 16px;
  border-color: #ff000000;
  background: var(--primary);
  border-radius: 5px;
  color: var(--black);
  cursor: pointer;
  outline: none;
  width: 150px !important;
}

.assign-takt-btn:disabled {
  background-color: transparent;
  color: #c4c4c4;
  border: 1px solid #c4c4c4;
}

.assign-takt-btn.unassign {
  background-color: #e54646;
}

.assign-takt-btn.assign {
  background-color: #69bf45;
}

.container-takt-options-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px;
}

.assign-subtitle {
  color: #545454;
  font-size: 14px;
  margin-right: 10px;
}

.assign-subtitle-optional {
  color: #545454;
  font-size: 14px;
  margin-right: 10px;
  font-style: italic;
}
.options-groups {
  display: grid;
  grid-template-columns: 120px 100px 150px;
  grid-column-gap: 2rem;
}

.levels-takt-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  outline: none;
  width: 100px;
  user-select: none;
}

.levels-takt-btn.group {
  width: 120px;
}

.title-levels-takt {
  color: #2c3421;
  margin-top: 5px;
  margin-bottom: 5px;
}

.line-block-levels {
  border-top: 1px solid #2c3421;
  margin-bottom: 10px;
}

.levels-dropdown-style .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.levels-dropdown-style .ant-popover-inner {
  border-radius: 10px !important;
}

.apply-visualization-style {
  margin-top: 14px;
  padding-left: 16px;
  padding-right: 16px;
}

/**
 * ===========
 * Toast Notify
 * ===========
 * */
.toast {
  position: fixed;
  top: 114px;
  right: 16px;

  max-width: 432px;
  padding: 6px 100px 10px 26px;
  background-color: #fff;
  color: #595959;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  transform: translateX(500px);
  transition: transform 0.2s;
}

.toast.toast--visible {
  transform: translateX(0px);
}

.toast.toast--assigned {
  background-color: #d6efcb;
}
.toast.toast--unassigned {
  background-color: #fbdddd;
}

.toast .toast__message {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.toast .toast__message > .count-tasks {
  color: var(--primary);
  font-weight: 700;
}
