.loaded-container {
  position: absolute;
  left: 75px;
  right: 0;
  bottom: 0;
  top: 132px;
  cursor: not-allowed;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #1890ff;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
}
@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
