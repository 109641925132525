.container {
  display: flex;
  justify-content: center;
  height: 57px;

  position: absolute;
  top: -57px;
  left: 0;
  right: 0;
}

.box {
  width: inherit;
  padding: 0 19px;
  display: grid;
  grid-template-rows: 13px 42px;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 5px;

  background-color: #fff;
  //box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

/**==== content ====*/
.realWeek {
  color: #34af00;
}

.expected {
  color: #121212;
}

.planning {
  color: #2c3421;
}

.committed {
  color: #f59d04;
}

.ppc {
  color: #38758b;
}

.realWeek,
.expected,
.planning,
.committed,
.ppc {
  align-self: start;
}

.realWeek span,
.expected span,
.planning span,
.committed span,
.ppc span {
  font-size: 10px;
  font-weight: 400;
}

.realWeek p,
.expected p,
.planning p,
.committed p,
.ppc p {
  font-size: 14px;
  font-weight: 700;
}

.realWeek,
.expected,
.planning,
.committed,
.ppc {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}

/*
 * ============
 *  Options
 * ============
 * */
.options {
  grid-column: 1 / -1;
  justify-self: center;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
}

.hideOptions {
  background-color: #2c3421;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 35px;
  height: 8px;
  cursor: pointer;
}

.showOptions {
  width: 222px;
}

.ellipse {
  content: '';
  background-color: #fff;
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 100%;
}

.ellipse:nth-child(2) {
  margin: 0 2px;
}

/**
 * ===================
 *  Selection Options
 * ===================
 * */
.selection {
  height: 100%;
  width: 120px;
  padding: 0 10px;
  background-color: #2c3421;
  color: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selection span {
  font-size: 10px;
}

.selectionRigth {
  border-bottom-right-radius: 5px;
  border-left: 1px solid #fff;
}

.selectionLeft {
  border-bottom-left-radius: 5px;
  border-right: 1px solid #fff;
}

.hideOptionsBtn {
  padding: 1px;
  width: 11px;
  height: 11px;
  background-color: #c4c4c4;
  border-radius: 100%;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  justify-content: center;

  position: absolute;
  right: 105px;
  top: 6px;
}

.hideOptionsBtn img {
  width: 7px;
}

.subtitle {
  font-size: 12px;
  font-weight: 500;
  color: #121212;
}

.hr {
  color: #909090;
  width: 90%;
  margin: auto;
}

.list {
  font-size: 12px;
}

.list,
.popoverContent {
  padding: 0 8px;
  margin: 2.5px 0;
}

.list:hover {
  background-color: #d6eeff;
}

.cursorPointer {
  cursor: pointer;
}
