.units-activity {
  font-size: 15px;
  color: #595959;
  padding-left: 10px;
}

.units-activity.assigned {
  font-style: italic;
}

.units-activity.assigned::after {
  content: 'ℹ';
  position: absolute;
  right: 15px;
  cursor: pointer;
}

.unitTotalActivities {
  color: #1890ff;
  font-weight: 500;
}

.unitTotalActivities:lang(es)::after {
  content: attr(data-totalActivities);
  font-weight: normal;
}

.unitTotalActivities:lang(en)::before {
  content: attr(data-totalActivities);
  padding-right: 5px;
  font-weight: normal;
}
