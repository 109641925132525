@import '../../stylesheets/base';

.accordion {
  &__item {
    &-header {
      cursor: pointer;
      user-select: none;
    }

    &-content {
      transition: opacity $long-transition-duration ease-in;
      pointer-events: none;
      height: 0;
      overflow: hidden;
      opacity: 0;
    }

    &--expanded &-content {
      pointer-events: all;
      height: auto;
      overflow: visible;
      opacity: 1;
    }
  }
}
