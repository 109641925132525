.txt-name {
  display: block !important;
  box-sizing: border-box !important;
  width: 270px !important;
  border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -webkit-border-radius: 0;
  border: 1px solid white !important;
  padding: 10px 0 !important;
  margin: auto !important;
  margin-bottom: 2px !important;
  font-size: 18px !important;
  top: 1px !important;
  border-bottom: 1px solid #2c3421 !important;
  text-align: center;
}

.frmCol {
  text-align: center;
}

.frmCalendar .frm {
  margin: 25px !important;
  display: flex !important;
  min-height: 151px;
  align-items: center;
  height: 70%;
}

.frmCalendar .btnSubmit,
.frmCalendar .ant-btn:hover,
.frmCalendar .ant-btn:focus {
  height: auto;
  margin-top: 0 !important;
  background-color: var(--primary);
  color: var(--black);
  font-size: 20px;
  width: 160px !important;
  border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  border: transparent;
  padding: 2px !important;
  cursor: pointer;
}

.frmCalendar .btnSubmit:hover {
  background-color: var(--primary) !important;
  color: var(--black) !important;
}

.frmCalendar .buttons {
  margin-top: 50px;
}

.footerBtns {
  text-align: center;
}

.frmCalendar .ant-modal-footer {
  padding: 0 20px 30px;
}

.frmCalendar .ant-modal-body {
  padding: 24px 24px 0 !important;
}

.frmCalendar .lblError {
  color: red;
}

.frmCalendar .title-frm-add {
  font-size: 18px;
  font-weight: bold;
  color: #2c3421;
  border-bottom: 1px solid #2c3421;
  padding-bottom: 10px;
}

.frmCalendar .title-close {
  color: #d1d1d1;
  float: right;
  cursor: pointer;
  font-size: 16px;
}

.frmCalendar .back-arrow {
  display: inline;
  cursor: pointer;
}
