.TooltipHTML {
  position: relative;
  display: inline-block;
}
.TooltipHTML .TooltipHTMLElement:hover {
  visibility: initial;
}

.TooltipHTML .TooltipHTMLtext {
  min-width: 30px;
  min-height: 32px;
  max-width: 250px;
  color: #fff;
  text-decoration: none;
  word-wrap: break-word;
  background-color: #000000bf;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px #00000026;
  box-shadow: 0 2px 8px #00000026;
  visibility: hidden;
  text-align: center;
  position: fixed;
  bottom: 100%;
  transform: translate(0%, -100%);
  opacity: 1;
  transition: opacity 0.3s;
  z-index: 9999;
  font-weight: 400;
  padding: 5px 3px;
  white-space: break-spaces;
  height: auto;
  line-height: 20px;
  height: fit-content;
}

.TooltipHTML .TooltipHTMLtext p {
  margin: 0;
  padding: 0 5px;
  font-size: 14px;
}

.TooltipHTML .TooltipHTMLtext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000000bf transparent transparent transparent;
}

.TooltipHTML:hover .TooltipHTMLtext {
  visibility: visible;
  opacity: 1;
}

.gantt_grid .gantt_grid_scale {
  z-index: 0 !important;
}
