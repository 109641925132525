.ant-upload,
.ant-upload-select-picture-card {
  display: block !important;
  float: none !important;
  max-width: 190px !important;
  width: auto !important;
  margin: auto !important;
  height: 190px !important;
  background-color: white !important;
  border: none !important;
}
