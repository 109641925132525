.structure-title {
  color: #2c3421;
  margin-top: 20px;
  font-size: 18px;
  margin-left: -3%;
}

.btn-create-structure {
  cursor: pointer;
  font-size: 13px;
  margin-top: 22px;
  background-color: #2c3421;
  border-radius: 5px;
  color: white;
  height: 24px;
  padding-top: 3px;
  text-align: center;
  margin-right: 6%;
}

.single-structure-card {
  cursor: pointer;
  margin-bottom: 13px;
  border-radius: 5px;
  border: 0.5px solid #2c3421;
  margin-left: 7% !important;
}

.structure-name {
  font-size: 16px;
  color: #2c3421;
}

.structure-creator {
  text-align: right;
  font-size: 11px;
  margin-top: 5px;
  color: #888888;
}

.structure-route {
  color: var(--primary);
  font-size: 12px;
  margin-bottom: 22px;
}

.structure-date {
  color: #888888;
  font-size: 12px;
  margin-bottom: -25px;
}

.structure-options {
  float: right;
  position: relative;
  top: -80px;
  left: 7px;
}

.type-structure-title {
  text-align: center;
  color: #2c3421;
  font-size: 11px;
  margin-bottom: 8px;
}

.type-selector-container {
  padding: 10px;
  border: 0.5px solid #2c3421;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 11px;
}

.type-name {
  font-size: 13px;
  color: #121212;
  padding-top: 2px;
}

.great-type-prototype {
  color: #2c3421;
  text-align: center;
  margin-bottom: 14px;
}

.description-type-prototype {
  color: #2c3421;
  text-align: center;
  margin-bottom: 40px;
}

.info-img-prototype {
  position: absolute;
  left: 207%;
  top: -14px;
}

.structure-input {
  border-radius: 5px;
  border: 0.3px solid rgba(48, 159, 226, 0.5);
  width: 100%;
  padding: 4px;
  padding-top: 7px;
  padding-left: 15px;
  margin-bottom: 18px;
}

.modal-creation-structure input {
  color: #888888;
}

.modal-creation-structure input::placeholder {
  color: #888888;
}

.location-title {
  color: #2c3421;
  margin-top: 19px;
}

.action-for-location {
  padding-right: 7px;
  cursor: pointer;
}

.tree-location-element {
  border-radius: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 7px;
}

.production-unit-container {
  background: #f8f8f8;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 6px;
}
