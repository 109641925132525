@import '../../../stylesheets/base';
@import '../../../assets/styles/variables.scss';

.general-info-panel {
  background-color: $black;
  border: 1px solid $black;
  border-radius: $default-border-radius;
  color: $white;
  box-shadow: 0;
  padding: 20px 20px 10px 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  min-width: 350px;
  max-width: 450px;
  flex: 1;

  &__title {
    color: $white;
    font-size: $font-base;
    grid-column: 1 / span 3;
    font-weight: 500;
  }

  &__progress {
    $chart-size: 80px;
    position: relative;
    grid-column: 4;
    height: 55px;
    overflow: hidden;
    top: -10px;
    margin-bottom: -6px;

    &-chart {
      position: relative;
      width: $chart-size;
      height: $chart-size;
      overflow: hidden;

      &,
      &-inner {
        border-radius: 50%;
      }

      &-inner {
        border: 4px solid var(--activity-card-chart-color);
        width: $chart-size - 10px;
        height: $chart-size - 10px;
        margin: 5px;
      }

      &-bottom-half,
      &-top-half {
        position: absolute;
        left: 0;
        right: 0;
        height: 40px;
      }

      &-bottom-half {
        bottom: 0;
        background-color: $black;
      }

      &-top-half {
        top: 0;
        transform-origin: bottom;
        background-color: $black;

        &-corner {
          position: absolute;
          left: 0;
          bottom: 0;
          height: 3px;
          width: 15px;
          background-color: $black;
        }
      }
    }

    &-amount,
    &-description {
      font-size: $font-2xs;
      width: $chart-size;
      text-align: center;
      display: inline-block;
      position: absolute;
    }

    &-amount {
      color: $primary;
      font-weight: 700;
      top: 30px;

      div {
        display: inline-block;
      }
    }

    &-description {
      top: 42px;
    }

    &-amount-editable-label {
      background-color: rgba($bluish-gray-70, 0.3);
      padding: 1px 4px;
      left: 0;
      top: -2px;
      width: 45px;
    }
  }

  &__breadcrumb {
    grid-column: 1 / span 4;
    grid-row: 2;
    color: $brand-orange-10;
    font-size: $font-2xs;
    line-height: $font-lg;
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid $white;

    span {
      padding-left: 3px;
    }
  }

  &__label {
    color: $gray-30;
    display: block;
    margin-bottom: 5px;
  }

  &__assignee {
    grid-column: 1 / span 2;
    padding-right: 10px;

    &-picker {
      background-color: $dark-green;
      padding: 5px 8px;
      line-height: 20px;
      border-radius: $default-border-radius;
      width: 100%;
      border-width: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: default;
    }
  }

  &__subtrade {
    grid-column: 3 / span 2;
    padding-left: 10px;

    &-button {
      padding: 5px 8px;
      line-height: 20px;
      border-radius: $default-border-radius;
      width: 100%;
      border-width: 0;
      cursor: default;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;

      // TODO: check whether these colors should be dynamic for some reason.
      // If not, add them to the variables.scss file.
      // background-color: #E0C9ED;
      // color: #913EC0;
    }
  }

  &__tags {
    grid-column: 1 / span 4;
    margin-top: 10px;

    ul {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      margin-bottom: 0;
    }

    li {
      border-top-left-radius: $small-border-radius;
      border-bottom-left-radius: $small-border-radius;
      border-radius: $default-border-radius;
      padding: 0 6px;
      height: 16px;
      font-weight: 600;
      white-space: nowrap;
      margin: 0 8px 10px 8px;
      position: relative;
      line-height: $font-sm;

      &::before {
        content: '';
        display: block;
        border-radius: $small-border-radius;
        width: 12px;
        height: 12px;
        background-color: inherit;
        transform: rotate(45deg);
        position: absolute;
        left: -3px;
        top: 2px;
      }

      &::after {
        content: '';
        display: block;
        width: 2px;
        height: 2px;
        background-color: $bluish-gray-90;
        left: -2px;
        top: calc(50% - 1px);
        position: absolute;
        border-radius: 50%;
      }

      span {
        position: relative;
        font-size: $font-2xs;
      }
    }
  }

  &__empty {
    font-size: $font-xs;
    color: $gray-80;
  }
}
