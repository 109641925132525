@import '../../stylesheets/base';

$gantt-settings-button-size: 26px;

.gantt-settings button {
  min-width: $gantt-settings-button-size;
  height: $gantt-settings-button-size;
  border: 1px solid $gray-30;

  i {
    line-height: $gantt-settings-button-size;
  }

  &:hover,
  &:global(.ant-dropdown-open) {
    border-color: $brand-blue-40;
  }
}
