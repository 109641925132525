.row {
  height: 30px;
  display: flex;
}

.row span:nth-child(even) {
  background-color: #efefef4d;
}

.row span {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 52px;

  font-weight: 300;
  font-size: 12px;
  color: #ffffff;

  border-bottom: 0.2px solid #d1d1d154;
}

.parent-task-color {
  background-color: #f5f5f5cc;
}

.border-subcontract {
  border-bottom: 0.6px solid #efefef !important;
  border-right: 0.6px solid #efefef;
}
