@import '../../../stylesheets/base';
@import '../../../assets/styles/variables.scss';

.ant-tooltip-inner {
  text-align: center;
}

.toolbar-massive-item--button {
  padding: 0;
  min-width: 30px;
  line-height: 30px;
  color: $white;
  background-color: $gray-20;
  border-width: 0;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color $short-transition-duration ease-in;
  height: 26px !important;
  button {
    background-color: transparent;
    border: none;
  }

  i {
    line-height: 32px;
  }

  &:hover:not(:disabled),
  &:global(.ant-dropdown-open) {
    background-color: $brand-blue-40;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.toolbar-item--button {
  padding: 0;
  min-width: 32px;
  line-height: 32px;
  color: $white;
  background-color: $gray-2;
  border-width: 0;
  position: relative;
  cursor: pointer;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  transition: background-color $short-transition-duration ease-in;

  button {
    background-color: transparent;
    border: none;
  }

  i {
    line-height: 32px;
  }

  &:hover:not(:disabled),
  &:global(.ant-dropdown-open) {
    background-color: $gray-80;
    img {
      @extend %inverted-color;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.toolbar-item--button--info::after {
  display: flex;
  justify-content: center;
  align-items: center;

  content: attr(data-counter-selected);
  width: 13px;
  height: 13px;

  border-radius: 50%;
  background-color: $primary;
  color: $black;

  font-size: 10px;
  font-weight: 300;

  position: absolute;
  bottom: 24px;
  left: 24px;

  z-index: 1;
}

.toolbar-item--select {
  min-width: 120px;
  font: $regular-s-font;

  :global .ant-select-selection {
    background-color: $gray-2;
    border-width: 0;
  }

  :global .ant-select-selection:hover,
  :global .ant-select-selection:active,
  :global .ant-select-selection:focus {
    border-color: transparent !important;
    border-right-width: 0px !important;
    box-shadow: none;
  }

  :global .ant-select-arrow i {
    color: $brand-blue-40;

    img {
      height: 16px !important;
      width: 16px !important;
    }
  }
}

.toolbar-item--cta {
  font: $regular-s-font;
  background-color: $primary;
  color: $black;

  &:hover:not(:disabled),
  &:global(.ant-dropdown-open) {
    background-color: $gray-80;
    color: $white;
  }
}

.toolbar-item--has-text {
  padding: 0 20px;
  font: $regular-s-font;

  i {
    margin-right: 5px;
  }
}

.toolbar-item--text-color-invert {
  color: $bed-rock-t2;
}

.toolbar-item--text-color-invert:hover {
  color: $white;
}

.toolbar-item--text-color-active {
  color: $white;
  background-color: $brand-blue-40;
}

.toolbar-item--switch {
  background-color: $brand-blue-40;
}

.toolbar-item--main-color-backround {
  background-color: $primary;
  color: $black;
}

.toolbar-item--main-color-bgmassive {
  background-color: $black !important;
  color: $white !important;
}

.toolbar-item--switch img {
  filter: invert(1) sepia(0%) saturate(321%) hue-rotate(137deg) brightness(103%)
    contrast(95%) !important;
}

.toolbar-item--uncheck {
  background-color: #efefef !important;
  color: $black;

  i img {
    width: 12px !important;
    height: 12px !important;
  }
}

.toolbar-item--uncheck:hover {
  color: $black !important;
  background-color: $primary !important;
}
