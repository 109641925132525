.check-box-title {
  font-weight: bold;
  font-size: 15px;
  position: relative;
  top: -2px;
  left: 7px;
}

.checkbox-section-container {
  margin-bottom: 20px;
  font-size: 12px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--dark-mint) !important;
  border-color: var(--dark-mint) !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--dark-mint) !important;
}

.attach-gantt-btn-style {
  background-color: #25a8ba !important;
  border-color: #25a8ba !important;
  color: white !important;
  font-weight: 600 !important;
  font-size: 10px !important;
  height: 30px !important;
  width: 150px !important;
  border-radius: 10px !important;
  position: relative;
  top: -20px;
}

.ant-upload-list-item {
  width: 200px !important;
}

.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
  position: relative !important;
}

.bottom-btns-style {
  width: 100% !important;
}

.cancel-btn-style {
  background-color: white !important;
  color: #25a8ba !important;
  border-color: white !important;
}
