.container__overlay-popover .ant-popover-content .ant-popover-inner {
  background-color: var(--dark-green-sec);
  border-radius: var(--border-radius-input-modal);
}

.container__overlay-popover.ant-popover-placement-bottom
  .ant-popover-content
  .ant-popover-arrow {
  border-top-color: var(--dark-green-sec);
  border-left-color: var(--dark-green-sec);
}

.container__overlay-popover.ant-popover-placement-top
  .ant-popover-content
  .ant-popover-arrow {
  border-right-color: var(--dark-green-sec);
  border-bottom-color: var(--dark-green-sec);
}

.container__overlay-popover
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content {
  padding: 20px 20px 24px;
}

.constraint-calendar .ant-fullcalendar.ant-fullcalendar-full {
  border: 0;
  margin-top: 26px;
}

.constraint-calendar
  .ant-fullcalendar.ant-fullcalendar-full
  .ant-fullcalendar-calendar-body {
  padding: 0;
  background-color: transparent;
}

.constraint-calendar
  .ant-fullcalendar.ant-fullcalendar-full
  .ant-fullcalendar-calendar-body
  .ant-fullcalendar-table {
  height: 134px;
}

.constraint-calendar
  .ant-fullcalendar.ant-fullcalendar-full
  .ant-fullcalendar-calendar-body
  .ant-fullcalendar-table
  thead {
  color: var(--primary);
}

.constraint-calendar
  .ant-fullcalendar.ant-fullcalendar-full
  .ant-fullcalendar-calendar-body
  .ant-fullcalendar-table
  thead
  tr
  th
  span {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.constraint-calendar
  .ant-fullcalendar.ant-fullcalendar-full
  .ant-fullcalendar-calendar-body
  .ant-fullcalendar-table
  .ant-fullcalendar-tbody
  tr {
  text-align: center;
  height: 22px;
}

.constraint-calendar
  .ant-fullcalendar.ant-fullcalendar-full
  .ant-fullcalendar-calendar-body
  .ant-fullcalendar-table
  .ant-fullcalendar-tbody
  tr
  td
  .constraint-item-calendar {
  background-color: transparent;
  color: var(--white);
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  cursor: pointer;
}

.constraint-calendar
  .ant-fullcalendar.ant-fullcalendar-full
  .ant-fullcalendar-calendar-body
  .ant-fullcalendar-table
  .ant-fullcalendar-tbody
  tr
  td.ant-fullcalendar-selected-day {
  background-color: var(--primary);
  border-radius: var(--border-radius-input-modal);
}

.constraint-calendar
  .ant-fullcalendar.ant-fullcalendar-full
  .ant-fullcalendar-calendar-body
  .ant-fullcalendar-table
  .ant-fullcalendar-tbody
  tr
  td.ant-fullcalendar-selected-day
  .constraint-item-calendar {
  color: var(--dark-green-sec);
  font-weight: 500;
}

.constraint-calendar .ant-fullcalendar-disabled-cell .constraint-item-calendar {
  opacity: var(--disabled-opacity);
}

.constraint-calendar
  .ant-fullcalendar.ant-fullcalendar-full
  .ant-fullcalendar-calendar-body
  tr
  td.ant-fullcalendar-next-month-btn-day {
  opacity: 0.7;
}

.constraint-calendar
  .ant-fullcalendar.ant-fullcalendar-full
  .ant-fullcalendar-calendar-body
  tr
  td.ant-fullcalendar-cell.ant-fullcalendar-disabled-cell
  .constraint-item-calendar {
  cursor: not-allowed;
}
