.text-list-assing {
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #121212;

  width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.row-list-assing {
  height: 14px;
}

.input-list-assing {
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-style: normal;
  height: 14px !important;
  outline: none !important;
  border-width: 0px !important;
  border: none !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #121212 !important;
  /* identical to box height */
}

.buttonn-assing-content {
  position: absolute;
  bottom: 20px;
  left: 0px;
  width: 100%;
  text-align: center;
}

.sector_list_container_assig {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
}
