.overlay-alert-popover .ant-popover-content .ant-popover-arrow {
  border-right-color: var(--dark-green-sec);
  border-bottom-color: var(--dark-green-sec);
}

.overlay-alert-popover .ant-popover-content .ant-popover-inner {
  background-color: transparent;
}

.overlay-alert-popover
  .ant-popover-content
  .ant-popover-inner
  div
  .ant-popover-inner-content {
  padding: 0;
}

.overlay-alert-popover .alert-popover {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 14px;
  background-color: var(--dark-green-sec);
  color: var(--white);
  border-radius: 10px;
}

.overlay-alert-popover .alert-popover h6 {
  margin-bottom: 20px;
  color: var(--white);
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
}

.overlay-alert-popover .alert-popover .alert-popover__buttons {
  display: flex;
}

.overlay-alert-popover .alert-popover .alert-popover__buttons button {
  height: 33px;
  border: 0;
  border-radius: 5px;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
}

.overlay-alert-popover
  .alert-popover
  .alert-popover__buttons
  button.buttons__cancel {
  width: 60px;
  background-color: #efefef;
  color: var(--black);
  margin-right: 10px;
}

.overlay-alert-popover
  .alert-popover
  .alert-popover__buttons
  button.buttons__delete {
  width: 60px;
  background-color: var(--primary);
  color: var(--black);
}

.trash-icon {
  cursor: pointer;
  opacity: 0.5 !important;
  &:hover {
    opacity: 1 !important;
  }
}
