.row {
  height: 30px;
  display: flex;
}

.row > span {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 52px;

  border-bottom: 0.2px solid #d1d1d154;
}

.row > span:nth-child(odd) {
  background-color: #efefef4d;
}

.row > span:last-child {
  flex-grow: 5;
  justify-content: flex-start;
  padding-left: 18px;
}

.row span:last-child span:hover {
  cursor: pointer;
}

.row span {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}

.parent-task-color {
  background-color: #f5f5f5cc;
}

.cnc {
  color: #121212;
  display: flex;
}

.cnc__icon {
  height: 45%;
  margin-right: 5px;
}

.plus-icon img {
  width: 12px;
  margin-right: 5px;
}

.completed-icon img {
  width: 14px !important;
}
