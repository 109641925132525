.responsible__container {
  width: 200px;
  height: 32px;
  background-color: var(--dark-green);
  border-radius: var(--border-radius-input-modal);
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsible__container.disabled {
  opacity: var(--disabled-opacity);
}

.responsible__container .container__avatar {
  width: 100%;
  cursor: pointer;
}

.responsible__container .container__avatar .popoverPriority {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsible__container .container__avatar .popoverPriority span {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.responsible__container .container__name span {
  font-size: 10px;
  line-height: 11px;
  font-weight: 300;
  color: var(--white);
}

.responsible__container .container__name span.name__active {
  font-size: 12px;
}

.container-image-custom-responsible.ant-popover-placement-bottom
  .ant-popover-content
  .ant-popover-arrow {
  border-top-color: var(--dark-green-sec);
  border-left-color: var(--dark-green-sec);
}

.container-image-custom-responsible .ant-popover-content .ant-popover-arrow {
  border-right-color: var(--dark-green-sec);
  border-bottom-color: var(--dark-green-sec);
}

.container-image-custom-responsible
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .select-search
  .select-search__value {
  background-color: var(--dark-green-sec);
}

.container-image-custom-responsible
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content {
  padding: 0;
}

.container-image-custom-responsible
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .select-search
  .select-search__value
  input {
  height: 40px;
  background-color: var(--dark-green-sec);
  color: var(--white);
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  border-radius: var(--border-radius-input-modal)
    var(--border-radius-input-modal) 0 0;
  padding: 0 10px;
}

.container-image-custom-responsible
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .select-search
  .select-search__value::after {
  width: 16px;
  height: 16px;
  background-image: url('../../../../../assets/img/icons/icon--search-orange.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container-image-custom-responsible
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .select-search
  .select-search__select {
  border-top: 0.5px solid var(--hr-color);
  background-color: var(--dark-green-sec);
  height: 152px;
}

.container-image-custom-responsible
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .select-search
  .select-search__select::-webkit-scrollbar {
  width: 4px;
  height: 36px;
}

.container-image-custom-responsible
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .select-search
  .select-search__select::-webkit-scrollbar-thumb {
  background-color: var(--gray-1);
  border-radius: 3px;
}

.container-image-custom-responsible
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .select-search
  .select-search__select::-webkit-scrollbar-track {
  background-color: var(--dark-green-sec);
  border-radius: 3px;
}

.container-image-custom-responsible
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .select-search
  .select-search__select
  .select-search__options
  .select-search__row {
  border: 0;
}

.container-image-custom-responsible
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .select-search
  .select-search__select
  .select-search__options
  .select-search__row
  .select-search__option {
  height: 30px;
  background-color: var(--dark-green-sec);
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  color: var(--white);
  padding: 0 10px;
}

.container-image-custom-responsible
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .select-search
  .select-search__select
  .select-search__options
  .select-search__row
  .select-search__option:hover {
  background-color: var(--black) !important;
  color: var(--white) !important;
  font-weight: 400;
}

.container-image-custom-responsible
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .select-search
  .select-search__select
  .select-search__options
  .select-search__row
  .select-search__option.is-selected {
  background-color: var(--primary) !important;
  color: var(--black) !important;
  font-weight: 400;
}
