.addTaskBtn {
  color: #d1d1d1;
  margin-left: 55px;
  margin-bottom: 12px;
  cursor: pointer;
  margin-top: 10px;
  transition: 0.3s;
  font-size: 13px;
}

.addTaskBtn:hover {
  color: black;
}

.addTaskBtn .anticon-plus {
  margin-right: 3px;
}

.fit-name-superposition {
  overflow: hidden !important;
  max-height: 35px !important;
  height: 35px !important;
  padding-top: 6px;
}

.hide-childs-btn {
  font-size: 10px !important;
  padding-right: 6px !important;
  cursor: pointer !important;
}

.show-childs-btn {
  font-size: 14px !important;
  padding-right: 6px !important;
  cursor: pointer !important;
  top: 1px;
  position: relative;
}
.vertical-center {
  margin: 0;
  position: relative;
  top: 30%;
  -ms-transform: translateY(-25%);
  transform: translateY(-25%);
  font-size: 13px;
}

.vertical-center-dates {
  margin: 0;
  position: relative;
  top: 20%;
  -ms-transform: translateY(-25%);
  transform: translateY(-25%);
}

.fit-at-middle-row {
  top: 0px;
  position: relative;
  padding: 0 15px;
  height: 40px !important;
  max-height: 40px !important;
  overflow: hidden !important;
}

.single-row-bottom-bordered {
  border-bottom: 1px solid #efefef !important;
  font-size: 12px !important;
}

.single-task-column-style {
  overflow: hidden !important;
  max-height: 40px !important;
  height: 40px !important;
}

.progress-custom-props {
  padding: 0 10% !important;
  left: 5px !important;
  font-size: 11px !important;
  position: relative;
  top: -2px;
}

.customPlanificationBlock {
  display: block;
  padding: 0px;
  margin-top: 1px;
  border: 1px solid transparent;
}

.on-resizing-optimize {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(139, 139, 139, 1) 47%,
    rgba(88, 88, 88, 0.44021358543417366) 100%
  );
}

.range-fixed-lookahead {
  height: 8px;
  position: relative !important;
  left: 35% !important;
  top: 2px;
  min-width: 60%;
}

.percentage-label-custom {
  position: relative;
  top: -15px;
  left: -3%;
  color: #a0a0a0;
  font-size: 13px;
}

.lookaheadLineStatus {
  border-radius: 10px !important;
  margin-right: 6px !important;
  display: inline;
}

.arrow-child-tasks {
  font-size: 12px !important;
  transform: rotate(90deg);
  width: 8px;
  margin-right: 6px;
}

.custom-header-top-list {
  /* background-color: #d8d8d8 !important; */
  padding: 0 15px;
  height: 30px !important;
  padding-top: 6px !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 13px !important;
  max-height: 30px !important;
  overflow: hidden !important;
  background-color: #121212;
  color: #ffffff;
}
.single-column-header {
  overflow: hidden !important;
  max-height: 18px !important;
}

.custom-select-lookahead .ant-select-selection--single {
  background: #736e6e00 !important;
  border-color: #736e6e00 !important;
  color: grey !important;
  font-size: 10px !important;
  height: 27px !important;
  width: 90%;
}

.adjust-width-style {
  width: 52% !important;
}

.custom-select-lookahead
  .ant-select-selection--single
  .ant-select-selection__rendered {
  margin-right: 0px !important;
  margin-left: 24% !important;
  min-width: 80px;
}

.custom-select-lookahead .ant-select-arrow {
  color: grey !important;
  visibility: hidden !important;
}

.plus-btn-add-task-planification {
  font-size: 10px !important;
  color: #a9a9a97d !important;
}

.plus-btn-add-task-planification:hover {
  font-size: 12px !important;
  color: #3b3b3b94 !important;
}

.custom-input-planification {
  border: none !important;
  font-size: 13px !important;
  top: 0px;
  max-width: 50% !important;
}

.custom-input-planification:hover {
  border: none !important;
}

.custom-input-planification:focus {
  border-color: #40a9ff;
  border-right-width: 0px !important;
  box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
}

.custom-input-planification .ant-input:hover {
  border-color: #40a9ff;
  border-right-width: 0px !important;
  box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
}

.custom-input-planification .ant-input:focus {
  border-color: #40a9ff;
  border-right-width: 0px !important;
  box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
}

.custom-input-planification .ant-select-dropdown {
  width: 100% !important;
}

.custom-dropdown-select-lookahead .ant-select-dropdown-menu-item {
  padding: 0px 0px !important;
  padding-left: 25% !important;
}

.custom-select-string .ant-select-selection-selected-value {
  float: unset !important;
  font-size: 14px !important;
  position: relative !important;
  top: -2px !important;
  left: 4% !important;
}

.custom-select-string .ant-select-selection--single {
  background: #736e6e00 !important;
  border-color: #736e6e00 !important;
  color: grey !important;
  font-size: 10px !important;
  height: 27px !important;
  width: 90%;
}

.custom-select-string-dropdown {
  text-align: center !important;
  width: 110px !important;
}

.custom-select-string-dropdown .ant-select-dropdown-menu-item {
  padding: 5px 0px !important;
}

.custom-input-number-planification {
  width: 35% !important;
  border: 0px solid #d9d9d9 !important;
  left: 0%;
  height: 28px !important;
  font-size: 12px !important;
  top: 0px;
}

.custom-input-number-planification .ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #d9d9d9;
  border-radius: 0 4px 4px 0;
  opacity: 0;
  -webkit-transition: opacity 0.24s linear 0.1s;
  transition: opacity 0.24s linear 0.1s;
  display: none !important;
}

.custom-select-planification-position {
  top: -3px !important;
  position: relative;
}

.custom-input-number-planification .ant-input-number-input {
  padding-left: 14% !important;
  text-align: center !important;
}

.custom-date-picker-planification {
  max-width: 100px !important;
}

.transparent-for-activity-column-header {
  color: #80808000;
}

.custom-date-picker-planification .ant-input {
  border: 0px solid #d9d9d9;
  height: 25px;
  font-size: 13px;
  left: 5%;
}

.custom-date-picker-planification .ant-input:hover {
  border-color: #40a9ff;
  border-right-width: 0px !important;
  box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
}

.custom-date-picker-planification .ant-calendar-picker-clear,
.ant-calendar-picker-icon {
  right: -6% !important;
  top: 59% !important;
  font-size: 10px !important;
  opacity: 0;
}

.custom-multi-img-selector .ant-select-selection {
  border: 0px solid #d9d9d9 !important;
  width: 90% !important;
}

.custom-multi-img-selector .ant-select-selection:hover {
  border-color: #40a9ff;
  border-right-width: 0px !important;
}

.custom-multi-select-pop {
  cursor: pointer;
}

.task-has-constraint {
  float: right !important;
  color: #f6a5a5 !important;
}

.container-image-custom .ant-popover-inner-content {
  padding: 0;
}

/**
 * Value wrapper
 */
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
 * Input
 */
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
  list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search__option.is-selected {
  background: #00a5ba;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #00a5ba;
  color: #fff;
}

.single-row-bottom-bordered:hover {
  background-color: #ededed3d !important;
}

/**
 * Group
 */
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__options {
  padding: 0 !important;
}

/**
 * Main wrapper
 */
.select-search {
  width: 300px;
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

.child-task-add {
  margin-left: 10px;
  cursor: pointer;
}

.ant-row .custom-header-top-list .ant-progress-text {
  color: #ffffff;
  font-size: 13px;
  margin-left: 0%;
}

.progress-custom-props .ant-progress-inner {
  width: 40px;
  height: 8px;
  margin-left: -3%;
}
.ant-row .fit-at-middle-row .ant-progress-text {
  font-size: 13px;
  margin-left: 0%;
}

.massive .ant-calendar-picker-input.ant-input {
  background: #f4f4f4 !important;
}
.lookahead-add-icon {
  width: 15px;
  height: 15px;
  margin: 5;
}

.lookahead-add-icon:hover {
  transform: scale(1.3);
  color: #2c3421;
}

.lookahead-delete-icon {
  width: 15px;
  height: 15px;
  margin: 5;
}

.lookahead-delete-icon:hover {
  transform: scale(1.1);
  color: #ff0000cf;
}

.delete-subcontract-lookahead:hover {
  transform: scale(1.3);
  color: #ff0000cf;
}

.unfit-task-icon {
  padding-right: 5px;
  color: #ff0000cf;
  font-size: 12px;
  top: 1px;
  position: relative;
  cursor: pointer;
}

.activity-modification-style .ant-modal-header {
  border-bottom: 1px solid #2c3421 !important;
  margin-left: 20px;
  margin-right: 20px;
  padding: 16px 0px;
}

.activity-modification-style .ant-modal-body {
  padding-bottom: 12px;
}

.activity-modification-style .ant-modal-footer {
  text-align: center;
}

.activity-modification-style .ant-modal-title {
  color: #2c3421 !important;
}

.activity-modification-style .ant-input {
  border-color: #309fe280;
}

.activity-modification-style .ant-input:hover {
  border-color: #97cff0;
}

.activity-modification-style .ant-btn:hover {
  border-color: var(--primary);
}

.commentary-container-activity-modification {
  color: rgb(48, 159, 233);
  font-size: 11px;
  padding-left: 3px;
  margin-bottom: 3px;
}

.calendar-activity-modification {
  margin-right: 8px;
}

.name-activity-modification {
  font-size: 14px;
}

.dates-activity-modification {
  position: relative;
  top: 1px;
  font-size: 12px;
  margin-right: 8px;
}

.modification-title-activity-modification {
  color: var(--primary);
  font-size: 14px;
  margin-bottom: 9px;
}

.dates-container-activity-modification {
  margin-bottom: 22px;
}

.date-task-container-activity-modification {
  padding: 5px 29% 5px 7px;
  background: #ffffff;
  border: 0.5px solid rgba(48, 159, 226, 0.5);
  border-radius: 5px;
}

.dates-container-task-activity-modification {
  text-align: center;
  margin-top: 1px;
  margin-bottom: 12px;
}

.fit-date-activity-modification {
  background-color: #ebebeb;
  color: #c1c1c1;
  border: 0.5px solid #ebebeb;
}

.unfit-icon-calendar {
  position: relative;
  top: -2.6px;
  left: 8px;
}

.activity-progress-style .range-fixed-lookahead {
  height: 19px;
  position: relative !important;
  left: 35% !important;
  top: 3px;
  min-width: 60%;
}
.disabled-fixed-input {
  top: -6px !important;
  left: -7px !important;
}

.range-fixed-lookahead #rrp-fill {
  z-index: 101 !important;
}

.range-fixed-lookahead #rrp-thumb {
  z-index: 101 !important;
}

.range-fixed-lookahead #rrp-track {
  background: #d8d8d8 !important;
}

.rollback-ponderator-icon {
  width: 12px !important;
  position: relative;
  top: -1px;
  cursor: pointer;
  margin-right: 7px;
}

.tableConstraints .input-description {
  font-size: 12px;
  color: #d1d1d1;
  border: 1px solid #d1d1d1;
  padding: 3px 7px;
}

.render-tag-at-table.non-odd-col {
  height: 100%;
}

.render-tag-at-table .tag-proplanner {
  top: 6px;
}

.render-tag-at-table .tag-name-custom-field {
  top: -1px !important;
}

.render-tag-at-table .tag-dot-custom-field {
  top: 6px;
}

.render-tag-at-table .add-responsable-new {
  top: 8px;
}

.render-tag-at-table .text-subcontract {
  top: 6px !important;
}

.render-tag-at-table .custom-gantt-subcontract .text-subcontract {
  max-width: none !important;
}

.render-responsible-at-table {
  top: 6px;
  position: relative;
}

.lookahead-view .fit-name-superposition .open-card-class {
  position: relative !important;
  top: -14px !important;
  height: 130% !important;
}

.lookahead-view .fit-name-superposition .editable-ipsum-custom {
  position: relative !important;
  top: 14px !important;
}

.lookahead-view .actions,
.lookahead-view .roadblocks {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 17px;
}

.lookahead-view .constraint-add-icon {
  margin-left: 8px;
}

.lookahead-view .constraint-add-icon img {
  width: 11px;
  margin-bottom: 2px;
  opacity: 0.8 !important;
}

.lookahead-view .ponderator-custom img {
  margin-right: 0 !important;
}
/** open task card with the left button table view */
.lookahead-table-view .single-row-bottom-bordered:hover .open-card-button-new {
  opacity: 1;
}

.lookahead-table-view .single-row-bottom-bordered .open-card-button-new {
  opacity: 0.01;
  cursor: pointer;
  background-image: url('../../assets/img/newlookahead/name/open-card-gray.png');
  width: 16px;
  height: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 1px;
  margin-right: 8px;
}

.lookahead-table-view
  .single-row-bottom-bordered:hover
  .open-card-button-new:hover {
  background-image: url('../../assets/img/newlookahead/name/open-card-orange.png');
}
