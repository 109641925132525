.listUsers .more-users {
  color: white;
  float: right;
  left: -9px;
  position: relative;
  top: 4px;
  font-size: 13px;
}

.imgLoadingHide {
  display: none;
}

.img-blur-project {
  filter: blur(10px);
  transition: 'filter 500ms ease';
}

.cover-divider {
  padding: 5%;
}

.loader-spinner-item-card {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.button-primary {
  background-color: #efefef;
  border-color: #efefef;
  color: #585858;
}

.spacer {
  margin-top: 10px;
}

.ant-card-extra {
  .dropdown-design-system__label {
    background-color: transparent !important;
    border: none !important;
  }
}
