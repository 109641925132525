@import '../../../assets/styles/variables.scss';

.popover-design-system {
  .ant-popover-content {
    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      border-radius: 5px;

      .ant-popover-inner-content {
        padding: 10px 0;

        .popover__content {
          .content__item {
            padding: 0 20px;
            transition-duration: 0.3s;
            cursor: pointer;

            &.disabled {
              opacity: $disabled-opacity;
            }

            .item__line {
              width: 100%;
              height: 1px;
              margin: 18px 0;
              background-color: $separation-line;
            }

            .item__option__informative {
              display: flex;
              align-items: center;

              .item__option {
                width: 100%;
                height: 30px;
                display: flex;
                align-items: center;

                .option__icon {
                  &.position--left {
                    order: 0;
                    margin-right: 10px;
                  }

                  &.position--right {
                    order: 1;
                    margin-left: 10px;
                  }

                  i {
                    img {
                      width: 14px;
                    }
                  }
                }

                span {
                  font-size: 12px;
                  line-height: 14px;
                  font-weight: 400;
                  color: $white;
                }
              }
            }

            &:hover {
              background-color: $black;

              .item__option {
                .option__icon {
                  i {
                    img {
                      filter: brightness(0) invert(1) !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.theme--default {
    .ant-popover-content {
      .ant-popover-arrow {
        display: none;
      }

      .ant-popover-inner {
        background-color: $dark-green;

        .ant-popover-inner-content {
          .popover__content {
            .content__item {
              &:hover {
                background-color: $black;
              }
            }
          }
        }
      }
    }
  }

  &.theme--dark {
    .ant-popover-content {
      .ant-popover-arrow {
        display: none;
      }

      .ant-popover-inner {
        background-color: $black;

        .ant-popover-inner-content {
          .popover__content {
            .content__item {
              &:hover {
                background-color: $dark-green;
              }
            }
          }
        }
      }
    }
  }
}
