.lookahead-toolbar {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 0 0;
  user-select: none;
  position: relative;

  &__levels-submenu {
    @for $i from 0 to 20 {
      li:nth-child(#{$i + 1}) span {
        padding-left: 4px * $i;
      }
    }
  }

  :global input[type='text'] {
    font-size: 12px;
    border-radius: 5px;
  }
}

.lookahead-popover div {
  inset: unset !important;
}

.lookahead-popover {
  position: absolute;
  top: 50px;

  z-index: 2;
}

.lookahead-range-btn {
  left: 205px;
}

.lookahead-filter-btn {
  left: calc(32px);
}

.lookahead-order-btn {
  left: calc((32px * 2) + 3px);
}

.lookahead-group-btn {
  left: calc((32px * 3) + 5px);
}

.lookahead-columns-btn {
  left: calc((32px * 4) + 8px);
  margin-top: -45px;
}

.lookahead-popover .filter-lookahead-multi-check {
  background-color: #fff;
  width: max-content !important;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.lookahead-right-side {
  display: flex;
  & :global .ant-input-search .anticon svg {
    color: #2c3421;
  }

  & :global .ant-input-search {
    border-radius: 5px;
    font-size: 12px;
    width: 173px !important;
  }
}
