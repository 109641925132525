@import '../../../assets/styles/variables.scss';

.empty-state-design-system {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .empty-state-design-system__icon {
    i {
      img {
        width: 84px;
        height: 84px;
      }
    }

    &.size--large {
      i {
        img {
          width: 200px;
          height: 200px;
        }
      }
    }
  }

  .empty-state-design-system__title {
    h2 {
      margin: 0;
      font: $medium-l-font;
      color: $black;
    }
  }

  .empty-state-design-system__description {
    text-align: center;
    p {
      font: $regular-s-font;
      color: $black;
      line-height: 15.23px;
      margin-bottom: 3px;
    }
  }
}
