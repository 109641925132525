.frm-calendar .div-day {
  width: 100%;
  display: flex;
  align-items: center;
}

.frm-calendar .day-label {
  text-align: left;
  cursor: pointer !important;
  padding: 4px 0;
  min-width: 100px;
}

.frm-calendar .day-check {
  text-align: center;
  min-width: 100px;
}

.frm-calendar .day-shift {
  text-align: center;
  display: flex;
  padding: 0 16px;
  margin: 3px 6px 3px 0px;
  border: 1px solid #2c3421;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  min-width: 226px !important;
}

.frm-calendar .disabled {
  background-color: #f5f5f5;
  color: white;
}

.frm-calendar .checkDays {
  margin: auto;
  padding: 0 6% !important;
  font-size: 13px;
}

.frm-calendar .shiftSelect {
  width: 85px !important;
  margin-right: 2px !important;
}

.frm-calendar .shiftSelect .ant-select-selection {
  border: none;
  font-size: 13px;
}

.frm-calendar .shiftSelect .ant-select-selection--single {
  height: 22px;
  position: relative;
  top: 5px;
}

.frm-calendar .shiftSelect .ant-select-selection-selected-value {
  position: relative;
  top: -3px;
}

.frm-calendar .header {
  color: var(--black);
}

.frm-calendar .header-col {
  display: inline !important;
  border: none !important;
}

.frm-calendar .all-checked-span {
  cursor: pointer;
}

.reply-shift-div {
  position: relative;
  left: 7px;
  top: 7px;
  cursor: pointer;
  visibility: hidden;
}

.frm-calendar .day-shift:hover .reply-shift-div {
  visibility: visible;
}

.dropdown-hours-style .ant-select-dropdown-menu-item {
  font-size: 13px !important;
}

.add-working-day-btn {
  border: 1px solid var(--dark-mint) !important;
  color: var(--black);
  box-sizing: border-box;
  border-radius: 5px;
  height: 30px;
  padding-top: 4px;
  padding-right: 10px;
  padding-left: 10px;
  min-width: 75px !important;
  cursor: pointer;
  text-align: center;
  margin-right: 5px;
  /* padding-left: 3% !important; */
  img {
    margin-right: 10px;
    padding-bottom: 0.6px;
  }
}

.on-hover-delete-working-day {
  opacity: 0;
  cursor: pointer;
}

.on-hover-delete-working-day:hover {
  opacity: 1;
}
