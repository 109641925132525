@import '../../../assets/styles/variables.scss';

.notification-design-system-v2 {
  border-radius: 5px;
  background-color: $white;
  padding: 15px 15px 15px 20px;
  box-shadow: 0px 4px 4px 0px rgba(88, 88, 88, 0.2);
  border-left-width: 5px;
  border-left-style: solid;

  &.type--info {
    border-left-color: $blue;
  }

  &.type--success {
    border-left-color: $dark-mint;
  }

  &.type--warning {
    border-left-color: $yellow;
  }

  &.type--error {
    border-left-color: $red;
  }

  .ant-notification-notice-content {
    .ant-notification-notice-with-icon {
      .ant-notification-notice-message {
        font: $regular-s-font;
        color: $black;
      }

      .ant-notification-notice-description {
        div {
          display: flex;
          flex-direction: column;
          gap: 5px;

          p {
            font: $regular-s-font;
            color: $black;
            margin: 0;
          }

          span {
            font: $light-s-font;
            color: $dark-gray;
          }
        }
      }
    }
  }
}
