.containerHeader {
  background-color: var(--cool-gray);
  height: 57px;
  padding: 0 18px 0 20px;
  display: flex;
  justify-content: space-between;
}

.tooltip {
  align-self: center;
}
