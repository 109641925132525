.show-units-activity-modal {
  max-height: 300px;
  overflow: auto;
}

.activity {
  font-size: 15px;
  color: #595959;
  padding-left: 10px;
}

.activity.disable {
  font-style: italic;
  text-decoration: line-through;
}

.activity.has_childs {
  color: #1890ff;
}

.assign-units-tooltip {
  color: var(--primary);
  font-weight: 500;
}

.assign-units-tooltip:lang(es)::after {
  content: attr(data-totalUnits);
  font-weight: normal;
}

.assign-units-tooltip:lang(en)::before {
  content: attr(data-totalUnits);
  padding-right: 5px;
  font-weight: normal;
}
