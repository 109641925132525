.filter-lookahead-select-string .ant-select-selection-selected-value {
  float: unset !important;
  font-size: 14px !important;
  position: relative !important;
  top: -2px !important;
  left: 4% !important;
}

.filter-container-array {
  width: 110% !important;
  top: -3px !important;
  padding-left: 11px !important;
}

.filter-lookahead-select-string .ant-select-selection--single {
  background: #736e6e00 !important;
  border-color: #736e6e00 !important;
  color: grey !important;
  top: -1px;
  text-align: center !important;
  font-size: 13px !important;
  height: 27px !important;
  width: 90%;
}

.filter-lookahead-select-string-dropdown {
  text-align: center !important;
}

.filter-lookahead-select-string-dropdown .ant-select-dropdown-menu-item {
  padding: 5px 0px !important;
}

.filter-lookahead .ant-select-arrow-icon svg {
  color: var(--primary) !important;
  width: 8px;
  height: 8px;
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.filter-lookahead-select-string .ant-select-selection:active {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
  box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
}

.filter-lookahead-select-string .ant-select-selection:focus {
  -webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
  box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
}

.filter-arrow-style {
  width: 8px;
  position: relative;
  top: 2px;
}

.select-custom-checkbox-filter-string {
  position: relative;
  top: 1px;
  color: grey;
  font-size: 14px;
}

.select-custom-checkbox-filter-arrow {
  top: 0px;
  left: 9px;
  width: 8px;
  position: relative;
}

.custom-search-bar-prop .ant-input-number-handler-wrap {
  visibility: hidden;
}
