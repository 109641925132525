@import '../../assets/styles/variables.scss';

.layoutLogin {
  &__container {
    width: 420px;
    position: relative;
    z-index: 1;
    background-color: $white;
    padding: 50px 50px 33px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__logo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 23px;

    h2 {
      font: $medium-l-font;
      text-align: center;
      margin: 0;
      color: $black;
    }
  }
}
