.fixed-lookahead-planification-filter-header {
  position: fixed !important;
  background-color: white;
  z-index: 10;
  top: 122px !important;
}

.lookahead-filter-header {
  height: 60px !important;
  padding: 25px !important;
}

.viewPlanificationLookahead {
  width: 100% !important;
}

.viewPlanificationLookahead .ant-select-selection--single {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 12px !important;
  border: none;
  border-bottom: 0px solid #00a8b9 !important;
  border-radius: 0px !important;
  height: 32px !important;
  position: relative;
  top: -6px;
}

.viewPlanificationLookahead .anticon:not(.ant-select-arrow) {
  color: var(--primary);
  width: 8px;
  height: 8px;
  /* margin-right: 10px; */
  position: relative;
}

.viewPlanificationLookahead .ant-select-arrow,
.viewSelect .ant-select-selection__clear {
  color: #00a8b9 !important;
}

.viewPlanificationLookahead .ant-select-arrow-icon {
  left: 1px;
  color: #00a8b9 !important;
}

.viewDropdownPlanification {
  width: 12.5% !important;
}

.viewDropdownPlanification .ant-select-dropdown-menu li {
  padding-left: 11px;
}

.viewDropdownPlanification .ant-select-dropdown-menu-item svg {
  color: #f18640 !important;
  margin-right: 5px;
}

.lookahead-search-on-page-bar .ant-input:hover {
  border-color: #40a9ff00;
  border-radius: 1px !important;
  border-bottom: 1px solid #2c3421 !important;
  border-right-width: 0px !important;
  width: 110px;
}

.lookahead-search-on-page-bar .ant-input:focus {
  border-color: #40a9ff00;
  border-right-width: 0px !important;
  outline: 0;
  box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
  width: 110px;
}

.lookahead-search-on-page-bar .ant-input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  align-items: center !important;
  height: 20px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 13px;
  line-height: 15px;
  background-color: #fff;
  background-image: none;
  border: 0px solid #d9d9d9 !important;
  border-bottom: 1px solid #d9d9d9 !important;
  border-radius: 1px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 110px;
}

.lookahead-search-on-page-bar {
  display: inline;
  top: -4px !important;
  position: relative !important;
}

.lookahead-search-on-page-bar .anticon svg {
  width: 12px;
  height: 12px;
  color: #2c3421;
  margin-right: 35px;
}

.counter-search-on-page {
  font-size: 10px;
  left: -9px;
  position: relative;
  top: 1px;
  color: #a2a2a2;
}

.lookahead-filter-header .fa-handshake:before {
  width: 16px;
  height: 16px;
}

.lookahead-img-filter {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.lookahead-view-grid-icon {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.lookahead-range-picker {
  background-color: white;
  padding-left: 18px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #00000033;
  width: 151px;
  padding-top: 13px;
  padding-bottom: 13px;
}

.filter-lookahead-multi-check {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;
  width: 200px !important;
  border-radius: 10px !important;
}

.filter-lookahead-multi-check .css-1qprcsu-option {
  background-color: #b2d4ff00 !important;
  color: #121212 !important;
  font-weight: normal !important;
  min-width: 0px !important;
  background-image: url('../../assets/img/checked.png');
  background-repeat: no-repeat;
  background-size: 10px;
  background-position-x: 13px;
  background-position-y: 13px;
}

.filter-lookahead-multi-check .css-1vr111p-option {
  background-color: #b2d4ff00 !important;
  color: #121212 !important;
  font-weight: normal !important;
  min-width: 0px !important;
  background-image: url('../../assets/img/unchecked.png');
  background-repeat: no-repeat;
  background-size: 10px;
  background-position-x: 13px;
  background-position-y: 13px;
}

.filter-lookahead-multi-check .css-1jllj6i-control {
  border-color: #2c3421 !important;
  border-radius: 1px !important;
  border-style: solid !important;
  border-width: 0px !important;
  border-bottom-width: 1px !important;
  min-width: 0px !important;
  min-height: 0px !important;
  width: 80% !important;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 0px !important;
  align-content: center;
}

.filter-lookahead-multi-check .css-6y1x9t-control {
  border-color: #2c3421 !important;
  border-radius: 1px !important;
  border-style: solid !important;
  border-width: 0px !important;
  border-bottom-width: 1px !important;
  min-width: 0px !important;
  min-height: 0px !important;
  width: 80% !important;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 0px !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1) !important;
}

.filter-lookahead-multi-check .css-1wy0on6 svg {
  color: #2c3421 !important;
  width: 16px !important;
}

.filter-lookahead-multi-check .css-1qprcsu-option input[type='checkbox'] {
  opacity: 0;
}

.filter-lookahead-multi-check .css-1vr111p-option input[type='checkbox'] {
  opacity: 0;
}

.filter-lookahead-multi-check .css-ik6y5r {
  box-shadow: inset 0 0px 0 rgba(0, 0, 0, 0.1) !important;
  font-size: 13px;
  font-weight: 400px;
}

.filter-lookahead-multi-check .css-11unzgr {
  max-height: inherit !important;
}

.filter-lookahead-multi-check .css-v1axir {
  max-height: 600px !important;
}
