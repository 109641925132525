@import '../../assets/styles/variables.scss';

.SelectCompanyModal__container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .SelectCompanyModal {
    &__title {
      h2 {
        font: $medium-l-font;
        color: $white;
        text-align: center;
        margin: 0;
      }
    }
    &__description {
      font: $regular-s-font;
      color: $white;
    }

    &__footer {
      display: flex;
      justify-content: flex-end;

      &__cancel {
        border-radius: 5px;
        padding: 8px 20px;
        background-color: $cool-gray;
        font: $regular-s-font;
        border: none;
      }
    }
  }
}
