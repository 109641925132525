.iframe {
  width: 100%;
  height: 100vh;
  border: 0;
}

.no-module-access {
  width: 100%;
  height: calc(100vh - 72px);
  display: flex;
  justify-content: center;
  align-items: center;
}
