.gantt-container {
  height: calc(100vh - 186px);
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
}

.top-fix-style {
  margin-top: -2px !important;
}

.baseline {
  position: absolute;
  border-radius: 18px;
  opacity: 0.6;
  height: 2px;
  background: #25a8baa1 !important;
  margin-top: 10px;
  z-index: 0;
}

.gantt_task_row.gantt_selected .gantt_task_cell {
  border-right-color: #ffec6e;
  z-index: -1;
}

.gantt_tree_icon.gantt_close {
  background-image: url('../../assets/img/line-down.png');
  width: 18px;
  background-size: 20px;
  cursor: pointer;
}

.gantt_tree_icon.gantt_open {
  background-image: url('../../assets/img/arrow-down.png');
  width: 18px;
  background-size: 20px;
  cursor: pointer;
}

.gantt_task_content {
  visibility: hidden;
}

.gantt_task_drag {
  width: 12px !important;
}

.gantt_line_wrapper div {
  margin-top: -1px !important;
}

.constraint-type-container-style {
  text-align: center !important;
  overflow: hidden;
  padding-top: 6px;
  line-height: 10px;
}

.gantt_marker {
  height: 100%;
  width: 2px;
  top: 0;
  position: absolute;
  text-align: center;
  background-color: #c4c4c4 !important;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.same_sontive {
  background-color: rgba(117, 117, 117, 0.25);
  width: 200px !important;
  left: -38px;
  position: relative;
}

.updColor {
  background-color: rgba(117, 117, 117, 0.25) !important;
}

.task-gantt-visualization-hernan
  .gantt_cell[data-column-name='expected_progress'] {
  background-color: rgba(117, 117, 117, 0.25) !important;
}

.task-gantt-visualization-hernan
  .gantt_cell[data-column-name='text']
  .gantt_tree_content {
  display: block !important;
  text-align: left !important;
}

.task-gantt-visualization-hernan .gantt_cell[data-column-name='freeSlack'] {
  background-color: rgba(117, 117, 117, 0.25) !important;
}
.task-gantt-visualization-hernan .gantt_cell[data-column-name='totalSlack'] {
  background-color: rgba(117, 117, 117, 0.25) !important;
}
.task-gantt-visualization-hernan
  .gantt_cell[data-column-name='custom_predecessors'] {
  background-color: rgba(117, 117, 117, 0.25) !important;
}
.task-gantt-visualization-hernan
  .gantt_cell[data-column-name='custom_sucessors'] {
  background-color: rgba(117, 117, 117, 0.25) !important;
}
.task-gantt-visualization-hernan .gantt_cell[data-column-name='used_cost'] {
  background-color: rgba(117, 117, 117, 0.25) !important;
}
.task-gantt-visualization-hernan .gantt_cell[data-column-name='real_cost'] {
  background-color: rgba(117, 117, 117, 0.25) !important;
}
.task-gantt-visualization-hernan .gantt_cell[data-column-name='is_critical'] {
  background-color: rgba(117, 117, 117, 0.25) !important;
}
.task-gantt-visualization-hernan
  .gantt_cell[data-column-name='expected_progress_base'] {
  background-color: rgba(117, 117, 117, 0.25) !important;
}

.new-gantt-chart .gantt_tree_icon.gantt_close {
  margin-left: 24px;
}

.new-gantt-chart .gantt_tree_icon.gantt_open {
  margin-left: 24px;
}
