.background-image {
  background-image: url('../../assets/img/bg-settings-2.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 200px;
}

.grid-settings {
  display: grid;
  grid-template-columns: 10% 89%;
}

/* BUTTON NAV SETTINGS */
.button-nav-settings {
  display: inline-block;
  font-weight: 400;
  color: #a18383;
  text-align: center;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 13px;
  line-height: 1.5;
  border: 0px solid #000000;
  cursor: pointer;
  background: #ffffff;
  outline: none;
}

.border-nav-settings {
  color: #000000;
  border-bottom: 3px solid var(--primary);
}

.no-image-span {
  width: 100%;
  text-align: center;
  display: inline-block;
}

.user-settings-company .ant-table-thead > tr:first-child > th,
.table-users-project .ant-table-thead > tr:first-child > th {
  background: #f5f5f5;
  color: #121212 !important;
  border-bottom: none;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.user-settings-company tbody > tr > td,
.table-users-project tbody > tr > td {
  background: white;
  border-bottom: 1px solid #f5f5f5 !important;
}

.user-settings-company tbody > tr,
.table-users-project tbody > tr,
.table-users-reports tbody > tr {
  background: white;
}

.user-settings-company tbody tr:nth-child(odd):hover,
.table-users-project tbody tr:nth-child(odd):hover,
.table-users-reports tbody tr:nth-child(odd):hover {
  background: white !important;
}

.user-settings-company tbody tr:nth-child(even):hover,
.table-users-project tbody tr:nth-child(even):hover,
.table-users-reports tbody tr:nth-child(even):hover {
  background: white !important;
}

.user-settings-company tbody > tr:hover > td,
.table-users-project tbody > tr:hover > td,
.table-users-reports tbody > tr:hover > td {
  background: white !important;
}

.select-rol .ant-select-selection-selected-value {
  overflow: initial !important;
}

.select-rol {
  position: relative;
  left: -11px;
}

.no-image-span-no-center {
  width: 100%;
  display: inline-block;
}

.weekly-ckeckbox {
  margin: 4px;
  text-align: center;
}

.table-users-reports .ant-table-thead > tr > th:nth-last-child(-n + 3) {
  text-align: center !important;
}

.table-users-reports tbody > tr > td {
  background: white;
  border-bottom: 1px solid #f5f5f5 !important;
}

.table-users-reports tbody > tr {
  background: white;
}

.table-users-reports tbody tr:nth-child(odd):hover {
  background: white !important;
}

.table-users-reports tbody tr:nth-child(even):hover {
  background: white !important;
}

.table-users-reports tbody > tr:hover > td {
  background: white !important;
}

.table-users-reports
  .weekly-ckeckbox
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.table-users-reports input[type='text']:disabled {
  background: white !important;
}

.table-users-project .inp-table::placeholder,
.user-settings-company .inp-table::placeholder {
  color: #d1d1d1;
}
