@import '../../assets/styles/variables.scss';

.modification-requests-modal {
  font-size: 13px;
  .content {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .label-text {
    color: $gray-1;
  }

  .content-text {
    color: $white;
  }

  .px-10 {
    padding: 0px 10px;
  }

  .activity-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
  }

  .badge-dark-green {
    background-color: $dark-green;
    display: flex;
    height: 24px;
    padding: 4.5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
  }

  .text-yellow {
    color: $yellow;
  }

  .badge-dark-green-small {
    background-color: $dark-green;
    padding: 0px 5px;
    border-radius: 5px;
  }

  .dates-content {
    margin-top: 20px;
    display: flex;
    gap: 10px;

    .dates-row {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      gap: 20px;
    }

    .item-row {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }

    .dates-column {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .dates-column-reverse {
      display: flex;
      flex-direction: column-reverse;
      gap: 5px;
    }
  }

  .comment-content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .textarea {
      border: 1px solid $dark-green;
      outline: none;
      border-radius: 5px;
      background-color: $dark-green;
      color: $white;
      flex-shrink: 0;
      padding: 10px;
      height: 65px;
      resize: none;
      transition: border 0.15s linear;

      &:focus {
        border: 1px solid $primary;
      }

      &:hover {
        border: 1px solid $primary;
      }
    }
  }

  .responsible-task-content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .responsible-data-content {
    margin-top: 20px;
    display: flex;
    width: 100%;
    gap: 20px;

    .company-circle {
      border-radius: 50%;
      height: 16px;
      width: 16px;
      margin-top: 2px;
    }

    img {
      height: 16px;
      width: 16px;
      border-radius: 100%;
    }

    .responsible-data-row {
      display: flex;
      width: 100%;
      flex-direction: row;
      gap: 7px;
    }

    .item-row {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }

    .responsible-data-column-1 {
      display: flex;
      flex-direction: column;
      width: 90px;
      gap: 10px;
    }

    .responsible-data-column {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .content-text {
      width: 150px;
    }

    .ml-5 {
      margin-left: 10px;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;
    flex-direction: row;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 32px;
      border: 0;
      padding: 0 20px;
      border-radius: 5px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: $black;
      transition-duration: 0.3s;

      &.cancel {
        background-color: $cool-gray;

        &:hover {
          background-color: $gray;
        }
      }

      &.accept {
        background-color: $primary;

        &.disabled {
          background: $primary-50 !important;
        }

        &:hover {
          background-color: $dark-mint;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}
