.grid-general-company {
  display: grid;
  grid-template-columns: 12% 20% 20% 20%;
  margin-top: 10px;
  grid-gap: 15px;
}

.grid-general-company-extraColumns {
  grid-template-columns: 12% 20% 20% 20% 20%;
}

.img-general-company {
  height: 100px;
  width: 100px;
  border-radius: 50px;
}
.description-form {
  font-size: 12px;
}

@media (min-width: 1005px) and (max-width: 1400px) {
  .camera-general-company {
    position: relative;
    left: 60%;
    bottom: 18px;
    background-color: #2c3421;
    border-radius: 64px;
  }
}

@media (min-width: 1360px) and (max-width: 1435px) {
  .camera-general-company {
    position: relative;
    left: 60%;
    bottom: 16px;
    background-color: #2c3421;
    border-radius: 64px;
  }
}

@media (min-width: 1436px) and (max-width: 1840px) {
  .camera-general-company {
    position: relative;
    left: -17%;
    bottom: -50px;
    background-color: #2c3421;
    border-radius: 64px;
  }
}

@media (min-width: 1841px) and (max-width: 2150px) {
  .camera-general-company {
    position: relative;
    left: -14%;
    bottom: -43px;
    background-color: #2c3421;
    border-radius: 64px;
  }
}

@media (min-width: 2151px) and (max-width: 2621px) {
  .camera-general-company {
    position: relative;
    left: -10%;
    bottom: -43px;
    background-color: #2c3421;
    border-radius: 64px;
  }
}

.input-text-company {
  border: 1px solid var(--black) !important;
  height: 34px !important;
}

.company-title {
  color: #2c3421;
}

.img-upload-logo {
  position: relative;
  cursor: pointer;
  top: -28px;
  left: 130px;
}

#file-input-edit-image {
  display: none;
}

.cont-imag-edit {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column;
}

.img-company-edit {
  border-radius: 10px;
}
