.order-row-radio .ant-radio-button-wrapper-checked {
  background: #2c3421 !important;
  border: 1px solid #2c3421 !important;
}

.non-selected-order {
  color: #7d7979;
  position: relative;
  top: 6px;
  left: 4px;
  font-size: 13px !important;
  font-weight: 400 !important;
  font-family:
    Roboto,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 6px 0 0 6px !important;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 6px 6px 0 !important;
}
