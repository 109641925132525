.container {
  padding: 10px 25px;

  display: flex;
  justify-content: space-between;

  & li {
    display: flex;
  }
}
