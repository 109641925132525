.main-activity-route {
  position: absolute;
  top: 16px;
  left: 30px;
  font-weight: 100;
  font-size: 10px !important;
}

.custom-div-row-container {
  position: relative !important;
  left: 0px !important;
  top: -6px !important;
}

.milestone-name-custom {
  font-weight: 600;
  position: absolute;
  z-index: 100;
  margin-left: -19px;
  font-size: 12px;
}
.gantt_tree_icon.gantt_close,
.gantt_tree_icon.gantt_open {
  min-height: 18px;
  height: 100%;
}
